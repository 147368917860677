import styled from 'styled-components'

export const Grid = styled.div`
    display: grid;
    grid-template-columns: 80% 20%;
    grid-gap: 20px;
`

export const Title = styled.h2`
    margin-bottom: 30px;
`
export const FiltersWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`
