import { FeedbackDialog, FeedbackDialogCreateRequest } from 'interfaces/FeedbackDialog'
import ApiService from 'services/ApiService'

const FeedbackDialogService = {
    list(page: number, isActive?: boolean | null, search?: string | null) {
        return ApiService.get(
            `/feedback-dialogs?page=${page}${isActive !== null ? `&isActive=${isActive}` : ''}${
                search ? `&search=${search}` : ''
            }`
        ).then((response) => {
            return {
                dialogs: response.data.content as FeedbackDialog[],
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    get(uuid: string) {
        return ApiService.get(`/feedback-dialogs/${uuid}`).then((response) => {
            return response.data as FeedbackDialog
        })
    },

    create(data: FeedbackDialogCreateRequest) {
        return ApiService.post(`/feedback-dialogs`, data).then((response) => {
            return response.data as FeedbackDialog
        })
    },

    update(uuid: string, data: FeedbackDialogCreateRequest) {
        return ApiService.patch(`/feedback-dialogs/${uuid}`, data).then((response) => {
            return response.data as FeedbackDialog
        })
    },
}

export default FeedbackDialogService
