import ApiService from './ApiService'
import { Knower, KnowerVerificationStatus, PromoteKnower } from 'interfaces/Knower'

const KnowerService = {
    get(uuid: string) {
        return ApiService.get(`knowers/${uuid}`).then((response) => {
            return response.data as Knower
        })
    },

    list(page: number) {
        return ApiService.get(`knowers?page=${page}`).then((response) => {
            return {
                knower: response.data.content as Array<Knower>,
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    updateVerificationStatus(uuid: string, verificationStatus: KnowerVerificationStatus) {
        return ApiService.patch(`knowers/${uuid}/verification-status`, verificationStatus)
    },

    updatePromotedKnower(uuid: string, data: PromoteKnower) {
        return ApiService.patch(`knowers/${uuid}/promoted`, data).then((response) => {
            return response.data
        })
    },
}

export default KnowerService
