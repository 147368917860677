import React, { useEffect, ReactNode } from 'react'
import styled from 'styled-components'

import styles from './Modal.module.css'

interface Props {
    show: boolean
    title: string
    onClose(): void
    children?: ReactNode
    fullWidth?: boolean
}

const ModalContainer = styled.div<{ fullWidth?: boolean }>`
    max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '750px')};
    width: 95%;
    margin: 0px auto;
    border: 0.5px solid rgba(125, 125, 125, 0.15);
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
`

const Modal = (props: Props) => {
    useEffect(() => {
        props.show &&
            document.addEventListener('keydown', (event) => {
                const escape = 27

                if (event.keyCode === escape) {
                    props.onClose()
                }
            })
    }, [props])

    const handleClick = () => props.onClose()

    if (!props.show) return null

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <ModalContainer fullWidth={!!props.fullWidth}>
                    <div className={styles.header}>
                        <span>{props.title}</span>
                        <span className={styles.close} onClick={handleClick}>
                            X
                        </span>
                    </div>

                    <div className={styles.body}>{props.children}</div>
                </ModalContainer>
            </div>
        </div>
    )
}

export default Modal
