import { State, ActionTypes, SET_OAUTH_TOKEN, SET_USER, SET_REVIEW_SETTINGS, SET_REPORT_SETTINGS } from './types'

export enum LocalStorageKey {
    User = 'USER',
    OauthToken = 'OAUTH_TOKEN',
    ReviewSettings = 'REVIEW_SETTINGS',
    ReportSettings = 'REPORT_SETTINGS',
}

const initialState: State = {
    oauthToken: JSON.parse(localStorage.getItem(LocalStorageKey.OauthToken)!) || null,
    user: JSON.parse(localStorage.getItem(LocalStorageKey.User)!) || null,
    reviewSettings: JSON.parse(localStorage.getItem(LocalStorageKey.ReviewSettings)!) || null,
    reportSettings: JSON.parse(localStorage.getItem(LocalStorageKey.ReportSettings)!) || null,
}

const reducer = (state = initialState, action: ActionTypes): State => {
    switch (action.type) {
        case SET_OAUTH_TOKEN:
            localStorage.setItem(LocalStorageKey.OauthToken, JSON.stringify(action.payload))
            return {
                ...state,
                oauthToken: action.payload,
            }

        case SET_USER:
            localStorage.setItem(LocalStorageKey.User, JSON.stringify(action.payload))
            return {
                ...state,
                user: action.payload,
            }

        case SET_REVIEW_SETTINGS:
            localStorage.setItem(LocalStorageKey.ReviewSettings, JSON.stringify(action.payload))
            return {
                ...state,
                reviewSettings: action.payload,
            }

        case SET_REPORT_SETTINGS:
            localStorage.setItem(LocalStorageKey.ReportSettings, JSON.stringify(action.payload))
            return {
                ...state,
                reportSettings: action.payload,
            }

        default:
            return state
    }
}

export type AppState = ReturnType<typeof reducer>
export default reducer
