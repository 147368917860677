import { ApiError, GlobalAuthApiService, TokenApiService } from './ApiService'
import { setOauthToken, setUser } from 'redux/actions'
import store from 'redux/store'
import UserService from 'services/UserService'
import { history } from 'App'
import { AuthToken } from 'interfaces/AuthToken'

const HCaptchaTokenHeader = 'X-HCaptcha'

const getAuthHeaders = (hCaptchaToken: string | null) => {
    const headers: Record<string, string> = {}
    if (hCaptchaToken) {
        headers[HCaptchaTokenHeader] = hCaptchaToken
    }
    return headers
}

const AuthService = {
    signInGoogle(idToken: string, hCaptchaToken: string) {
        return GlobalAuthApiService.post(
            'auth/signin/google',
            { idToken, hasAdminAccess: true },
            {
                headers: getAuthHeaders(hCaptchaToken),
            }
        ).then((response) => {
            const data = response.data as AuthToken
            store.dispatch(setOauthToken(data))
            return UserService.getMe().then((user) => {
                return data
            })
        })
    },

    loginWithRefreshToken(refreshToken: string) {
        return TokenApiService.post('oauth/token', {
            refreshToken,
        }).then((response) => {
            const data = response.data as AuthToken
            store.dispatch(setOauthToken(data))
            return data
        })
    },

    refreshToken() {
        const token = store.getState().oauthToken
        if (!token) {
            return Promise.reject('User does not have a token')
        }
        return this.loginWithRefreshToken(token.refreshToken)
    },

    logout() {
        const token = store.getState().oauthToken
        return TokenApiService.post('oauth/logout', { refreshToken: token?.refreshToken })
            .catch((error: ApiError) => {
                error.handleUnknown('An error occurred while logging out.')
            })
            .finally(() => {
                store.dispatch(setOauthToken(null))
                store.dispatch(setUser(null))
                history.push('/')
            })
    },
}

export default AuthService
