import React, { useEffect, useRef, useState } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { badRequestFormErrors, formatUTCDateForRequest } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { useHistory } from 'react-router-dom'
import Button from 'components/elements/Button'
import { Input, FormSelect, SelectOptionWithLabel, SelectOption, Checkbox } from 'components/inputs/Input'
import routes from 'lib/constants/routes'
import { LanguageInterface } from 'interfaces/Language'
import { ManualEmailRequest, ManualEmail, EmailCategories } from 'interfaces/ManualEmails'
import ManualEmailsService from 'services/ManualEmailsService'
import { useUserTargetingForm } from 'utils/hooks/useUserTargetingForm'
import LanguageService from 'services/LanguageService'
import ModalSelect from 'components/inputs/ModalSelect'
import { returnCategoryDescription, validateEmail } from 'utils/email'

type FormData = {
    language: SelectOption<LanguageInterface>
    subject: string
    type: string
    category: SelectOption<EmailCategories>
    fromName: string | null
    fromEmail: string | null
    replyTo: string | null
    ignoreEmailConfirmation: boolean
    scheduledOn: string
    experimentVariantIdentifier: string | null
}

interface Props {
    manualEmail: ManualEmail | null
    isNew: boolean
    updateManualEmail: (email: ManualEmail) => void
}

const loadingAreas = {
    form: 'manualEmailForm',
    save: 'saveManualEmail',
}

const ManualEmailForm = ({ isNew, manualEmail, updateManualEmail }: Props) => {
    const { userTargetingId, renderTargetingModal, renderTargetingButton } = useUserTargetingForm(
        manualEmail?.userTargetingId,
        isNew
    )
    const [initialData, setInitialData] = useState<FormData>()
    const [languageOptions, setLanguageOptions] = useState<SelectOptionWithLabel<LanguageInterface>[]>()
    const formRef = useRef<FormHandles>(null)
    const history = useHistory()

    useEffect(() => {
        trackPromise(
            LanguageService.list(true)
                .then((languages) => {
                    const options = languages.map((lang) => ({
                        label: lang.englishName,
                        identifier: lang.id.toString(),
                        data: lang,
                    }))
                    setLanguageOptions(options)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting languages.')
                }),
            loadingAreas.form
        )
    }, [])

    const categoriesOptions = Object.values(EmailCategories).map((category) => ({
        label: category,
        identifier: category,
        data: category,
        description: returnCategoryDescription(category),
    }))

    useEffect(() => {
        if (!manualEmail) return

        const initialData: FormData = {
            language: {
                identifier: manualEmail.language.id.toString(),
                data: manualEmail.language,
            },
            subject: manualEmail.subject,
            type: manualEmail.type,
            category: {
                data: manualEmail.category,
                identifier: manualEmail.category,
            },
            fromName: manualEmail.fromName,
            fromEmail: manualEmail.fromEmail,
            replyTo: manualEmail.replyTo,
            ignoreEmailConfirmation: manualEmail.ignoreEmailConfirmation || false,
            scheduledOn: manualEmail?.scheduledOn ? manualEmail.scheduledOn.slice(0, -1) : '',
            experimentVariantIdentifier: manualEmail.experimentVariantIdentifier,
        }
        setInitialData(initialData)
    }, [manualEmail, userTargetingId])

    const handleSubmit: SubmitHandler<FormData> = (data) => {
        formRef.current!.setErrors({})

        if (!userTargetingId) {
            toast.error('You need to add User Targeting!')
            return
        }

        if (data.fromEmail && !validateEmail(data.fromEmail)) {
            formRef.current!.setFieldError('fromEmail', 'Please enter a valid email address.')
            return
        }

        if (data.scheduledOn && data.scheduledOn?.length > 0 && new Date() > new Date(data.scheduledOn)) {
            formRef.current!.setFieldError('scheduledOn', 'Schedule date has to be in the future.')
            return
        }

        let requestBody: ManualEmailRequest = {
            languageId: data.language.data.id,
            userTargetingId: userTargetingId,
            // Create Endpoint doesn't allow empty string
            bodyDesign: manualEmail?.bodyDesign ?? 'empty',
            bodyHtml: manualEmail?.bodyHtml ?? 'empty',
            category: data.category.data,
            fromEmail: data.fromEmail || null,
            fromName: data.fromName || null,
            replyTo: data.replyTo || null,
            subject: data.subject,
            type: data.type,
            experimentVariantIdentifier: data.experimentVariantIdentifier || null,
            ignoreEmailConfirmation: data.ignoreEmailConfirmation,
        }

        if (data.scheduledOn) {
            requestBody = {
                ...requestBody,
                scheduledOn: formatUTCDateForRequest(data.scheduledOn),
            }
        }

        let handleFunc
        if (isNew) {
            handleFunc = ManualEmailsService.create(requestBody)
        } else {
            handleFunc = ManualEmailsService.update(manualEmail!.uuid, requestBody)
        }

        trackPromise(
            handleFunc
                .then((email) => {
                    updateManualEmail(email)
                    toast.success('The changes to Manual Email have been saved!')
                    history.push(routes.manualEmailRoute(email.uuid))
                })
                .catch((error: ApiError) => {
                    if (error.type === ErrorTypes.FormValidation) {
                        badRequestFormErrors(error, formRef.current!)
                    } else {
                        error.handleUnknown('An error occurred while saving manual email.')
                    }
                }),
            loadingAreas.save
        )
    }

    return (
        <div>
            <h3>{isNew ? 'Create new' : 'Update'} Manual Emails</h3>
            {isNew ? (
                <p>
                    {`The email body can be changed after creating the email in the "Update" Tab, but first fill the form below.`}
                </p>
            ) : null}
            {renderTargetingModal()}
            <Form ref={formRef} initialData={initialData} onSubmit={handleSubmit}>
                <Input name="subject" placeholder="Subject" />
                <Input name="type" placeholder="Type" tooltip="Used for tracking" />
                <Input name="fromName" placeholder="From Name (optional)" />
                <Input name="fromEmail" placeholder="From Email (optional)" />
                <Input name="replyTo" placeholder="Reply to (optional)" />
                <Input name="scheduledOn" placeholder="Scheduled on in UTC time (Optional)" type="datetime-local" />
                {categoriesOptions && <ModalSelect name="category" options={categoriesOptions} label="Category" />}
                {renderTargetingButton()}
                {languageOptions && <FormSelect name="language" options={languageOptions} placeholder="Language" />}
                <Input
                    name="experimentVariantIdentifier"
                    placeholder="Experiment Variant Identifier (Optional)"
                    type="text"
                />
                <Checkbox
                    name="ignoreEmailConfirmation"
                    placeholder="Ignore email confirmation (for legal reasons, this should only be used in the US)"
                />
                <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                    {isNew ? 'Save' : 'Update'}
                </Button>
            </Form>
        </div>
    )
}

export default ManualEmailForm
