import React, { useCallback, useEffect, useState } from 'react'
import Table, { TableData } from 'components/modules/Table'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import Stack from 'components/elements/Stack'
import routes from 'lib/constants/routes'
import SchoolTypeService from 'services/SchoolTypeService'
import Meta from 'components/modules/Head'

interface Props {}

const loadingAreas = {
    container: 'schoolTypeContainer',
}

const SchoolTypes = (props: Props) => {
    const [data, setTableData] = useState<TableData[]>([])

    const fetchData = useCallback(async () => {
        trackPromise(
            SchoolTypeService.getAll()
                .then((schoolTypes) => {
                    const tableData = schoolTypes.map((types) => ({
                        id: types.id,
                        columns: [
                            types.id,
                            types.name,
                            types.regions.map((region) => region.code).join(', '),
                            types.language.englishName,
                            types.country.englishName,
                        ],
                        onClick: routes.schoolTypeRoute(types.id),
                    }))

                    setTableData(tableData)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting school types.')
                }),
            loadingAreas.container
        )
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <section>
            <Meta title="School Types" />
            <Stack gutter={10}>
                <h2>School Types</h2>
                <Table
                    columns={['#', 'Name', 'Regions', 'Language', 'Country']}
                    data={data}
                    page={0}
                    totalPages={1}
                    totalElements={1}
                    setPage={() => null}
                    area={loadingAreas.container}
                />
            </Stack>
        </section>
    )
}

export default SchoolTypes
