import ApiService from 'services/ApiService'
import { SeoSubTopic, UpdateSeoSubTopic } from 'interfaces/SeoSubTopic'

const SeoSubTopicService = {
    list(uuid: string) {
        return ApiService.get(`seo/topics/${uuid}/subtopics`).then((response) => {
            return response.data.content as SeoSubTopic[]
        })
    },

    get(uuid: string) {
        return ApiService.get(`/seo/subtopics/${uuid}`).then((response) => {
            return response.data as SeoSubTopic
        })
    },

    create(seoSubject: UpdateSeoSubTopic) {
        return ApiService.post('/seo/subtopics', seoSubject).then((response) => {
            return response.data as SeoSubTopic
        })
    },

    update(uuid: string, seoSubject: UpdateSeoSubTopic) {
        return ApiService.patch(`/seo/subtopics/${uuid}`, seoSubject).then((response) => {
            return response.data as SeoSubTopic
        })
    },
}

export default SeoSubTopicService
