import React, { useEffect, useState } from 'react'
import CSVImporter, { CSVData } from 'components/FileUpload/CSVImport'
import { toast } from 'react-toastify'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import { NoWrapText } from 'style'
import Table, { TableData } from 'components/modules/Table'
import { ManualCommunicationCSV, ManualCommunicationPlaceholderRequest } from 'interfaces/ManualCommunicationPlaceholder'
import ManualCommunicationPlaceholderService from 'services/ManualCommunicationPlaceholderService'
import { ApiError } from 'services/ApiService'

interface Props {
    manualEmailUuid?: string
    manualPushNotificationUuid?: string
}

const ManualCommunicationPlaceholder = ({ manualEmailUuid, manualPushNotificationUuid }: Props) => {
    const [communicationPlaceholder, setCommunicationPlaceholder] = useState<ManualCommunicationCSV[]>([])
    const [tableData, setTableData] = useState<TableData[]>()

    const handleCSVUploadComplete = async (csvData: CSVData[]) => {
        setCommunicationPlaceholder(csvData as ManualCommunicationCSV[])

        const progressText = document.getElementById('title')

        const text = `Imported ${csvData.length} communication placeholder rows.`
        if (progressText) progressText.textContent = text
        toast.info(text)

        const communicationRequest: ManualCommunicationPlaceholderRequest[] = (csvData as ManualCommunicationCSV[]).map(
            ({ user_uuid, ...rest }) => ({
                manualEmailUuid: manualEmailUuid ?? null,
                manualPushNotificationUuid: manualPushNotificationUuid ?? null,
                placeholders: rest,
                userUuid: user_uuid,
            })
        )

        ManualCommunicationPlaceholderService.create(communicationRequest)
            .then(() => toast.success('Communication placeholder was succesfully uploaded!'))
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('An error occurred while creating communication placeholder.')
                } else {
                    throw error
                }
            })
    }

    useEffect(() => {
        if (!communicationPlaceholder?.length) return

        const table = communicationPlaceholder.map(({ user_uuid, ...rest }, id) => ({
            id,
            columns: [<NoWrapText key={`placeholder-${id}`}>{user_uuid}</NoWrapText>, ...Object.values(rest)],
        }))
        setTableData(table)
    }, [communicationPlaceholder, setTableData])

    return (
        <section>
            <Stack>
                <Box>
                    <strong>Requirements:</strong>
                    <ul>
                        <li>
                            {`First row of the CSV file must contain correct user_uuid as the first column followed by parameters values`}
                        </li>
                    </ul>
                    <br />
                    <strong>Example document:</strong>&nbsp;
                    <a href="/files/CommunicationPlaceholder.csv" download="example_communication_placeholder.csv">
                        Download
                    </a>
                    <br />
                    <CSVImporter title="" handleCSVUploadComplete={handleCSVUploadComplete} showHeader />
                    {tableData?.length ? (
                        <Table
                            columns={Object.keys(Object.values(communicationPlaceholder)[0])}
                            data={tableData}
                            page={0}
                            totalPages={1}
                            totalElements={tableData.length}
                        />
                    ) : null}
                </Box>
            </Stack>
        </section>
    )
}

export default ManualCommunicationPlaceholder
