import React from 'react'
import Button from 'components/elements/Button'
import { ButtonText } from 'components/elements/Text'
import Modal from 'components/modules/Modal'
import theme from 'lib/constants/theme'
import { useToggleState } from 'utils/hooks/useToggleState'
import parse from 'html-react-parser'
import styled from 'styled-components'

const Container = styled.div`
    * {
        text-align: unset;
    }
`

interface Props {
    htmlContent: string
    showDisplay?: boolean
}

const HtmlContentPreview = ({ htmlContent, showDisplay }: Props) => {
    const [showHtmlContentModal, toggleHtmlContentModal] = useToggleState(false)

    return (
        <Container>
            {showDisplay ? (
                <>{parse(htmlContent)}</>
            ) : (
                <>
                    <Button onClick={toggleHtmlContentModal} fullWidth={false}>
                        <ButtonText color={theme.colors.white}>Preview HTML Content</ButtonText>
                    </Button>
                    <Modal show={showHtmlContentModal} onClose={toggleHtmlContentModal} title="HTML Content Preview">
                        {parse(htmlContent)}
                    </Modal>
                </>
            )}
        </Container>
    )
}

export default HtmlContentPreview
