import theme from 'lib/constants/theme'
import React from 'react'
import styled from 'styled-components'
import { ButtonText } from './Text'

const Progress = styled.div<{ value: number; isSmall?: boolean }>`
    padding: 20px 0;
    width: ${({ isSmall }) => (isSmall ? 300 : 450)}px;
    text-align: center;
    position: relative;
    .progress-values {
        display: flex;
        justify-content: space-between;
        padding-bottom: 7.5px;
    }
    .progress {
        padding: 6px;
        background: rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
        overflow: hidden;
    }

    .progress-bar {
        height: 18px;
        border-radius: 4px;
        transition: 0.4s linear;
        transition-property: width, background-color;
    }

    .progress-striped .progress-bar {
        background-color: ${theme.colors.knowunityBlueDark};
        width: 100%;
        background-image: linear-gradient(
            45deg,
            rgb(101, 160, 241) 25%,
            transparent 25%,
            transparent 50%,
            rgb(101, 160, 241) 50%,
            rgb(101, 160, 241) 75%,
            transparent 75%,
            transparent
        );
        animation: progressAnimationStrike 6s forwards;
    }

    @keyframes progressAnimationStrike {
        from {
            width: 0;
        }
        to {
            width: ${({ value }) => value}%;
        }
    }
`

interface Props {
    max: number
    currentValue: number
    isSmall?: boolean
}

const ProgressBar = ({ currentValue, max, isSmall }: Props) => {
    return (
        <div>
            <Progress value={Math.floor((100 * currentValue) / max)} isSmall={isSmall}>
                <div className="progress-values">
                    <ButtonText color={theme.colors.white}>
                        {!isSmall ? `Current count: ` : 'Current: '}
                        {currentValue}
                    </ButtonText>
                    <ButtonText color={theme.colors.white}>
                        {!isSmall ? `Expected count: ` : 'Expected: '}
                        {max}
                    </ButtonText>
                </div>
                <div className="progress progress-striped">
                    <div className="progress-bar"></div>
                </div>
            </Progress>
        </div>
    )
}

export default ProgressBar
