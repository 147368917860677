import { SET_OAUTH_TOKEN, ActionTypes, SET_USER, SET_REVIEW_SETTINGS, SET_REPORT_SETTINGS } from './types'
import { AuthToken } from 'interfaces/AuthToken'
import { User } from 'interfaces/User'
import { ReviewSettings } from 'interfaces/ReviewSettings'
import { ReportSettings } from 'interfaces/ReportSettings'

export const setOauthToken = (token: AuthToken | null): ActionTypes => ({
    type: SET_OAUTH_TOKEN,
    payload: token,
})

export const setUser = (user: User | null): ActionTypes => ({
    type: SET_USER,
    payload: user,
})

export const setReviewSettings = (reviewSettings: ReviewSettings | null): ActionTypes => ({
    type: SET_REVIEW_SETTINGS,
    payload: reviewSettings,
})

export const setReportSettings = (reportSettings: ReportSettings | null): ActionTypes => ({
    type: SET_REPORT_SETTINGS,
    payload: reportSettings,
})
