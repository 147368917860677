import React, { useState } from 'react'
import { ButtonLink } from 'components/elements/Button'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import { useCallback } from 'react'
import RefactoredTable from 'components/modules/RefactoredTable'
import routes from 'lib/constants/routes'
import Stack from 'components/elements/Stack'
import { ControlWrapper, FilterFormGrid } from 'style'
import ManualEmailsService from 'services/ManualEmailsService'
import { formatDateTime, formatText } from 'utils'
import SelectElement from 'components/inputs/SelectElement'
import Meta from 'components/modules/Head'
import { formatManualCommunicationProgress } from 'utils/manualCommunication'

const loadingAreas = {
    container: 'manualEmailsContainer',
}

const ManualEmails = () => {
    const [isSent, setIsSent] = useState<boolean | null>(null)
    const [isAuthor, setIsAuthor] = useState<boolean>(false)

    const handleUpdateSentFilter = (isSent: boolean | null) => setIsSent(isSent)
    const handleUpdateAuthorFilter = (isAuthor: boolean) => setIsAuthor(isAuthor)

    const sendOptions = [
        {
            label: 'All',
            data: null,
        },
        {
            label: 'Yes',
            data: true,
        },
        {
            label: 'No',
            data: false,
        },
    ]

    const authorOptions = [
        {
            label: 'All',
            data: false,
        },
        {
            label: 'Me',
            data: true,
        },
    ]

    const fetchData = useCallback(
        (page: number) => {
            return trackPromise(
                ManualEmailsService.list(page, isAuthor, isSent)
                    .then((data) => {
                        if (page !== data.page) return

                        return {
                            totalPages: data.totalPages,
                            totalElements: data.totalElements,

                            elements: data.emails.map((e) => ({
                                id: e.uuid,
                                columns: [
                                    e.uuid,
                                    formatText(e.subject, 30),
                                    e.type,
                                    e.user.username,
                                    e?.sentOn ? formatDateTime(e.sentOn) : 'Not sent yet',
                                    <div key={`progress-${e.uuid}`}>
                                        {formatManualCommunicationProgress(e.expectedUsersCount, e.actualUsersCount)}
                                    </div>,
                                ],
                                onClick: routes.manualEmailRoute(e.uuid),
                            })),
                        }
                    })
                    .catch((error: ApiError) => {
                        error.handleUnknown('An error occurred while getting manual emails.')
                    }),
                loadingAreas.container
            )
        },
        [isAuthor, isSent]
    )

    return (
        <section>
            <Meta title="Manual Emails" />
            <Stack gutter={5}>
                <h2>Manual Emails</h2>
                <FilterFormGrid gridAmount={3}>
                    <ControlWrapper>
                        <ButtonLink to={routes.manualEmailCreateRoute} fullWidth={false} icon={<AddIcon />}>
                            Add Manual Email
                        </ButtonLink>
                    </ControlWrapper>
                    <SelectElement
                        label="Filter by author"
                        noMargin
                        options={authorOptions}
                        onUpdate={handleUpdateAuthorFilter}
                    />
                    <SelectElement
                        label="Filter by send status"
                        noMargin
                        options={sendOptions}
                        onUpdate={handleUpdateSentFilter}
                    />
                </FilterFormGrid>
                <RefactoredTable
                    columns={['#', 'Subject', 'Tracking Type', 'Creator', 'Sent on', 'Progress']}
                    loadData={fetchData}
                    loadingArea={loadingAreas.container}
                    refetchKey={`${isAuthor}_${isSent}`}
                />
            </Stack>
        </section>
    )
}

export default ManualEmails
