import { FormGroup } from 'components/inputs/Input'
import React, { useEffect, useState } from 'react'

interface Props {
    defaultValue?: number | null
    onInput(value: number): void
    max?: number
    min?: number
}

const NumberInput = ({ defaultValue, onInput, max, min }: Props) => {
    const [value, setValue] = useState<number | null>(defaultValue ?? null)

    const handleChange = (value: number) => {
        setValue(value)
    }

    useEffect(() => {
        defaultValue && setValue(defaultValue)
    }, [defaultValue])

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleChange(+event.target.value)
        onInput(+event.target.value)
    }

    return (
        <FormGroup noMargin>
            <input onInput={handleInput} value={value?.toString()} type="number" min={min} max={max} />
        </FormGroup>
    )
}

export default NumberInput
