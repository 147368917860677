import { TopicCellsRow, TopicActionRequest, Taxonomy } from 'interfaces/Taxonomy'
import ApiService from './ApiService'

const TaxonomyService = {
    listBySubject(subjectId: number) {
        return ApiService.get(`/taxonomy/topics/admin?subjectId=${subjectId}`).then((response) => {
            return response.data.content as TopicCellsRow[]
        })
    },

    batchUpdateTopics(topics: TopicActionRequest[]) {
        return ApiService.post(`/taxonomy/topics/batch`, { topics }).then((response) => {
            return response.data
        })
    },

    import(topics: Taxonomy[]) {
        return ApiService.post(`/taxonomy/import`, { topics }).then((response) => {
            return response.data
        })
    },
}

export default TaxonomyService
