import React, { useCallback, useEffect, useState } from 'react'
import LoadingArea from 'components/elements/LoadingArea'
import { Link, RouteComponentProps } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import { DiscussionForumQuestion } from 'interfaces/DiscussionForumQuestion'
import DiscussionForumQuestionService from 'services/DiscussionForumQuestionService'
import ReportContentPreview from 'components/modules/ContentPreview/ReportContentPreview'
import { AttachmentWrapper } from 'pages/Reports/Reports.css'
import { DiscussionForumAnswer } from 'interfaces/DiscussionForumAnswer'
import { QuestionAnswerWrapper } from './DiscussionForumQuestions.css'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import routes from 'lib/constants/routes'
import Meta from 'components/modules/Head'

interface Props extends RouteComponentProps<{ uuid: string }> {}

const loadingAreas = {
    question: 'questionContainer',
    answers: 'answersContainer',
}

const DiscussionForumQuestionComponent = (props: Props) => {
    const [reportedQuestion, setReportedQuestion] = useState<DiscussionForumQuestion>()
    const [questionAnswers, setQuestionsAnswers] = useState<DiscussionForumAnswer[]>()
    const uuid = props.match.params.uuid

    const fetchData = useCallback(() => {
        if (uuid) {
            trackPromise(
                DiscussionForumQuestionService.get(uuid)
                    .then((forumQuestion) => {
                        setReportedQuestion(forumQuestion)
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while getting discussion forum question.')
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.question
            )
            trackPromise(
                DiscussionForumQuestionService.getAnswers(uuid)
                    .then((forumQuestionAnswers) => {
                        setQuestionsAnswers(forumQuestionAnswers)
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while getting discussion forum question answers.')
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.answers
            )
        }
    }, [uuid])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <section>
            <Meta title="Discussion Forum Question" />
            <h2>Discussion Forum Question</h2>
            <Box>
                <Stack>
                    <h3>Information</h3>
                    <LoadingArea area={loadingAreas.question}>
                        {reportedQuestion && (
                            <>
                                <p>
                                    <strong>Title:</strong>&nbsp;
                                    {reportedQuestion.title}
                                </p>
                                <p>
                                    <strong>Text:</strong>&nbsp;
                                    {reportedQuestion.text}
                                </p>
                                <p>
                                    <strong>User: </strong>&nbsp;
                                    <Link to={routes.userRoute(reportedQuestion.user.uuid)}>
                                        {reportedQuestion.user.name}
                                    </Link>
                                </p>
                                <p>
                                    <strong>Subject:</strong>&nbsp;
                                    {reportedQuestion.subject.name}
                                </p>
                                <p>
                                    <strong>Answers count:</strong>&nbsp;
                                    {reportedQuestion.answersCount}
                                </p>
                                <p>
                                    <strong>Attached Know: </strong>&nbsp;
                                    {reportedQuestion.know ? (
                                        <Link to={routes.knowRoute(reportedQuestion.know.uuid)}>
                                            {reportedQuestion.know.title}
                                        </Link>
                                    ) : (
                                        'None'
                                    )}
                                </p>
                                <div>
                                    <h3>Attachments</h3>
                                    {reportedQuestion.attachments && (
                                        <AttachmentWrapper>
                                            {reportedQuestion.attachments.map((attachment, index) => (
                                                <ReportContentPreview
                                                    key={index}
                                                    baseUuid={reportedQuestion.uuid}
                                                    attachment={attachment}
                                                    type="QUESTION"
                                                />
                                            ))}
                                        </AttachmentWrapper>
                                    )}
                                </div>
                            </>
                        )}
                    </LoadingArea>
                </Stack>
            </Box>
            {questionAnswers?.length ? (
                <Box>
                    <Stack>
                        <h3>Discussion Forum Question Answers</h3>
                        <LoadingArea area={loadingAreas.answers}>
                            {questionAnswers.map((answer) => (
                                <QuestionAnswerWrapper key={`answer-${answer.uuid}`}>
                                    <p>
                                        <strong>Text: </strong>
                                        {answer.text}
                                        &nbsp;
                                    </p>
                                    {answer.isAcceptedAnswer && (
                                        <p>
                                            <strong>Is accepted: </strong>&nbsp;
                                            <p>Yes</p>
                                            &nbsp;
                                        </p>
                                    )}
                                    <p>
                                        <strong>User: </strong>&nbsp;
                                        <Link to={routes.userRoute(answer.user.uuid)}>{answer.user?.name ?? ''}</Link>
                                    </p>
                                    <p>
                                        <strong>Attached Know: </strong>&nbsp;
                                        {answer.know ? (
                                            <Link to={routes.knowRoute(answer.know.uuid)}>{answer.know.title}</Link>
                                        ) : (
                                            'None'
                                        )}
                                    </p>
                                    {!!answer.attachments.length && (
                                        <div>
                                            <h3>Attachments</h3>
                                            <AttachmentWrapper>
                                                {answer.attachments.map((attachment, index) => (
                                                    <ReportContentPreview
                                                        key={index}
                                                        baseUuid={answer!.uuid}
                                                        attachment={attachment}
                                                        type="ANSWER"
                                                    />
                                                ))}
                                            </AttachmentWrapper>
                                        </div>
                                    )}
                                </QuestionAnswerWrapper>
                            ))}
                        </LoadingArea>
                    </Stack>
                </Box>
            ) : null}
        </section>
    )
}

export default DiscussionForumQuestionComponent
