/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import Stack from 'components/elements/Stack'
import { Body2 } from 'components/elements/Text'
import FormSelect from 'components/inputs/FormSelect'
import { Grade } from 'interfaces/Grade'
import { SchoolType } from 'interfaces/SchoolType'
import theme from 'lib/constants/theme'
import { useEffect, useRef, useState } from 'react'
import { ApiError } from 'services/ApiService'
import GradeService from 'services/GradeService'
import styled from 'styled-components'
import { uniqueBy } from 'utils'

const GradesGrid = styled.div<{ itemsPerCol: number }>`
    display: grid;
    grid-template-columns: ${({ itemsPerCol }) => `repeat(${itemsPerCol}, 1fr)`};
    gap: 10px;
    @media (max-width: 1050px) {
        grid-template-columns: ${({ itemsPerCol }) => `repeat(${itemsPerCol - 1}, 1fr)`};
    }
`

const GradeItem = styled.div<{ isSelected: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: ${theme.borderRadius.normal};
    background-color: ${({ isSelected }) => (isSelected ? theme.colors.knowunityBlue : theme.colors.captionGray)};
    height: 48px;
    min-width: 48px;
    p {
        color: ${({ isSelected }) => (isSelected ? theme.colors.white : theme.colors.subtitleBlack)};
        text-align: center;
    }
`

interface Props {
    toggleGrade: (grade: Grade) => void
    selectedSchoolTypes: SchoolType[] | undefined
    selectedGrades: Grade[]
    limitToOneSelectedItem?: boolean
}

const GradeSelectionGrades = ({ selectedSchoolTypes, selectedGrades, toggleGrade, limitToOneSelectedItem }: Props) => {
    const [grades, setGrades] = useState<Grade[]>([])

    const limitedFormRef = useRef<FormHandles>(null)

    const selectedGradeIds = selectedGrades.sort((a, b) => a.level - b.level).map((g) => g.id)

    useEffect(() => {
        async function fetchGradesBySchoolType(schoolType: SchoolType[]) {
            const promises: Promise<void>[] = []
            let gradesOptions: Grade[] = []

            schoolType.forEach((school) => {
                const promise = GradeService.listGradesBySchoolType(school.id)
                    .then((grades) => {
                        gradesOptions = [...gradesOptions, ...grades]
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while fetching school types.')
                        } else {
                            throw error
                        }
                    })

                promises.push(promise)
            })
            Promise.all(promises).then(() => {
                setGrades(uniqueBy('id', gradesOptions).sort((a, b) => a.id - b.id))
            })
        }

        if (selectedSchoolTypes && selectedSchoolTypes?.length > 0) {
            fetchGradesBySchoolType(selectedSchoolTypes)
        } else {
            setGrades([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSchoolTypes])

    if (!grades) return null

    const generateGradeOption = (grades: Grade[]) => [
        { label: 'No filter', identifier: 'no-filter', data: null },
        ...grades.map((grade) => ({
            label: grade.longName,
            identifier: grade.id.toString(),
            data: grade,
        })),
    ]

    const intialLimitedGradeData = {
        label: selectedGrades[0]?.longName,
        data: selectedGrades[0],
    }

    return (
        <Stack>
            <GradesGrid itemsPerCol={limitToOneSelectedItem ? 1 : 4}>
                {limitToOneSelectedItem ? (
                    <Form ref={limitedFormRef} onSubmit={() => {}} initialData={{ grades: intialLimitedGradeData }}>
                        <FormSelect
                            name="grades"
                            options={generateGradeOption(grades)}
                            placeholder=""
                            onUpdate={toggleGrade}
                            noMargin={false}
                        />
                    </Form>
                ) : (
                    grades.map((grade) => (
                        <GradeItem
                            key={grade.id}
                            onClick={() => toggleGrade(grade)}
                            isSelected={selectedGradeIds.includes(grade.id)}
                        >
                            <Body2>{grade.longName}</Body2>
                        </GradeItem>
                    ))
                )}
            </GradesGrid>
        </Stack>
    )
}

export default GradeSelectionGrades
