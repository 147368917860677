import { ChatMessage, SurroundingChatMessages, Chat } from 'interfaces/Chat'
import { ReportItem } from 'interfaces/Report'
import ApiService from 'services/ApiService'

const ChatService = {
    get(uuid: string) {
        return ApiService.get(`chats/${uuid}`).then((response) => {
            return response.data as Chat
        })
    },

    getMessages(uuid: string) {
        return ApiService.get(`chats/${uuid}/messages`).then((response) => {
            return response.data.content as ChatMessage[]
        })
    },

    getSurroundingMessages(chatUUID: string, messageUUID: string) {
        return ApiService.get(`chats/${chatUUID}/messages/${messageUUID}/surrounding`).then((response) => {
            return response.data.content as SurroundingChatMessages[]
        })
    },

    getReportItems(uuid: string, page?: number) {
        return ApiService.get(`chats/${uuid}/messages/reports/items${page ? `?page=${page}` : ''}`).then((response) => {
            return {
                reports: response.data.content as ReportItem[],
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },
}

export default ChatService
