import ApiService, { GlobalAuthApiService } from 'services/ApiService'
import {
    NewsletterUserSettings,
    NewUser,
    User,
    UserAdminNotes,
    UserModerationHistory,
    UserSocialRequest,
    UserVerificationStatus,
} from 'interfaces/User'
import { setUser } from 'redux/actions'
import store from 'redux/store'
import { CountryCode } from 'interfaces/Country'
import { BanStatus, CreateBanOptions, RemoveBanOptions, ResetProfileOptions } from 'interfaces/UserBan'

const UserService = {
    changeDataCenter(accountId: string, countryCode: CountryCode) {
        return GlobalAuthApiService.patch(`accounts/${accountId}/country`, { countryCode }).then((response) => {
            store.dispatch(setUser(response.data))
            return response.data as User
        })
    },

    getMe() {
        return ApiService.get('users/me').then((response) => {
            store.dispatch(setUser(response.data))
            return response.data as User
        })
    },

    get(uuid: string) {
        return ApiService.get(`users/${uuid}/admin`).then((response) => {
            return response.data as User
        })
    },

    getByUsername(username: string) {
        return ApiService.get(`users/usernames/${username}`).then((response) => {
            return response.data as User
        })
    },

    list(page: number, search?: string | null, email?: string | null) {
        return ApiService.get(
            `users?page=${page}${search ? `&search=${search}` : ''}${email ? `&email=${email}` : ''}`
        ).then((response) => {
            return {
                users: response.data.content as Array<User>,
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    delete(uuid: string) {
        return GlobalAuthApiService.delete(`accounts/${uuid}`).then((response) => {
            return response.data as User
        })
    },

    create(user: NewUser) {
        return ApiService.post(`users/admin`, user).then((response) => {
            return response.data as User
        })
    },

    resetParams(uuid: string, options: ResetProfileOptions) {
        return ApiService.post(`users/${uuid}/reset`, options).then((response) => {
            return response.data as User
        })
    },

    createBan(uuid: string, options: CreateBanOptions) {
        return ApiService.post(`users/${uuid}/ban`, options).then((response) => {
            return response.data as User
        })
    },

    removeBan(uuid: string, options: RemoveBanOptions) {
        return ApiService.delete(`users/${uuid}/ban`, { data: options }).then((response) => {
            return response.data as User
        })
    },

    getBanStatus(uuid: string) {
        return ApiService.get(`users/${uuid}/ban-status`).then((response) => {
            return response.data as BanStatus
        })
    },

    getAdminNotes(uuid: string) {
        return ApiService.get(`users/${uuid}/admin-notes`).then((response) => {
            return response.data.content as UserAdminNotes[]
        })
    },

    addAdminNotes(uuid: string, comment: string) {
        return ApiService.post(`users/${uuid}/admin-notes`, { comment }).then((response) => {
            return response.data.content as UserAdminNotes[]
        })
    },

    getUserNewsletterSettings(userUuid: string) {
        return ApiService.get(`/users/${userUuid}/newsletter-status`).then((response) => {
            return response.data as NewsletterUserSettings
        })
    },

    updateUserNewsletterSettings(userUuid: string, settings: NewsletterUserSettings) {
        return ApiService.patch(`/users/${userUuid}/newsletter-status`, settings).then((response) => {
            return response.data as NewsletterUserSettings
        })
    },

    updateVerificationStatus(userUuid: string, o: { isAmbassador: boolean; isCommunityModerator: boolean }) {
        return ApiService.patch(`users/${userUuid}/verification-status`, o)
    },

    getUserVerificationStatus(userUuid: string) {
        return ApiService.get(`/users/${userUuid}/verification-status`).then((response) => {
            return response.data as UserVerificationStatus
        })
    },

    addEngagementPoints(userUuid: string, body: { type: string; points: number }) {
        return ApiService.post(`/users/${userUuid}/engagement-points`, body)
    },

    addManualPenaltyPoints(userUuid: string, body: { penaltyPoints: number }) {
        return ApiService.patch(`/users/${userUuid}/penalty-points`, body)
    },

    getModerationHistory(userUuid: string) {
        return ApiService.get(`/users/${userUuid}/moderation-history`).then((response) => {
            return {
                moderationHistory: response.data.content as Array<UserModerationHistory>,
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    assignAsSubjectExpert(userUuid: string, subjectIds: number[]) {
        return ApiService.patch(`/users/${userUuid}/expert-subjects`, { subjectIds })
    },

    getSubscriptionInformation(userUuid: string) {
        return ApiService.get(`/users/${userUuid}/subscriptions`).then((response) => {
            return response.data as UserVerificationStatus
        })
    },

    connectSubscriptionCode(userUuid: string, subscriptionCode: string) {
        return ApiService.post(`/users/${userUuid}/subscriptions/${subscriptionCode}/connect`, { subscriptionCode })
    },

    getAssociatedUsers(userUuid: string) {
        return ApiService.get(`/users/${userUuid}/associated-users`).then((response) => {
            return response.data.content as User[]
        })
    },

    unlinkAssociatedUsers(userUuid: string, associatedUserUuid: string) {
        return ApiService.delete(`/users/${userUuid}/associated-users/${associatedUserUuid}`)
    },

    update(userUuid: string, userSocialData: UserSocialRequest): Promise<User> {
        return ApiService.patch(`users/${userUuid}`, userSocialData).then((response) => {
            return response.data as User
        })
    },

    removeSchoolFromProfile(userUuid: string) {
        return ApiService.patch(`users/${userUuid}`, { schoolUuid: null })
    },
}

export default UserService
