import React from 'react'
import Stack from 'components/elements/Stack'
import { Body1 } from 'components/elements/Text'
import { EmailCategories } from 'interfaces/ManualEmails'
import { toast } from 'react-toastify'

export const returnCategoryDescription = (category: EmailCategories) => {
    switch (category) {
        case EmailCategories.Feedback:
            return 'Send form to ask users about their feedback'
        case EmailCategories.Knower:
            return 'Information for knowers'
        case EmailCategories.Advertisement:
            return 'Advertisement from external partners (mostly for sales team)'
        case EmailCategories.FeatureAnnouncement:
            return 'Information about new features of Knowunity'
        default:
            return ''
    }
}

export function validateEmail(email: string): boolean {
    const emailWithoutWhitespace = email.replace(/\s/g, '')
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(emailWithoutWhitespace).toLowerCase())
}

export const handleClickRateHelpersCheck = (html: string) => {
    if (!html) return

    const linkTagsMatches = html.match(/<a[^>]*?>/g)
    if (!linkTagsMatches?.length) return
    const inproperLinks: string[] = []
    linkTagsMatches.forEach((match) => {
        const hrefs = match.match(/href="([^"]*)/)
        if (
            !hrefs?.length ||
            hrefs[1].startsWith('https://bit') ||
            hrefs[1].startsWith('mailto:') ||
            hrefs[1].startsWith('https://a.knowunity') ||
            hrefs[1].startsWith('{{unsubscribe')
        )
            return
        inproperLinks.push(hrefs[1])
    })

    if (!inproperLinks.length) return
    toast.warning(
        <Stack gutter={10}>
            <Body1>{`The following links:`}</Body1>

            {inproperLinks.map((href, i) => (
                <span key={`links-${i}`}>{`${i + 1}: ${href}`}</span>
            ))}
            <Body1>{`are not using bit.ly shortener, so it can't be tracked properly!`}</Body1>
        </Stack>,
        {
            autoClose: false,
            progress: 0,
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: false,
        }
    )
}

export const getDefaultEmailBody = () => ({
    counters: {
        u_row: 10,
        u_column: 13,
        u_content_text: 29,
        u_content_image: 9,
        u_content_button: 3,
        u_content_heading: 3,
    },
    body: {
        id: '6VL3JZmJJg',
        rows: [
            {
                id: '-p0AAlWkme',
                cells: [1],
                columns: [
                    {
                        id: 'BdN-gf6-Z5',
                        contents: [
                            {
                                id: 'x8Um1ll71h',
                                type: 'image',
                                values: {
                                    containerPadding: '10px',
                                    anchor: '',
                                    src: {
                                        url: 'https://assets.unlayer.com/projects/251621/1727804465562-Logo_black.png',
                                        width: 960,
                                        height: 463,
                                        id: 24508499,
                                        size: 9241,
                                        maxWidth: '50%',
                                        autoWidth: false,
                                        contentType: 'image/png',
                                    },
                                    textAlign: 'center',
                                    altText: 'Knowunity logo',
                                    action: {
                                        name: 'web',
                                        values: {
                                            href: '',
                                            target: '_blank',
                                        },
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_image_1',
                                        description: '',
                                        htmlClassNames: 'u_content_image',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    pending: false,
                                },
                            },
                        ],
                        values: {
                            _meta: {
                                htmlID: 'u_column_1',
                                htmlClassNames: 'u_column',
                            },
                            border: {},
                            padding: '0px',
                            backgroundColor: '',
                        },
                    },
                ],
                values: {
                    displayCondition: null,
                    columns: false,
                    _styleGuide: null,
                    backgroundColor: '',
                    columnsBackgroundColor: '',
                    backgroundImage: {
                        url: '',
                        fullWidth: true,
                        repeat: 'no-repeat',
                        size: 'custom',
                        position: 'center',
                    },
                    padding: '0px',
                    anchor: '',
                    hideDesktop: false,
                    _meta: {
                        htmlID: 'u_row_1',
                        htmlClassNames: 'u_row',
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                },
            },
            {
                id: 'uhOsF4pM6U',
                cells: [1],
                columns: [
                    {
                        id: 'NrkGuN64Bx',
                        contents: [
                            {
                                id: '_B_oWeS6GT',
                                type: 'text',
                                values: {
                                    containerPadding: '44px 10px 12px',
                                    anchor: '',
                                    fontSize: '20px',
                                    textAlign: 'center',
                                    lineHeight: '105%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_text_1',
                                        description: '',
                                        htmlClassNames: 'u_content_text',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 105%;"><strong>BACK TO SCHOOL</strong></p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'DmxObt9SvP',
                                type: 'heading',
                                values: {
                                    containerPadding: '10px',
                                    anchor: '',
                                    headingType: 'h1',
                                    fontSize: '82px',
                                    textAlign: 'center',
                                    lineHeight: '100%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_heading_1',
                                        description: '',
                                        htmlClassNames: 'u_content_heading',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<strong>5 tips for better grades</strong>',
                                    _languages: {},
                                },
                            },
                            {
                                id: '5s53snLrPj',
                                type: 'text',
                                values: {
                                    containerPadding: '10px',
                                    anchor: '',
                                    fontSize: '24px',
                                    textAlign: 'center',
                                    lineHeight: '105%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_text_2',
                                        description: '',
                                        htmlClassNames: 'u_content_text',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 105%;">Let\'s make it to straight A</p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'Qqq6Gsla95',
                                type: 'heading',
                                values: {
                                    containerPadding: '60px 34px 12px',
                                    anchor: '',
                                    headingType: 'h1',
                                    fontSize: '20px',
                                    color: '#030712',
                                    textAlign: 'left',
                                    lineHeight: '140%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_heading_2',
                                        description: '',
                                        htmlClassNames: 'u_content_heading',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<span>Hey {{name}}</span>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'V-9mNSUbRO',
                                type: 'text',
                                values: {
                                    containerPadding: '10px 34px',
                                    anchor: '',
                                    fontSize: '16px',
                                    color: '#374151',
                                    textAlign: 'left',
                                    lineHeight: '175%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_text_27',
                                        description: '',
                                        htmlClassNames: 'u_content_text',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 175%;">We get it. Learning can be tough. Teachers can be rubbish. That\'s why a few 17-year-olds created this app to help millions of students do better at school. We\'ve been in your shoes too.</p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'cwjhZbPDY2',
                                type: 'button',
                                values: {
                                    href: {
                                        name: 'web',
                                        values: {
                                            href: 'https://a.knowunity.de/app?source=manual_email',
                                            target: '_blank',
                                        },
                                        attrs: {
                                            href: '{{href}}',
                                            target: '{{target}}',
                                        },
                                    },
                                    buttonColors: {
                                        color: '#FFFFFF',
                                        backgroundColor: '#2563eb',
                                        hoverColor: '#FFFFFF',
                                        hoverBackgroundColor: '#3AAEE0',
                                    },
                                    size: {
                                        autoWidth: true,
                                        width: '100%',
                                    },
                                    fontWeight: 700,
                                    fontSize: '14px',
                                    lineHeight: '120%',
                                    textAlign: 'center',
                                    padding: '10px 20px',
                                    border: {},
                                    borderRadius: '12px',
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    containerPadding: '28px',
                                    anchor: '',
                                    _meta: {
                                        htmlID: 'u_content_button_3',
                                        description: '',
                                        htmlClassNames: 'u_content_button',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<span style="line-height: 16.8px;">HELP ME</span>',
                                    _languages: {},
                                    calculatedWidth: 102,
                                    calculatedHeight: 37,
                                },
                            },
                        ],
                        values: {
                            _meta: {
                                htmlID: 'u_column_2',
                                htmlClassNames: 'u_column',
                            },
                            border: {},
                            padding: '0px',
                            borderRadius: '0px',
                            backgroundColor: '#ffffff',
                        },
                    },
                ],
                values: {
                    displayCondition: null,
                    columns: false,
                    _styleGuide: null,
                    backgroundColor: '',
                    columnsBackgroundColor: '',
                    backgroundImage: {
                        url: '',
                        fullWidth: true,
                        repeat: 'no-repeat',
                        size: 'custom',
                        position: 'center',
                    },
                    padding: '0px',
                    anchor: '',
                    hideDesktop: false,
                    _meta: {
                        htmlID: 'u_row_2',
                        description: '',
                        htmlClassNames: 'u_row',
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                },
            },
            {
                id: 'rvHLE29PKH',
                cells: [1],
                columns: [
                    {
                        id: 'Yu7-VW3S4B',
                        contents: [
                            {
                                id: 'rSNA3O3hoZ',
                                type: 'text',
                                values: {
                                    containerPadding: '34px',
                                    anchor: '',
                                    fontSize: '16px',
                                    color: '#374151',
                                    textAlign: 'left',
                                    lineHeight: '175%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_text_6',
                                        description: '',
                                        htmlClassNames: 'u_content_text',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 175%;">Here are our top tips to get back into a learning habit:</p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: '5Kva9KeSrt',
                                type: 'text',
                                values: {
                                    containerPadding: '0px 34px 12px',
                                    anchor: '',
                                    fontSize: '30px',
                                    textAlign: 'left',
                                    lineHeight: '105%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_text_7',
                                        description: '',
                                        htmlClassNames: 'u_content_text',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 105%;"><strong>Start small</strong></p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'NBD_PA4DLH',
                                type: 'text',
                                values: {
                                    containerPadding: '0px 34px 28px',
                                    anchor: '',
                                    fontSize: '16px',
                                    color: '#374151',
                                    textAlign: 'left',
                                    lineHeight: '175%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_text_8',
                                        description: '',
                                        htmlClassNames: 'u_content_text',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 175%;">Even 10 minutes of studying is better than nothing.</p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'hA8t-ae_UU',
                                type: 'text',
                                values: {
                                    containerPadding: '0px 34px 12px',
                                    anchor: '',
                                    fontSize: '30px',
                                    textAlign: 'left',
                                    lineHeight: '105%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_text_9',
                                        htmlClassNames: 'u_content_text',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 105%;"><strong>Use Knowunity</strong></p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'oT9iaBsIHn',
                                type: 'text',
                                values: {
                                    containerPadding: '0px 34px 28px',
                                    anchor: '',
                                    fontSize: '16px',
                                    color: '#374151',
                                    textAlign: 'left',
                                    lineHeight: '175%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_text_10',
                                        description: '',
                                        htmlClassNames: 'u_content_text',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 175%;">Access content that’s easy to understand and remember</p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'CP62iNp72b',
                                type: 'text',
                                values: {
                                    containerPadding: '0px 34px 12px',
                                    anchor: '',
                                    fontSize: '30px',
                                    textAlign: 'left',
                                    lineHeight: '105%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_text_11',
                                        description: '',
                                        htmlClassNames: 'u_content_text',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 105%;"><strong>Reward yourself</strong></p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: '_nAFi0NDJ8',
                                type: 'text',
                                values: {
                                    containerPadding: '0px 34px 28px 33px',
                                    anchor: '',
                                    fontSize: '16px',
                                    color: '#374151',
                                    textAlign: 'left',
                                    lineHeight: '175%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_text_12',
                                        htmlClassNames: 'u_content_text',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 175%;">Take breaks, eat chocolate and do something you enjoy after studying</p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: '0r-NIfBuQW',
                                type: 'text',
                                values: {
                                    containerPadding: '0px 34px 12px',
                                    anchor: '',
                                    fontSize: '30px',
                                    textAlign: 'left',
                                    lineHeight: '105%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_text_13',
                                        description: '',
                                        htmlClassNames: 'u_content_text',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 105%;"><strong>Prepare an ideal study environment</strong></p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'KpXy2psxPO',
                                type: 'text',
                                values: {
                                    containerPadding: '0px 34px 28px',
                                    anchor: '',
                                    fontSize: '16px',
                                    color: '#374151',
                                    textAlign: 'left',
                                    lineHeight: '175%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_text_14',
                                        description: '',
                                        htmlClassNames: 'u_content_text',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 175%;">Whether it’s quiet or with background lofi beats</p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'wuxXXHnclp',
                                type: 'text',
                                values: {
                                    containerPadding: '0px 34px 12px',
                                    anchor: '',
                                    fontSize: '30px',
                                    textAlign: 'left',
                                    lineHeight: '105%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_text_28',
                                        htmlClassNames: 'u_content_text',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 105%;"><strong>Summarise</strong></p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'yLOp5WEDEK',
                                type: 'text',
                                values: {
                                    containerPadding: '0px 34px 28px',
                                    anchor: '',
                                    fontSize: '16px',
                                    color: '#374151',
                                    textAlign: 'left',
                                    lineHeight: '175%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_text_3',
                                        description: '',
                                        htmlClassNames: 'u_content_text',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 175%;">Make mind maps, try flashcards, or note down the key points</p>',
                                    _languages: {},
                                },
                            },
                        ],
                        values: {
                            _meta: {
                                htmlID: 'u_column_3',
                                htmlClassNames: 'u_column',
                            },
                            border: {},
                            padding: '0px',
                            borderRadius: '0px',
                            backgroundColor: '#dbeafe',
                        },
                    },
                ],
                values: {
                    displayCondition: null,
                    columns: false,
                    _styleGuide: null,
                    backgroundColor: '',
                    columnsBackgroundColor: '',
                    backgroundImage: {
                        url: '',
                        fullWidth: true,
                        repeat: 'no-repeat',
                        size: 'custom',
                        position: 'center',
                    },
                    padding: '0px',
                    anchor: '',
                    hideDesktop: false,
                    _meta: {
                        htmlID: 'u_row_3',
                        description: '',
                        htmlClassNames: 'u_row',
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                },
            },
            {
                id: 'dwOow5SNww',
                cells: [1],
                columns: [
                    {
                        id: '3Jk5vzcl9h',
                        contents: [
                            {
                                id: 'O2KZuLKlq_',
                                type: 'text',
                                values: {
                                    containerPadding: '34px 34px 10px',
                                    anchor: '',
                                    fontSize: '16px',
                                    color: '#374151',
                                    textAlign: 'left',
                                    lineHeight: '175%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_text_29',
                                        htmlClassNames: 'u_content_text',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 175%;">We get it. Learning can be tough. Teachers can be rubbish. That\'s why a few 17-year-olds created this app to help millions of students do better at school. We\'ve been in your shoes too.</p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'Ras8Jdhqpt',
                                type: 'button',
                                values: {
                                    href: {
                                        name: 'web',
                                        values: {
                                            href: 'https://a.knowunity.de/app?source=manual_email',
                                            target: '_blank',
                                        },
                                        attrs: {
                                            href: '{{href}}',
                                            target: '{{target}}',
                                        },
                                    },
                                    buttonColors: {
                                        color: '#FFFFFF',
                                        backgroundColor: '#2563eb',
                                        hoverColor: '#FFFFFF',
                                        hoverBackgroundColor: '#3AAEE0',
                                    },
                                    size: {
                                        autoWidth: true,
                                        width: '100%',
                                    },
                                    fontWeight: 700,
                                    fontSize: '14px',
                                    lineHeight: '120%',
                                    textAlign: 'center',
                                    padding: '10px 20px',
                                    border: {},
                                    borderRadius: '12px',
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    containerPadding: '28px',
                                    anchor: '',
                                    _meta: {
                                        htmlID: 'u_content_button_2',
                                        description: '',
                                        htmlClassNames: 'u_content_button',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<span style="line-height: 16.8px;">ACE THIS YEAR</span>',
                                    _languages: {},
                                    calculatedWidth: 148,
                                    calculatedHeight: 37,
                                },
                            },
                            {
                                id: 'oxnl701_UX',
                                type: 'image',
                                values: {
                                    containerPadding: '10px',
                                    anchor: '',
                                    src: {
                                        url: 'https://assets.unlayer.com/projects/251621/1727804504128-03_Flask.png',
                                        width: 1160,
                                        height: 1160,
                                        id: 24508524,
                                        size: 428772,
                                        maxWidth: '50%',
                                        autoWidth: false,
                                        contentType: 'image/png',
                                    },
                                    textAlign: 'center',
                                    altText: 'School bag icon',
                                    action: {
                                        name: 'web',
                                        values: {
                                            href: '',
                                            target: '_blank',
                                        },
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_image_9',
                                        description: '',
                                        htmlClassNames: 'u_content_image',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    pending: false,
                                },
                            },
                            {
                                id: 'c31ir6hCKk',
                                type: 'text',
                                values: {
                                    containerPadding: '0px 34px 12px',
                                    anchor: '',
                                    fontSize: '30px',
                                    textAlign: 'left',
                                    lineHeight: '105%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_text_16',
                                        description: '',
                                        htmlClassNames: 'u_content_text',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 105%;"><strong>Back to school</strong></p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'Wsn7D3h3Az',
                                type: 'text',
                                values: {
                                    containerPadding: '12px 34px 10px',
                                    anchor: '',
                                    fontSize: '16px',
                                    color: '#374151',
                                    textAlign: 'left',
                                    lineHeight: '175%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_text_15',
                                        description: '',
                                        htmlClassNames: 'u_content_text',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 175%;">Lorem ipsum dolor sit amet consectetur. Tellus lorem metus ut phasellus scelerisque ultrices faucibus scelerisque purus. Pulvinar sit diam enim aliquam lectus. Augue in fermentum ut.</p>',
                                    _languages: {},
                                },
                            },
                        ],
                        values: {
                            _meta: {
                                htmlID: 'u_column_11',
                                htmlClassNames: 'u_column',
                            },
                            border: {},
                            padding: '0px',
                            borderRadius: '0px',
                            backgroundColor: '#ffffff',
                        },
                    },
                ],
                values: {
                    displayCondition: null,
                    columns: false,
                    _styleGuide: null,
                    backgroundColor: '',
                    columnsBackgroundColor: '',
                    backgroundImage: {
                        url: '',
                        fullWidth: true,
                        repeat: 'no-repeat',
                        size: 'custom',
                        position: 'center',
                    },
                    padding: '0px',
                    anchor: '',
                    hideDesktop: false,
                    _meta: {
                        htmlID: 'u_row_8',
                        description: '',
                        htmlClassNames: 'u_row',
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                },
            },
            {
                id: '6jrrJnys3f',
                cells: [1, 1],
                columns: [
                    {
                        id: 'nkU_suThwf',
                        contents: [
                            {
                                id: 'TsyXRsl6KA',
                                type: 'image',
                                values: {
                                    containerPadding: '10px 34px',
                                    anchor: '',
                                    src: {
                                        url: 'https://assets.unlayer.com/projects/251621/1727803460257-Pixel%2012.png',
                                        width: 624,
                                        height: 1310,
                                        id: 24507914,
                                        size: 682146,
                                        maxWidth: '100%',
                                        autoWidth: true,
                                        contentType: 'image/png',
                                    },
                                    textAlign: 'center',
                                    altText: 'Mockup device showing Knowunity feature',
                                    action: {
                                        name: 'web',
                                        values: {
                                            href: '',
                                            target: '_blank',
                                        },
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_image_6',
                                        description: '',
                                        htmlClassNames: 'u_content_image',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    pending: false,
                                },
                            },
                        ],
                        values: {
                            _meta: {
                                htmlID: 'u_column_7',
                                htmlClassNames: 'u_column',
                            },
                            border: {},
                            padding: '25px 0px 0px',
                            borderRadius: '0px',
                            backgroundColor: '#ffffff',
                        },
                    },
                    {
                        id: 'dCcLt_SE-Y',
                        contents: [
                            {
                                id: 'DB7790IdgV',
                                type: 'text',
                                values: {
                                    containerPadding: '25px 34px 12px',
                                    anchor: '',
                                    fontSize: '30px',
                                    textAlign: 'left',
                                    lineHeight: '105%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_text_20',
                                        description: '',
                                        htmlClassNames: 'u_content_text',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 105%;"><strong>Find better notes</strong></p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'hca5RYVY0w',
                                type: 'text',
                                values: {
                                    containerPadding: '12px 34px 10px',
                                    anchor: '',
                                    fontSize: '16px',
                                    color: '#374151',
                                    textAlign: 'left',
                                    lineHeight: '175%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_text_21',
                                        description: '',
                                        htmlClassNames: 'u_content_text',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 175%;">Lorem ipsum dolor sit amet consectetur. Tellus lorem metus ut phasellus scelerisque ultrices faucibus scelerisque purus. Pulvinar sit diam enim aliquam lectus. Augue in fermentum ut.</p>',
                                    _languages: {},
                                },
                            },
                        ],
                        values: {
                            _meta: {
                                htmlID: 'u_column_8',
                                htmlClassNames: 'u_column',
                            },
                            border: {},
                            padding: '0px',
                            borderRadius: '0px',
                            backgroundColor: '#ffffff',
                        },
                    },
                ],
                values: {
                    displayCondition: null,
                    columns: false,
                    _styleGuide: null,
                    backgroundColor: '',
                    columnsBackgroundColor: '',
                    backgroundImage: {
                        url: '',
                        fullWidth: true,
                        repeat: 'no-repeat',
                        size: 'custom',
                        position: 'center',
                    },
                    padding: '0px 34px',
                    anchor: '',
                    hideDesktop: false,
                    _meta: {
                        htmlID: 'u_row_6',
                        description: '',
                        htmlClassNames: 'u_row',
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                },
            },
            {
                id: 'rHC_La3-0s',
                cells: [1, 1],
                columns: [
                    {
                        id: 'PW7T0WYPtl',
                        contents: [
                            {
                                id: '9o4-HNWxpp',
                                type: 'text',
                                values: {
                                    containerPadding: '25px 34px 12px',
                                    anchor: '',
                                    fontSize: '30px',
                                    textAlign: 'left',
                                    lineHeight: '105%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_text_22',
                                        description: '',
                                        htmlClassNames: 'u_content_text',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 105%;"><strong>Chat with an AI assistant</strong></p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'cEk7gl6z_j',
                                type: 'text',
                                values: {
                                    containerPadding: '12px 34px 10px',
                                    anchor: '',
                                    fontSize: '16px',
                                    color: '#374151',
                                    textAlign: 'left',
                                    lineHeight: '175%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_text_23',
                                        description: '',
                                        htmlClassNames: 'u_content_text',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 175%;">Lorem ipsum dolor sit amet consectetur. Tellus lorem metus ut phasellus scelerisque ultrices faucibus scelerisque purus. Pulvinar sit diam enim aliquam lectus. Augue in fermentum ut.</p>',
                                    _languages: {},
                                },
                            },
                        ],
                        values: {
                            _meta: {
                                htmlID: 'u_column_9',
                                htmlClassNames: 'u_column',
                            },
                            border: {},
                            padding: '25px 0px 0px',
                            borderRadius: '0px',
                            backgroundColor: '#ffffff',
                        },
                    },
                    {
                        id: '94QeDVnaFY',
                        contents: [
                            {
                                id: 'ib67-8G2df',
                                type: 'image',
                                values: {
                                    containerPadding: '10px 34px',
                                    anchor: '',
                                    src: {
                                        url: 'https://assets.unlayer.com/projects/251621/1727803602918-Device.png',
                                        width: 297,
                                        height: 599,
                                        id: 24507990,
                                        size: 54718,
                                        maxWidth: '100%',
                                        autoWidth: true,
                                        contentType: 'image/png',
                                    },
                                    textAlign: 'center',
                                    altText: 'Mockup device showing Knowunity feature',
                                    action: {
                                        name: 'web',
                                        values: {
                                            href: '',
                                            target: '_blank',
                                        },
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_image_7',
                                        description: '',
                                        htmlClassNames: 'u_content_image',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    pending: false,
                                },
                            },
                        ],
                        values: {
                            _meta: {
                                htmlID: 'u_column_10',
                                htmlClassNames: 'u_column',
                            },
                            border: {},
                            padding: '0px',
                            borderRadius: '0px',
                            backgroundColor: '#ffffff',
                        },
                    },
                ],
                values: {
                    displayCondition: null,
                    columns: false,
                    _styleGuide: null,
                    backgroundColor: '',
                    columnsBackgroundColor: '',
                    backgroundImage: {
                        url: '',
                        fullWidth: true,
                        repeat: 'no-repeat',
                        size: 'custom',
                        position: 'center',
                    },
                    padding: '0px 34px',
                    anchor: '',
                    hideDesktop: false,
                    _meta: {
                        htmlID: 'u_row_7',
                        description: '',
                        htmlClassNames: 'u_row',
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                },
            },
            {
                id: 'r5v1E1_Qtw',
                cells: [1],
                columns: [
                    {
                        id: '4ZUKaB9sxF',
                        contents: [
                            {
                                id: '7x8ePL2_R5',
                                type: 'text',
                                values: {
                                    containerPadding: '92px 34px 10px',
                                    anchor: '',
                                    fontSize: '16px',
                                    color: '#374151',
                                    textAlign: 'left',
                                    lineHeight: '175%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_text_24',
                                        description: '',
                                        htmlClassNames: 'u_content_text',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 175%;">Take care of yourself,</p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'LKjex6igX3',
                                type: 'image',
                                values: {
                                    containerPadding: '10px',
                                    anchor: '',
                                    src: {
                                        url: 'https://assets.unlayer.com/projects/251621/1727804447084-Group%2039922.png',
                                        width: 712,
                                        height: 323,
                                        id: 24508481,
                                        size: 25690,
                                        maxWidth: '50%',
                                        autoWidth: false,
                                        contentType: 'image/png',
                                    },
                                    textAlign: 'center',
                                    altText: 'Knowunity signature',
                                    action: {
                                        name: 'web',
                                        values: {
                                            href: '',
                                            target: '_blank',
                                        },
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_image_8',
                                        description: '',
                                        htmlClassNames: 'u_content_image',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    pending: false,
                                },
                            },
                            {
                                id: 'MV6GWypyaf',
                                type: 'text',
                                values: {
                                    containerPadding: '10px 34px',
                                    anchor: '',
                                    fontSize: '16px',
                                    color: '#374151',
                                    textAlign: 'left',
                                    lineHeight: '175%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_text_25',
                                        description: '',
                                        htmlClassNames: 'u_content_text',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 175%;">P.S. Nothing beats that feeling of incredible grades</p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'IfkbIISeKV',
                                type: 'text',
                                values: {
                                    containerPadding: '80px 34px 10px',
                                    anchor: '',
                                    fontSize: '12px',
                                    color: '#374151',
                                    textAlign: 'center',
                                    lineHeight: '175%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _styleGuide: null,
                                    _meta: {
                                        htmlID: 'u_content_text_26',
                                        description: '',
                                        htmlClassNames: 'u_content_text',
                                    },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 175%;">Not interested anymore? You can unsubscribe <a rel="noopener" href="{{unsubscribeLink}}" target="_blank" data-u-link-value="eyJuYW1lIjoid2ViIiwiYXR0cnMiOnsiaHJlZiI6Int7aHJlZn19IiwidGFyZ2V0Ijoie3t0YXJnZXR9fSJ9LCJ2YWx1ZXMiOnsiaHJlZiI6Int7dW5zdWJzY3JpYmVMaW5rfX0iLCJ0YXJnZXQiOiJfYmxhbmsifX0=">here</a>.<br />Knowunity GmbH, Julie-Wolfthorn-Str. 10115 Berlin, Germany.</p>',
                                    _languages: {},
                                },
                            },
                        ],
                        values: {
                            _meta: {
                                htmlID: 'u_column_12',
                                htmlClassNames: 'u_column',
                            },
                            border: {},
                            padding: '0px',
                            _override: {
                                mobile: {
                                    backgroundColor: '#ffffff',
                                },
                            },
                            borderRadius: '0px',
                            backgroundColor: '#ffffff',
                        },
                    },
                ],
                values: {
                    displayCondition: null,
                    columns: false,
                    _styleGuide: null,
                    backgroundColor: '',
                    columnsBackgroundColor: '',
                    backgroundImage: {
                        url: '',
                        fullWidth: true,
                        repeat: 'no-repeat',
                        size: 'custom',
                        position: 'center',
                    },
                    padding: '0px',
                    anchor: '',
                    hideDesktop: false,
                    _meta: {
                        htmlID: 'u_row_9',
                        description: '',
                        htmlClassNames: 'u_row',
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                },
            },
        ],
        headers: [],
        footers: [],
        values: {
            _styleGuide: null,
            popupPosition: 'center',
            popupWidth: '600px',
            popupHeight: 'auto',
            borderRadius: '10px',
            contentAlign: 'center',
            contentVerticalAlign: 'center',
            contentWidth: 500,
            fontFamily: {
                label: 'Arial',
                value: 'arial,helvetica,sans-serif',
            },
            textColor: '#000000',
            popupBackgroundColor: '#FFFFFF',
            popupBackgroundImage: {
                url: '',
                fullWidth: true,
                repeat: 'no-repeat',
                size: 'cover',
                position: 'center',
            },
            popupOverlay_backgroundColor: 'rgba(0, 0, 0, 0.1)',
            popupCloseButton_position: 'top-right',
            popupCloseButton_backgroundColor: '#DDDDDD',
            popupCloseButton_iconColor: '#000000',
            popupCloseButton_borderRadius: '0px',
            popupCloseButton_margin: '0px',
            popupCloseButton_action: {
                name: 'close_popup',
                attrs: {
                    onClick: "document.querySelector('.u-popup-container').style.display = 'none';",
                },
            },
            language: {},
            backgroundColor: '#65a0f1',
            preheaderText: '',
            linkStyle: {
                body: true,
                linkColor: '#2563eb',
                linkHoverColor: '#0000ee',
                linkUnderline: false,
                linkHoverUnderline: true,
                inherit: false,
            },
            backgroundImage: {
                url: '',
                fullWidth: true,
                repeat: 'no-repeat',
                size: 'custom',
                position: 'center',
            },
            _meta: {
                htmlID: 'u_body',
                description: '',
                htmlClassNames: 'u_body',
            },
        },
    },
    schemaVersion: 17,
})
