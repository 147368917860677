import React, { useRef, useState } from 'react'
import Modal from 'components/modules/Modal'
import { ApiError } from 'services/ApiService'
import { useToggleState } from 'utils/hooks/useToggleState'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { Checkbox } from 'components/inputs/Input'
import Button from 'components/elements/Button'

const SIZABLE_USER_COUNT_THRESHOLD = 5000

type UseUserCountProps = {
    runValidation: () => void
    renderUserCountModal: () => React.ReactNode
    userCount: number
}

interface FormData {
    userAmountAgreement: boolean
    spellingTyposAgreement: boolean
    checkWithTechTeamAgreement: boolean
}

export function useUserCount(getEstimatedReceiversCount: () => Promise<number>, onSuccess: () => void): UseUserCountProps {
    const [showUserCountModal, toggleUserCountModal] = useToggleState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [userCount, setUserCount] = useState(0)

    const formRef = useRef<FormHandles>(null)

    const runValidation = () => {
        getEstimatedReceiversCount()
            .then((userCount) => {
                let message = ''
                switch (true) {
                    case userCount === 0:
                        message =
                            '0 users would receive this manual notice. Change User Targeting Settings in "Update" Tab to increase it.'
                        break
                    case userCount! > 0 && userCount! < SIZABLE_USER_COUNT_THRESHOLD:
                        message = `The notice you are about to send will be received by around ${userCount} users`
                        break
                    case userCount! > SIZABLE_USER_COUNT_THRESHOLD:
                        message = `The notice you are about to send will be received by around ${userCount} users, which is more than the threshold of ${SIZABLE_USER_COUNT_THRESHOLD} users. Please ask the Tech Team on Slack if it's okay to send this notice.`
                        break
                    default:
                        message = 'There is a problem with gathering information about targeted user count. Try again later'
                        break
                }
                setErrorMessage(message)
                toggleUserCountModal()
                setUserCount(userCount ?? 0)
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('An error occurred while getting targeted user count')
                } else {
                    throw error
                }
                return false
            })
    }

    const handleSubmit: SubmitHandler<FormData> = (data) => {
        const { userAmountAgreement, spellingTyposAgreement, checkWithTechTeamAgreement } = data
        if (!userAmountAgreement)
            formRef.current!.setFieldError(
                'userAmountAgreement',
                `You need to agree that you want send the notice to ${userCount} users`
            )

        if (!spellingTyposAgreement)
            formRef.current!.setFieldError('spellingTyposAgreement', `You need to check if there are no typos in the notice`)

        if (!checkWithTechTeamAgreement && userCount > SIZABLE_USER_COUNT_THRESHOLD)
            formRef.current!.setFieldError(
                'checkWithTechTeamAgreement',
                `You need to check back with Tech Team if you're allowed to send this notice`
            )
        if (Object.values(data).some((x) => x === false)) return
        onSuccess()
        toggleUserCountModal()
    }

    const renderUserCountModal = () => (
        <Modal show={showUserCountModal} title="Are you sure you want to send this notice" onClose={toggleUserCountModal}>
            <Form ref={formRef} onSubmit={handleSubmit}>
                <p>{errorMessage}</p>
                <br />
                {userCount > 0 ? (
                    <>
                        <h3 style={{ color: 'var(--primary-color-dark)' }}>
                            <strong>{`User count: ${userCount}`}</strong>
                        </h3>
                        <Checkbox name="userAmountAgreement" placeholder={`I want to send it to ${userCount} users`} />
                        <Checkbox name="spellingTyposAgreement" placeholder="I checked the texts for spelling mistakes" />
                        {userCount > SIZABLE_USER_COUNT_THRESHOLD && (
                            <Checkbox
                                name="checkWithTechTeamAgreement"
                                placeholder=" I checked with the Tech Team that I am allowed to send it out."
                            />
                        )}
                        <Button fullWidth>I want to send it</Button>
                    </>
                ) : null}
            </Form>
        </Modal>
    )

    return { runValidation, renderUserCountModal, userCount: userCount ?? 0 }
}
