import { AcceptReportReason, ReportType } from 'interfaces/Report'

const knowRejectionReasonsTitles = ['NO_REPORT_REASON', 'FAULTY_KNOW_MISINFORMATION']

const noReportReasonsTiles = ['NO_REPORT_REASON']

const getReportReasonsKeysBasedOnReportType = (reportType: ReportType) => {
    switch (reportType) {
        case ReportType.know:
            return knowRejectionReasonsTitles
        case ReportType.user:
        case ReportType.question:
        case ReportType.answer:
        case ReportType.comment:
        case ReportType.chat:
        case ReportType.chatMessage:
        case ReportType.answerComment:
        case ReportType.aiChatBot:
            return noReportReasonsTiles

        default:
            throw new Error(`Unknown report type: ${reportType}`)
    }
}

export default getReportReasonsKeysBasedOnReportType

export const formatReportReasonTitle = (reason: string) =>
    reason.replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : ' ' + d.toUpperCase()))

export const filterReportReasonsByVialionLevel = (reasons: AcceptReportReason[], violationLevel: 'QUALITY' | 'SAFETY') =>
    reasons.filter((reason) => reason.violationLevel.toString().startsWith(violationLevel))
