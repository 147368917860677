import React, { useCallback, useState } from 'react'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import RefactoredTable from 'components/modules/RefactoredTable'
import Stack from 'components/elements/Stack'
import Meta from 'components/modules/Head'
import SchoolCreateService from 'services/SchoolCreateService'
import { CountryCode } from 'interfaces/Country'
import SelectElement from 'components/inputs/SelectElement'
import { FilterFormGrid } from 'style'
import routes from 'lib/constants/routes'
import { useCountryOptions } from 'hooks/useCountryOptions'

const loadingAreas = {
    container: 'moderationKeywordsContainer',
    delete: 'moderationKeywordDelete',
    edit: 'moderationKeywordEdit',
}

const SchoolsSuggestions = () => {
    const [selectedCountry, setSelectedCountry] = useState<CountryCode>(CountryCode.Germany)

    const fetchData = useCallback(
        (page: number) => {
            return trackPromise(
                SchoolCreateService.list(page, selectedCountry)
                    .then((data) => {
                        const redirectUuids = data.schools.map((s) => s.uuid)

                        return {
                            totalPages: 0,
                            totalElements: data.schools.length,
                            elements: data.schools.map((s, i) => ({
                                id: s.uuid,
                                columns: [s.uuid, s.name, `${s.city}, ${s.streetWithNumber}`, s.userUuid],
                                onClick: routes.schoolsSuggestionRoute(s.uuid, { redirectUuids }),
                            })),
                        }
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while getting moderation keywords.')
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.container
            )
        },
        [selectedCountry]
    )

    const countryTypeOptions = useCountryOptions()
    const updateCountryCode = (countryCode: CountryCode) => setSelectedCountry(countryCode)

    return (
        <section>
            <Meta title="School suggestions" />
            <Stack gutter={5}>
                <h2>School suggestions</h2>
                <FilterFormGrid gridAmount={2}>
                    <div />
                    <SelectElement label="Country code" noMargin options={countryTypeOptions} onUpdate={updateCountryCode} />
                </FilterFormGrid>
                <RefactoredTable
                    columns={['#', 'Name', 'Location name', 'User uuid']}
                    loadData={fetchData}
                    loadingArea={loadingAreas.container}
                />
            </Stack>
        </section>
    )
}

export default SchoolsSuggestions
