import React from 'react'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import config from 'utils/config'

interface Props {
    onVerify: (hCaptchaToken: string) => void
}

const HcaptchaComponent = ({ onVerify }: Props) => <HCaptcha sitekey={config.getHCaptchaKey()} onVerify={onVerify} />

export default HcaptchaComponent
