import React, { useCallback } from 'react'
import { User } from 'interfaces/User'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import { formatDateTime } from 'utils'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import styled from 'styled-components'
import ReportService from 'services/ReportService'
import routes from 'lib/constants/routes'
import { Report } from 'interfaces/Report'
import RefactoredTable from 'components/modules/RefactoredTable'

const loadingAreas = {
    userReportsHistory: 'userReportsHistory',
}

const TitleRow = styled.div`
    display: flex;
    justify-content: space-between;
`

const columns = ['Type', 'Status', 'Employee Reasons', 'Last reported']

interface Props {
    user: User
}

const UserReportsHistory = ({ user }: Props) => {
    const userUuid = user.uuid

    const formatStatus = (reports: Report[]) => {
        const statusCount: Record<string, number> = {}
        for (const report of reports) {
            if (Object.prototype.hasOwnProperty.call(statusCount, report.status)) {
                statusCount[report.status]++
            } else {
                statusCount[report.status] = 1
            }
        }
        const statusArray = Object.keys(statusCount)
        return statusArray.map((status) => `${status} (${statusCount[status]}x)`)
    }

    const formatEmployeeReason = (reports: Report[]) => {
        const reasonsCount: Record<string, number> = {}
        for (const report of reports) {
            if (Object.prototype.hasOwnProperty.call(reasonsCount, report.employeeReason)) {
                reasonsCount[report.employeeReason]++
            } else {
                reasonsCount[report.employeeReason] = 1
            }
        }
        const reasonArray = Object.keys(reasonsCount)
        return reasonArray.map((reason) => `${reason} (${reasonsCount[reason]}x)`)
    }

    const formatLastReported = (reports: Report[]) => {
        const reportsCreatedOn = reports.map((report) => new Date(report.createdOn))
        const lastReportCreatedOn = reportsCreatedOn.sort().reverse()[0]
        return formatDateTime(lastReportCreatedOn)
    }

    const fetchData = useCallback(
        (page: number) => {
            return trackPromise(
                ReportService.listUserReports(userUuid, page)
                    .then((data) => {
                        if (page !== data.page) return

                        return {
                            totalPages: data.totalPages,
                            totalElements: data.totalElements,
                            elements: data.content.map((reportItem) => ({
                                id: reportItem.uuid,
                                columns: [
                                    reportItem.type,
                                    formatStatus(reportItem.reports),
                                    formatEmployeeReason(reportItem.reports),
                                    formatLastReported(reportItem.reports),
                                ],
                                onClick: routes.reportRoute(reportItem.uuid),
                            })),
                        }
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while getting user report history.')
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.userReportsHistory
            )
        },
        [userUuid]
    )

    return (
        <Box>
            <Stack>
                <TitleRow>
                    <h3>User report history</h3>
                </TitleRow>

                <RefactoredTable
                    columns={columns}
                    loadData={fetchData}
                    loadingArea={loadingAreas.userReportsHistory}
                    refetchKey={undefined}
                    openInNewTabOnRowClick
                />
            </Stack>
        </Box>
    )
}

export default UserReportsHistory
