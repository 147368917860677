import React, { useCallback, useEffect, useState } from 'react'
import Modal from 'components/modules/Modal'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import UserTargetingForm from 'components/modules/UserTargeting/UserTargetingForm'
import { useToggleState } from './useToggleState'
import Button from 'components/elements/Button'
import { UserTargeting, UserTargetingTypes } from 'interfaces/UserTargeting'
import UserTargetingService from 'services/UserTargetingService'
import { ApiError } from 'services/ApiService'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import styled from 'styled-components'
import theme from 'lib/constants/theme'

const InformationGrid = styled.div`
    display: grid;
    padding: 15px;
    grid-template-columns: 1fr auto;
    background-color: ${theme.colors.darkViolet};
    border: 1px solid ${theme.colors.accentBlue};
    border-radius: ${theme.borderRadius.normal};
    .buttons {
        display: flex;
        justify-content: start;
        gap: 15px;
    }
`

type UseUserTargetingFormProps = {
    userTargetingId: number | null
    renderTargetingModal: () => React.ReactNode
    renderTargetingButton: () => React.ReactNode
    renderTargetingInformation: (o?: { hideBoxWrapper?: boolean }) => React.ReactNode
}

export function useUserTargetingForm(
    defaultUserTargetingId?: number,
    isNew?: boolean,
    onClose?: () => void,
    options?: {
        hideDaysSinceUserCreation?: boolean
    }
): UseUserTargetingFormProps {
    const [userTargetingData, setUserTargetingData] = useState<UserTargeting>()
    const [userTargetingId, setUserTargetingId] = useState<number | null>(defaultUserTargetingId ?? null)
    const [showUserTargetingForm, toggleUserTargetingForm] = useToggleState(false)

    useEffect(() => {
        defaultUserTargetingId && setUserTargetingId(defaultUserTargetingId)
    }, [defaultUserTargetingId])

    const fetchUserTargeting = (id: number) => {
        UserTargetingService.get(id)
            .then((targeting) => {
                setUserTargetingData(targeting)
            })
            .catch((error: ApiError) => {
                error.handleUnknown('An error occurred while fetching user targeting.')
            })
    }

    useEffect(() => {
        userTargetingId && fetchUserTargeting(userTargetingId)
    }, [userTargetingId])

    const handleClose = (id: number) => {
        setUserTargetingId(id)
        toggleUserTargetingForm()
        fetchUserTargeting(id)
        onClose && onClose()
    }

    const handleRemoveUserTargeting = () => setUserTargetingId(null)

    const returnLabelBasedOnUserStatus = (isKnower?: boolean | null) => {
        switch (true) {
            case isKnower === true:
                return 'Only knowers'
            case isKnower === false:
                return 'Users that are not knowers'
            default:
                return 'Every user (not depending on status)'
        }
    }

    const renderTargetingInformation = useCallback(
        (o?: { hideBoxWrapper?: boolean }) => {
            const Wrapper = o?.hideBoxWrapper ? styled.span`` : Box
            return (
                <>
                    <Wrapper>
                        <Stack>
                            <h3>User Targeting Data</h3>

                            {userTargetingId ? (
                                <>
                                    {userTargetingData?.type === UserTargetingTypes.Attributes ? (
                                        <>
                                            <p>
                                                <strong>Countries:</strong>&nbsp;
                                                {userTargetingData?.countries?.map((x) => x.englishName)?.join(', ')}
                                            </p>
                                            <p>
                                                <strong>Regions:</strong>&nbsp;
                                                {userTargetingData?.regions
                                                    ?.map((x) => `${x.regionName} (${x.code})`)
                                                    .join(', ')}
                                            </p>
                                            <p>
                                                <strong>Grades:</strong>&nbsp;
                                                {userTargetingData?.grades
                                                    ?.map((x) => `${x.level} (${x.longName})`)
                                                    ?.filter((item, pos, self) => self.indexOf(item) === pos)
                                                    ?.join(', ')}
                                            </p>
                                            <p>
                                                <strong>School types:</strong>&nbsp;
                                                {userTargetingData?.schoolTypes
                                                    ?.map((x) => `${x.name} (${x.country.code})`)
                                                    .join(', ')}
                                            </p>
                                            <p>
                                                <strong>Issue subjects:</strong>&nbsp;
                                                {userTargetingData?.issueSubjects?.map((s) => `${s.name}`).join(', ')}
                                            </p>
                                            <p>
                                                <strong>Location prefixes:</strong>&nbsp;
                                                {userTargetingData?.locationPrefixes?.map((x) => x.prefix).join(', ')}
                                            </p>
                                            {!options?.hideDaysSinceUserCreation ? (
                                                <p>
                                                    <strong>Max days since user creation:</strong>&nbsp;
                                                    {userTargetingData.daysSinceUserCreation ?? 'Not specified'}
                                                </p>
                                            ) : null}
                                            <p>
                                                <strong>Days since user was active:</strong>&nbsp;
                                                {`Min: ${userTargetingData.minLastActiveSinceDaysAgo ?? 'N/A'}, Max: ${
                                                    userTargetingData.maxLastActiveSinceDaysAgo ?? 'N/A'
                                                }`}
                                            </p>
                                            <p>
                                                <strong>User status:</strong>&nbsp;
                                                {returnLabelBasedOnUserStatus(userTargetingData?.isKnower)}
                                            </p>
                                            <p>
                                                <strong>Include users without grades:</strong>&nbsp;
                                                {userTargetingData.includeUsersWithoutGrade ? 'Yes' : 'No'}
                                            </p>
                                            <p>
                                                <strong>Include users without school types:</strong>&nbsp;
                                                {userTargetingData.includeUsersWithoutSchoolType ? 'Yes' : 'No'}
                                            </p>
                                            {userTargetingData?.persona ? (
                                                <p>
                                                    <strong>Persona:</strong>&nbsp;
                                                    {userTargetingData.persona}
                                                </p>
                                            ) : null}
                                            <p>
                                                <strong>Out of school status:</strong>&nbsp;
                                                {userTargetingData.outOfSchoolStatus?.toLowerCase() ?? '-'}
                                            </p>
                                        </>
                                    ) : (
                                        <p>
                                            <strong>User count manually imported from CSV:</strong>&nbsp;
                                            {userTargetingData?.userCount}
                                        </p>
                                    )}
                                </>
                            ) : (
                                'Create User Targeting to see the targeted groups'
                            )}
                        </Stack>
                    </Wrapper>
                </>
            )
        },
        [userTargetingData, userTargetingId, options]
    )

    const renderTargetingButton = () => (
        <InformationGrid>
            {renderTargetingInformation()}
            <div className="buttons">
                <Button
                    fullWidth={false}
                    icon={userTargetingId ? <EditIcon /> : <AddIcon />}
                    onClick={toggleUserTargetingForm}
                    type="button"
                >
                    {`${userTargetingId ? 'Update' : 'Create'} Targeting`}
                </Button>
                {userTargetingId ? (
                    <Button
                        fullWidth={false}
                        icon={<DeleteIcon />}
                        onClick={handleRemoveUserTargeting}
                        type="button"
                        color={theme.colors.secondaryRed}
                        hoverColor={theme.colors.secondaryRedDarker}
                    >
                        Reset Targeting
                    </Button>
                ) : null}
            </div>
        </InformationGrid>
    )

    const renderTargetingModal = () => (
        <Modal show={showUserTargetingForm} title="Add user targeting" onClose={toggleUserTargetingForm}>
            <UserTargetingForm
                setTargetingId={handleClose}
                userTargetingId={userTargetingId}
                isNew={isNew}
                options={{ hideDaysSinceUserCreation: options?.hideDaysSinceUserCreation }}
            />
        </Modal>
    )

    return { userTargetingId, renderTargetingModal, renderTargetingButton, renderTargetingInformation }
}
