import React from 'react'
import { ButtonLink } from 'components/elements/Button'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import { useCallback } from 'react'
import RefactoredTable from 'components/modules/RefactoredTable'
import routes from 'lib/constants/routes'
import Stack from 'components/elements/Stack'
import { FilterFormGrid } from 'style'
import ManualChatMessagesService from 'services/ManualChatMessagesService'
import { formatDateTime, formatText } from 'utils'
import Meta from 'components/modules/Head'
import { formatManualCommunicationProgress } from 'utils/manualCommunication'

const loadingAreas = {
    container: 'manualChatMessageContainer',
}

const ManualChatMessages = () => {
    const fetchData = useCallback((page: number) => {
        return trackPromise(
            ManualChatMessagesService.list(page)
                .then((data) => {
                    if (page !== data.page) return

                    return {
                        totalPages: data.totalPages,
                        totalElements: data.totalElements,

                        elements: data.chatMessages.map((c) => ({
                            id: c.uuid,
                            columns: [
                                c.uuid,
                                formatText(c.message, 30),
                                c.user.username,
                                formatDateTime(c.createdOn),
                                c?.sentOn ? formatDateTime(c.sentOn) : 'Not sent yet',
                                formatManualCommunicationProgress(c.expectedUsersCount, c.actualUsersCount),
                            ],
                            onClick: routes.manualChatMessageRoute(c.uuid),
                        })),
                    }
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting manual chat messages.')
                }),
            loadingAreas.container
        )
    }, [])

    return (
        <section>
            <Meta title="Manual Chat Messages" />
            <Stack gutter={5}>
                <h2>Manual Chat Messages</h2>
                <FilterFormGrid gridAmount={2}>
                    <ButtonLink to={routes.manualChatMessageCreateRoute} fullWidth={false} icon={<AddIcon />}>
                        Add Manual Chat Message
                    </ButtonLink>
                </FilterFormGrid>
                <RefactoredTable
                    columns={['#', 'Message', 'Creator', 'Created on', 'Sent on', 'Progress']}
                    loadData={fetchData}
                    loadingArea={loadingAreas.container}
                    refetchKey={undefined}
                />
            </Stack>
        </section>
    )
}

export default ManualChatMessages
