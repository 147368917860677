import { ChatMessage } from './Chat'
import { Comment } from './Comment'
import { DiscussionForumAnswer, DiscussionForumAnswerComment } from './DiscussionForumAnswer'
import { DiscussionForumQuestion } from './DiscussionForumQuestion'
import { Know } from './Know'
import { ReportAiChatBotMinimalResponse } from './ReportAiChatBotMinimalResponse'
import { User, UserModerationHistoryViolationLevel } from './User'

export interface ReportItem {
    reportedChatMessage: ChatMessage | null
    reportedChatUuid: string | null
    reportedComment: Comment | null
    reportedCommunityPostUuid: null
    reportedDiscussionForumAnswer: DiscussionForumAnswer | null
    reportedDiscussionForumQuestion: DiscussionForumQuestion | null
    reportedDiscussionForumAnswerComment: DiscussionForumAnswerComment | null
    reportedAiChatBot: ReportAiChatBotMinimalResponse | null
    reportedKnow: Know | null
    reportedUser: User | null
    reports: Report[]
    uuid: string
    type: ReportType
}

export interface Report {
    createdOn: string
    description: string
    employeeReason: string
    id: number
    reportingUser: User
    closedByUser: User | null
    status: ReportStatus
    platform: string
    isAnonymous: boolean
    userReason: string | null
}

export enum ReportStatus {
    Open = 'OPEN',
    Accepted = 'ACCEPTED',
    Rejected = 'REJECTED',
    OnHold = 'ON_HOLD',
}
export enum ReportType {
    user = 'USER',
    know = 'KNOW',
    question = 'QUESTION',
    answer = 'ANSWER',
    comment = 'COMMENT',
    chat = 'CHAT',
    chatMessage = 'CHAT_MESSAGE',
    answerComment = 'ANSWER_COMMENT',
    aiChatBot = 'AI_CHAT_BOT',
}

export interface ResetUserProfileParams {
    resetProfilePicture: boolean
    resetBio: boolean
    resetUsername: boolean
    resetName: boolean
}

export interface AcceptReportReason {
    name: string
    violationLevel: UserModerationHistoryViolationLevel
    supportedContentTypes: ReportType[]
}
