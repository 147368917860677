import Box from 'components/elements/Box'
import { User } from 'interfaces/User'
import React, { useCallback, useEffect, useState } from 'react'
import UserCreateBanModal from './UserCreateBanModal'
import { BanStatus } from 'interfaces/UserBan'
import { useToggleState } from 'utils/hooks/useToggleState'
import UserService from 'services/UserService'
import { ApiError } from 'services/ApiService'
import { UserRemoveBanModal } from './UserRemoveBanModal'
import Button from 'components/elements/Button'
import LoadingArea from 'components/elements/LoadingArea'
import Stack from 'components/elements/Stack'
import { formatDate } from 'utils'

interface Props {
    user: User
}

const loadingAreas = {
    userModerationStatus: 'userModerationStatus',
}

const UserModerationStatus = (props: Props) => {
    const [moderationStatus, setModerationStatus] = useState<BanStatus>()
    const [showCreateBanModal, toggleCreateBanModal] = useToggleState(false)
    const [showRemoveBanModal, toggleRemoveBanModal] = useToggleState(false)

    const fetchData = useCallback(() => {
        UserService.getBanStatus(props.user.uuid)
            .then((response) => {
                setModerationStatus(response)
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    setModerationStatus(undefined)
                    error.handleUnknown('An error occurred while getting the moderation status.')
                } else {
                    throw error
                }
            })
    }, [props.user.uuid])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <Box>
            <Stack>
                <h3>User Moderation Status</h3>

                <p>
                    <strong>Penalty points:</strong>&nbsp;
                    {props.user.penaltyPoints}
                </p>
                <LoadingArea area={loadingAreas.userModerationStatus}>
                    {moderationStatus && (
                        <>
                            <p>
                                <strong>Is banned:</strong>&nbsp;
                                {moderationStatus.isUserBanned
                                    ? moderationStatus.isForeverBanned
                                        ? 'Yes (forever)'
                                        : `Yes (until ${formatDate(moderationStatus.userBannedUntil!)})`
                                    : 'No'}
                            </p>
                            <p>
                                <strong>Has banned devices:</strong>&nbsp;
                                {moderationStatus.isDeviceBanned ? 'Yes' : 'No'}
                            </p>
                            <p>
                                <strong>Is banned from being a chat admin:</strong>&nbsp;
                                {moderationStatus.isBannedFromChatAdmin ? 'Yes' : 'No'}
                            </p>

                            {!moderationStatus.isUserBanned && (
                                <Button onClick={toggleCreateBanModal} fullWidth noMargin>
                                    Ban user
                                </Button>
                            )}
                            {moderationStatus.isUserBanned && (
                                <Button onClick={toggleRemoveBanModal} fullWidth noMargin>
                                    Remove ban
                                </Button>
                            )}

                            <UserCreateBanModal
                                userUuid={props.user.uuid}
                                show={showCreateBanModal}
                                onClose={toggleCreateBanModal}
                                onCreatedBan={() => fetchData()}
                            />
                            <UserRemoveBanModal
                                userUuid={props.user.uuid}
                                show={showRemoveBanModal}
                                onClose={toggleRemoveBanModal}
                                showUnblockDevices={moderationStatus.isDeviceBanned ?? false}
                                onRemovedBan={fetchData}
                            />
                        </>
                    )}
                </LoadingArea>
            </Stack>
        </Box>
    )
}

export default UserModerationStatus
