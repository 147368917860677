import { AdAttachment, AdAttachmentType } from 'interfaces/AdAttachmentSet'

export const returnAttachmentContent = (adAttachment: AdAttachment) => {
    switch (adAttachment.type) {
        case AdAttachmentType.Link:
            return adAttachment?.link?.name
        case AdAttachmentType.Event:
            return adAttachment?.events[0]?.title
        case AdAttachmentType.Survey:
            return adAttachment?.applicationSurvey?.title
        default:
            return ''
    }
}
