import React from 'react'
import { Comment } from 'interfaces/Comment'
import styled from 'styled-components'
import ProfilePicture from './ProfilePicturePreview'
import { Body2 } from 'components/elements/Text'
import theme from 'lib/constants/theme'

const CommentsContainer = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    padding: 10px;
    border: 1px solid ${theme.colors.knowunityBlue};
    border-radius: ${theme.borderRadius.normal};
    width: fit-content;
    gap: 15px;
    align-items: center;
    cursor: pointer;
    p {
        padding-right: 15px;
    }
`

interface Props {
    comment: Comment
}

const CommentPreview = ({ comment }: Props) => {
    return (
        <CommentsContainer>
            <ProfilePicture user={comment.user} />
            <Body2 color={theme.colors.white}>{comment.content}</Body2>
        </CommentsContainer>
    )
}

export default CommentPreview
