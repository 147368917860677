import React, { ReactNode } from 'react'
import Modal from 'components/modules/Modal'

interface Props {
    open: boolean
    handleClose: () => void
    children?: ReactNode
}

const GradeSelectionDialog = ({ open, handleClose, children }: Props) => {
    return (
        <Modal show={open} title="Choose grade" onClose={handleClose}>
            {children}
        </Modal>
    )
}

export default GradeSelectionDialog
