/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import RefactoredTable from 'components/modules/RefactoredTable'
import { FilterFormGrid, NoWrapText } from 'style'
import Stack from 'components/elements/Stack'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import Modal from 'components/modules/Modal'
import { useToggleState } from 'utils/hooks/useToggleState'
import SelectElement from 'components/inputs/SelectElement'
import Meta from 'components/modules/Head'
import ModerationKeywordsService from 'services/ModerationKeywordsService'
import { LanguageCode, LanguageInterface } from 'interfaces/Language'
import { ModerationKeyword, ModerationKeywordType } from 'interfaces/ModerationKeyword'
import Row from 'components/elements/Row'
import Button from 'components/elements/Button'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import ModerationKeywordsForm from './ModerationKeywordsForm'
import { toast } from 'react-toastify'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { SelectOptionWithLabel } from 'components/inputs/Input'
import LanguageService from 'services/LanguageService'

const loadingAreas = {
    container: 'moderationKeywordsContainer',
    delete: 'moderationKeywordDelete',
    edit: 'moderationKeywordEdit',
}

const ModerationKeywordsComponent = () => {
    const [selectedModerationKeyword, setSelectedModerationKeyword] = useState<ModerationKeyword | null>(null)
    const [languageOptions, setLanguageOptions] = useState<SelectOptionWithLabel<LanguageInterface | null>[] | null>(null)
    const [languageCode, setLanguageCode] = useState<LanguageCode | null>(LanguageCode.German)
    const [moderationType, setModerationType] = useState<ModerationKeywordType>(ModerationKeywordType.BlockedWords)
    const [showModerationKeywordChangeModal, toggleModerationKeywordChangeModal] = useToggleState(false)
    const [refetch, setRefetch] = useState(false)
    const formRef = useRef<FormHandles>(null)

    useEffect(() => {
        LanguageService.list(true)
            .then((languages) => {
                const options = [
                    ...languages.map((lang) => ({
                        label: lang.englishName,
                        identifier: lang.id.toString(),
                        data: lang,
                    })),
                    {
                        label: 'Global',
                        identifier: 'global',
                        data: null,
                    },
                ]
                setLanguageOptions(options)
            })
            .catch((error: ApiError) => {
                error.handleUnknown('An error occurred while getting languages.')
            })
    }, [])

    const deleteModerationKeyword = useCallback(({ uuid, name }: ModerationKeyword) => {
        if (!window.confirm(`Do you really want to delete the "${name}" moderation keyword?`)) return

        trackPromise(
            ModerationKeywordsService.delete(uuid)
                .then(() => {
                    toast.success('Moderation keyword has been deleted')
                    setRefetch(true)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.NotFound) {
                            toast.error("Moderation keyword with this uuid doesn't exist! Try again.")
                            return
                        } else {
                            error.handleUnknown('An error occurred while deleting the moderation keyword.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.delete
        )
    }, [])

    const handleKeywordEditSelection = useCallback((moderationKeyword: ModerationKeyword) => {
        setSelectedModerationKeyword(moderationKeyword)
        toggleModerationKeywordChangeModal()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const formatLanguageLabel = useCallback(
        (languageId: number) =>
            languageOptions?.length ? languageOptions.find((options) => options?.data?.id === languageId)?.label : '-',
        [languageOptions]
    )

    const fetchData = useCallback(
        (page: number) => {
            return trackPromise(
                ModerationKeywordsService.list(page, moderationType, languageCode)
                    .then((data) => {
                        setRefetch(false)

                        return {
                            totalPages: 0,
                            totalElements: data.keywords.length,
                            elements: data.keywords.map((keyword, i) => ({
                                id: keyword.uuid,
                                columns: [
                                    keyword.uuid,
                                    keyword.name,
                                    keyword.languageId ? formatLanguageLabel(keyword.languageId) : 'global',
                                    <NoWrapText key={`type-${keyword.uuid}`}>
                                        {keyword.type === ModerationKeywordType.BlockedWords
                                            ? 'Blocked word'
                                            : 'Reported word'}
                                    </NoWrapText>,
                                    <Row key={i}>
                                        <Button
                                            onClick={() => handleKeywordEditSelection(keyword)}
                                            noMargin
                                            fullWidth
                                            loadingArea={loadingAreas.edit}
                                            icon={<EditIcon />}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            onClick={() => deleteModerationKeyword(keyword)}
                                            noMargin
                                            fullWidth
                                            loadingArea={loadingAreas.delete}
                                            icon={<DeleteIcon />}
                                        >
                                            Delete
                                        </Button>
                                    </Row>,
                                ],
                            })),
                        }
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while getting moderation keywords.')
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.container
            )
        },
        [moderationType, languageCode, formatLanguageLabel, handleKeywordEditSelection, deleteModerationKeyword]
    )

    const languageCodeOptions = [
        ...Object.keys(LanguageCode).map((typeKey) => ({
            label: typeKey,
            identifier: typeKey,
            // eslint-disable-next-line
            // @ts-ignore
            data: LanguageCode[typeKey],
        })),
        {
            label: 'Global',
            identifier: 'global',
            data: null,
        },
    ]

    const moderationTypeOptions = Object.keys(ModerationKeywordType).map((typeKey) => ({
        label: typeKey,
        identifier: typeKey,
        // eslint-disable-next-line
        // @ts-ignore
        data: ModerationKeywordType[typeKey],
    }))

    const onCloseActionModal = () => {
        setSelectedModerationKeyword(null)
        toggleModerationKeywordChangeModal()
    }

    const onSuccess = () => {
        setRefetch(true)
        onCloseActionModal()
    }

    const initialData = {
        language: {
            label: languageCode,
            data: languageCode,
        },
        moderationType: {
            label: moderationType,
            data: moderationType,
        },
    }

    const changeLanguage = (data: LanguageCode) => setLanguageCode(data)
    const changeModerationType = (data: ModerationKeywordType) => setModerationType(data)

    return (
        <section>
            <Meta title="Moderation Keywords" />
            <Stack gutter={5}>
                <h2>Moderation Keywords</h2>
                <Form ref={formRef} onSubmit={() => {}} initialData={initialData}>
                    <FilterFormGrid gridAmount={2}>
                        <SelectElement
                            label="Language code"
                            noMargin
                            options={languageCodeOptions}
                            defaultValue={languageCodeOptions.findIndex((option) => option.data === languageCode)}
                            onUpdate={changeLanguage}
                        />
                        <SelectElement
                            label="Moderation Type"
                            noMargin
                            options={moderationTypeOptions}
                            defaultValue={moderationTypeOptions.findIndex((option) => option.data === moderationType)}
                            onUpdate={changeModerationType}
                        />
                    </FilterFormGrid>
                    <FilterFormGrid gridAmount={3}>
                        <Button
                            onClick={toggleModerationKeywordChangeModal}
                            noMargin
                            fullWidth
                            icon={<AddIcon />}
                            type="button"
                        >
                            Add keyword/s
                        </Button>
                        <div />
                        {/* <Search onSearch={setSearchQuery} fullWidth label="⠀" /> */}
                    </FilterFormGrid>
                </Form>

                <RefactoredTable
                    columns={['#', 'Name', 'Language', 'Type', 'Actions']}
                    loadData={fetchData}
                    loadingArea={loadingAreas.container}
                    refetchKey={`${languageCode}_${moderationType}_${refetch}`}
                />
            </Stack>

            <Modal
                show={showModerationKeywordChangeModal}
                onClose={onCloseActionModal}
                title={selectedModerationKeyword ? 'Edit moderation keyword' : 'Add moderation keyword/s'}
            >
                <ModerationKeywordsForm
                    moderationKeyword={selectedModerationKeyword}
                    onSuccess={onSuccess}
                    languageOptions={languageOptions}
                />
            </Modal>
        </section>
    )
}

export default ModerationKeywordsComponent
