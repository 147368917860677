import React, { useCallback, useEffect, useState } from 'react'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import Stack from 'components/elements/Stack'
import Meta from 'components/modules/Head'
import CareerPartnerService from 'services/CareerPartnerService'
import { CareerPartner } from 'interfaces/CareerPartner'
import Box from 'components/elements/Box'
import CopyIcon from 'components/elements/CopyIcon'
import GoBackButton from 'components/elements/GoBackButton'
import LoadingArea from 'components/elements/LoadingArea'
import routes from 'lib/constants/routes'
import { RouteComponentProps } from 'react-router'
import CareersPartnersForm from './CareersPartnersForm'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Button from 'components/elements/Button'
import { useUserTargetingForm } from 'utils/hooks/useUserTargetingForm'
import Tabs from 'components/modules/Tabs'
import CareerPartnerPostsForm from './CareerPartnerPostsForm'

const loadingAreas = {
    container: 'careerPartnerContainer',
    delete: 'deleteCareerPartner',
}

interface Props extends RouteComponentProps<{ uuid: string }> {}

const CareersPartnerComponent = (props: Props) => {
    const [careerPartner, setCareerPartner] = useState<CareerPartner | null>(null)
    const history = useHistory()

    const uuid = props.match.params.uuid
    const isNew = !uuid || uuid === 'create'

    const fetchData = useCallback(() => {
        if (!uuid || isNew) return

        trackPromise(
            CareerPartnerService.get(uuid)
                .then((careerPartner) => {
                    setCareerPartner(careerPartner)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting career partner.')
                }),
            loadingAreas.container
        )
    }, [isNew, uuid])

    const { userTargetingId, renderTargetingModal, renderTargetingButton, renderTargetingInformation } =
        useUserTargetingForm(careerPartner?.userTargetingId ?? undefined, isNew, fetchData, {
            hideDaysSinceUserCreation: true,
        })

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const updateCareerPartner = (careerPartner: CareerPartner) => setCareerPartner(careerPartner)

    const deleteCareerPartner = (careerPartner: CareerPartner) => {
        if (!window.confirm(`Do you really want to delete Career Partner ${careerPartner.name}?`)) return

        trackPromise(
            CareerPartnerService.delete(careerPartner.uuid)
                .then(() => {
                    history.push(routes.careerPartnersRoute)
                    toast.success(`Career Partner ${careerPartner.name} was deleted!`)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while deleting the Career Partner.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.delete
        )
    }

    return (
        <section>
            <Meta title="Competition" />
            <h2>Career Partner</h2>
            <GoBackButton route={routes.careerPartnersRoute} routeName="Career Partners" />
            <br />
            {!isNew && careerPartner ? (
                <Tabs>
                    <div data-label="Info">
                        <Box>
                            <Stack>
                                <h3>Information</h3>
                                <LoadingArea area={loadingAreas.container}>
                                    <>
                                        <p>
                                            <strong>Uuid:</strong>&nbsp;
                                            {careerPartner.uuid}
                                            <CopyIcon text={careerPartner.uuid} />
                                        </p>
                                        <p>
                                            <strong>Name:</strong>&nbsp;
                                            {careerPartner.name}
                                        </p>
                                        {careerPartner?.knowerUuid ? (
                                            <p>
                                                <strong>Knower uuid:</strong>&nbsp;
                                                <Link target="_blank" to={routes.knowerRoute(careerPartner.knowerUuid)}>
                                                    {careerPartner.knowerUuid}
                                                </Link>
                                                <CopyIcon text={careerPartner.knowerUuid} />
                                            </p>
                                        ) : null}
                                        {careerPartner?.customerUuid ? (
                                            <p>
                                                <strong>Customer uuid:</strong>&nbsp;
                                                <Link
                                                    target="_blank"
                                                    to={routes.adsCustomerRoute(careerPartner.customerUuid)}
                                                >
                                                    {careerPartner.customerUuid}
                                                </Link>
                                                <CopyIcon text={careerPartner.customerUuid} />
                                            </p>
                                        ) : null}
                                        {careerPartner?.userTargetingId ? renderTargetingInformation() : null}
                                        <br />
                                        <Button
                                            onClick={() => deleteCareerPartner(careerPartner)}
                                            fullWidth={false}
                                            color="var(--red)"
                                            hoverColor="var(--red-dark)"
                                            loadingArea={loadingAreas.delete}
                                        >
                                            Delete Career Partner
                                        </Button>
                                    </>
                                </LoadingArea>
                            </Stack>
                        </Box>
                    </div>
                    <div data-label="Update">
                        <CareersPartnersForm
                            isNew={isNew}
                            careerPartner={careerPartner}
                            updateCareerPartner={updateCareerPartner}
                            userTargetingId={userTargetingId}
                            renderTargetingButton={renderTargetingButton}
                        />
                    </div>
                    <div data-label="Posts">
                        <CareerPartnerPostsForm careerPartnerUuid={careerPartner.uuid} />
                    </div>
                </Tabs>
            ) : (
                <CareersPartnersForm
                    isNew={isNew}
                    careerPartner={careerPartner}
                    updateCareerPartner={updateCareerPartner}
                    userTargetingId={userTargetingId}
                    renderTargetingButton={renderTargetingButton}
                />
            )}

            {renderTargetingModal()}
        </section>
    )
}

export default CareersPartnerComponent
