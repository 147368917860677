/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react'
import { Form } from '@unform/web'
import { FormHandles, SubmitHandler } from '@unform/core'
import Button from 'components/elements/Button'
import LoadingArea from 'components/elements/LoadingArea'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { badRequestFormErrors } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import KnowService from 'services/KnowService'
import { AxiosResponse } from 'axios'
import { Checkbox } from 'components/inputs/Input'

const loadingAreas = {
    save: 'saveKnow',
    form: 'knowForm',
}

interface FormData {
    approveDelete: boolean
}

interface Props {
    onSuccess: () => void
    knowUuids: string[]
}

const KnowDeletingForm = ({ knowUuids, onSuccess }: Props) => {
    const formRef = useRef<FormHandles>(null)

    const handleKnowsTransfer = async () => {
        const promises: Promise<void | AxiosResponse<any, any>>[] = []

        await knowUuids.forEach((uuid) => {
            const promise = trackPromise(
                KnowService.delete(uuid).catch((error: ApiError) => {
                    if (error.type === ErrorTypes.FormValidation) {
                        badRequestFormErrors(error, formRef.current!)
                    } else {
                        error.handleUnknown('An error occurred while deleting knows.')
                    }
                }),
                loadingAreas.save
            )
            promises.push(promise)
        })
        await Promise.all(promises).then(() => {
            toast.success(`${knowUuids.length} knows have been deleted!`)
            onSuccess()
        })
    }

    const handleSubmit: SubmitHandler<FormData> = async (data) => {
        formRef.current!.setErrors({})

        if (!data.approveDelete) {
            formRef.current?.setFieldError('approveDelete', 'You need to approve the deletion of the knows!')
            return
        }

        await handleKnowsTransfer()
    }

    return (
        <div>
            <LoadingArea area={loadingAreas.form}>
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <Checkbox
                        name="approveDelete"
                        placeholder="I am aware that by clicking the button I will delete all selected knows permanently"
                    />
                    <Button fullWidth loadingArea={loadingAreas.save} color="var(--red)" hoverColor="var(--red-dark)">
                        {`Delete ${knowUuids.length} know/knows`}
                    </Button>
                </Form>
            </LoadingArea>
        </div>
    )
}

export default KnowDeletingForm
