import ApiService from 'services/ApiService'
import { ManualChatMessage, CreateManualChatMessage } from 'interfaces/ManualChatMessage'

const ManualChatMessagesService = {
    list(page: number) {
        return ApiService.get(`/manual-chat-messages?page=${page}`).then((response) => {
            return {
                chatMessages: response.data.content as ManualChatMessage[],
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    create(message: CreateManualChatMessage) {
        return ApiService.post('/manual-chat-messages', message).then((response) => {
            return response.data as ManualChatMessage
        })
    },

    duplicate(uuid: string) {
        return ApiService.post(`/manual-chat-messages/${uuid}/duplicate`).then((response) => {
            return response.data as ManualChatMessage
        })
    },

    update(uuid: string, chatMessage: CreateManualChatMessage) {
        return ApiService.patch(`/manual-chat-messages/${uuid}`, chatMessage).then((response) => {
            return response.data as ManualChatMessage
        })
    },

    get(uuid: string) {
        return ApiService.get(`/manual-chat-messages/${uuid}`).then((response) => {
            return response.data as ManualChatMessage
        })
    },
    approve(uuid: string) {
        return ApiService.post(`/manual-chat-messages/${uuid}/approve`).then((response) => {
            return response.data as ManualChatMessage
        })
    },

    revoke(uuid: string) {
        return ApiService.delete(`/manual-chat-messages/${uuid}/approve`).then((response) => {
            return response.data as ManualChatMessage
        })
    },

    send(uuid: string) {
        return ApiService.post(`/manual-chat-messages/${uuid}/send`).then((response) => {
            return response.data as ManualChatMessage
        })
    },

    getEstimatedReceiversCount(uuid: string) {
        return ApiService.get(`/manual-chat-messages/${uuid}/estimated-receivers-count`).then((response) => {
            return response.data as number
        })
    },
}

export default ManualChatMessagesService
