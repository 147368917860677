import React from 'react'
import Spinner from 'components/elements/Spinner'
import Button from 'components/elements/Button'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg'
import { ReactComponent as SendIcon } from 'assets/icons/send.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import Box from 'components/elements/Box'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/reducer'
import { ManualEmail } from 'interfaces/ManualEmails'
import ManualEmailsService from 'services/ManualEmailsService'
import Stack from 'components/elements/Stack'
import { useUserCount } from 'lib/features/userCount'
import { useHistory } from 'react-router-dom'
import { Body2 } from 'components/elements/Text'
import routes from 'lib/constants/routes'

interface Props {
    email: ManualEmail
    fetchData: () => void
}

const loadingAreas = {
    approve: 'approveEmail',
    send: 'sendEmail',
    sendDraft: 'sendDraftEmail',
    revoke: 'revokeEmail',
    duplicate: 'duplicateEmail',
}

const ManualEmailAction = ({ email, fetchData }: Props) => {
    const authenticatedUser = useSelector((state: AppState) => state.user)
    const emailUuid = email.uuid
    const history = useHistory()

    const sendEmail = () => {
        trackPromise(
            ManualEmailsService.send(emailUuid)
                .then(() => {
                    toast.success('Manual email has been sent')
                    fetchData()
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.Duplicate) {
                            toast.error('This manual email has already been send!')
                        } else if (error.type === ErrorTypes.BadRequest) {
                            toast.error(error.message)
                        } else {
                            error.handleUnknown('An error occurred while sending the manual email.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.send
        )
    }

    const { runValidation, renderUserCountModal } = useUserCount(
        () => ManualEmailsService.getEstimatedReceiversCount(emailUuid),
        sendEmail
    )

    const handleApprove = () => {
        trackPromise(
            ManualEmailsService.approve(emailUuid)
                .then(() => {
                    toast.success('Manual email has been approved')
                    fetchData()
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.Duplicate) {
                            toast.error('This manual email has already been approved!')
                        } else {
                            error.handleUnknown('An error occurred while approving the manual email.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.approve
        )
    }

    const handleRevoke = () => {
        trackPromise(
            ManualEmailsService.revoke(emailUuid)
                .then(() => {
                    toast.success('Approval for manual email has been revoked')
                    fetchData()
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while revoking approval from the manual email.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.revoke
        )
    }

    const handleSend = () => runValidation()

    const handleSendDraft = () => {
        trackPromise(
            ManualEmailsService.sendDraft(emailUuid)
                .then(() => {
                    toast.success('Manual email draft has been sent')
                    fetchData()
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while sending the manual email draft.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.sendDraft
        )
    }

    const isApproverUser = email.approvedByUser?.uuid === authenticatedUser?.uuid
    const isOwner = email.user.uuid === authenticatedUser?.uuid
    const isApproved = !!email.approvedOn && !!email.approvedByUser
    const isSent = !!email.sentOn

    const duplicateEmail = () => {
        trackPromise(
            ManualEmailsService.duplicate(emailUuid)
                .then((email) => {
                    toast.success('Manual email has been duplicated')
                    history.push(routes.manualEmailRoute(email.uuid, 'general'))
                    window.location.reload()
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while duplicating the manual email.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.duplicate
        )
    }

    if (!email) return <Spinner forceShow />

    return (
        <Box>
            <Stack>
                <h3>General actions</h3>
                <Body2>
                    After clicking on the duplicate option you would be redirected to the create email form with prefilled
                    information
                </Body2>
                <Button fullWidth onClick={duplicateEmail} loadingArea={loadingAreas.duplicate}>
                    Duplicate this email
                </Button>
                <h3>Approval</h3>
                <Body2>Every email has to be approved by a different team member in the company.</Body2>

                {!isSent && (
                    <Button fullWidth icon={<SendIcon />} loadingArea={loadingAreas.sendDraft} onClick={handleSendDraft}>
                        Send email draft to yourself
                    </Button>
                )}
                {isApproved &&
                    (isApproverUser ? (
                        <Button fullWidth icon={<CloseIcon />} loadingArea={loadingAreas.revoke} onClick={handleRevoke}>
                            Revoke approval for manual email
                        </Button>
                    ) : (
                        <p>Only the person that added the approval can also remove the approval.</p>
                    ))}
                {!isApproved && (
                    <Button fullWidth icon={<CheckIcon />} loadingArea={loadingAreas.approve} onClick={handleApprove}>
                        Approve manual email
                    </Button>
                )}
                <h3>Send</h3>
                {isApproved && isOwner && !isSent ? (
                    <>
                        <Button fullWidth icon={<SendIcon />} loadingArea={loadingAreas.send} onClick={handleSend}>
                            Send manual email
                        </Button>
                    </>
                ) : (
                    <>
                        {!isApproved && <p>The email has to be approved before it can be sent.</p>}
                        {!isOwner && <p>Only the creator of the email can send it out.</p>}
                        {isSent && <p>The email has been already send.</p>}
                    </>
                )}
            </Stack>
            {renderUserCountModal()}
        </Box>
    )
}

export default ManualEmailAction
