export interface UserRolesUpdateRequest {
    admin: boolean
    content: boolean
    customerSupport: boolean
    report: boolean
    sales: boolean
    expansion: boolean
    experimentationPlatform: boolean
}

export enum Roles {
    admin = 'ADMIN',
    sales = 'SALES',
    content = 'CONTENT',
    customerSupport = 'CUSTOMER_SUPPORT',
    report = 'REPORT',
    product = 'PRODUCT',
    finance = 'FINANCE',
    expansion = 'EXPANSION',
    experimentationPlatform = 'EXPERIMENTATION_PLATFORM',
}
