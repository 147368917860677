import ApiService from 'services/ApiService'
import { AdAttachment, AdAttachmentRequest } from 'interfaces/AdAttachmentSet'

const AdAttachmentService = {
    listAttachmentsByCustomers(customerUuid: string) {
        return ApiService.get(`/ads/customers/${customerUuid}/attachments`).then((response) => {
            return response.data.content as AdAttachment[]
        })
    },

    createSet(data: AdAttachmentRequest) {
        return ApiService.post('/ads/assets/attachments', data).then((response) => {
            return response.data as AdAttachment
        })
    },

    updateSet(uuid: string, data: AdAttachmentRequest) {
        return ApiService.patch(`/ads/assets/attachments/${uuid}`, data).then((response) => {
            return response.data as AdAttachment
        })
    },

    deleteSet(setUuid: string) {
        return ApiService.delete(`/ads/assets/attachments/${setUuid}`)
    },
}

export default AdAttachmentService
