import React from 'react'
import Spinner from 'components/elements/Spinner'
import Button from 'components/elements/Button'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg'
import { ReactComponent as SendIcon } from 'assets/icons/send.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import Box from 'components/elements/Box'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/reducer'
import Stack from 'components/elements/Stack'
import { ManualChatMessage } from 'interfaces/ManualChatMessage'
import ManualChatMessagesService from 'services/ManualChatMessagesService'
import { useUserCount } from 'lib/features/userCount'
import { useHistory } from 'react-router'
import routes from 'lib/constants/routes'
import { Body2 } from 'components/elements/Text'

interface Props {
    chatMessage: ManualChatMessage
    fetchData: () => void
}

const loadingAreas = {
    approve: 'approveChatMessage',
    send: 'sendChatMessage',
    sendDraft: 'sendDraftChatMessage',
    revoke: 'revokeChatMessage',
    duplicate: 'duplicateChatMessage',
}

const ManualChatMessageAction = ({ chatMessage, fetchData }: Props) => {
    const authenticatedUser = useSelector((state: AppState) => state.user)
    const chatMessageUuid = chatMessage.uuid
    const history = useHistory()

    const sendManualChatMessage = () => {
        trackPromise(
            ManualChatMessagesService.send(chatMessageUuid)
                .then(() => {
                    toast.success('Manual Chat Message has been sent')
                    fetchData()
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.Duplicate) {
                            toast.error('This manual chat message has already been send!')
                        } else {
                            error.handleUnknown('An error occurred while sending the manual chat message.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.send
        )
    }

    const { runValidation, renderUserCountModal } = useUserCount(
        () => ManualChatMessagesService.getEstimatedReceiversCount(chatMessageUuid),
        sendManualChatMessage
    )

    const handleApprove = () => {
        trackPromise(
            ManualChatMessagesService.approve(chatMessageUuid)
                .then(() => {
                    toast.success('Manual Chat Message has been approved')
                    fetchData()
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.Duplicate) {
                            toast.error('This Manual Chat Message has already been approved!')
                        } else {
                            error.handleUnknown('An error occurred while approving the Manual Chat Message.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.approve
        )
    }

    const handleRevoke = () => {
        trackPromise(
            ManualChatMessagesService.revoke(chatMessageUuid)
                .then(() => {
                    toast.success('Approval for manual chat message has been revoked')
                    fetchData()
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while revoking approval from the manual chat message.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.revoke
        )
    }

    const handleSend = () => runValidation()

    const isApproverUser = chatMessage.approvedByUser?.uuid === authenticatedUser?.uuid
    const isOwner = chatMessage.user.uuid === authenticatedUser?.uuid
    const isApproved = !!chatMessage.approvedOn && !!chatMessage.approvedByUser

    const duplicateChatMessage = () => {
        trackPromise(
            ManualChatMessagesService.duplicate(chatMessageUuid)
                .then((chatMessage) => {
                    toast.success('Manual chat message has been duplicated')
                    history.push(routes.manualChatMessageRoute(chatMessage.uuid))
                    window.location.reload()
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while duplicating the manual email.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.duplicate
        )
    }

    if (!chatMessage) return <Spinner forceShow />

    return (
        <Box>
            <Stack>
                <Body2>
                    After clicking on the duplicate option you would be redirected to the create chat message form with
                    prefilled information
                </Body2>
                <Button fullWidth onClick={duplicateChatMessage} loadingArea={loadingAreas.duplicate}>
                    Duplicate this chat message
                </Button>
                <h3>Approval</h3>
                <p>Every chat message has to be approved by a different team member in the company.</p>
                {isApproved &&
                    (isApproverUser ? (
                        <Button fullWidth icon={<CloseIcon />} loadingArea={loadingAreas.revoke} onClick={handleRevoke}>
                            Revoke approval for manual chat message
                        </Button>
                    ) : (
                        <p>Only the person that added the approval can also remove the approval.</p>
                    ))}
                {!isApproved && (
                    <Button fullWidth icon={<CheckIcon />} loadingArea={loadingAreas.approve} onClick={handleApprove}>
                        Approve manual chat message
                    </Button>
                )}
                <h3>Send</h3>
                {isApproved && isOwner ? (
                    <>
                        <Button fullWidth icon={<SendIcon />} loadingArea={loadingAreas.send} onClick={handleSend}>
                            Send manual chat message
                        </Button>
                    </>
                ) : (
                    <>
                        {!isApproved && <p>The chat message has to be approved before it can be sent.</p>}
                        {!isOwner && <p>Only the creator of the chat message can send it out.</p>}
                    </>
                )}
            </Stack>
            {renderUserCountModal()}
        </Box>
    )
}

export default ManualChatMessageAction
