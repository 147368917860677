import React from 'react'
import ContentPreview from 'components/modules/ContentPreview/ContentPreview'
import { AttachmentData } from 'interfaces/DiscussionForumQuestion'

interface Props {
    attachmentData: AttachmentData
}

const DiscussionForumContentPreview = ({ attachmentData }: Props) => (
    <ContentPreview contentType={attachmentData.fileType} previewUrl={attachmentData.contentUrl} />
)

export default DiscussionForumContentPreview
