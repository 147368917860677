import theme from 'lib/constants/theme'
import styled from 'styled-components'

interface BubbleWrapperProps {
    $isReportedUser: boolean
}

export const ChatWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: ${theme.colors.darkBackground}55;
    border-radius: ${theme.borderRadius.normal};
`

export const UsersTitle = styled.div`
    display: flex;
    justify-content: space-between;
`

export const BubbleWrapper = styled.div<BubbleWrapperProps>`
    display: flex;
    justify-content: ${({ $isReportedUser }) => ($isReportedUser ? 'flex-end' : 'start')};
    div {
        margin: 3px;
        min-width: 100px;
    }
    a {
        color: #fff;
        text-decoration: underline;
    }
    P {
        margin: 0;
    }
`

export const UserText = styled.p<BubbleWrapperProps>`
    color: ${({ $isReportedUser }) => ($isReportedUser ? 'var(--red)' : theme.colors.body2Black)};
    padding-bottom: 10px;
`
export const RightChatBubble = styled.div`
    background-color: var(--background-blue);
    border-radius: 5px 0 5px 5px;
    border: 1px solid var(--red);
    color: #fff;
    padding: 10px;
`

export const LeftChatBubble = styled.div`
    background-color: var(--background-blue);
    border-radius: 0 5px 5px 5px;
    color: #fff;
    border: 1px solid ${theme.colors.knowunityBlue};
    padding: 10px;
`
