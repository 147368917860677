import React, { useCallback } from 'react'

import Box from 'components/elements/Box'
import RefactoredTable from 'components/modules/RefactoredTable'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import KnowService from 'services/KnowService'
import { formatDate } from 'utils'

interface ReviewHistorySectionProps {
    knowUuid: string
    loadingAreaHistory: string
}

export const ReviewHistorySection = ({ loadingAreaHistory, knowUuid }: ReviewHistorySectionProps) => {
    const fetchReviewHistory = useCallback(
        (page: number) => {
            return trackPromise(
                KnowService.listReviewHistory(knowUuid, page)
                    .then((data) => {
                        if (page !== data.page) return
                        return {
                            totalPages: data.totalPages,
                            totalElements: data.totalElements,
                            elements: data.content.map((history) => ({
                                id: history.id,
                                columns: [
                                    history.status,
                                    formatDate(history.createdOn),
                                    history?.user ? `${history.user.name} (${history.user.uuid})` : 'unspecified user',
                                    history.message,
                                    history.reason,
                                ],
                            })),
                        }
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while listing know review history.')
                        } else {
                            throw error
                        }
                    }),
                loadingAreaHistory
            )
        },
        [knowUuid, loadingAreaHistory]
    )
    return (
        <Box style={{ marginTop: 10 }}>
            <h3>Review History</h3>
            <RefactoredTable
                key={'review-history'}
                columns={['Status', 'Date', 'User', 'Message', 'Reason']}
                loadData={fetchReviewHistory}
                loadingArea={loadingAreaHistory}
                refetchKey={knowUuid}
            />
        </Box>
    )
}
