import React, { useCallback, useEffect, useState } from 'react'
import LoadingArea from 'components/elements/LoadingArea'
import { RouteComponentProps } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import styled from 'styled-components'
import { SchoolType } from 'interfaces/SchoolType'
import SchoolTypeService from 'services/SchoolTypeService'
import GoBackButton from 'components/elements/GoBackButton'
import routes from 'lib/constants/routes'
import Meta from 'components/modules/Head'

interface Props extends RouteComponentProps<{ id: string }> {}

const loadingAreas = {
    container: 'schoolTypeContainer',
}

const Container = styled.section`
    padding-bottom: 25px;
`

const SchoolTypeComponent = (props: Props) => {
    const [schoolType, setSchoolType] = useState<SchoolType | null>(null)
    const id = props.match.params.id

    const fetchData = useCallback(() => {
        if (!id) return

        trackPromise(
            SchoolTypeService.get(+id)
                .then((schoolType) => {
                    setSchoolType(schoolType)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting school type.')
                }),
            loadingAreas.container
        )
    }, [id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <Container>
            <Meta title="School Type" />
            <h2>School Type</h2>
            <GoBackButton route={routes.schoolTypesRoute} routeName="School Types" />
            {schoolType && (
                <Box>
                    <Stack>
                        <h3>Information</h3>
                        <LoadingArea area={loadingAreas.container}>
                            {schoolType && (
                                <>
                                    <p>
                                        <strong>Id:</strong>&nbsp;
                                        {schoolType.id}
                                    </p>
                                    <p>
                                        <strong>Name:</strong>&nbsp;
                                        {schoolType.name}
                                    </p>
                                    <p>
                                        <strong>Sort Id:</strong>&nbsp;
                                        {schoolType.sortId}
                                    </p>
                                    <p>
                                        <strong>Regions:</strong>&nbsp;
                                        {schoolType.regions.map((x) => x.regionName).join(', ')}
                                    </p>
                                    <p>
                                        <strong>Language:</strong>&nbsp;
                                        {schoolType.language.englishName}
                                    </p>
                                    <p>
                                        <strong>Country:</strong>&nbsp;
                                        {schoolType.country.localizedName}
                                    </p>
                                </>
                            )}
                        </LoadingArea>
                    </Stack>
                </Box>
            )}
        </Container>
    )
}

export default SchoolTypeComponent
