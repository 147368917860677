import { UserRolesUpdateRequest } from 'interfaces/UserRole'
import { GlobalAuthApiService } from './ApiService'

const UserRoleService = {
    get(uuid: string) {
        return GlobalAuthApiService.get(`accounts/${uuid}/roles`).then((response) => {
            return response.data.content as string[]
        })
    },
    patch(uuid: string, data: UserRolesUpdateRequest) {
        return GlobalAuthApiService.patch(`accounts/${uuid}/roles`, data).then((response) => {
            return response.data
        })
    },
}

export default UserRoleService
