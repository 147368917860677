import { LanguageInterface } from './Language'
import { ManualCommmunicationStatus } from './ManualCommunicationPlaceholder'
import { User } from './User'

export enum EmailCategories {
    Feedback = 'FEEDBACK',
    Knower = 'KNOWER',
    Advertisement = 'ADVERTISEMENT',
    FeatureAnnouncement = 'FEATURE_ANNOUNCEMENT',
    Mandatory = 'MANDATORY',
    Standard = 'STANDARD',
}

export type ManualEmail = {
    uuid: string
    approvedByUser: User | null
    approvedOn: string | null
    bodyDesign: string
    bodyHtml: string
    category: EmailCategories
    language: LanguageInterface
    sentOn: string | null
    subject: string
    type: string
    user: User
    userTargetingId: number
    actualUsersCount: number | null
    expectedUsersCount: number | null
    fromName: string | null
    fromEmail: string | null
    replyTo: string | null
    ignoreEmailConfirmation: boolean
    scheduledOn: string | null
    status: ManualCommmunicationStatus
    lastProcessedOn: string | null
    experimentVariantIdentifier: string | null
}

export type UpdateEmailBody = {
    bodyDesign: string
    bodyHtml: string
}

export type ManualEmailRequest = {
    bodyDesign: string
    bodyHtml: string
    languageId: number
    userTargetingId: number
    subject: string
    type: string
    category: EmailCategories
    fromName: string | null
    fromEmail: string | null
    replyTo: string | null
    ignoreEmailConfirmation: boolean
    scheduledOn?: string | null
    experimentVariantIdentifier: string | null
}
