import { AuthToken } from 'interfaces/AuthToken'
import { User } from 'interfaces/User'
import { ReviewSettings } from 'interfaces/ReviewSettings'
import { ReportSettings } from 'interfaces/ReportSettings'

export interface State {
    oauthToken: AuthToken | null
    user: User | null
    reviewSettings: ReviewSettings | null
    reportSettings: ReportSettings | null
}

export const SET_OAUTH_TOKEN = 'SET_OAUTH_TOKEN'
export interface SetOauthTokenAction {
    type: typeof SET_OAUTH_TOKEN
    payload: AuthToken | null
}

export const SET_USER = 'SET_USER'
export interface SetUserAction {
    type: typeof SET_USER
    payload: User | null
}

export const SET_REVIEW_SETTINGS = 'SET_REVIEW_SETTINGS'
export interface SetReviewAction {
    type: typeof SET_REVIEW_SETTINGS
    payload: ReviewSettings | null
}

export const SET_REPORT_SETTINGS = 'SET_REPORT_SETTINGS'

export interface SetReportAction {
    type: typeof SET_REPORT_SETTINGS
    payload: ReportSettings | null
}

export type ActionTypes = SetOauthTokenAction | SetUserAction | SetReviewAction | SetReportAction
