import React, { useCallback, useEffect, useState } from 'react'
import { ApiError } from 'services/ApiService'
import { Body2 } from 'components/elements/Text'
import KnowService from 'services/KnowService'
import Button from 'components/elements/Button'
import { useToggleState } from 'utils/hooks/useToggleState'
import Modal from 'components/modules/Modal'
import styled from 'styled-components'
import theme from 'lib/constants/theme'
import { useActionsMenuBar } from 'utils/hooks/useActionsMenuBar'
import { Know } from 'interfaces/Know'
import { filterRejectionReasonsByVialionLevel } from 'lib/features/knows'
import { KnowRejectionReason } from 'interfaces/KnowRejectionReasons'
import LoadingArea from 'components/elements/LoadingArea'

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    .images {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        img {
            border-radius: ${theme.borderRadius.normal};
        }
    }
`

interface Props {
    knowUuid: string
    updateKnow: (know: Know) => void
    isKnowInReview: boolean
    showEditKnowModal: boolean
}

const KnowVisualSimilarImages = ({ knowUuid, updateKnow, isKnowInReview, showEditKnowModal }: Props) => {
    const [showKnowVisualImagesModal, toggleKnowVisualImagesModal] = useToggleState(false)
    const [similarImages, setSimilarImages] = useState<string[]>()
    const [mediaQualityReason, setMediaQualityReason] = useState<KnowRejectionReason>()
    const { rejectKnow } = useActionsMenuBar({ updateKnow, knowUuid, isKnowInReview, showEditKnowModal })

    useEffect(() => {
        KnowService.getRejectionReasons()
            .then((rejectionReasons) =>
                setMediaQualityReason(
                    filterRejectionReasonsByVialionLevel(rejectionReasons, 'QUALITY').filter(
                        (reason) => reason.name === 'MEDIA_COPYRIGHT'
                    )[0]
                )
            )
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('An error occurred while getting rejection reasons.')
                } else {
                    throw error
                }
            })
    }, [])

    const fetchAssets = useCallback(() => {
        KnowService.getVisuallySimilarKnows(knowUuid)
            .then((images) => {
                setSimilarImages(images)
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('An error occurred while getting plagiarism candidates.')
                } else {
                    throw error
                }
            })
    }, [knowUuid])

    const onShowKnowSimilarImagesButtonClick = () => {
        toggleKnowVisualImagesModal()
        fetchAssets()
    }

    const onRejectKnowClick = () => {
        if (!mediaQualityReason) return
        rejectKnow(mediaQualityReason)
    }

    return (
        <>
            <Button fullWidth={false} onClick={onShowKnowSimilarImagesButtonClick}>
                {`Show potential copyright violations`}
            </Button>
            <Modal
                title="Potential copyright violations"
                show={showKnowVisualImagesModal}
                onClose={toggleKnowVisualImagesModal}
            >
                <Container>
                    <Body2 color={theme.colors.white}>Image results from search request</Body2>
                    <div className="images">
                        {similarImages?.length ? (
                            similarImages?.map((image, i) => (
                                <img key={i} src={image} height={250} alt="potential plagiarism" />
                            ))
                        ) : (
                            <LoadingArea />
                        )}
                    </div>
                    <Button fullWidth={false} onClick={onRejectKnowClick}>
                        {`Reject for copyright`}
                    </Button>
                </Container>
            </Modal>
        </>
    )
}

export default KnowVisualSimilarImages
