import React, { useCallback, useState } from 'react'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import RefactoredTable from 'components/modules/RefactoredTable'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import Stack from 'components/elements/Stack'
import Meta from 'components/modules/Head'
import { ControlWrapper, FilterFormGrid } from 'style'
import routes from 'lib/constants/routes'
import Search from 'components/elements/Search'
import { ButtonLink } from 'components/elements/Button'
import theme from 'lib/constants/theme'
import { CountryCode } from 'interfaces/Country'
import SelectElement from 'components/inputs/SelectElement'
import SchoolsService from 'services/SchoolsService'
import { useCountryOptions } from 'hooks/useCountryOptions'

const loadingAreas = {
    container: 'schoolsContainer',
}

const Schools = () => {
    const [searchQuery, setSearchQuery] = useState<string>('')
    const [selectedCountry, setSelectedCountry] = useState<CountryCode>(CountryCode.Germany)

    const fetchData = useCallback(
        (page: number) => {
            return trackPromise(
                SchoolsService.list(page, searchQuery, selectedCountry)
                    .then((data) => {
                        return {
                            totalPages: data.totalPages,
                            totalElements: data.totalElements,
                            elements: data.schools.map((s) => ({
                                id: s.uuid,
                                columns: [
                                    s.uuid,
                                    s.name,
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    `${s.streetWithNumber}, ${s.city ?? (s as any)?.locationName}`,
                                    <img key={s.uuid} width={30} height="auto" src={s.iconUrl} alt="school icon" />,
                                ],
                                onClick: routes.schoolRoute(s.uuid),
                            })),
                        }
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while getting moderation keywords.')
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.container
            )
        },
        [searchQuery, selectedCountry]
    )

    const countryOptions = useCountryOptions()

    const updateCountryCode = (countryCode: CountryCode) => setSelectedCountry(countryCode)

    return (
        <section>
            <Meta title="Schools" />
            <Stack gutter={5}>
                <h2>School</h2>
                <FilterFormGrid gridAmount={3}>
                    <ControlWrapper>
                        <ButtonLink
                            to={routes.schoolCreateRoute}
                            fullWidth={false}
                            icon={<AddIcon />}
                            hoverColor={theme.colors.knowunityBlue}
                            color={theme.colors.knowunityBlueDark}
                        >
                            Creat a School
                        </ButtonLink>
                    </ControlWrapper>
                    <Search onSearch={setSearchQuery} fullWidth label="Search by school name" />
                    <SelectElement label="Country code" noMargin options={countryOptions} onUpdate={updateCountryCode} />
                </FilterFormGrid>
                <RefactoredTable
                    columns={['#', 'Name', 'Location name', 'Icon']}
                    loadData={fetchData}
                    loadingArea={loadingAreas.container}
                />
            </Stack>
        </section>
    )
}

export default Schools
