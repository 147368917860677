import React, { useEffect, useRef, useState } from 'react'
import { Form } from '@unform/web'
import { Checkbox, Input, SelectMultiple, SelectOptionWithLabel } from 'components/inputs/Input'
import Button from 'components/elements/Button'
import { FormHandles, SubmitHandler } from '@unform/core/typings/types'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { toast } from 'react-toastify'
import { trackPromise } from 'react-promise-tracker'
import { badRequestFormErrors, getFileNameFromFileUrl } from 'utils'
import { ApiError } from 'services/ApiService'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { AdsAsset, AdsAssetFormat, AdsAssetRequest, AdsAssetContentType } from 'interfaces/AdsAsset'
import ContentPreview from 'components/modules/ContentPreview/ContentPreview'
import { ContentTypes } from 'interfaces/Know'
import FileUploadService from 'services/FileUploadService'
import { ContentPrefixes } from 'utils/constants/ContentPrefix'
import UploadWrapper from 'components/FileUpload/UploadWrapper'
import FormSelect from 'components/inputs/FormSelect'
import FormatImage from 'assets/ads/formats.jpg'
import styled from 'styled-components'
import { hasWhiteSpace } from 'utils/isValidHttpUrl'
import { generateInitialValue } from 'utils/forms'
import AdAttachmentService from 'services/AdAttachmentService'
import { AdAttachment } from 'interfaces/AdAttachmentSet'
import { returnAttachmentContent } from 'utils/adAttachments'

const FormatImageContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
`

interface UploadContent {
    filename: string
    url: string
}

interface FormData {
    name: string
    contentType: SelectOptionWithLabel<AdsAssetContentType>
    boostedForUserUuids: string
    url: string
    ctaText: string | null
    knowUuid: string | null
    format: SelectOptionWithLabel<AdsAssetFormat>
    attachments: SelectOptionWithLabel<string>[]
    showCTA: boolean
    useInAppBrowser: boolean
}

const loadingAreas = {
    imageUpload: 'adsAssetFormImageUpload',
    save: 'adsAssetFormSave',
}

const contentTypeOptions: SelectOptionWithLabel<AdsAssetContentType>[] = [
    {
        label: 'Image',
        identifier: 'image',
        data: AdsAssetContentType.Image,
    },
    {
        label: 'Video',
        identifier: 'video',
        data: AdsAssetContentType.Video,
    },
    {
        label: 'Know',
        identifier: 'know',
        data: AdsAssetContentType.Know,
    },
]

const formatOptions: SelectOptionWithLabel<AdsAssetFormat>[] = [
    {
        label: 'Banner',
        identifier: 'banner',
        data: AdsAssetFormat.Banner,
    },
    {
        label: 'Full screen',
        identifier: 'full-screen',
        data: AdsAssetFormat.FullScreen,
    },
]

interface Props {
    isNew: boolean
    customerUuid: string
    asset?: AdsAsset
    onSubmit(data: AdsAssetRequest): void
    apiError?: ApiError
    assetAttachments?: AdAttachment[]
}

const AdAssetForm = (props: Props) => {
    const [uploadedContent, setUploadedContent] = useState<UploadContent | null>(null)
    const [initialData, setInitialData] = useState<FormData | undefined>()
    const [selectedAdContentType, setSelectedAdContentType] = useState<AdsAssetContentType>(AdsAssetContentType.Image)
    const [adAttachementsOptions, setAdAttachmentsOptions] = useState<SelectOptionWithLabel<string>[]>()
    const formRef = useRef<FormHandles>(null)

    const asset = props.asset
    const customerUuid = props.customerUuid
    const isNew = props.isNew

    useEffect(() => {
        if (!customerUuid || isNew) return

        AdAttachmentService.listAttachmentsByCustomers(customerUuid)
            .then((attachments) => {
                const attachmentOptions = attachments.map((attachment) => ({
                    label: `${returnAttachmentContent(attachment)} (${attachment.type})`,
                    identifier: attachment.uuid,
                    data: attachment.uuid,
                }))

                setAdAttachmentsOptions(attachmentOptions)
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('An error occurred while getting customer attachments.')
                } else {
                    throw error
                }
            })
    }, [customerUuid, isNew])

    useEffect(() => {
        if (!asset) return

        setSelectedAdContentType(asset.contentType)

        setInitialData({
            name: asset.name ?? '',
            contentType: generateInitialValue(contentTypeOptions, asset.contentType),
            url: asset.url ?? '',
            boostedForUserUuids: asset.boostedForUserUuids.join(',') ?? null,
            knowUuid: asset.knowUuid ?? null,
            ctaText: asset.ctaText ?? null,
            showCTA: asset.showCta ?? true,
            useInAppBrowser: asset.useInAppBrowser ?? false,
            format: generateInitialValue(formatOptions, asset.format),
            attachments: props.assetAttachments
                ? props.assetAttachments.map((attachment) => ({
                      label: `${returnAttachmentContent(attachment)} (${attachment.type})`,
                      identifier: attachment.uuid,
                      data: attachment.uuid,
                  }))
                : [],
        })
        if (asset.imageUrl) {
            setUploadedContent({
                filename: getFileNameFromFileUrl(asset.imageUrl),
                url: asset.imageUrl,
            })
        }
    }, [asset, props.assetAttachments, setSelectedAdContentType])

    useEffect(() => {
        if (!props.apiError) return
        badRequestFormErrors(props.apiError, formRef.current!)
    }, [props.apiError])

    const handleSubmit: SubmitHandler<FormData> = (formData) => {
        formRef.current!.setErrors({})

        if (!formData.url && selectedAdContentType !== AdsAssetContentType.Know) {
            formRef.current?.setFieldError('url', `Link is required!`)
            return
        }

        if (hasWhiteSpace(formData.url)) {
            formRef.current?.setFieldError('url', `Link can't contain whitespace!`)
            return
        }

        if (selectedAdContentType === AdsAssetContentType.Video) {
            if (formData.format.data === AdsAssetFormat.Banner) {
                formRef.current?.setFieldError('format', `The format for videos should be set to full screen!`)
                return
            }
        }

        const isImageOrVideo =
            formData.contentType.data === AdsAssetContentType.Image ||
            formData.contentType.data === AdsAssetContentType.Video

        const attachments = formData?.attachments?.length
            ? formData?.attachments?.map((attachmentOption) => attachmentOption.data)
            : []

        let body: AdsAssetRequest = {
            name: formData.name,
            contentType: formData.contentType.data,
            boostedForUserUuids:
                formData.boostedForUserUuids !== '' ? formData.boostedForUserUuids.split(',').map((u) => u.trim()) : [],
            url: formData.url,
            imageFilename: isImageOrVideo && uploadedContent ? uploadedContent.filename : null,
            ctaText: formData.ctaText,
            format: formData.format.data,
            attachments: attachments ?? null,
            showCTA: formData.showCTA ?? false,
            useInAppBrowser: formData.useInAppBrowser ?? false,
        }

        if (formData.knowUuid && formData.contentType.data === AdsAssetContentType.Know) {
            body = {
                ...body,
                knowUuid: formData.knowUuid,
            }
        }

        props.onSubmit(body)
    }

    const allowedFileFormats = ['image/png', 'image/jpg', 'video/mp4', 'image/gif']

    const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const innerFile = event.target.files![0]
        if (!innerFile) {
            toast.error('You need to upload an asset image!')
            return
        }
        const maxFileSize = 25 * 1000000

        if (!allowedFileFormats.includes(innerFile.type)) {
            toast.error('Please upload your file as image or video!')
            return
        }

        if (innerFile.size > maxFileSize) {
            toast.error('Sorry, the file is too big. The maximum file size is 25 MB.')
            return
        }

        trackPromise(
            FileUploadService.upload(innerFile, ContentPrefixes.AD)
                .then(({ url, filename }) => {
                    setUploadedContent({
                        url,
                        filename,
                    })
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.InvalidMIMEType) {
                            toast.error('Please choose a different file format.')
                        } else {
                            error.handleUnknown('An error occurred while uploading the image')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.imageUpload
        )
    }

    const handleContentTypeUpdate = (contentType: AdsAssetContentType) => setSelectedAdContentType(contentType)

    const isImageOrVideo =
        selectedAdContentType === AdsAssetContentType.Image || selectedAdContentType === AdsAssetContentType.Video

    return (
        <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
            <Input name="name" placeholder="Asset name" />
            <FormSelect
                name="contentType"
                placeholder="Content Type"
                noMargin
                options={contentTypeOptions}
                onUpdate={handleContentTypeUpdate}
            />
            <FormSelect name="format" options={formatOptions} placeholder="Format" noMargin={false} />
            {isImageOrVideo ? (
                <>
                    <UploadWrapper
                        handleFileUpload={onChangeFile}
                        area={loadingAreas.imageUpload}
                        acceptRules={allowedFileFormats.join(', ')}
                    >
                        <p>Click here to select an image or video.</p>
                        <ContentPreview
                            contentType={
                                selectedAdContentType === AdsAssetContentType.Image ? ContentTypes.image : ContentTypes.video
                            }
                            previewUrl={uploadedContent?.url ?? ''}
                        />
                    </UploadWrapper>
                    <FormatImageContainer>
                        <img src={FormatImage} alt="Ad Assets format" />
                    </FormatImageContainer>
                </>
            ) : (
                <Input name="knowUuid" placeholder="Know UUID" />
            )}
            <Input name="url" placeholder="URL" />
            <Input name="ctaText" placeholder="Call To Action Text" />
            <Checkbox name="showCTA" placeholder="Show CTA" />
            <Checkbox name="useInAppBrowser" placeholder="Use in app browser" />
            {!isNew && adAttachementsOptions ? (
                <SelectMultiple<string>
                    name="attachments"
                    options={adAttachementsOptions}
                    label="Ad Attachments"
                    //onUpdate={onUpdate}
                />
            ) : null}
            <Input name="boostedForUserUuids" placeholder="Boosted for users (enter several uuids separated by comma)" />
            <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                {props.isNew ? 'Create' : 'Update'}
            </Button>
        </Form>
    )
}

export default AdAssetForm
