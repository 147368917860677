import React, { useRef } from 'react'
import Spinner from 'components/elements/Spinner'
import Button from 'components/elements/Button'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg'
import { ReactComponent as SendIcon } from 'assets/icons/send.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import Box from 'components/elements/Box'
import { ManualPushNotification } from 'interfaces/ManualPushNotification'
import ManualPushNotificationsService from 'services/ManualPushNotificationsService'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/reducer'
import Stack from 'components/elements/Stack'
import { useUserCount } from 'lib/features/userCount'
import { useHistory } from 'react-router'
import routes from 'lib/constants/routes'
import { Body2 } from 'components/elements/Text'
import { Input } from 'components/inputs/Input'
import { Form } from '@unform/web'
import { FormHandles, SubmitHandler } from '@unform/core'

interface Props {
    notification: ManualPushNotification
    fetchData: () => void
}

const loadingAreas = {
    approve: 'approvePushNotification',
    send: 'sendPushNotification',
    revoke: 'revokePushNotification',
    duplicate: 'duplicatePushNotification',
    sendDraft: 'sendDraftPushNotification',
}

const ManualPushNotificationsAction = ({ notification, fetchData }: Props) => {
    const authenticatedUser = useSelector((state: AppState) => state.user)
    const notificationUuid = notification.uuid
    const history = useHistory()
    const formRef = useRef<FormHandles>(null)

    const sendManualPushNotification = () => {
        trackPromise(
            ManualPushNotificationsService.send(notificationUuid)
                .then(() => {
                    toast.success('Manual push notification has been sent')
                    fetchData()
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.Duplicate) {
                            toast.error('This Manual push notification has already been send!')
                        } else {
                            error.handleUnknown('An error occurred while sending the Manual push notification.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.send
        )
    }

    const { runValidation, renderUserCountModal } = useUserCount(
        () => ManualPushNotificationsService.getEstimatedReceiversCount(notificationUuid),
        sendManualPushNotification
    )

    const handleApprove = () => {
        trackPromise(
            ManualPushNotificationsService.approve(notificationUuid)
                .then(() => {
                    toast.success('Manual push notification has been approved')
                    fetchData()
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.Duplicate) {
                            toast.error('This Manual push notification has already been approved!')
                        } else {
                            error.handleUnknown('An error occurred while approving the Manual push notification.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.approve
        )
    }

    const handleRevoke = () => {
        trackPromise(
            ManualPushNotificationsService.revoke(notificationUuid)
                .then(() => {
                    toast.success('Approval for Manual push notification has been revoked')
                    fetchData()
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while revoking the Manual push notification.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.revoke
        )
    }

    const handleSend = () => runValidation()

    const isApproverUser = notification.approvedByUser?.uuid === authenticatedUser?.uuid
    const isOwner = notification.user.uuid === authenticatedUser?.uuid
    const isApproved = !!notification.approvedOn && !!notification.approvedByUser

    const isSent = !!notification.sentOn

    const duplicatePushNotification = () => {
        trackPromise(
            ManualPushNotificationsService.duplicate(notificationUuid)
                .then((newNotification) => {
                    ManualPushNotificationsService.update(newNotification.uuid, {
                        type: `${newNotification.type}_duplicate`,
                        title: notification.title,
                    })
                        .then(() => {
                            toast.success('Manual push notification has been duplicated')
                            history.push(routes.manualPushNotificationRoute(newNotification.uuid))
                            window.location.reload()
                        })
                        .catch((error) => {
                            if (error instanceof ApiError) {
                                error.handleUnknown('An error occurred while updating the manual push notification.')
                            } else {
                                throw error
                            }
                        })
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while duplicating the manual push notification.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.duplicate
        )
    }

    const handleSendDraft: SubmitHandler<{ userUuid: string }> = ({ userUuid }) => {
        formRef.current!.setErrors({})

        if (!userUuid) {
            formRef.current?.setFieldError('userUuid', 'This field is required')
            return
        }
        trackPromise(
            ManualPushNotificationsService.sendDraft(notificationUuid, userUuid)
                .then(() => {
                    toast.success('Manual email draft has been sent')
                    fetchData()
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while sending the manual email draft.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.sendDraft
        )
    }

    if (!notification) return <Spinner forceShow />

    return (
        <Box>
            <Stack>
                <h3>General actions</h3>
                <Body2>
                    After clicking on the duplicate option you would be redirected to the create push notification form with
                    prefilled information
                </Body2>
                <Button fullWidth onClick={duplicatePushNotification} loadingArea={loadingAreas.duplicate}>
                    Duplicate this push notification
                </Button>

                {!isSent && (
                    <Form ref={formRef} onSubmit={handleSendDraft}>
                        <Input name="userUuid" placeholder="User uuid" />
                        <Button fullWidth icon={<SendIcon />} loadingArea={loadingAreas.sendDraft}>
                            Send push notification draft
                        </Button>
                    </Form>
                )}
                <h3>Approval</h3>
                <p>Every push notification has to be approved by a different team member in the company.</p>
                {isApproved &&
                    (isApproverUser ? (
                        <Button fullWidth icon={<CloseIcon />} loadingArea={loadingAreas.revoke} onClick={handleRevoke}>
                            Revoke approval for push notification
                        </Button>
                    ) : (
                        <p>Only the person that added the approval can also remove the approval.</p>
                    ))}
                {!isApproved && (
                    <Button fullWidth icon={<CheckIcon />} loadingArea={loadingAreas.approve} onClick={handleApprove}>
                        Approve push notification
                    </Button>
                )}

                <h3>Send</h3>
                {!notification.sentOn ? (
                    isApproved && isOwner ? (
                        <>
                            <Button fullWidth icon={<SendIcon />} loadingArea={loadingAreas.send} onClick={handleSend}>
                                Send push notification
                            </Button>
                        </>
                    ) : (
                        <>
                            {(!isApproved || !isOwner) && (
                                <p>The push notification has to be approved before it can be sent.</p>
                            )}
                        </>
                    )
                ) : (
                    <p>Has already been sent.</p>
                )}
            </Stack>
            {renderUserCountModal()}
        </Box>
    )
}

export default ManualPushNotificationsAction
