import React, { useRef } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import Box from 'components/elements/Box'
import Button from 'components/elements/Button'
import { Input } from 'components/inputs/Input'
import Meta from 'components/modules/Head'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { badRequestFormErrors } from 'utils'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import CourseService from 'services/CourseService'

const loadingAreas = {
    fundraise: 'fundraise',
}

const Fundraise = () => {
    const formRef = useRef<FormHandles>(null)

    const handleCourseFundraiseDataUpdate: SubmitHandler<{
        courseUuid: string
        amount: number
        goal: number
        endDate: string
    }> = ({ courseUuid, amount, goal, endDate }) => {
        formRef.current!.setErrors({})

        if (isNaN(new Date(endDate).getTime())) {
            formRef.current!.setFieldError('endDate', 'Please specify the correct date')
            return
        }
        if (new Date().getTime() > new Date(endDate).getTime()) {
            formRef.current!.setFieldError('endDate', 'The end date has to be in the future.')
            return
        }

        trackPromise(
            CourseService.fundraiseUpdate(courseUuid, {
                amount: +amount,
                goal: +goal,
                endDate: new Date(endDate).toISOString(),
            })
                .then(() => {
                    toast.success(`Fundraise data was updated`)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.FormValidation) {
                            badRequestFormErrors(error, formRef.current!)
                        } else {
                            error.handleUnknown('An error occurred while adding updating course fundraise data.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.fundraise
        )
    }

    return (
        <section>
            <Meta title="Fundraise course" />
            <h2>Fundraise course</h2>
            <br />
            <Box>
                <Form ref={formRef} onSubmit={handleCourseFundraiseDataUpdate}>
                    <Input name="courseUuid" placeholder="Course Uuid" />
                    <Input name="amount" placeholder="Amount" />
                    <Input name="goal" placeholder="Goal" />
                    <Input name="endDate" placeholder="End date" type="date" />
                    <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.fundraise}>
                        Update
                    </Button>
                </Form>
            </Box>
        </section>
    )
}

export default Fundraise
