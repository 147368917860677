import React from 'react'
import { User } from 'interfaces/User'
import UserAdminNotes from 'components/features/users/UserAdminNotes'
import UserModerationStatus from 'components/features/users/UserModerationStatus'
import UserReportsHistory from 'components/features/users/UserReportsHistory'
import UserModerationHistory from 'components/features/users/UserModerationHistory'
import UserManualPenaltyPoints from 'components/features/users/UserManualPenaltyPoints'
import UserAssociatedUsers from 'components/features/users/UserAssociatedUsers'
import UserManualSocialForm from 'components/features/users/UserManualSocialForm'

interface Props {
    user: User
    fetchData: () => void
}

const UserSafety = ({ user, fetchData }: Props) => {
    return (
        <div>
            <UserModerationStatus user={user} />
            <UserManualPenaltyPoints user={user} fetchData={fetchData} />
            <UserManualSocialForm user={user} fetchData={fetchData} />
            <UserAssociatedUsers user={user} />
            <UserModerationHistory user={user} />
            <UserReportsHistory user={user} />
            <UserAdminNotes user={user} />
        </div>
    )
}

export default UserSafety
