import { KnowFormat, KnowStatus } from 'interfaces/Know'
import { LanguageCode } from 'interfaces/Language'
import { ReviewGroup, InternalRatingType } from 'interfaces/ReviewSettings'

export const reviewGroupOptions: { label: string; data: ReviewGroup | null }[] = [
    {
        label: 'No filter',
        data: null,
    },
    {
        label: 'Group A',
        data: ReviewGroup.GroupA,
    },
    {
        label: 'Group B',
        data: ReviewGroup.GroupB,
    },
    {
        label: 'Group C',
        data: ReviewGroup.GroupC,
    },
]

export const formatOptions: { label: string; data: KnowFormat | null }[] = [
    {
        label: 'No filter',
        data: null,
    },
    {
        label: 'PDFs',
        data: KnowFormat.PDF,
    },
    {
        label: 'Flashcards',
        data: KnowFormat.Flashcards,
    },
    {
        label: 'Audio',
        data: KnowFormat.Audio,
    },
]

export const statusOptions: { label: string; data: KnowStatus }[] = [
    {
        label: 'Public',
        data: KnowStatus.Public,
    },
    {
        label: 'In review',
        data: KnowStatus.InReview,
    },
    {
        label: 'In extended review',
        data: KnowStatus.InExtendedReview,
    },
    {
        label: 'Rejection Appealed',
        data: KnowStatus.RejectionAppealed,
    },
]
export const internalRatingOptions: { label: string; data: InternalRatingType }[] = [
    {
        label: 'No filter',
        data: null,
    },
    {
        label: '1 star',
        data: 1,
    },
    {
        label: '2 stars',
        data: 2,
    },
    {
        label: '3 stars',
        data: 3,
    },
]

export const isAmbassadorOptions: { label: string; data: boolean | null }[] = [
    {
        label: 'No filter',
        data: null,
    },
    {
        label: 'Ambassador',
        data: true,
    },
]

export const languageOptions: { label: string; data: LanguageCode }[] = [
    {
        label: 'No filter',
        data: null,
    },
    ...Object.keys(LanguageCode).map((languageKey) => ({
        label: languageKey,
        // eslint-disable-next-line
        // @ts-ignore
        data: LanguageCode[languageKey],
    })),
]
