import React from 'react'
import ReactDOM from 'react-dom'
import './reset.css'
import './index.css'
import App from './App'
import { Provider } from 'react-redux'
import store from './redux/store'
import { ProSidebarProvider } from 'react-pro-sidebar'
// TODO: Setup Sentry

ReactDOM.render(
    <React.StrictMode>
        <ProSidebarProvider>
            <Provider store={store}>
                <App />
            </Provider>
        </ProSidebarProvider>
    </React.StrictMode>,
    document.getElementById('root')
)
