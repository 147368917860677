import ApiService from './ApiService'
import { KnowledgeArea } from 'interfaces/KnowledgeArea'

const KnowledgeAreaService = {
    list() {
        return ApiService.get(`/knowledge-areas`).then((response) => {
            return response.data.content as KnowledgeArea[]
        })
    },
}

export default KnowledgeAreaService
