import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { badRequestFormErrors, getNextRedirectUuid, isUUID } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import Button from 'components/elements/Button'
import { FormSelect, Input, SelectOption } from 'components/inputs/Input'
import SchoolCreateService from 'services/SchoolCreateService'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import { useHistory } from 'react-router-dom'
import routes from 'lib/constants/routes'
import { SchoolFromSuggestion, SchoolSuggestion } from 'interfaces/SchoolSuggestion'
import theme from 'lib/constants/theme'
import CountriesService from 'services/CountriesService'
import Table, { TableData } from 'components/modules/Table'
import { Body1, ButtonText } from 'components/elements/Text'
import Modal from 'components/modules/Modal'
import SchoolCreateForm from 'pages/Schools/SchoolCreateForm'
import Row from 'components/elements/Row'
import CollapsibleCard from 'components/features/CollapsibleCard'

const loadingAreas = {
    delete: 'deleteSuggestion',
    save: 'saveSuggestion',
    coordinates: 'fetchCoordinates',
    deleteSchool: 'deleteSchool',
}

export interface SchoolFormData {
    name: string
    city: string
    streetWithNumber: string
    userUuid: string
    postalCode: string
    schoolSuggestionUUID: string
    latitude: number
    longitude: number
    countryId: SelectOption<number>
}

interface Props {
    schoolSuggestion: SchoolSuggestion | null
    schoolSuggestionUuid: string
}

const SchoolSuggestionCreateForm = ({ schoolSuggestion, schoolSuggestionUuid }: Props) => {
    const [countryOptions, setCountryOptions] = useState<{ label: string; identifier: string; data: number }[]>([])
    const [selectedSchool, setSelectedSchool] = useState<SchoolFromSuggestion | null>(null)
    const [duplicateSchools, setDuplicateSchools] = useState<TableData[]>()
    const [schoolToDelete, setSchoolToDelete] = useState<string>()
    const [coords, setCoords] = useState<{ latitude?: number; longitude?: number }>()
    const [showEditSchoolModal, setEditSchoolModal] = useState(false)
    const [showDeleteSchoolModal, setDeleteSchoolModal] = useState(false)
    const formRef = useRef<FormHandles>(null)
    const deleteFormRef = useRef<FormHandles>(null)
    const history = useHistory()

    useEffect(() => {
        CountriesService.list()
            .then((countries) => {
                if (!countries) return
                setCountryOptions(countries.map((c) => ({ label: c.name, identifier: c.id.toString(), data: c.id })))
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('Unable to list countries.')
                    return
                } else {
                    throw error
                }
            })
    }, [])

    const goToNextSchoolSuggestion = useCallback(() => {
        const nextRedirect = getNextRedirectUuid(schoolSuggestionUuid)
        if (nextRedirect === undefined) {
            return
        } else if (nextRedirect === null) {
            history.push(routes.schoolsSuggestionsRoute)
            return
        }

        history.push(routes.schoolsSuggestionRoute(nextRedirect.nextUuid, { redirectUuids: nextRedirect.remainingUuids }))
    }, [history, schoolSuggestionUuid])

    const markSuggestionAsDuplicate = useCallback(
        (schoolUuid: string) => {
            if (!schoolSuggestionUuid) return

            SchoolCreateService.markAsDuplicate(schoolSuggestionUuid, schoolUuid)
                .then(() => {
                    toast.success('Suggestion was marked as a duplicate')
                    goToNextSchoolSuggestion()
                    setSelectedSchool(null)
                })
                .catch((error: ApiError) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('There was an issue while marking suggestion as duplicate')
                    } else {
                        throw error
                    }
                })
        },
        [goToNextSchoolSuggestion, schoolSuggestionUuid]
    )

    const generateDuplicateSchoolTableData = useCallback(
        (suggestions: SchoolFromSuggestion[]) => {
            return suggestions.map((school, id) => ({
                id,
                columns: [
                    school.uuid,
                    school.name,
                    `${school.streetWithNumber}, ${school.city}, ${school.postalCode}`,
                    <Stack key={`school-coords-${school.uuid}`} style={{ minWidth: 100 }}>
                        <Body1 color={theme.colors.white}>{`${school.latitude} 
                        ${
                            coords?.latitude
                                ? `(difference: ${Math.abs(Math.abs(school.latitude) - Math.abs(coords.latitude)).toFixed(
                                      3
                                  )})`
                                : ''
                        }
                    `}</Body1>
                        <Body1 color={theme.colors.white}>{`${school.longitude} 
                        ${
                            coords?.longitude
                                ? `(difference: ${Math.abs(Math.abs(school.longitude) - Math.abs(coords.longitude)).toFixed(
                                      3
                                  )})`
                                : ''
                        }
                    `}</Body1>
                    </Stack>,
                    <Row key={`row-${school.uuid}`} style={{ minWidth: 350 }}>
                        <Button
                            key={`${school.uuid}-edit-button`}
                            fullWidth
                            type="button"
                            loadingArea={loadingAreas.coordinates}
                            onClick={() => {
                                setSelectedSchool(school)
                                setEditSchoolModal(true)
                            }}
                        >
                            <ButtonText textAlign="center" color={theme.colors.white}>
                                Edit
                            </ButtonText>
                        </Button>

                        <Button
                            key={`${school.uuid}-mark-as-duplicate-button`}
                            fullWidth
                            type="button"
                            loadingArea={loadingAreas.coordinates}
                            onClick={() => {
                                markSuggestionAsDuplicate(school.uuid)
                            }}
                        >
                            <ButtonText textAlign="center" color={theme.colors.white}>
                                Duplicate
                            </ButtonText>
                        </Button>

                        <Button
                            key={`${school.uuid}-delete-button`}
                            fullWidth
                            type="button"
                            loadingArea={loadingAreas.coordinates}
                            onClick={() => {
                                setSchoolToDelete(school.uuid)
                                setDeleteSchoolModal(true)
                            }}
                        >
                            <ButtonText textAlign="center" color={theme.colors.white}>
                                Delete
                            </ButtonText>
                        </Button>
                    </Row>,
                ],
            }))
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [markSuggestionAsDuplicate]
    )

    useEffect(() => {
        if (!schoolSuggestion) return

        SchoolCreateService.searchForDuplicates(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            `${(schoolSuggestion as any)?.locationName} ${schoolSuggestion?.name} ${schoolSuggestion?.city}`,
            schoolSuggestion.countryId
        )
            .then((schoolDuplicates) => {
                const schools = generateDuplicateSchoolTableData(schoolDuplicates)

                setDuplicateSchools(schools)
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('Unable to list school duplicates.')
                    return
                } else {
                    throw error
                }
            })
    }, [generateDuplicateSchoolTableData, schoolSuggestion])

    const getCoordinates = () => {
        const schoolFormData = formRef.current?.getData() as SchoolFormData

        if (!schoolFormData) return

        trackPromise(
            SchoolCreateService.getCoordinatesByAddress(schoolFormData)
                .then(({ latitude, longitude }) => {
                    const formatedLatitude = latitude.toPrecision(7)
                    const formatedLongitude = longitude.toPrecision(7)
                    formRef.current?.setFieldValue('latitude', formatedLatitude)
                    formRef.current?.setFieldValue('longitude', formatedLongitude)
                    setCoords({ latitude: +formatedLatitude, longitude: +formatedLongitude })
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('Unable to list countries.')
                        return
                    } else {
                        throw error
                    }
                }),
            loadingAreas.coordinates
        )
    }

    const resetFields = useCallback(() => {
        if (!schoolSuggestion) return

        formRef.current?.setFieldValue('name', schoolSuggestion.name ?? '')
        formRef.current?.setFieldValue('city', schoolSuggestion.city ?? '')
        formRef.current?.setFieldValue(
            'streetWithNumber',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (schoolSuggestion as any)?.locationName ?? schoolSuggestion.streetWithNumber ?? ''
        )
        formRef.current?.setFieldValue('userUuid', schoolSuggestion.userUuid ?? '')
        formRef.current?.setFieldValue('postalCode', '')
        formRef.current?.setFieldValue('latitude', '')
        formRef.current?.setFieldValue('longitude', '')
        setDuplicateSchools(undefined)
    }, [schoolSuggestion])

    useEffect(() => {
        resetFields()
    }, [resetFields])

    const validateSchoolSuggestionFormData = ({ longitude, countryId, latitude, ...data }: SchoolFormData) => {
        if (!schoolSuggestion) return false

        if (!data.name) {
            formRef.current?.setFieldError('name', 'This field is required')
            return false
        }

        if (!data.streetWithNumber) {
            formRef.current?.setFieldError('streetWithNumber', 'This field is required')
            return false
        }

        if (!data.city) {
            formRef.current?.setFieldError('city', 'This field is required')
            return false
        }

        if (!data.postalCode) {
            formRef.current?.setFieldError('postalCode', 'This field is required')
            return false
        }

        if (!data.userUuid) {
            formRef.current?.setFieldError('userUuid', 'This field is required')
            return false
        }

        if (!isUUID(data.userUuid)) {
            formRef.current?.setFieldError('userUuid', 'Please enter a correct Uuid')
            return false
        }

        if (!latitude) {
            formRef.current?.setFieldError('latitude', 'This field is required')
            return false
        }

        if (!longitude) {
            formRef.current?.setFieldError('longitude', 'This field is required')
            return false
        }

        if (!countryId.data) {
            formRef.current?.setFieldError('countryId', 'This field is required')
            return false
        }

        return true
    }

    const checkForDuplicated = (name: string, postalCode: string, countryId: number) => {
        trackPromise(
            SchoolCreateService.checkForDuplicates(name, postalCode, countryId)
                .then((suggestions) => {
                    if (suggestions.length > 0) {
                        toast.info(
                            `There are ${suggestions.length} similar schools. Please look through duplicate candidate and decide if this school needs to be added`
                        )

                        const schools = generateDuplicateSchoolTableData(suggestions)

                        setDuplicateSchools(schools)
                        return
                    }
                })
                .catch((error: ApiError) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('School with similar data was already added')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.save
        )
    }

    const handleSubmit: SubmitHandler<SchoolFormData> = ({ longitude, countryId, latitude, ...data }) => {
        formRef.current!.setErrors({})

        if (!schoolSuggestion || !validateSchoolSuggestionFormData({ longitude, countryId, latitude, ...data })) return

        trackPromise(
            SchoolCreateService.create({
                ...data,
                countryId: +countryId.data,
                latitude: +latitude,
                longitude: +longitude,
                schoolSuggestionUUID: schoolSuggestion?.uuid,
            })
                .then(() => {
                    toast.success('New school was added!')
                    goToNextSchoolSuggestion()
                })
                .catch((error: ApiError) => {
                    if (error.type === ErrorTypes.FormValidation) {
                        badRequestFormErrors(error, formRef.current!)
                    } else {
                        error.handleUnknown('An error occurred while adding new school.')
                    }
                }),
            loadingAreas.save
        )
    }

    const deleteSchoolSuggestion = () => {
        if (!schoolSuggestionUuid) return

        trackPromise(
            SchoolCreateService.delete(schoolSuggestionUuid)
                .then(() => {
                    toast.success('Suggestion was deleted!')
                    goToNextSchoolSuggestion()
                    setSelectedSchool(null)
                })
                .catch((error: ApiError) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('There was an issue while deleting school suggestion')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.delete
        )
    }

    const handleDeleteSchoolSubmit: SubmitHandler<{ targetSchoolUuid: string }> = async ({ targetSchoolUuid }) => {
        deleteFormRef.current!.setErrors({})

        if (!schoolToDelete) return

        if (!targetSchoolUuid) {
            deleteFormRef.current?.setFieldError('targetSchoolUuid', 'This field is required')
            return
        }

        trackPromise(
            SchoolCreateService.deleteSchool(schoolToDelete, targetSchoolUuid)
                .then(() => {
                    toast.success('The school was deleted')
                    setDeleteSchoolModal(false)
                    setSchoolToDelete(undefined)
                    setSelectedSchool(null)
                    const { name, postalCode, countryId } = formRef.current!.getData() as SchoolFormData

                    checkForDuplicated(name, postalCode, countryId.data)
                })
                .catch((error: ApiError) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while deleteing the school.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.deleteSchool
        )
    }

    const updateDuplicateSchoolData = () => {
        const { name, postalCode, countryId } = formRef.current!.getData() as SchoolFormData

        checkForDuplicated(name, postalCode, countryId.data)
        setSelectedSchool(null)
    }

    return (
        <section>
            <Box>
                <Stack>
                    <h3>Create new school from suggestion</h3>
                    {duplicateSchools?.length ? (
                        <CollapsibleCard
                            openByDefault={false}
                            title={
                                <Body1 color={theme.colors.white}>Duplicate candidates ({duplicateSchools.length})</Body1>
                            }
                        >
                            <Stack>
                                <Table
                                    columns={['#', 'Name', 'Location', 'Coordinates', 'Actions']}
                                    data={duplicateSchools}
                                    page={0}
                                    totalPages={1}
                                    totalElements={duplicateSchools.length}
                                />
                            </Stack>
                        </CollapsibleCard>
                    ) : null}
                    <Form
                        ref={formRef}
                        initialData={{
                            ...schoolSuggestion,
                            countryId: countryOptions.find((x) => x.data === schoolSuggestion?.countryId),
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            streetWithNumber: schoolSuggestion?.streetWithNumber || (schoolSuggestion as any)?.locationName,
                        }}
                        onSubmit={handleSubmit}
                    >
                        <Input name="name" placeholder="Name" />
                        <Input name="streetWithNumber" placeholder="Location street & number" />
                        <Input name="city" placeholder="City" />
                        <Input name="postalCode" placeholder="Postal code" />
                        <Input name="userUuid" placeholder="User Uuid who suggested the school" />
                        {countryOptions && <FormSelect name="countryId" options={countryOptions} placeholder="Country" />}
                        <Button fullWidth type="button" loadingArea={loadingAreas.coordinates} onClick={getCoordinates}>
                            {`Fetch coordinates (Make sure to input city & postal code beforehand)`}
                        </Button>
                        <Input
                            name="latitude"
                            placeholder="Latitude (example: 11.123456)"
                            type="number"
                            step="0.000001"
                            onUpdate={(value) => setCoords({ latitude: +value })}
                        />
                        <Input
                            name="longitude"
                            placeholder="Longitude (example: 22.123456)"
                            type="number"
                            step="0.000001"
                            onUpdate={(value) => setCoords({ longitude: +value })}
                        />
                        <br />

                        <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                            {'Save'}
                        </Button>
                    </Form>
                    <span />
                    <Stack gutter={25}>
                        <Button
                            fullWidth
                            onClick={deleteSchoolSuggestion}
                            hoverColor={theme.colors.secondaryRed}
                            color={theme.colors.secondaryRedDarker}
                            noMargin
                        >
                            {'Delete this school suggestion'}
                        </Button>
                        <Button
                            fullWidth
                            onClick={goToNextSchoolSuggestion}
                            hoverColor={theme.colors.secondaryRed}
                            color={theme.colors.secondaryRedDarker}
                            noMargin
                        >
                            {'Skip (go to the next school suggestion from the queue)'}
                        </Button>
                    </Stack>
                </Stack>
            </Box>
            <Modal title="Edit School" show={showEditSchoolModal} onClose={() => setEditSchoolModal(false)}>
                {selectedSchool ? (
                    <SchoolCreateForm
                        school={selectedSchool}
                        schoolUuid={selectedSchool.uuid}
                        isNew={false}
                        updateSchool={(_: SchoolFromSuggestion) => {
                            setSelectedSchool(null)
                            updateDuplicateSchoolData()
                            setEditSchoolModal(false)
                        }}
                    />
                ) : null}
            </Modal>

            <Modal title="Delete School" show={showDeleteSchoolModal} onClose={() => setDeleteSchoolModal(false)}>
                <Form ref={deleteFormRef} onSubmit={handleDeleteSchoolSubmit}>
                    <Input
                        name="targetSchoolUuid"
                        placeholder="Uuid of the target school that all students would be moved to"
                    />
                    <Button
                        fullWidth
                        type="submit"
                        onClick={() => {
                            deleteFormRef.current?.submitForm()
                        }}
                        hoverColor={theme.colors.secondaryRed}
                        color={theme.colors.secondaryRedDarker}
                        noMargin
                    >
                        {'Save'}
                    </Button>
                </Form>
            </Modal>
        </section>
    )
}

export default SchoolSuggestionCreateForm
