import ApiService from 'services/ApiService'
import { CountryCode } from 'interfaces/Country'
import { SeoSubject, UpdateSeoSubject } from 'interfaces/SeoSubject'

const SeoSubjectService = {
    list(countryCode: CountryCode) {
        return ApiService.get(`seo/subjects?countryCode=${countryCode}`).then((response) => {
            return response.data.content as SeoSubject[]
        })
    },

    get(uuid: string) {
        return ApiService.get(`/seo/subjects/${uuid}`).then((response) => {
            return response.data as SeoSubject
        })
    },

    create(seoSubject: UpdateSeoSubject) {
        return ApiService.post('/seo/subjects', seoSubject).then((response) => {
            return response.data as SeoSubject
        })
    },

    update(uuid: string, seoSubject: UpdateSeoSubject) {
        return ApiService.patch(`/seo/subjects/${uuid}`, seoSubject).then((response) => {
            return response.data as SeoSubject
        })
    },
}

export default SeoSubjectService
