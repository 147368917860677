import React from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import Button from 'components/elements/Button'
import { Body2, ButtonText, Headline5 } from 'components/elements/Text'
import { Input } from 'components/inputs/Input'
import Modal from 'components/modules/Modal'
import { useRef, useState } from 'react'
import { useToggleState } from 'utils/hooks/useToggleState'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import CollapsibleCard from 'components/features/CollapsibleCard'
import theme from 'lib/constants/theme'
import styled from 'styled-components'
import { CompetitionPrize } from 'interfaces/Competition'
import Row from 'components/elements/Row'

const ButtonRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    button {
        height: 30px;
    }
`

type PrizeUpdate = CompetitionPrize & { index: number }

interface Props {
    prizes: CompetitionPrize[]
    setCompetitionPrizes: React.Dispatch<React.SetStateAction<CompetitionPrize[]>>
}

const CompetitionPrizeForm = ({ prizes, setCompetitionPrizes }: Props) => {
    const prizeFormRef = useRef<FormHandles>(null)
    const [selectedPrize, setSelectedPrize] = useState<PrizeUpdate | null>(null)
    const [showPrizeForm, togglePrizeForm] = useToggleState(false)

    const updateCompetitionPrize = (prize: CompetitionPrize) => setCompetitionPrizes((prevPrize) => [...prevPrize, prize])

    const handleEditPrize = (prize: CompetitionPrize, index: number) => {
        setSelectedPrize({ ...prize, index })
        togglePrizeForm()
    }

    const handleDeletePrize = (indexToDelete: number) =>
        setCompetitionPrizes((prizes) => prizes.filter((_, i) => i !== indexToDelete))

    const handlePrizeFormSubmit: SubmitHandler<Omit<CompetitionPrize, 'type'>> = (prize) => {
        if (!prize.podiumText) {
            prizeFormRef.current!.setFieldError('podiumText', 'This field is required!')
            return
        }

        if (!prize.rankingPosition) {
            prizeFormRef.current!.setFieldError('rankingPosition', 'This field is required!')
            return
        }

        if (!prize.shortText) {
            prizeFormRef.current!.setFieldError('shortText', 'This field is required!')
            return
        }

        if (!prize.text) {
            prizeFormRef.current!.setFieldError('text', 'This field is required!')
            return
        }

        if (!prize.title) {
            prizeFormRef.current!.setFieldError('title', 'This field is required!')
            return
        }

        if (selectedPrize) {
            const { index } = selectedPrize

            setCompetitionPrizes((prevPrizes) => {
                const tempPrizes = prevPrizes
                tempPrizes[index] = { ...prize, type: 'WEEKLY' }
                return tempPrizes
            })
        } else {
            updateCompetitionPrize({ ...prize, type: 'WEEKLY' })
        }
        setSelectedPrize(null)
        togglePrizeForm()
    }

    return (
        <>
            <Modal show={showPrizeForm} title={selectedPrize ? 'Update Prize' : 'Add Prize'} onClose={togglePrizeForm}>
                <Body2>
                    Hint: you still need to save the competition after adding Prize on the bottom of the form in order to
                    save the changes
                </Body2>
                <Form ref={prizeFormRef} onSubmit={handlePrizeFormSubmit} initialData={{ ...selectedPrize }}>
                    <Input name="title" placeholder="Title" />
                    <Input name="podiumText" placeholder="Podium text" />
                    <Input name="rankingPosition" placeholder="Ranking position" type="number" />
                    <Input name="text" placeholder="Text" />
                    <Input name="shortText" placeholder="Short text" />
                    <Button fullWidth icon={<SaveIcon />} type="button" onClick={() => prizeFormRef.current?.submitForm()}>
                        Save
                    </Button>
                </Form>
            </Modal>
            <Box>
                <Stack>
                    <ButtonRow>
                        <Headline5 color={theme.colors.white} marginBottom={10}>
                            Prizes
                        </Headline5>
                        <Button fullWidth={false} onClick={togglePrizeForm} icon={<AddIcon />} type="button">
                            <ButtonText color={theme.colors.white}>Add Prize</ButtonText>
                        </Button>
                    </ButtonRow>

                    {prizes.length ? (
                        <>
                            {prizes.map((prize, i) => (
                                <CollapsibleCard
                                    key={i}
                                    title={
                                        <ButtonRow>
                                            <Body2 color={theme.colors.white}>{`${i + 1}: ${prize.title}`}</Body2>
                                            <Row gap={20}>
                                                <Button
                                                    fullWidth={false}
                                                    onClick={() => handleDeletePrize(i)}
                                                    type="button"
                                                    icon={<DeleteIcon />}
                                                    color={theme.colors.secondaryRed}
                                                    hoverColor={theme.colors.secondaryRedDarker}
                                                >
                                                    Delete
                                                </Button>
                                                <Button
                                                    fullWidth={false}
                                                    onClick={() => handleEditPrize(prize, i)}
                                                    type="button"
                                                    icon={<EditIcon />}
                                                >
                                                    Edit
                                                </Button>
                                            </Row>
                                        </ButtonRow>
                                    }
                                    openByDefault={false}
                                >
                                    <Stack>
                                        <Body2 color={theme.colors.white}>{`Text: ${prize.text}`}</Body2>
                                        <Body2 color={theme.colors.white}>{`Short text: ${prize.shortText}`}</Body2>
                                        <Body2 color={theme.colors.white}>{`Podium text: ${prize.podiumText}`}</Body2>
                                        <Body2 color={theme.colors.white}>{`Type: ${prize.type}`}</Body2>
                                        <Body2
                                            color={theme.colors.white}
                                        >{`Ranking position: ${prize.rankingPosition}`}</Body2>
                                    </Stack>
                                </CollapsibleCard>
                            ))}
                        </>
                    ) : (
                        <Body2>No prizes items were added</Body2>
                    )}
                </Stack>
            </Box>
        </>
    )
}

export default CompetitionPrizeForm
