const colors = {
    knowunityBlue: '#65a0f1',
    knowunityPink: '#97348C',
    knowunityBlueDark: '#4990f3',
    lightKnowunityPink: '#98348c',
    subtitleBlack: '#3D3E40',
    accentBlue: '#2B3F5F',
    darkerAccentBlue: '#1C2735',
    body1Black: '#616366',
    body2Black: '#919599',
    captionGray: '#C2C7CC',
    outlineGray: '#E3E6EB',
    buttonTextBlack: '#18191A',
    white: '#ffffff',
    black: '#000000',
    darkBackground: '#111418',
    deepSeaBlue: '#2A46BD',
    flamingoPink: '#F15599',
    forestGreen: '#1E8148',
    snowWhite: '#EFF3F9',
    blueIce: '#C8DFFF',
    accentOrange: '#FF9078',
    placeholderGray: '#9299b2',
    secondaryRed: '#e6423c',
    secondaryRedDarker: '#b9202f',
    lightViolet: '#292f45',
    darkViolet: '#262a3a',
    hoverViolet: '#303658',
    violet: '#2c3349',
}

const borderRadius = {
    smallest: '2px',
    small: '5px',
    large: '8px',
    card: '15px',
    normal: '15px',
    message: '20px',
    huge: '30px',
}

const zIndex = {
    cookieBanner: 100,
    navBarMobileExpanded: 95,
    modal: 90,
    tooltipText: 80,
    navBar: 70,
    navBarUserDropdown: 65,
    topBanner: 42,
    homepageBanner: 40,
    player: 40,
    playerIcons: 41,
    FAB: 50,
    sidebarMenu: 71,
    sidebarMenuDialog: 95,
}

const theme = {
    colors,
    borderRadius,
    zIndex,
}

export default theme
