import React, { useRef } from 'react'
import { Form } from '@unform/web'
import Box from 'components/elements/Box'
import Button from 'components/elements/Button'
import { Body1 } from 'components/elements/Text'
import { Input } from 'components/inputs/Input'
import theme from 'lib/constants/theme'
import { FormHandles, SubmitHandler } from '@unform/core'
import { User } from 'interfaces/User'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import UserService from 'services/UserService'
import { badRequestFormErrors } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'

const loadingAreas = {
    delete: 'deleteUser',
    businessPartner: 'businessPartner',
}

interface Props {
    user: User
    fetchData: () => void
}

const UserManualPenaltyPoints = ({ user, fetchData }: Props) => {
    const penaltyPointsFormRef = useRef<FormHandles>(null)
    const userUuid = user.uuid

    const handleUSEngagementPointsSubmit: SubmitHandler<{ points: number }> = ({ points }) => {
        penaltyPointsFormRef.current!.setErrors({})

        trackPromise(
            UserService.addManualPenaltyPoints(userUuid, { penaltyPoints: +points })
                .then(() => {
                    fetchData()
                    toast.success(`User penalty points were set to ${points}`)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.FormValidation) {
                            badRequestFormErrors(error, penaltyPointsFormRef.current!)
                        } else {
                            error.handleUnknown('An error occurred while adding user engagement points.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.businessPartner
        )
    }

    return (
        <Box>
            <Body1 color={theme.colors.white} marginBottom={15}>
                Penalty Points
            </Body1>
            <Form
                ref={penaltyPointsFormRef}
                onSubmit={handleUSEngagementPointsSubmit}
                initialData={{ points: +user.penaltyPoints }}
            >
                <Input name="points" placeholder="Points count" type="number" />
                <Button fullWidth loadingArea={loadingAreas.businessPartner}>
                    Set penalty points number
                </Button>
            </Form>
        </Box>
    )
}

export default UserManualPenaltyPoints
