import React, { useCallback, useEffect, useRef, useState } from 'react'
import LoadingArea from 'components/elements/LoadingArea'
import Button, { ButtonLink } from 'components/elements/Button'
import { Link, RouteComponentProps, useHistory } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker'
import { badRequestFormErrors, formatTimeDifference, getDateDaysDifference } from 'utils'
import { ApiError } from 'services/ApiService'
import AdSystemService from 'services/AdSystemService'
import { AdCampaign as AdCampaignInterface } from 'interfaces/AdSystem'
import Box from 'components/elements/Box'
import CopyIcon from 'components/elements/CopyIcon'
import routes from 'lib/constants/routes'
import { ReactComponent as BackIcon } from 'assets/icons/chefron-left.svg'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import RefactoredTable from 'components/modules/RefactoredTable'
import { ControlsWrapper, ControlWrapper, EditButton, MetaBox } from 'style'
import Stack from 'components/elements/Stack'
import theme from 'lib/constants/theme'
import { useToggleState } from 'utils/hooks/useToggleState'
import Modal from 'components/modules/Modal'
import UpdateCampaignForm, { FormData } from './UpdateCampaignForm'
import { FormHandles, SubmitHandler } from '@unform/core'
import { toast } from 'react-toastify'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { useUserTargetingForm } from 'utils/hooks/useUserTargetingForm'
import EditRecordInline from 'components/elements/EditRecordInline'
import { Headline5 } from 'components/elements/Text'
import styled from 'styled-components'
import { renderPreviewInTable, renderViewHealth, renderViewProgress } from 'lib/features/ads'
import Meta from 'components/modules/Head'

interface Props extends RouteComponentProps<{ id: string; uuid: string }> {}

const loadingAreas = {
    save: 'saveAdCustomer',
    information: 'informationAdCustomer',
    container: 'asAssetsContainer',
}

const ChipContainer = styled.div`
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
`

const Chip = styled.div`
    border-radius: ${theme.borderRadius.message};
    border: 3px solid ${theme.colors.hoverViolet};
    height: auto;
    padding: 5px 10px;
    width: fit-content;
    background-color: ${theme.colors.lightViolet};
`

const DAYS_LIMIT_CAMPAIGN_DAYS = 730 // 2 Years

const AdCampaign = (props: Props) => {
    const [adCampaign, setAdCampaign] = useState<AdCampaignInterface>()
    const [refetchTable, toggleRefetch] = useToggleState(false)

    const [showEditCampaignModal, toggleEditCampaignModal] = useToggleState(false)
    const history = useHistory()
    const formRef = useRef<FormHandles>(null)
    const id = props.match.params.id
    const isNew = id === 'create'
    const customerUuid = props.match.params.uuid

    const fetchData = useCallback(() => {
        id &&
            !isNew &&
            trackPromise(
                AdSystemService.getCampaign(id)
                    .then((campaign) => {
                        setAdCampaign(campaign)
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while getting campaign.')
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.information
            )
    }, [id, isNew])

    const { userTargetingId, renderTargetingModal, renderTargetingButton, renderTargetingInformation } =
        useUserTargetingForm(adCampaign?.userTargetingId, isNew, fetchData, { hideDaysSinceUserCreation: true })

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleSubmit: SubmitHandler<FormData> = (data) => {
        if (!userTargetingId) {
            toast.error('You need to add User Targeting!')
            return
        }

        if (data.interests?.length && data.interests.length > 3) {
            formRef.current?.setFieldError('interests', 'You can select maximum of 3 interests')
            return
        }

        const isEndOnInPast = data.endOn && new Date(data.endOn) < new Date()

        const daysToEndDifference = data.endOn ? getDateDaysDifference(data.endOn) : null

        const daysToStartDifference = data.startOn ? getDateDaysDifference(data.startOn) : null

        if (daysToStartDifference && daysToStartDifference > DAYS_LIMIT_CAMPAIGN_DAYS) {
            formRef.current?.setFieldError('startOn', ' Start On date can be max 2 years in the future')
            return
        }

        if (daysToEndDifference && daysToEndDifference > DAYS_LIMIT_CAMPAIGN_DAYS) {
            formRef.current?.setFieldError('endOn', ' End On date can be max 2 years in the future')
            return
        }

        if (isEndOnInPast) {
            const confirmContinue = window.confirm(
                'You have entered an end date for the campaign that is in the past. Are you sure to continue?'
            )
            if (!confirmContinue) return
        }

        const body = {
            name: data.name,
            isActive: data.isActive,
            goal: data.goal ? +data.goal : 0,
            startOn: data.startOn ? new Date(data.startOn).toISOString() : null,
            endOn: data.endOn ? new Date(data.endOn).toISOString() : null,
            interests: data.interests.map((interest) => interest.data.uuid),
            goalType: data.goalType.data,
            userTargetingId,
            frequencyCap: data.frequencyCap ? Number.parseInt(data.frequencyCap) : null,
            salesDealId: data.salesDealId || null,
        }

        if (!body) return

        let handleFunc
        if (isNew) {
            handleFunc = AdSystemService.createCampaign(customerUuid, body)
        } else {
            handleFunc = AdSystemService.updateCampaign(id, body)
        }
        trackPromise(
            handleFunc
                .then((campaign) => {
                    setAdCampaign(campaign)
                    toast.success('Campaign data has been saved.')
                    history.push(routes.adsCampaignRoute(customerUuid, campaign.uuid))
                    !isNew && toggleEditCampaignModal()
                })
                .catch((error: ApiError) => {
                    if (error.type === ErrorTypes.FormValidation) {
                        badRequestFormErrors(error, formRef.current!)
                    } else {
                        error.handleUnknown(`An error occurred while ${isNew ? 'creating' : 'updating'} a campaign.`)
                    }
                }),
            loadingAreas.save
        )
    }

    const updateAdAssetPartially = useCallback(
        (itemUuid: string, recordName: string, recordValue: string | number, hideForm: () => void) => {
            if (!recordValue) return

            const body = {
                [`${recordName}`]: recordValue,
            }

            trackPromise(
                AdSystemService.updateAssetPartially(itemUuid, body)
                    .then(() => {
                        fetchData()
                        toast.success('Asset data has been saved.')
                        hideForm()
                        toggleRefetch()
                    })
                    .catch((error: ApiError) => {
                        if (error.type === ErrorTypes.FormValidation) {
                            badRequestFormErrors(error, formRef.current!)
                        } else {
                            error.handleUnknown(`An error occurred while updating an asset.`)
                        }
                    }),
                loadingAreas.save
            )
        },
        [fetchData, toggleRefetch]
    )

    const fetchAssets = useCallback(
        (page: number) => {
            return trackPromise(
                AdSystemService.listAssets(id, page)
                    .then((data) => {
                        if (page !== data.page) return

                        return {
                            totalPages: data.totalPages,
                            totalElements: data.totalElements,
                            elements: data.ads.map((a) => ({
                                id: a.uuid,
                                columns: [
                                    a.uuid,
                                    <EditRecordInline
                                        itemUuid={a.uuid}
                                        key={`edit-${a.name}`}
                                        name="name"
                                        value={a.name}
                                        onSubmit={updateAdAssetPartially}
                                    >
                                        {a.name}
                                    </EditRecordInline>,
                                    renderPreviewInTable(a),
                                    a.clicks,
                                    renderViewProgress(a),
                                    renderViewHealth(a),
                                    <Link key={a.uuid} to={routes.adsAssetRoute(customerUuid, id, a.uuid)}>
                                        <Button noMargin fullWidth={false}>
                                            Go to
                                        </Button>
                                    </Link>,
                                ],
                            })),
                        }
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while getting campaigns assets.')
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.container
            )
        },
        [customerUuid, id, updateAdAssetPartially]
    )

    return (
        <section>
            <Meta title="Ad Campaign" />
            <h2>Ad Campaign</h2>

            {!isNew && (
                <LoadingArea>
                    <ControlsWrapper>
                        <ControlWrapper>
                            <ButtonLink to={routes.adsCustomerRoute(customerUuid)} fullWidth={false} icon={<BackIcon />}>
                                Go back to customer
                            </ButtonLink>
                        </ControlWrapper>
                    </ControlsWrapper>
                    <Box>
                        <Stack>
                            <MetaBox>
                                <h3>Information</h3>
                                <EditButton onClick={toggleEditCampaignModal} />
                            </MetaBox>
                            {adCampaign && (
                                <>
                                    <p>
                                        <strong>UUID:</strong>&nbsp;
                                        {adCampaign.uuid}
                                        <CopyIcon text={adCampaign.uuid} />
                                    </p>
                                    <p>
                                        <strong>Customer:</strong>&nbsp;
                                        {`${adCampaign.customer.name} (${adCampaign.customer.uuid})`}
                                        <CopyIcon text={adCampaign.customer.uuid} />
                                    </p>
                                    <p>
                                        <strong>Name:</strong>&nbsp;
                                        {adCampaign.name}
                                    </p>
                                    <p>
                                        <strong>Goal Type:</strong>&nbsp;
                                        {adCampaign.goalType}
                                    </p>
                                    <p>
                                        <strong>Goal:</strong>&nbsp;
                                        {adCampaign.goal}
                                    </p>
                                    <p>
                                        <strong>Is Active:</strong>&nbsp;
                                        {adCampaign.isActive ? 'Yes' : 'No'}
                                    </p>
                                    <p>
                                        <strong>Start On:</strong>&nbsp;
                                        {adCampaign.startOn ? formatTimeDifference(adCampaign.startOn) : 'never'}
                                    </p>
                                    <p>
                                        <strong>End On:</strong>&nbsp;
                                        {adCampaign.endOn ? formatTimeDifference(adCampaign.endOn) : 'never'}
                                    </p>
                                    <p>
                                        <strong>Frequency Cap:</strong>&nbsp;
                                        {adCampaign.frequencyCap
                                            ? `A user can see this campaign at most ${adCampaign.frequencyCap} times per hour`
                                            : 'disabled'}
                                    </p>
                                    <p>
                                        <strong>Sales Deal ID:</strong>&nbsp;
                                        {adCampaign.salesDealId ?? 'Not specified'}
                                    </p>
                                    <Headline5 color={theme.colors.white} fontWeigth={500}>
                                        Interests
                                    </Headline5>
                                    <ChipContainer>
                                        {adCampaign?.interests?.length
                                            ? adCampaign?.interests?.map((interest) => (
                                                  <Chip key={interest.tag}>{interest.tag}</Chip>
                                              ))
                                            : 'Not specified'}
                                    </ChipContainer>
                                    {renderTargetingInformation({ hideBoxWrapper: true })}
                                </>
                            )}
                        </Stack>
                    </Box>
                </LoadingArea>
            )}
            {!isNew && adCampaign ? (
                <Box>
                    <h2>Ad Assets</h2>
                    <ControlsWrapper>
                        <ControlWrapper>
                            <ButtonLink
                                to={routes.adsCreateAssetRoute(customerUuid, id)}
                                fullWidth={false}
                                icon={<AddIcon />}
                                hoverColor={theme.colors.knowunityBlue}
                                color={theme.colors.knowunityBlueDark}
                            >
                                Add Asset
                            </ButtonLink>
                        </ControlWrapper>
                    </ControlsWrapper>
                    <RefactoredTable
                        columns={['#', 'Name ✎', 'Asset', 'Clicks', 'Views', 'View health', 'Action']}
                        loadData={fetchAssets}
                        loadingArea={loadingAreas.container}
                        refetchKey={refetchTable}
                    />
                </Box>
            ) : (
                <Box>
                    <UpdateCampaignForm
                        adCampaign={adCampaign}
                        isNew={isNew}
                        refetchData={fetchData}
                        handleSubmit={handleSubmit}
                        renderTargetingButton={renderTargetingButton}
                        userTargetingId={userTargetingId}
                        formRef={formRef}
                    />
                </Box>
            )}
            <Modal show={showEditCampaignModal} title={'Update Campaign information'} onClose={toggleEditCampaignModal}>
                <UpdateCampaignForm
                    isNew={isNew}
                    adCampaign={adCampaign}
                    refetchData={fetchData}
                    handleSubmit={handleSubmit}
                    renderTargetingButton={renderTargetingButton}
                    userTargetingId={userTargetingId}
                    formRef={formRef}
                />
            </Modal>
            {renderTargetingModal()}
        </section>
    )
}

export default AdCampaign
