import React, { useCallback, useState } from 'react'
import { User } from 'interfaces/User'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import styled from 'styled-components'
import RefactoredTable from 'components/modules/RefactoredTable'
import UserService from 'services/UserService'
import routes from 'lib/constants/routes'
import Button, { ButtonA } from 'components/elements/Button'
import Row from 'components/elements/Row'
import { toast } from 'react-toastify'
import { ErrorTypes } from 'utils/constants/ErrorTypes'

const loadingAreas = {
    userModerationHistory: 'userModerationHistory',
}

const TitleRow = styled.div`
    display: flex;
    justify-content: space-between;
`

interface Props {
    user: User
}

const UserAssociatedUsers = ({ user }: Props) => {
    const [refetch, setRefetch] = useState(false)
    const userUuid = user.uuid

    const unlinkAssociatedUser = useCallback(
        (associatedUserUuid: string) => {
            if (!window.confirm(`Do you really want to unlink the associated user: "${associatedUserUuid}"?`)) return

            UserService.unlinkAssociatedUsers(userUuid, associatedUserUuid)
                .then(() => {
                    toast.success('Moderation keyword has been deleted')
                    setRefetch(true)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.NotFound) {
                            toast.error("Associated user with this uuid doesn't exist! Try again.")
                            return
                        } else {
                            error.handleUnknown('An error occurred while unlinking associated user.')
                        }
                    } else {
                        throw error
                    }
                })
        },
        [userUuid]
    )

    const fetchData = useCallback(
        (page: number) => {
            return trackPromise(
                UserService.getAssociatedUsers(userUuid)
                    .then((data) => {
                        return {
                            totalPages: 1,
                            totalElements: data.length,
                            elements: data.map((d) => ({
                                id: d.uuid,
                                columns: [
                                    d.uuid,
                                    d.name,
                                    d.username,
                                    <Row key={`associated-user-actions-${d.username}`}>
                                        <ButtonA
                                            key={`associated-user-${d.uuid}`}
                                            fullWidth={false}
                                            to={routes.userRoute(d.uuid)}
                                            target="_blank"
                                        >
                                            Go to user
                                        </ButtonA>
                                        <Button onClick={() => unlinkAssociatedUser(d.uuid)} noMargin fullWidth>
                                            Unlink
                                        </Button>
                                    </Row>,
                                ],
                            })),
                        }
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while getting associated users.')
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.userModerationHistory
            )
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [userUuid, unlinkAssociatedUser, refetch]
    )

    return (
        <Box>
            <Stack>
                <TitleRow>
                    <h3>Associated users</h3>
                </TitleRow>

                <RefactoredTable
                    columns={['User Uuid', 'Name', 'Username', 'Actions']}
                    loadData={fetchData}
                    loadingArea={loadingAreas.userModerationHistory}
                    refetchKey={refetch}
                />
            </Stack>
        </Box>
    )
}

export default UserAssociatedUsers
