import { ActiveCampaignFilter } from 'interfaces/AdSystem'
import { PayoutFilter } from 'pages/PayoutRequests/PayoutRequests'

export enum LocalStorageKey {
    Payout = 'PAYOUT_FILTERS',
    ActiveCampaigns = 'ACTIVE_CAMPAIGNS',
    KnowsFilters = 'KNOWS_FILTERS',
}

export function storePayoutRequestFilter(payoutFilter: PayoutFilter) {
    localStorage.setItem(LocalStorageKey.Payout, JSON.stringify(payoutFilter))
}

export function getPayoutRequestFilter() {
    const item = localStorage.getItem(LocalStorageKey.Payout)
    if (!item) {
        return null
    }
    return JSON.parse(item) as PayoutFilter
}

export function storeActiveCampaignFilter(activeCampaignFilter: ActiveCampaignFilter) {
    localStorage.setItem(LocalStorageKey.ActiveCampaigns, JSON.stringify(activeCampaignFilter))
}

export function getActiveCampaignFilter() {
    const item = localStorage.getItem(LocalStorageKey.ActiveCampaigns)
    if (!item) {
        return null
    }
    return JSON.parse(item) as ActiveCampaignFilter
}
