import React, { useCallback, useEffect, useState } from 'react'
import Table, { TableData } from 'components/modules/Table'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import Stack from 'components/elements/Stack'
import { FilterFormGrid } from 'style'
import routes from 'lib/constants/routes'
import RegionService from 'services/RegionService'
import { CountryCode } from 'interfaces/Country'
import SelectElement from 'components/inputs/SelectElement'
import Meta from 'components/modules/Head'
import { useCountryOptions } from 'hooks/useCountryOptions'
import CountriesService from 'services/CountriesService'

interface Props {}

const loadingAreas = {
    container: 'regionsContainer',
}

const Regions = (props: Props) => {
    const [data, setTableData] = useState<TableData[]>([])
    const [selectedCountry, setSelectedCountry] = useState<CountryCode>(CountryCode.Germany)

    const fetchData = useCallback(async () => {
        trackPromise(
            CountriesService.listLanguages(selectedCountry)
                .then((languages) => {
                    if (languages.length === 0) return []

                    return RegionService.listRegionsByCountry(selectedCountry, languages[0].code)
                })
                .then((regions) => {
                    if (!regions) return

                    const tableData = regions.map((region) => ({
                        id: region.id,
                        columns: [region.id, region.regionName, region.code, region.isDefault ? 'Yes' : 'No'],
                        onClick: routes.regionRoute(region.id),
                    }))

                    setTableData(tableData)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting regions.')
                }),
            loadingAreas.container
        )
    }, [selectedCountry])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const countryOptions = useCountryOptions()
    const updateCountryCode = (countryCode: CountryCode) => setSelectedCountry(countryCode)

    return (
        <section>
            <Meta title="Regions" />
            <Stack gutter={10}>
                <h2>Regions</h2>
                <FilterFormGrid gridAmount={2}>
                    <div />
                    <SelectElement label="Country code" noMargin options={countryOptions} onUpdate={updateCountryCode} />
                </FilterFormGrid>
                <Table
                    columns={['#', 'Name', 'Code', 'Is default']}
                    data={data}
                    page={0}
                    totalPages={1}
                    totalElements={1}
                    setPage={() => null}
                    area={loadingAreas.container}
                />
            </Stack>
        </section>
    )
}

export default Regions
