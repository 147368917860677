import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Body1, Headline4 } from 'components/elements/Text'
import theme from 'lib/constants/theme'
import { Knower } from 'interfaces/Knower'
import KnowService from 'services/KnowService'
import { ApiError } from 'services/ApiService'
import EmptyProfilePictureBackground from 'assets/empty_profile_picture.png'
import { KnowStatus } from 'interfaces/Know'
import { Tooltip, TooltipText } from 'style'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'

const Container = styled.div`
    display: grid;
    gap: 5px;
    .header {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 100px;

        .stats {
            p {
                color: ${theme.colors.captionGray};
                text-align: left;
            }
            display: grid;
            gap: 5px;
            grid-template-columns: 175px 1fr;
            place-content: center;
        }
        .badges {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            padding-top: 10px;
        }
        .badge {
            width: fit-content;
            padding: 5px 10px;
            border: 2px solid ${theme.colors.knowunityBlue};
            border-radius: ${theme.borderRadius.normal};
            color: ${theme.colors.knowunityBlue};
            font-weight: 700;
        }
    }
`

const ProfilePicture = styled.div<{ hasImage?: boolean }>`
    height: 125px;
    width: 125px;
    display: grid;
    place-content: center;
    position: relative;
    img {
        height: 125px;
        width: 125px;
        border-radius: 50%;
        background-color: ${theme.colors.knowunityBlue};
    }
    ${({ hasImage }) =>
        !hasImage
            ? css`
                  &::after {
                      position: absolute;
                      height: 125px;
                      width: 125px;
                      display: grid;
                      place-content: center;
                      content: 'User';
                      font-size: 30px;
                  }
              `
            : ''}
`

interface Props {
    knower: Knower
}

const KnowerInfo = ({ knower }: Props) => {
    const [knowsInReviewCount, setKnowsInReviewCount] = useState(0)
    const [knowsPublicCount, setKnowsPublicCount] = useState(0)

    useEffect(() => {
        KnowService.listAllByKnower(knower.uuid)
            .then((response) => {
                const reviewKnowsCount = response.knows.filter(
                    (know) => know.status === KnowStatus.InReview || know.status === KnowStatus.InExtendedReview
                ).length
                setKnowsInReviewCount(reviewKnowsCount)
                const publicKnowsCount = response.knows.filter((know) => know.status === KnowStatus.Public).length
                setKnowsPublicCount(publicKnowsCount)
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('An error occurred while getting knower knows.')
                } else {
                    throw error
                }
            })
    }, [knower.uuid])

    return (
        <Container>
            <div className="header">
                <ProfilePicture hasImage={!!knower.user.profilePicture}>
                    <img src={knower.user.profilePicture ?? EmptyProfilePictureBackground} alt="Profile" />
                </ProfilePicture>
                <span>
                    <div className="stats">
                        <Body1>Followers</Body1>
                        <Body1>{knower.followerCount}</Body1>
                        <Body1>Knows In Review</Body1>
                        <Body1>
                            {knowsInReviewCount}
                            <Tooltip>
                                <InfoIcon />
                                <TooltipText>
                                    This numbers represents the number of knows that are currently in review among the last
                                    20 knows uploaded by the user
                                </TooltipText>
                            </Tooltip>
                        </Body1>
                        <Body1>Knows already public</Body1>
                        <Body1>
                            {knowsPublicCount}
                            <Tooltip>
                                <InfoIcon />
                                <TooltipText>
                                    This numbers represents the number of knows that are currently public among the last 20
                                    knows uploaded by the user
                                </TooltipText>
                            </Tooltip>
                        </Body1>
                    </div>
                    <div className="badges">
                        {knower.user.isAmbassador ? <div className="badge">Ambassador</div> : ''}
                        {knower.isDiscussionForumPartner ? <div className="badge">Discussion Forum Partner</div> : ''}
                    </div>
                </span>
            </div>
            <Headline4 color={theme.colors.knowunityBlue}>{knower.user.name}</Headline4>
            <Body1 color={theme.colors.captionGray} marginBottom={10}>{`@${knower.user.username}`}</Body1>
            <Body1 color={theme.colors.captionGray}>{knower.user.bio}</Body1>
        </Container>
    )
}

export default KnowerInfo
