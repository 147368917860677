import React, { useEffect, useRef, useState } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { badRequestFormErrors } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { useHistory } from 'react-router-dom'
import Button from 'components/elements/Button'
import { Checkbox, FormSelect, Input, SelectOption } from 'components/inputs/Input'
import routes from 'lib/constants/routes'
import { Country } from 'interfaces/Country'
import CountriesService from 'services/CountriesService'
import { SeoSubject, UpdateSeoSubject } from 'interfaces/SeoSubject'
import SeoSubjectService from './SeoSubjectService'
import Editor from 'components/modules/Editor/Editor'
import { isConversionElementPresent, isHeading1Present, isSlugInvalid } from 'lib/features/glossary'

interface FormData {
    country: SelectOption<Country>
    description: string
    slug: string
    isPublished: boolean
}

interface Props {
    seoSubject: SeoSubject | null
    isNew: boolean
    updateSeoSubject: (seoSubject: SeoSubject) => void
}

const loadingAreas = {
    form: 'subjectsForm',
    save: 'saveSubject',
}

const SeoSubjectForm = ({ isNew, seoSubject, updateSeoSubject }: Props) => {
    const [initialData, setInitialData] = useState<FormData>()
    const [countryOptions, setCountryOptions] = useState<{ label: string; identifier: string; data: Country }[]>([])
    const [htmlContent, setHtmlContent] = useState('')
    const formRef = useRef<FormHandles>(null)
    const history = useHistory()

    useEffect(() => {
        trackPromise(
            CountriesService.list()
                .then((countries) => {
                    if (!countries) return
                    setCountryOptions(countries.map((c) => ({ label: c.name, identifier: c.id.toString(), data: c })))
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('Unable to list countries.')
                        return
                    } else {
                        throw error
                    }
                }),
            loadingAreas.form
        )
    }, [isNew])

    useEffect(() => {
        if (!seoSubject) return
        const { country, ...rest } = seoSubject || {}

        const initialData: FormData = {
            country: { identifier: country.id.toString(), data: country },
            ...rest,
        }
        setHtmlContent(seoSubject.contentHtml)
        setInitialData(initialData)
    }, [seoSubject])

    const handleSubmit: SubmitHandler<FormData> = (data) => {
        formRef.current!.setErrors({})

        if (!htmlContent) {
            toast.error('Please enter the the content of the subject!')
            return
        }

        if (!isConversionElementPresent(htmlContent)) return

        if (isHeading1Present(htmlContent)) return

        if (isSlugInvalid(data.slug)) {
            formRef.current?.setFieldError(
                'slug',
                'Slug must consist of only lower case letters (a-z), numbers [0-9], and dashes (-)'
            )
            return
        }

        const { country, ...rest } = data

        const requestBody: UpdateSeoSubject = {
            contentHtml: htmlContent,
            countryId: country.data.id,
            title: seoSubject?.title ?? '',
            ...rest,
        }

        let handleFunc
        if (!isNew && seoSubject?.uuid) {
            handleFunc = SeoSubjectService.update(seoSubject.uuid, requestBody)
        } else {
            handleFunc = SeoSubjectService.create(requestBody)
        }

        trackPromise(
            handleFunc
                .then((subject) => {
                    updateSeoSubject(subject)
                    toast.success('The changes to seo subject have been saved!')
                    history.push(routes.seoSubjectRoute(subject.uuid))
                })
                .catch((error: ApiError) => {
                    if (error.type === ErrorTypes.FormValidation) {
                        badRequestFormErrors(error, formRef.current!)
                    } else {
                        error.handleUnknown('An error occurred while saving subject.')
                    }
                }),
            loadingAreas.save
        )
    }

    return (
        <div>
            <h3>{isNew ? 'Create new' : 'Update'} Seo Subject</h3>
            <Form ref={formRef} initialData={initialData} onSubmit={handleSubmit}>
                {countryOptions && <FormSelect name="country" options={countryOptions} placeholder="Countries" />}
                <Checkbox name="isPublished" placeholder="Is published" />
                <Input name="slug" placeholder="Slug (used for linking)" />
                <Input name="description" placeholder="Keywords (separated by commas)" />
                <Editor value={htmlContent} onChange={setHtmlContent} />

                <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                    {isNew ? 'Save' : 'Update'}
                </Button>
            </Form>
        </div>
    )
}

export default SeoSubjectForm
