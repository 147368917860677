import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import GoBackButton from 'components/elements/GoBackButton'
import SchoolHolidayCreateForm from './SchoolHolidayCreateForm'
import routes from 'lib/constants/routes'
import Meta from 'components/modules/Head'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import CopyIcon from 'components/elements/CopyIcon'
import { SchoolHoliday } from 'interfaces/SchoolHoliday'
import SchoolHolidaysService from 'services/SchoolHolidaysService'
import { formatDate } from 'utils'
import { toast } from 'react-toastify'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import Button from 'components/elements/Button'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'

interface Props extends RouteComponentProps<{ uuid: string }> {}

const loadingAreas = {
    container: 'schoolSuggestionContainer',
    delete: 'schoolHolidayDelete',
}

const SchoolHolidayComponent = (props: Props) => {
    const [schoolHoliday, setSchoolHoliday] = useState<SchoolHoliday | null>(null)
    const uuid = props.match.params.uuid
    const isNew = uuid === 'create' || !uuid
    const history = useHistory()

    const fetchData = useCallback(() => {
        if (isNew) return

        trackPromise(
            SchoolHolidaysService.get(uuid)
                .then((schoolHoliday) => {
                    setSchoolHoliday(schoolHoliday)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting school holiday.')
                }),
            loadingAreas.container
        )
    }, [isNew, uuid])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const updateSchoolHoliday = (schoolHoliday: SchoolHoliday) => setSchoolHoliday(schoolHoliday)

    const handleDeleteSchoolHoliday = useCallback(() => {
        if (!schoolHoliday) return
        trackPromise(
            SchoolHolidaysService.delete(schoolHoliday.uuid)
                .then(() => {
                    toast.success('School holiday has been deleted')
                    history.push(routes.schoolHolidaysRoute)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.NotFound) {
                            toast.error("School holiday with this uuid doesn't exist! Try again.")
                            return
                        } else {
                            error.handleUnknown('An error occurred while deleting the school holiday.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.delete
        )
    }, [history, schoolHoliday])

    return (
        <section>
            <Meta title="School Holiday" />
            <h2>School Holiday</h2>
            <GoBackButton route={routes.schoolHolidaysRoute} routeName="School Holidays" />
            {schoolHoliday && (
                <Box>
                    <Stack>
                        <h3>Information</h3>
                        <p>
                            <strong>Uuid:</strong>&nbsp;
                            {schoolHoliday.uuid}
                            <CopyIcon text={schoolHoliday.uuid} />
                        </p>
                        <p>
                            <strong>Name:</strong>&nbsp;
                            {schoolHoliday.name}
                        </p>
                        <p>
                            <strong>Start Date:</strong>&nbsp;
                            {formatDate(schoolHoliday.startDate)}
                        </p>
                        <p>
                            <strong>End Date:</strong>&nbsp;
                            {formatDate(schoolHoliday.endDate)}
                        </p>
                        <p>
                            <strong>Regions:</strong>&nbsp;
                            {schoolHoliday.regions?.length
                                ? schoolHoliday.regions?.map((r) => `${r.name} (${r.code})`).join(', ')
                                : 'Not specified'}
                        </p>
                        <p>
                            <strong>Active:</strong>&nbsp;
                            {schoolHoliday.isActive ? 'Yes' : 'No'}
                        </p>
                        <br />
                        <Button
                            onClick={handleDeleteSchoolHoliday}
                            loadingArea={loadingAreas.delete}
                            noMargin
                            fullWidth={false}
                            icon={<DeleteIcon />}
                            color="var(--red)"
                            hoverColor="var(--red-dark)"
                        >
                            Delete this school holiday
                        </Button>
                    </Stack>
                </Box>
            )}
            <SchoolHolidayCreateForm
                schoolHoliday={schoolHoliday}
                schoolHolidayUuid={schoolHoliday?.uuid}
                isNew={isNew}
                updateSchoolHoliday={updateSchoolHoliday}
            />
        </section>
    )
}

export default SchoolHolidayComponent
