import ApiService from 'services/ApiService'
import { CreateManualPushNotification, ManualPushNotification } from 'interfaces/ManualPushNotification'

const ManualPushNotificationsService = {
    list(page: number, languageId?: string) {
        return ApiService.get(
            `/manual-push-notifications?page=${page}${languageId ? `&languageId=${languageId}` : ''}`
        ).then((response) => {
            return {
                notifications: response.data.content as ManualPushNotification[],
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    create(notification: CreateManualPushNotification) {
        return ApiService.post('/manual-push-notifications', notification).then((response) => {
            return response.data as ManualPushNotification
        })
    },

    duplicate(uuid: string) {
        return ApiService.post(`/manual-push-notifications/${uuid}/duplicate`).then((response) => {
            return response.data as ManualPushNotification
        })
    },

    update(uuid: string, school: Partial<CreateManualPushNotification>) {
        return ApiService.patch(`/manual-push-notifications/${uuid}`, school).then((response) => {
            return response.data as ManualPushNotification
        })
    },

    get(uuid: string) {
        return ApiService.get(`/manual-push-notifications/${uuid}`).then((response) => {
            return response.data as ManualPushNotification
        })
    },

    approve(uuid: string) {
        return ApiService.post(`/manual-push-notifications/${uuid}/approve`).then((response) => {
            return response.data as ManualPushNotification
        })
    },

    revoke(uuid: string) {
        return ApiService.delete(`/manual-push-notifications/${uuid}/approve`).then((response) => {
            return response.data as ManualPushNotification
        })
    },

    send(uuid: string) {
        return ApiService.post(`/manual-push-notifications/${uuid}/send`).then((response) => {
            return response.data as ManualPushNotification
        })
    },

    sendDraft(uuid: string, userUuid: string) {
        return ApiService.post(`/manual-push-notifications/${uuid}/send-draft?userUUID=${userUuid}`).then((response) => {
            return response.data as ManualPushNotification
        })
    },

    getEstimatedReceiversCount(uuid: string) {
        return ApiService.get(`/manual-push-notifications/${uuid}/estimated-receivers-count`).then((response) => {
            return response.data as number
        })
    },
}

export default ManualPushNotificationsService
