import React, { useEffect, useState } from 'react'
import { ApiError } from 'services/ApiService'
import ContentPreview from './ContentPreview'
import { DiscussionForumAttachment } from 'interfaces/DiscussionForumQuestion'
import DiscussionForumQuestionService from 'services/DiscussionForumQuestionService'

interface Props {
    baseUuid: string
    attachment: DiscussionForumAttachment
    type: 'QUESTION' | 'ANSWER'
}

const ReportQuestionsContentPreview = (props: Props) => {
    const [previewUrl, setPreviewUrl] = useState<string | null>(null)

    useEffect(() => {
        props.type === 'QUESTION'
            ? DiscussionForumQuestionService.getAttachment(props.baseUuid, props.attachment.uuid)
                  .then((url) => {
                      setPreviewUrl(url)
                  })
                  .catch((error) => {
                      if (error instanceof ApiError) {
                          error.handleUnknown('An error occurred while getting discussion forum question attachment.')
                      } else {
                          throw error
                      }
                  })
            : DiscussionForumQuestionService.getAnswersAttachment(props.baseUuid, props.attachment.uuid)
                  .then((url) => {
                      setPreviewUrl(url)
                  })
                  .catch((error) => {
                      if (error instanceof ApiError) {
                          error.handleUnknown('An error occurred while getting discussion forum answer attachment.')
                      } else {
                          throw error
                      }
                  })
    }, [props.attachment.uuid, props.baseUuid, props.type])

    if (!previewUrl) return null

    return <ContentPreview contentType={props.attachment.fileType} previewUrl={previewUrl} />
}

export default ReportQuestionsContentPreview
