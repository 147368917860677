import { SchoolRequest } from 'interfaces/FormSchool'
import ApiService from './ApiService'
import { SchoolFromSuggestion, SchoolSuggestion } from 'interfaces/SchoolSuggestion'
import { SchoolFormData } from 'pages/SchoolSuggestions/SchoolSuggestionCreateForm'

const SchoolCreateService = {
    list(page: number, countryCode?: string) {
        return ApiService.get(`/schools/suggestions?page=${page}&countryCode=${countryCode}`).then((response) => {
            return {
                schools: response.data.content as SchoolSuggestion[],
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    get(uuid: string) {
        return ApiService.get(`/schools/suggestions/${uuid}`).then((response) => {
            return response.data as SchoolSuggestion
        })
    },

    delete(uuid: string) {
        return ApiService.delete(`/schools/suggestions/${uuid}`).then((response) => {
            return response.data as SchoolSuggestion
        })
    },

    create(data: SchoolRequest) {
        return ApiService.post(`/schools`, data).then((response) => {
            return response.data as SchoolSuggestion
        })
    },

    markAsDuplicate(suggestionUuid: string, schoolUuid: string) {
        return ApiService.post(`/schools/suggestions/${suggestionUuid}/mark-as-duplicate`, { schoolUuid })
    },

    deleteSchool(schoolUuid: string, targetSchool: string) {
        return ApiService.delete(`/schools/${schoolUuid}`, { data: { targetSchoolUuid: targetSchool } }).then((response) => {
            return response.data
        })
    },

    searchForDuplicates(schoolName: string, countryId: number) {
        return ApiService.get(`/schools/search?query=${schoolName}&countryId=${countryId}`).then((response) => {
            return response.data.content as SchoolFromSuggestion[]
        })
    },

    checkForDuplicates(schoolName: string, postalCode: string, countryId: number) {
        return ApiService.get(`/schools/duplicates?name=${schoolName}&postalCode=${postalCode}&countryId=${countryId}`).then(
            (response) => {
                return response.data.content as SchoolFromSuggestion[]
            }
        )
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getCoordinatesByAddress({ name, streetWithNumber, city, countryId, postalCode }: SchoolFormData) {
        return ApiService.get(
            `/schools/coordinates-by-address?country=${
                countryId.data
            }&city=${city}&streetWithName=${`${name}, ${streetWithNumber}&postalCode=${postalCode}`}`
        ).then((response) => {
            return response.data as { latitude: number; longitude: number }
        })
    },
}

export default SchoolCreateService
