import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { setUrlParam } from 'utils'
import { PaginationHookObject, PaginationObject } from './types'

interface PaginationProps {
    initialPage?: number
    initialElementsCount?: number
    initialTotalPages?: number
}

const urlParamPageKey = 'page'

export const usePagination = ({
    initialPage,
    initialElementsCount,
    initialTotalPages,
}: PaginationProps): PaginationHookObject => {
    const [pagination, setPagination] = useState<PaginationObject>({
        pageNumber: initialPage || 0,
        totalElements: initialElementsCount || 20,
        totalPages: initialTotalPages || 1,
    })
    const location = useLocation()

    useEffect(() => {
        const pageParam = new URLSearchParams(location.search).get(urlParamPageKey)
        if (pageParam) {
            setPagination((pagination) => ({ ...pagination, pageNumber: +pageParam - 1 }))
        } else {
            setPagination((pagination) => ({ ...pagination, pageNumber: 0 }))
        }
    }, [location])

    const setUrlPageParam = (page: number) => {
        if (page === 0) {
            setUrlParam(urlParamPageKey, null)
            return
        }

        setUrlParam(urlParamPageKey, (page + 1).toString())
    }

    const setCurrentPage = useCallback((value: number): void => {
        setUrlPageParam(value)
        setPagination((pagination) => ({ ...pagination, pageNumber: value }))
    }, [])

    const setElementsCount = useCallback((value: number): void => {
        setPagination((pagination) => ({ ...pagination, totalElements: value }))
    }, [])

    const setTotalPages = useCallback((value: number): void => {
        setPagination((pagination) => ({ ...pagination, totalPages: value }))
    }, [])

    const goToNextPage = () => {
        const nextPage = pagination.pageNumber + 1
        if (nextPage === pagination.totalPages) return

        setCurrentPage(nextPage)
        setUrlPageParam(nextPage)
    }

    const goToPreviousPage = () => {
        if (pagination.pageNumber === 0) return
        const prevPage = pagination.pageNumber - 1

        setCurrentPage(prevPage)
        setUrlPageParam(prevPage)
    }

    const skipTwoNextPage = () => {
        const nextPage = pagination.pageNumber + 2
        if (nextPage >= pagination.totalPages) return

        setCurrentPage(nextPage)
        setUrlPageParam(nextPage)
    }

    const skipTwoPreviousPage = () => {
        if (pagination.pageNumber <= 1) return
        const prevPage = pagination.pageNumber - 2

        setCurrentPage(prevPage)
        setUrlPageParam(prevPage)
    }

    return {
        pagination,
        setCurrentPage,
        setTotalPages,
        setElementsCount,
        goToNextPage,
        goToPreviousPage,
        skipTwoNextPage,
        skipTwoPreviousPage,
    }
}
