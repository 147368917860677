import React, { useEffect, useRef, useState } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { badRequestFormErrors } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { useHistory } from 'react-router-dom'
import Button from 'components/elements/Button'
import { Checkbox, FormSelect, Input, SelectOption } from 'components/inputs/Input'
import routes from 'lib/constants/routes'
import ExperimentsService from './ExperimentsService'
import { Experiment, ExperimentVariant, UpdateExperiment } from 'interfaces/Experiment'
import Box from 'components/elements/Box'
import VariantForm from './VariantForm'
import { isIdentifierInvalid } from 'lib/features/experiments'

interface FormData {
    name: string
    identifier: string
    description: string
    startOn: string | null
    endOn: string | null
    jiraTicketUrl: string
    squad: SelectOption<string | null> | null
    cohort: SelectOption<string | null>
    minimumAppBuildNumber: string
    rolloutFraction: number | null
    isExclusiveCohort: boolean
}

interface Props {
    isNew: boolean
    experiment: Experiment | null
    updateExperiment: (experiment: Experiment) => void
}

const loadingAreas = {
    form: 'experimentForm',
    save: 'saveExperiment',
}

const ExperimentForm = ({ isNew, experiment, updateExperiment }: Props) => {
    const [initialData, setInitialData] = useState<FormData>()
    const [cohortsOptions, setCohortOptions] = useState<{ label: string; identifier: string; data: string | null }[]>()
    const [variants, setVariants] = useState<ExperimentVariant[] | null>(experiment?.variants ?? null)
    const formRef = useRef<FormHandles>(null)
    const history = useHistory()
    const initialIdentifiersArray = experiment?.variants.map((variant) => variant.identifier)

    const squadOptions = ['Core', 'AI', 'Growth', 'Monetisation', 'US'].map((squad) => ({
        label: squad,
        identifier: squad,
        data: squad,
    }))

    useEffect(() => {
        trackPromise(
            ExperimentsService.getCohorts()
                .then((cohorts) => {
                    const options = [
                        {
                            label: 'Everyone',
                            identifier: 'everyone',
                            data: null,
                        },
                        ...cohorts.map((cohort) => ({
                            label: cohort.description,
                            identifier: cohort.code,
                            data: cohort.code,
                        })),
                    ]
                    setCohortOptions(options)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('Unable to list cohorts.')
                        return
                    } else {
                        throw error
                    }
                }),
            loadingAreas.form
        )
    }, [])

    useEffect(() => {
        if (!experiment || !cohortsOptions) return

        const selectedCohortOption = cohortsOptions.find((cohort) => cohort.data === experiment.cohortCode)
        const initialData: FormData = {
            name: experiment.name,
            description: experiment.description,
            startOn: experiment.startOn ? experiment.startOn.slice(0, 10) : null,
            endOn: experiment.endOn ? experiment.endOn.slice(0, 10) : null,
            identifier: experiment.identifier,
            squad: experiment.squad
                ? {
                      data: experiment.squad,
                      identifier: experiment.squad,
                  }
                : null,
            cohort: selectedCohortOption
                ? {
                      identifier: selectedCohortOption.data ?? 'everyone',
                      data: selectedCohortOption.data,
                  }
                : {
                      identifier: 'everyone',
                      data: null,
                  },
            jiraTicketUrl: experiment.jiraTicketUrl,
            isExclusiveCohort: experiment.isExclusiveCohort,
            rolloutFraction: experiment.rolloutFraction,
            minimumAppBuildNumber: experiment.minimumAppBuildNumber ? experiment.minimumAppBuildNumber.toString() : '',
        }

        setInitialData(initialData)
    }, [experiment, cohortsOptions])

    const handleSubmit: SubmitHandler<FormData> = (data) => {
        formRef.current!.setErrors({})

        const { cohort, startOn, endOn, identifier, squad, minimumAppBuildNumber, jiraTicketUrl, rolloutFraction, ...rest } =
            data

        if (isIdentifierInvalid(identifier)) {
            formRef.current!.setFieldError(
                'identifier',
                'Identifier must consist of only lower case letters (a-z), numbers (0-9) and underscores (_).'
            )
            return
        }

        if (!endOn && startOn) {
            formRef.current!.setFieldError('endOn', 'You need to also provide end date if you want to include time frame!')
            return
        }

        if (endOn && !startOn) {
            formRef.current!.setFieldError(
                'startOn',
                'You need to also provide start date if you want to include time frame!'
            )
            return
        }

        if (startOn && endOn && new Date(startOn) > new Date(endOn)) {
            toast.error("End time can't be before start time.")
            return
        }

        if (!jiraTicketUrl) {
            formRef.current!.setFieldError('jiraTicketUrl', 'Please enter ticket url')
            return
        }

        if (!squad?.data) {
            formRef.current!.setFieldError('squad', 'Please which squad added the experiment')
            return
        }

        if (!!rolloutFraction && (+rolloutFraction > 99 || rolloutFraction < 1)) {
            formRef.current!.setFieldError('rolloutFraction', 'Please enter correct number (between 1-99)')
            return
        }

        const requestBody: UpdateExperiment = {
            cohortCode: cohort.data,
            startOn: startOn ? new Date(startOn).toISOString() : null,
            endOn: endOn ? new Date(endOn).toISOString() : null,
            variants: variants ?? [],
            squad: squad.data,
            identifier,
            jiraTicketUrl,
            minimumAppBuildNumber: minimumAppBuildNumber ? +minimumAppBuildNumber : null,
            rolloutFraction: rolloutFraction ? +rolloutFraction : null,
            ...rest,
        }

        let handleFunc
        if (!isNew && experiment?.uuid) {
            const hasExperimentStarted = startOn && new Date() > new Date(startOn)
            const updatingVariantsWarningText =
                'Updating the variants after the experiment started, could lead to major issues. This could cause users to be migrated from one variant to another. Are you sure that you want to continue?'

            const experimentsIdentifiersArray = variants && variants.map((variant) => variant.identifier)

            const haveMatchingExperimentIdentifiers =
                initialIdentifiersArray &&
                experimentsIdentifiersArray &&
                experimentsIdentifiersArray.every((identifier, i) => identifier === initialIdentifiersArray[i])

            if (hasExperimentStarted && !haveMatchingExperimentIdentifiers && !window.confirm(updatingVariantsWarningText)) {
                return
            }

            handleFunc = ExperimentsService.update(experiment.uuid, requestBody)
        } else {
            handleFunc = ExperimentsService.create(requestBody)
        }

        trackPromise(
            handleFunc
                .then((experiment) => {
                    updateExperiment(experiment)
                    toast.success('The changes to experiment have been saved!')
                    history.push(routes.experimentRoute(experiment.uuid))
                })
                .catch((error: ApiError) => {
                    if (error.type === ErrorTypes.FormValidation) {
                        badRequestFormErrors(error, formRef.current!)
                    } else {
                        error.handleUnknown('An error occurred while saving experiment.')
                    }
                }),
            loadingAreas.save
        )
    }

    const changeVariants = (variants: ExperimentVariant[]) => setVariants(variants)

    return (
        <Box>
            <h3>{isNew ? 'Create new' : 'Update'} Experiment</h3>
            <Form ref={formRef} initialData={initialData} onSubmit={handleSubmit}>
                <Input name="name" placeholder="Name" />
                <Input name="identifier" placeholder="Identifier" />
                <Input name="description" placeholder="Description" />
                <Input name="startOn" placeholder="Start date (Optional)" type="date" />
                <Input name="endOn" placeholder="End date (Optional)" type="date" />
                <Input
                    name="jiraTicketUrl"
                    placeholder={`Jira Ticket URL (you can enter multiple tickets separated by commas)`}
                />
                <Input name="minimumAppBuildNumber" placeholder="Minimum App Build Number" type="number" />
                <Input name="rolloutFraction" placeholder="Rollout Fraction (1-99)" type="number" />
                <FormSelect name="squad" options={squadOptions} placeholder="Squad" />
                {cohortsOptions && <FormSelect name="cohort" options={cohortsOptions} placeholder="Cohort" />}
                <Checkbox
                    name="isExclusiveCohort"
                    placeholder="Is cohort exclusive? (requires approval by product and tech leadership)"
                />
                <VariantForm
                    cohortsOptions={cohortsOptions}
                    variants={variants}
                    parentFormRef={formRef}
                    changeVariants={changeVariants}
                />
                <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                    {isNew ? 'Save' : 'Update'}
                </Button>
            </Form>
        </Box>
    )
}

export default ExperimentForm
