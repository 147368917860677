import React, { useEffect, useRef, useState } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { badRequestFormErrors, formatUTCDateForRequest, isUUID } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { useHistory } from 'react-router-dom'
import Button from 'components/elements/Button'
import { Input, FormSelect, SelectOptionWithLabel, SelectOption, Textarea } from 'components/inputs/Input'
import routes from 'lib/constants/routes'
import { LanguageInterface } from 'interfaces/Language'
import { useUserTargetingForm } from 'utils/hooks/useUserTargetingForm'
import LanguageService from 'services/LanguageService'
import { CreateManualChatMessage, ManualChatMessage } from 'interfaces/ManualChatMessage'
import ManualChatMessagesService from 'services/ManualChatMessagesService'

interface Props {
    chatMessage: ManualChatMessage | null
    isNew: boolean
    updateManualChatMessage: (chatMessage: ManualChatMessage) => void
    senderUserUuid: string
}

const loadingAreas = {
    form: 'manualChatMessageForm',
    save: 'saveManualChatMessage',
}

interface FormData {
    message: string
    senderUserUuid: string
    knowUuid: string
    language: SelectOption<LanguageInterface>
    scheduledOn: string
}

const ManualChatMessageForm = ({ isNew, chatMessage, updateManualChatMessage, senderUserUuid }: Props) => {
    const { userTargetingId, renderTargetingModal, renderTargetingButton } = useUserTargetingForm(
        chatMessage?.userTargetingId,
        isNew
    )
    const [initialData, setInitialData] = useState<FormData>()
    const [languageOptions, setLanguageOptions] = useState<SelectOptionWithLabel<LanguageInterface>[]>()
    const formRef = useRef<FormHandles>(null)
    const history = useHistory()

    useEffect(() => {
        trackPromise(
            LanguageService.list(true)
                .then((languages) => {
                    const options = languages.map((lang) => ({
                        label: lang.englishName,
                        identifier: lang.id.toString(),
                        data: lang,
                    }))
                    setLanguageOptions(options)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting languages.')
                }),
            loadingAreas.form
        )
    }, [])

    useEffect(() => {
        if (!chatMessage) return

        const initialData: FormData = {
            message: chatMessage.message,
            senderUserUuid: chatMessage.senderUserUuid,
            knowUuid: chatMessage.knowUuid ?? '',
            language: { identifier: chatMessage.language.id.toString(), data: chatMessage.language },
            scheduledOn: chatMessage?.scheduledOn ? chatMessage.scheduledOn.slice(0, -1) : '',
        }

        setInitialData(initialData)
    }, [chatMessage, senderUserUuid, userTargetingId])

    const handleSubmit: SubmitHandler<FormData> = (data) => {
        formRef.current!.setErrors({})

        if (!data.senderUserUuid) {
            formRef.current!.setFieldError(
                'senderUserUuid',
                `You need to input Uuid of the user who will be shown as sender in chat window (Your uuid is: ${senderUserUuid}) `
            )
            return
        }

        if (!userTargetingId) {
            toast.error('You need to add User Targeting!')
            return
        }

        if (!isUUID(data.senderUserUuid)) {
            formRef.current!.setFieldError('senderUserUuid', 'Please enter a valid user uuid.')
            return
        }

        if (!!data.knowUuid?.length && !isUUID(data.knowUuid)) {
            formRef.current!.setFieldError('knowUuid', 'Please enter a valid know uuid.')
            return
        }

        if (data.scheduledOn && data.scheduledOn?.length > 0 && new Date() > new Date(data.scheduledOn)) {
            formRef.current!.setFieldError('scheduledOn', 'Schedule date has to be in the future.')
            return
        }

        let requestBody: CreateManualChatMessage = {
            message: data.message,
            languageId: data.language.data.id,
            userTargetingId: userTargetingId,
            senderUserUuid: data.senderUserUuid,
        }

        if (data.scheduledOn) {
            requestBody = {
                ...requestBody,
                scheduledOn: formatUTCDateForRequest(data.scheduledOn),
            }
        }

        if (data.knowUuid) {
            requestBody = {
                ...requestBody,
                knowUuid: data.knowUuid.length ? data.knowUuid : null,
            }
        }

        let handleFunc
        if (isNew) {
            handleFunc = ManualChatMessagesService.create(requestBody)
        } else {
            handleFunc = ManualChatMessagesService.update(chatMessage!.uuid, requestBody)
        }

        trackPromise(
            handleFunc
                .then((chatMessage) => {
                    updateManualChatMessage(chatMessage)
                    toast.success('The changes to Manual Chat Message have been saved!')
                    history.push(routes.manualChatMessageRoute(chatMessage.uuid))
                })
                .catch((error: ApiError) => {
                    if (error.type === ErrorTypes.FormValidation) {
                        badRequestFormErrors(error, formRef.current!)
                    } else {
                        error.handleUnknown('An error occurred while saving Manual Chat Message.')
                    }
                }),
            loadingAreas.save
        )
    }

    return (
        <div>
            <h3>{isNew ? 'Create new' : 'Update'} Manual Chat Message</h3>

            {renderTargetingModal()}
            <Form ref={formRef} initialData={initialData} onSubmit={handleSubmit}>
                <Textarea name="message" placeholder="Message (max 1500 characters)" showCharactersCount />
                <Input
                    name="senderUserUuid"
                    placeholder="Uuid of the user who will be shown as sender in chat window (initially the creator uuid is loaded)"
                />
                <Input name="knowUuid" placeholder="Attached know (know UUID)" />
                <Input name="scheduledOn" placeholder="Scheduled on in UTC time (Optional)" type="datetime-local" />
                {renderTargetingButton()}
                {languageOptions && <FormSelect name="language" options={languageOptions} placeholder="Language" />}
                <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                    {isNew ? 'Save' : 'Update'}
                </Button>
            </Form>
        </div>
    )
}

export default ManualChatMessageForm
