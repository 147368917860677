/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useRef } from 'react'
import { Form } from '@unform/web'
import CSVImporter, { CSVData } from 'components/FileUpload/CSVImport'
import { toast } from 'react-toastify'
import { FormHandles } from '@unform/core'
import { ImportHeaderLabels, processTaxonomyImportCSVData } from 'lib/features/taxonomyImport'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import TaxonomyService from 'services/TaxonomyService'
import { ApiError } from 'services/ApiService'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { Body1 } from 'components/elements/Text'
import Meta from 'components/modules/Head'

const TaxonomyImportComponent = () => {
    const taxonomyImportFormRef = useRef<FormHandles>(null)

    const handleCSVUploadComplete = async (csvData: CSVData[]) => {
        const formattedTaxonomyJson = await processTaxonomyImportCSVData(csvData, toast)

        const headers = Object.keys(csvData[0])
        const headerLabels = Object.values(ImportHeaderLabels)

        for (let i = 0; i < headers.length; i++) {
            if (!headerLabels.includes(headers[i] as ImportHeaderLabels) && headers[i] !== 'SubTopic2') {
                toast.warn(
                    `${headers[i]} is not part of the standard headers (Subject, Topic, SubTopic, SubTopic2, Grade). Please check your csv file!`
                )
                return
            }
        }

        if (!formattedTaxonomyJson || !formattedTaxonomyJson.length) {
            toast.warning('No taxonomy data found')
            return
        }

        const progressText = document.getElementById('title')
        formattedTaxonomyJson.unshift()

        TaxonomyService.import(formattedTaxonomyJson)
            .then(() => {
                toast.success('The upload was successful')
                const text = `Imported ${formattedTaxonomyJson.length} taxonomy rows.`
                if (progressText) progressText.textContent = text
            })
            .catch((error: ApiError) => {
                if (error instanceof ApiError) {
                    if (error.type === ErrorTypes.BadRequest) {
                        toast.error(
                            <>
                                {error.message.split('\n')?.map((line, i) => (
                                    <Body1 fontWeigth={400} key={i}>
                                        {line}
                                    </Body1>
                                ))}
                            </>,
                            { progress: 'false' }
                        )
                    } else {
                        error.handleUnknown(`An error occurred while importing taxonomy.`)
                    }
                } else {
                    throw error
                }
                const text = `Import failed ❌`
                if (progressText) progressText.textContent = text
            })
    }

    return (
        <section>
            <Meta title="Taxonomy Import" />
            <Stack>
                <h2>Taxonomy Import</h2>
                <Box>
                    <strong>Requirements:</strong>
                    <ul>
                        <li>{`First row of the CSV file must contain correct Column Name: Subject, Topic, Subtopic, SubTopic2, Grade`}</li>
                        <li>{`Subject Id and Grade level must match the data on the database`}</li>
                    </ul>
                    <br />
                    <strong>Example document:</strong>&nbsp;
                    <a href="/files/ExampleTaxonomyImport.csv" download="example_taxonomy_import.csv">
                        Download
                    </a>
                    <Form ref={taxonomyImportFormRef} onSubmit={() => {}}>
                        <br />

                        <CSVImporter
                            title="CSV Upload area for Taxonomy Import"
                            handleCSVUploadComplete={handleCSVUploadComplete}
                            showHeader
                        />
                    </Form>
                </Box>
            </Stack>
        </section>
    )
}

export default TaxonomyImportComponent
