import ApiService from './ApiService'
import { CountryCode } from 'interfaces/Country'
import { Region } from 'interfaces/Region'
import { LanguageCode } from 'interfaces/Language'

const RegionService = {
    listRegionsByCountry(country: number | CountryCode, contentLanguage: LanguageCode | number) {
        return ApiService.get(`/countries/${country}/content-languages/${contentLanguage}/regions`).then((response) => {
            return response.data.content as Region[]
        })
    },

    get(id: number) {
        return ApiService.get(`/regions/${id}`).then((response) => {
            return response.data as Region
        })
    },
}

export default RegionService
