const toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike', 'link'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['clean'],

    // Not needed atm
    // [{ script: 'sub' }, { script: 'super' }],
    // ['blockquote'],
    // [{ align: [] }],
]

export const modules = {
    toolbar: toolbarOptions,
    clipboard: { matchVisual: false },
    history: {
        delay: 2000,
        maxStack: 100,
        userOnly: true,
    },
}
