import React, { useCallback, useEffect, useState } from 'react'
import LoadingArea from 'components/elements/LoadingArea'
import { RouteComponentProps } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import styled from 'styled-components'
import GoBackButton from 'components/elements/GoBackButton'
import routes from 'lib/constants/routes'
import { SeoTopic } from 'interfaces/SeoTopic'
import SeoTopicService from './SeoTopicService'
import SeoTopicsForm from './SeoTopicForm'
import { EditButton, MetaBox } from 'style'
import { useToggleState } from 'utils/hooks/useToggleState'
import Modal from 'components/modules/Modal'
import CopyIcon from 'components/elements/CopyIcon'
import HtmlContentPreview from '../HtmlContentPreview'
import SeoSubTopics from '../SeoSubTopic/SeoSubTopics'
import Meta from 'components/modules/Head'

interface Props extends RouteComponentProps<{ uuid: string; subjectUuid: string }> {
    seoSubjectUuid: string
}

const loadingAreas = {
    container: 'subjectContainer',
}

const Container = styled.section`
    padding-bottom: 25px;
`

const SeoTopicComponent = (props: Props) => {
    const [seoTopic, setSeoTopic] = useState<SeoTopic | null>(null)
    const [showEditForm, toggleEditForm] = useToggleState(false)
    const { uuid, subjectUuid } = props.match.params
    const isNew = !uuid || uuid === 'create'
    const updateSeoTopic = (seoTopic: SeoTopic) => setSeoTopic(seoTopic)

    const handleFormUpdate = (seoTopic: SeoTopic) => {
        updateSeoTopic(seoTopic)
        showEditForm && toggleEditForm()
    }

    const fetchData = useCallback(() => {
        if (!uuid || isNew) return

        trackPromise(
            SeoTopicService.get(uuid)
                .then((seoTopic) => {
                    updateSeoTopic(seoTopic)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting topic.')
                }),
            loadingAreas.container
        )
    }, [uuid, isNew])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <Container>
            <Meta title="SEO Topic" />
            <h2>SEO Topic</h2>
            <GoBackButton route={routes.seoSubjectRoute(subjectUuid)} routeName="SEO Subject" />
            {!isNew ? (
                <>
                    <Box>
                        <Stack>
                            <MetaBox>
                                <h3>Information</h3>
                                <EditButton onClick={toggleEditForm} />
                            </MetaBox>
                            <LoadingArea area={loadingAreas.container}>
                                {seoTopic && (
                                    <>
                                        <p>
                                            <strong>Uuid:</strong>&nbsp;
                                            {seoTopic.uuid}
                                            <CopyIcon text={seoTopic.uuid} />
                                        </p>
                                        <p>
                                            <strong>Slug:</strong>&nbsp;
                                            {seoTopic.slug}
                                        </p>
                                        <p>
                                            <strong>Is published:</strong>&nbsp;
                                            {seoTopic.isPublished ? 'Yes' : 'No'}
                                        </p>
                                        <p>
                                            <strong>Meta Title:</strong>&nbsp;
                                            {seoTopic.title}
                                        </p>
                                        <p>
                                            <strong>Keywords:</strong>&nbsp;
                                            {seoTopic.description}
                                        </p>
                                        <HtmlContentPreview htmlContent={seoTopic.contentHtml} />
                                    </>
                                )}
                            </LoadingArea>
                        </Stack>
                        <Modal show={showEditForm} title="Edit SEO Topic" onClose={toggleEditForm}>
                            <SeoTopicsForm
                                seoTopic={seoTopic}
                                isNew={false}
                                updateSeoTopic={handleFormUpdate}
                                seoSubjectUuid={subjectUuid}
                            />
                        </Modal>
                    </Box>
                    {seoTopic ? (
                        <Box>
                            <SeoSubTopics seoSubjectUuid={subjectUuid} seoTopicUuid={seoTopic.uuid} />
                        </Box>
                    ) : null}
                </>
            ) : (
                <SeoTopicsForm
                    seoTopic={seoTopic}
                    isNew={isNew}
                    updateSeoTopic={updateSeoTopic}
                    seoSubjectUuid={subjectUuid}
                />
            )}
        </Container>
    )
}

export default SeoTopicComponent
