import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import KnowerGeneral from './KnowerGeneral'
import KnowerPayments from './KnowerPayments'
import KnowerKnows from './KnowerKnows'
import Tabs from 'components/modules/Tabs'
import styled, { css } from 'styled-components'

interface Props extends RouteComponentProps<{ id: string }> {
    knowerUuid?: string
}

const Section = styled.section<{ fullWidth?: boolean }>`
    ${({ fullWidth }) =>
        fullWidth
            ? css`
                  all: unset;
              `
            : ''}
`

const Knower = (props: Props) => {
    const knowerUuid = props.knowerUuid ?? props.match.params.id

    return (
        <Section fullWidth={!!props.knowerUuid}>
            <h2>Knower</h2>
            <Tabs deactivateUrlParam>
                <div data-label="Knows">
                    <KnowerKnows knowerUuid={knowerUuid} />
                </div>
                <div data-label="General">
                    <KnowerGeneral knowerUuid={knowerUuid} />
                </div>
                <div data-label="Payments">
                    <KnowerPayments knowerUuid={knowerUuid} />
                </div>
            </Tabs>
        </Section>
    )
}

export default withRouter(Knower)
