/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { Region } from 'interfaces/Region'
import { SchoolType } from 'interfaces/SchoolType'
import { useEffect, useRef, useState } from 'react'
import { trackPromise } from 'react-promise-tracker'
import SchoolTypeService from 'services/SchoolTypeService'
import { ApiError } from 'services/ApiService'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import Stack from 'components/elements/Stack'
import { FormSelect, SelectMultiple, SelectOption, SelectOptionWithLabel } from 'components/inputs/Input'

interface Props {
    selectedRegion: Region | null
    onUpdate: (schoolTypes: SchoolType[]) => void
    changeDefaultSchoolTypes?: (schoolType: SchoolType[]) => void
    initialSchoolTypes?: SchoolType[]
    limitToOneSelectedItem?: boolean
}

interface FormData {
    gradeSelectionSchoolTypes: SelectOption<SchoolType>[]
}

const GradeSelectionSchoolTypes = ({
    selectedRegion,
    onUpdate,
    initialSchoolTypes,
    changeDefaultSchoolTypes,
    limitToOneSelectedItem,
}: Props) => {
    const [initialData, setInitialData] = useState<FormData>()

    const [schoolTypeOptions, setSchoolTypeOptions] = useState<SelectOptionWithLabel<SchoolType>[]>()

    const formRef = useRef<FormHandles>(null)

    useEffect(() => {
        if (!selectedRegion) return

        trackPromise(
            SchoolTypeService.listSchoolTypesByRegion(selectedRegion.id)
                .then((schoolTypes) => {
                    const schoolTypesOptions = schoolTypes

                    const options = schoolTypesOptions.map((type) => ({
                        label: type.name,
                        identifier: type.id.toString(),
                        data: type,
                    }))

                    setSchoolTypeOptions(options)

                    if (initialSchoolTypes && initialSchoolTypes?.length > 0) {
                        setInitialData({
                            gradeSelectionSchoolTypes: initialSchoolTypes.map((s) => ({
                                data: s,
                                identifier: s.id.toString(),
                            })),
                        })

                        if (!changeDefaultSchoolTypes) return

                        const selectedSchoolTypesFromKnow = schoolTypesOptions.filter((schoolType) =>
                            initialSchoolTypes.find((knowSchoolType) => knowSchoolType.id === schoolType.id)
                        )

                        !!selectedSchoolTypesFromKnow && changeDefaultSchoolTypes(selectedSchoolTypesFromKnow)
                    } else {
                        if (schoolTypesOptions[0]) {
                            setInitialData({
                                gradeSelectionSchoolTypes: [
                                    { data: schoolTypesOptions[0], identifier: schoolTypesOptions[0].id.toString() },
                                ],
                            })

                            if (changeDefaultSchoolTypes) {
                                changeDefaultSchoolTypes([schoolTypesOptions[0]])
                            }
                        }
                    }
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while fetching the school types.')
                    } else {
                        throw error
                    }
                })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRegion])

    if (!schoolTypeOptions || schoolTypeOptions.length === 1) return null

    return (
        <Stack>
            <Form ref={formRef} onSubmit={() => {}} initialData={initialData}>
                {limitToOneSelectedItem ? (
                    <FormSelect
                        name="gradeSelectionSchoolTypes"
                        options={schoolTypeOptions}
                        placeholder="Select School types"
                        onUpdate={(schoolType) => onUpdate([schoolType])}
                    />
                ) : (
                    <SelectMultiple<SchoolType>
                        name="gradeSelectionSchoolTypes"
                        options={schoolTypeOptions}
                        label={`Select School types`}
                        onUpdate={onUpdate}
                        dataCy="schoolTypes-fake"
                    />
                )}
            </Form>
        </Stack>
    )
}

export default GradeSelectionSchoolTypes
