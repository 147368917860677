import React from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import Button from 'components/elements/Button'
import { Body2, ButtonText, Headline5 } from 'components/elements/Text'
import { Input } from 'components/inputs/Input'
import Modal from 'components/modules/Modal'
import { CompetitionFAQ } from 'interfaces/Competition'
import { useRef, useState } from 'react'
import { useToggleState } from 'utils/hooks/useToggleState'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import CollapsibleCard from 'components/features/CollapsibleCard'
import theme from 'lib/constants/theme'
import styled from 'styled-components'
import Row from 'components/elements/Row'

const ButtonRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    button {
        height: 30px;
    }
`

type FAQUpdate = CompetitionFAQ & { index: number }

interface Props {
    faqs: CompetitionFAQ[]
    setCompetitionFAQs: React.Dispatch<React.SetStateAction<CompetitionFAQ[]>>
}

const CompetitionFAQForm = ({ faqs, setCompetitionFAQs }: Props) => {
    const faqFormRef = useRef<FormHandles>(null)
    const [selectedFAQ, setSelectedFAQ] = useState<FAQUpdate | null>(null)
    const [showFAQForm, toggleFAQForm] = useToggleState(false)

    const updateCompetitionFAQ = (faq: CompetitionFAQ) => setCompetitionFAQs((prevFAQ) => [...prevFAQ, faq])

    const handleEditFAQ = (faq: CompetitionFAQ, index: number) => {
        setSelectedFAQ({ ...faq, index })
        toggleFAQForm()
    }

    const handleDeleteFAQ = (indexToDelete: number) =>
        setCompetitionFAQs((faqs) => faqs.filter((_, i) => i !== indexToDelete))

    const handleFAQFormSubmit: SubmitHandler<CompetitionFAQ> = (faq) => {
        if (!faq.answer) {
            faqFormRef.current!.setFieldError('answer', 'This field is required!')
            return
        }

        if (!faq.question) {
            faqFormRef.current!.setFieldError('question', 'This field is required!')
            return
        }

        if (selectedFAQ) {
            const { index } = selectedFAQ

            setCompetitionFAQs((prevFaqs) => {
                const tempFaqs = prevFaqs
                tempFaqs[index] = faq
                return tempFaqs
            })
        } else {
            updateCompetitionFAQ(faq)
        }
        setSelectedFAQ(null)
        toggleFAQForm()
    }

    return (
        <>
            <Modal show={showFAQForm} title={selectedFAQ ? 'Update FAQ item' : 'Add FAQ item'} onClose={toggleFAQForm}>
                <Body2>
                    Hint: you still need to save the competition after adding FAQ items on the bottom of the form in order to
                    save the changes
                </Body2>
                <Form ref={faqFormRef} onSubmit={handleFAQFormSubmit} initialData={{ ...selectedFAQ }}>
                    <Input name="question" placeholder="Question" />
                    <Input name="answer" placeholder="Answer" />
                    <Button fullWidth icon={<SaveIcon />} type="button" onClick={() => faqFormRef.current?.submitForm()}>
                        Save
                    </Button>
                </Form>
            </Modal>
            <Box>
                <Stack>
                    <ButtonRow>
                        <Headline5 color={theme.colors.white} marginBottom={10}>
                            FAQ
                        </Headline5>
                        <Button fullWidth={false} onClick={toggleFAQForm} icon={<AddIcon />} type="button">
                            <ButtonText color={theme.colors.white}>Add FAQ</ButtonText>
                        </Button>
                    </ButtonRow>

                    {faqs.length ? (
                        <>
                            {faqs.map((faq, i) => (
                                <CollapsibleCard
                                    key={i}
                                    title={
                                        <ButtonRow>
                                            <Body2 color={theme.colors.white}>{`${i + 1}. Question: ${faq.question}`}</Body2>
                                            <Row gap={20}>
                                                <Button
                                                    fullWidth={false}
                                                    onClick={() => handleDeleteFAQ(i)}
                                                    type="button"
                                                    icon={<DeleteIcon />}
                                                    color={theme.colors.secondaryRed}
                                                    hoverColor={theme.colors.secondaryRedDarker}
                                                >
                                                    Delete
                                                </Button>
                                                <Button
                                                    fullWidth={false}
                                                    onClick={() => handleEditFAQ(faq, i)}
                                                    type="button"
                                                    icon={<EditIcon />}
                                                >
                                                    Edit
                                                </Button>
                                            </Row>
                                        </ButtonRow>
                                    }
                                    openByDefault={false}
                                >
                                    <Body2 color={theme.colors.white} fontWeigth={500}>{`Answer: ${faq.answer}`}</Body2>
                                </CollapsibleCard>
                            ))}
                        </>
                    ) : (
                        <Body2>No FAQs items were added</Body2>
                    )}
                </Stack>
            </Box>
        </>
    )
}

export default CompetitionFAQForm
