import React, { useEffect, useRef, useState } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { badRequestFormErrors } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import Button from 'components/elements/Button'
import { FormSelect, Input, SelectOption } from 'components/inputs/Input'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import { useHistory } from 'react-router-dom'
import routes from 'lib/constants/routes'
import { SchoolFromSuggestion } from 'interfaces/SchoolSuggestion'
import SchoolsService from 'services/SchoolsService'
import CountriesService from 'services/CountriesService'

const loadingAreas = {
    delete: 'deleteSchool',
    save: 'saveSchool',
}

export interface SchoolFormData {
    name: string
    city: string
    streetWithNumber: string
    userUuid: string
    postalCode: string
    latitude: number
    longitude: number
    countryId: SelectOption<number>
}

interface Props {
    school: SchoolFromSuggestion | null
    schoolUuid?: string
    isNew: boolean
    updateSchool: (school: SchoolFromSuggestion) => void
}

const SchoolCreateForm = ({ school, schoolUuid, isNew, updateSchool }: Props) => {
    const [countryOptions, setCountryOptions] = useState<{ label: string; identifier: string; data: number }[]>([])
    const formRef = useRef<FormHandles>(null)
    const history = useHistory()

    useEffect(() => {
        CountriesService.list()
            .then((countries) => {
                if (!countries) return
                setCountryOptions(countries.map((c) => ({ label: c.name, identifier: c.id.toString(), data: c.id })))
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('Unable to list countries.')
                    return
                } else {
                    throw error
                }
            })
    }, [])

    const validateschoolFormData = ({ longitude, latitude, ...data }: SchoolFormData) => {
        if (!data.name) {
            formRef.current?.setFieldError('name', 'This field is required')
            return false
        }

        if (!data.streetWithNumber) {
            formRef.current?.setFieldError('streetWithNumber', 'This field is required')
            return false
        }

        if (!data.city) {
            formRef.current?.setFieldError('city', 'This field is required')
            return false
        }

        if (!data.postalCode) {
            formRef.current?.setFieldError('postalCode', 'This field is required')
            return false
        }

        if (!latitude) {
            formRef.current?.setFieldError('latitude', 'This field is required')
            return false
        }

        if (!longitude) {
            formRef.current?.setFieldError('longitude', 'This field is required')
            return false
        }

        return true
    }

    const handleSubmit: SubmitHandler<SchoolFormData> = ({ longitude, latitude, countryId, ...data }) => {
        formRef.current!.setErrors({})

        if (!validateschoolFormData({ longitude, latitude, countryId, ...data })) return

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let requestBody: any = {
            ...data,
            latitude: +latitude,
            longitude: +longitude,
        }

        if (isNew) {
            requestBody = {
                ...requestBody,
                countryId: countryId.data,
            }
        }

        const handleFunction = isNew ? SchoolsService.create(requestBody) : SchoolsService.update(schoolUuid!, requestBody)

        trackPromise(
            handleFunction
                .then((school) => {
                    toast.success(`School has been ${isNew ? 'created' : 'updated'}`)
                    isNew ? history.push(routes.schoolRoute(school.uuid)) : updateSchool(school)
                })
                .catch((error: ApiError) => {
                    if (error.type === ErrorTypes.FormValidation) {
                        badRequestFormErrors(error, formRef.current!)
                    } else {
                        error.handleUnknown('An error occurred while adding new school.')
                    }
                }),
            loadingAreas.save
        )
    }

    return (
        <Box>
            <Stack>
                <h3>Update school</h3>
                <Form
                    ref={formRef}
                    initialData={{
                        ...school,
                    }}
                    onSubmit={handleSubmit}
                >
                    <Input name="name" placeholder="Name" />
                    <Input name="streetWithNumber" placeholder="Location street & number" />
                    <Input name="city" placeholder="City" />
                    <Input name="postalCode" placeholder="Postal code" />
                    {countryOptions && isNew ? (
                        <FormSelect name="countryId" options={countryOptions} placeholder="Country" />
                    ) : null}
                    <Input name="latitude" placeholder="Latitude (example: 11.123456)" type="number" step="0.000001" />
                    <Input name="longitude" placeholder="Longitude (example: 22.123456)" type="number" step="0.000001" />

                    <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                        {'Save'}
                    </Button>
                </Form>
            </Stack>
        </Box>
    )
}

export default SchoolCreateForm
