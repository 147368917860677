import { AuthToken } from 'interfaces/AuthToken'
import { setOauthToken } from 'redux/actions'
import { LocalStorageKey } from 'redux/reducer'
import store from 'redux/store'

const getLocalStorageOauthToken = () => {
    const oauthToken = localStorage.getItem(LocalStorageKey.OauthToken)

    if (oauthToken === null) return null

    return JSON.parse(oauthToken) as AuthToken
}

// This way the oauth token is propagated properly between
// tabs. Previously each tab would try to refresh tokens
// on their own which ended up removing user from the
// session. Using listener to localStorage changes
// we can apply new tokens in all other tabs.
export const subscribeToOauthTokenRefreshes = () => {
    window.addEventListener('storage', (event) => {
        if (event.storageArea !== localStorage) return

        if (event.key === LocalStorageKey.OauthToken) {
            const broadcastOauthToken = getLocalStorageOauthToken()

            if (!broadcastOauthToken) return
            store.dispatch(setOauthToken(broadcastOauthToken))
        }
    })
}
