import ApiService from './ApiService'
import { SearchContent } from 'interfaces/Search'
import { CurriculumAnalysisRequest, CurriculumAnalysisType } from 'interfaces/CurriculumAnalysis'
import { LanguageCode } from 'interfaces/Language'
import { CountryCode } from 'interfaces/Country'

const SearchService = {
    getKnows(
        searchQuery: string,
        origin?: string,
        limit?: number,
        contentLanguageCode?: LanguageCode,
        gradeLevelForRanking?: number,
        schoolTypeNameForRanking?: string,
        countryCodeForRanking?: CountryCode,
        regionCodeForRanking?: string
    ) {
        return ApiService.get(
            `/search?query=${searchQuery}&contentType=KNOW${limit !== undefined ? '&limit=' + limit : ''}${
                origin ? `&origin=${origin}` : ''
            }${contentLanguageCode ? `&contentLanguageCode=${contentLanguageCode}` : ''}${
                gradeLevelForRanking ? `&gradeLevelForRanking=${gradeLevelForRanking}` : ''
            }${schoolTypeNameForRanking ? `&schoolTypeNameForRanking=${schoolTypeNameForRanking}` : ''}${
                regionCodeForRanking ? `&regionCodeForRanking=${regionCodeForRanking}` : ''
            }${countryCodeForRanking ? `&countryCodeForRanking=${countryCodeForRanking}` : ''}`
        ).then((response) => {
            return {
                content: response.data.content as SearchContent[],
                totalElements: response.data.totalElements as number,
            }
        })
    },

    bulkCurriculumSearch(items: CurriculumAnalysisRequest[], origin?: string) {
        return ApiService.post(`/search/bulk${origin ? `?origin=${origin}` : ''}`, { items }).then((response) => {
            return response.data.content as CurriculumAnalysisType[]
        })
    },
}

export default SearchService
