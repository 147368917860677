import { ManualCommunicationPlaceholderRequest } from 'interfaces/ManualCommunicationPlaceholder'
import ApiService from 'services/ApiService'

const ManualCommunicationPlaceholderService = {
    create(data: ManualCommunicationPlaceholderRequest[]) {
        return ApiService.post(`/manual-communication-placeholders`, { manualCommunicationPlaceholders: data }).then(
            (response) => {
                return response.data
            }
        )
    },

    update(data: ManualCommunicationPlaceholderRequest[]) {
        return ApiService.patch(`/manual-communication-placeholders`, { manualCommunicationPlaceholders: data }).then(
            (response) => {
                return response.data
            }
        )
    },
}

export default ManualCommunicationPlaceholderService
