import ApiService from 'services/ApiService'
import {
    AdCustomer,
    UpdateAdCustomer,
    AdCampaign,
    CreateAdCampaign,
    UpdateAdCampaign,
    InlineAdRecord,
    AdsInterest,
    AssetTimeframes,
} from 'interfaces/AdSystem'
import { AdsAsset, AdsAssetRequest } from 'interfaces/AdsAsset'
import { AdAttachment } from 'interfaces/AdAttachmentSet'

const AdSystemService = {
    // CUSTOMER
    listCustomers(page: number, isActive?: boolean | null, search?: string | null) {
        return ApiService.get(
            `/ads/customers?page=${page}${isActive !== null ? `&hasActiveCampaign=${isActive}` : ''}${
                search ? `&search=${search}` : ''
            }`
        ).then((response) => {
            return {
                ads: response.data.content as AdCustomer[],
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    getCustomer(uuid: string) {
        return ApiService.get(`/ads/customers/${uuid}`).then((response) => {
            return response.data as AdCustomer
        })
    },

    createCustomer(data: UpdateAdCustomer) {
        return ApiService.post('/ads/customers', data).then((response) => {
            return response.data as AdCustomer
        })
    },

    updateCustomer(uuid: string, data: UpdateAdCustomer) {
        return ApiService.patch(`/ads/customers/${uuid}`, data).then((response) => {
            return response.data as AdCustomer
        })
    },

    // CAMPAIGN
    listCampaign(customerUuid: string, page: number, showActiveCampaigns?: boolean | null) {
        return ApiService.get(
            `/ads/customers/${customerUuid}/campaigns?page=${page}${
                showActiveCampaigns !== null ? `&isActive=${showActiveCampaigns}` : ''
            }`
        ).then((response) => {
            return {
                campaings: response.data.content as AdCampaign[],
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    getCampaign(uuid: string) {
        return ApiService.get(`/ads/campaigns/${uuid}`).then((response) => {
            return response.data as AdCampaign
        })
    },

    createCampaign(customerUuid: string, data: CreateAdCampaign) {
        return ApiService.post(`/ads/customers/${customerUuid}/campaigns`, data).then((response) => {
            return response.data as AdCampaign
        })
    },

    updateCampaign(uuid: string, data: UpdateAdCampaign) {
        return ApiService.patch(`/ads/campaigns/${uuid}`, data).then((response) => {
            return response.data as AdCampaign
        })
    },

    updateCampaignPartially(uuid: string, data: InlineAdRecord) {
        return ApiService.patch(`/ads/campaigns/${uuid}`, data).then((response) => {
            return response.data as AdCampaign
        })
    },

    listAdsInterests() {
        return ApiService.get(`/ads/interests`).then((response) => {
            return response.data.content as AdsInterest[]
        })
    },

    // ASSETS
    listAssets(campaignUuid: string, page: number) {
        return ApiService.get(`/ads/campaigns/${campaignUuid}/assets?page=${page}`).then((response) => {
            return {
                ads: response.data.content as AdsAsset[],
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    getAsset(uuid: string) {
        return ApiService.get(`/ads/assets/${uuid}`).then((response) => {
            return response.data as AdsAsset
        })
    },

    getAssetAttachments(uuid: string) {
        return ApiService.get(`/ads/assets/${uuid}/attachments`).then((response) => {
            return response.data.content as AdAttachment[]
        })
    },

    createAsset(customerUuid: string, data: AdsAssetRequest) {
        return ApiService.post(`/ads/campaigns/${customerUuid}/assets`, data).then((response) => {
            return response.data as AdsAsset
        })
    },

    updateAsset(uuid: string, data: AdsAssetRequest) {
        return ApiService.patch(`/ads/assets/${uuid}`, data).then((response) => {
            return response.data as AdsAsset
        })
    },

    updateAssetPartially(uuid: string, data: InlineAdRecord) {
        return ApiService.patch(`/ads/assets/${uuid}`, data).then((response) => {
            return response.data as AdsAsset
        })
    },

    listCurrentAssets(page: number, timeframe: AssetTimeframes) {
        return ApiService.get(`/ads/assets?page=${page}&timeframe=${timeframe}`).then((response) => {
            return {
                ads: response.data.content as AdsAsset[],
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },
}

export default AdSystemService
