import React from 'react'
import Spinner from 'components/elements/Spinner'
import { User, UserVerificationStatus } from 'interfaces/User'
import { ButtonA } from 'components/elements/Button'
import Stack from 'components/elements/Stack'
import Box from 'components/elements/Box'
import CopyIcon from 'components/elements/CopyIcon'
import KnowContentPreview from 'components/modules/ContentPreview/ContentPreview'
import { ContentTypes } from 'interfaces/Know'
import { formatDateTime } from 'utils'
import InformationCard from 'components/features/InformationCard'
import { tiktokAccount, instagramAccount } from 'lib/constants/urls'

interface Props {
    user: User
    userVerification?: UserVerificationStatus
}

const UserGeneral = ({ user, userVerification }: Props) => (
    <>
        {user ? (
            <>
                <Box>
                    <Stack>
                        <h3>Information</h3>
                        <p>
                            <strong>UUID:</strong>&nbsp;
                            {user.uuid}
                            <CopyIcon text={user.uuid} />
                        </p>
                        <p>
                            <strong>Knower:</strong>&nbsp;
                            {user.knowerUuid ? (
                                <>
                                    {user.knowerUuid}
                                    <CopyIcon text={user.knowerUuid} />
                                </>
                            ) : (
                                'No'
                            )}
                        </p>
                        <p>
                            <strong>E-Mail address:</strong>&nbsp;
                            {user.email} ({user.active ? 'confirmed' : 'not confirmed'})
                        </p>
                        <p>
                            <strong>Name:</strong>&nbsp;
                            {`${user.name} (${user.pronouns ?? 'not specified pronouns'})`}
                        </p>
                        <p>
                            <strong>Username:</strong>&nbsp;
                            {user.username}
                        </p>
                        <p>
                            <strong>Country:</strong>&nbsp;
                            {user.country.englishName}
                        </p>
                        <p>
                            <strong>Account created on:</strong>&nbsp;
                            {formatDateTime(user.createdOn)}
                        </p>
                        <p>
                            <strong>Interface Language:</strong>&nbsp;
                            {user.interfaceLanguage.englishName}
                        </p>
                        <p>
                            <strong>Region:</strong>&nbsp;
                            {user.region ? user.region.name : 'Not selected'}
                        </p>
                        <p>
                            <strong>Grade:</strong>&nbsp;
                            {user.grade ? user.grade.shortName : 'Not selected'}
                        </p>
                        <p>
                            <strong>School Type:</strong>&nbsp;
                            {user.schoolType ? user.schoolType.name : 'Not selected'}
                        </p>
                        <p>
                            <strong>Premium Status:</strong>&nbsp;
                            {user.premiumStatus}
                        </p>
                        <p>
                            <strong>Verified email:</strong>&nbsp;
                            {user.active ? 'Yes' : 'No'}
                        </p>
                        <p>
                            <strong>Profile picture:</strong>&nbsp;
                            {user.profilePicture ? (
                                <Stack>
                                    <KnowContentPreview contentType={ContentTypes.image} previewUrl={user.profilePicture} />
                                    <ButtonA to={user.profilePicture} fullWidth={false} target="_blank">
                                        Link to image
                                    </ButtonA>
                                </Stack>
                            ) : (
                                'User has no profile picture'
                            )}
                        </p>
                        {userVerification?.isAmbassador ? (
                            <InformationCard title={'Verification Status'}>
                                <Stack gutter={8}>
                                    <p>
                                        <strong>Is Ambassador:</strong>&nbsp;
                                        {userVerification?.isAmbassador ? 'Yes' : 'No'}
                                    </p>
                                </Stack>
                            </InformationCard>
                        ) : null}
                        <p>
                            <strong>TikTok:</strong>&nbsp;
                            {user?.tiktokUsername ? (
                                <a href={tiktokAccount(user.tiktokUsername)} target="_blank" rel="noreferrer">
                                    {user.tiktokUsername}
                                </a>
                            ) : (
                                'None'
                            )}
                        </p>
                        <p>
                            <strong>Instagram:</strong>&nbsp;
                            {user?.instagramUsername ? (
                                <a href={instagramAccount(user.instagramUsername)} target="_blank" rel="noreferrer">
                                    {user.instagramUsername}
                                </a>
                            ) : (
                                'None'
                            )}
                        </p>
                    </Stack>
                </Box>
            </>
        ) : (
            <Spinner forceShow />
        )}
    </>
)

export default UserGeneral
