import { UserTargeting } from 'interfaces/UserTargeting'
import React, { useEffect, useState } from 'react'
import { ApiError } from 'services/ApiService'
import UserTargetingService from 'services/UserTargetingService'

interface Props {
    userTargetingId: number
}

const AdCampaignTargetedRegions = ({ userTargetingId }: Props) => {
    const [userTargeting, setUserTargeting] = useState<UserTargeting>()

    useEffect(() => {
        UserTargetingService.get(userTargetingId)
            .then((targeting) => {
                setUserTargeting(targeting)
            })
            .catch((error: ApiError) => {
                error.handleUnknown('An error occurred while fetching user targeting.')
            })
    }, [userTargetingId])

    return (
        <p>
            {(userTargeting?.countries.map((c) => c.code).join(', '), userTargeting?.regions.map((r) => r.code).join(', '))}
        </p>
    )
}

export default AdCampaignTargetedRegions
