export type ManualCommunicationPlaceholderRequest = {
    manualEmailUuid?: string | null
    manualPushNotificationUuid?: string | null
    placeholders: {
        [key: string]: string
    }
    userUuid: string
}

export type ManualCommunicationCSV = {
    user_uuid: string
    [key: string]: string
}

export enum ManualCommmunicationStatus {
    Scheduled = 'SCHEDULED',
    ToBeProcessed = 'TO_BE_PROCESSED',
    Processing = 'PROCESSING',
    Completed = 'COMPLETED',
}
