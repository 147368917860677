import theme from 'lib/constants/theme'
import styled from 'styled-components'
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg'

export const Warning = styled.p`
    background-color: #ffedb5;
    border: 3px solid #f0d06e;
    border-left: 10px solid #f0d06e;
    padding: 10px;
    display: grid;
    place-items: center start;
    border-radius: 5px;
    button {
        border-radius: 5px;
    }
    text-align: left;
    color: #444;
`

export const FilterFormGrid = styled.div<{ readonly gridAmount: number }>`
    display: grid;
    grid-template-columns: repeat(${(props) => props.gridAmount}, 1fr);
    place-content: end;
    align-items: end;
    grid-gap: 15px;
    button {
        margin-bottom: 8px;
    }
`

export const Tooltip = styled.span`
    position: relative;
    display: inline-block;
    margin-left: 5px;

    &:hover span {
        visibility: visible;
    }
`

export const TooltipText = styled.span<{ width?: number }>`
    visibility: hidden;
    background-color: #5f5f5f;
    word-break: break-word;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    position: absolute;
    z-index: 100;
    width: ${({ width }) => width ?? 300}px;
    bottom: 25px;
    right: -${({ width }) => (width ? width / 2 : 150)}px;
`

export const ControlsWrapper = styled.div`
    display: flex;
    place-items: center;
`

export const ControlWrapper = styled.div`
    flex: 1;
    padding: 10px;
    input {
        width: 25vw;
    }
`

export const NoWrapText = styled.p`
    white-space: nowrap;
    color: ${theme.colors.knowunityBlue};
`

export const MetaBox = styled.div`
    position: relative;
`

export const EditButton = styled(EditIcon)`
    position: absolute;
    cursor: pointer;
    top: 20px;
    right: 20px;
`
