import React, { RefObject, useCallback, useEffect, useState } from 'react'
import { Form } from '@unform/web'
import { Input, Textarea, FormSelect, SelectMultiple, SelectOption, SelectOptionWithLabel } from 'components/inputs/Input'
import Button from 'components/elements/Button'
import { KnowType, Know } from 'interfaces/Know'
import { Grade } from 'interfaces/Grade'
import { Subject } from 'interfaces/Subject'
import { FormHandles } from '@unform/core/typings/types'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import KnowTypeService from 'services/KnowTypeService'
import SubjectService from 'pages/Subjects/SubjectService'
import { trackPromise } from 'react-promise-tracker'
import LoadingArea from 'components/elements/LoadingArea'
import { ApiError } from 'services/ApiService'
import SchoolTypeService from 'services/SchoolTypeService'
import { SchoolType } from 'interfaces/SchoolType'
import { returnFormattedExamBoard } from 'lib/features/examBoards'
import { ExamBoard } from 'interfaces/ExamBoard'
import { fetchGradesBasedOnSchoolType } from 'lib/features/grades'
import CountriesService from 'services/CountriesService'

interface Props {
    formRef: RefObject<FormHandles>
    know: Know
    handleSubmit(data: FormData): void
}

export interface FormData {
    title: string
    description: string
    knowType: SelectOption<KnowType>
    grades: SelectOption<Grade>[]
    subjects: SelectOption<Subject>[]
    schoolTypes: SelectOption<SchoolType>[]
    examBoards: SelectOption<ExamBoard>[]
}

export const loadingAreas = {
    knowMetaOptions: 'knowMetaOptions',
    knowMetaFormSubmit: 'knowMetaFormSubmit',
}

const KnowMetaForm = (props: Props) => {
    const [knowTypeOptions, setKnowTypeOptions] = useState<SelectOptionWithLabel<KnowType>[]>()
    const [gradesOptions, setGradesOptions] = useState<SelectOptionWithLabel<Grade>[]>()
    const [subjectsOptions, setSubjectOptions] = useState<SelectOptionWithLabel<Subject>[]>()
    const [schoolTypeOptions, setSchoolTypeOptions] = useState<SelectOptionWithLabel<SchoolType>[]>()
    const [examBoardsOptions, setExamBoardsOptions] = useState<SelectOptionWithLabel<ExamBoard>[]>()
    const [initialData, setInitialData] = useState<FormData>()
    const [selectedSchoolTypes, setSelectedSchoolTypes] = useState<SchoolType[]>()

    const handleGradeOptionsUpdate = (gradeOptions: SelectOptionWithLabel<Grade>[]) => setGradesOptions(gradeOptions)

    const fetchData = useCallback(() => {
        let innerKnowTypeOptions: typeof knowTypeOptions = []
        let innerSubjectsOptions: typeof subjectsOptions = []
        let innerSchoolTypesOptions: typeof schoolTypeOptions = []
        let innerExamBoardsOptions: typeof examBoardsOptions = []

        const knowTypePromise = trackPromise(
            KnowTypeService.list(props.know.contentLanguage.id)
                .then((types) => {
                    innerKnowTypeOptions = types.map((type) => ({
                        label: type.name,
                        identifier: type.id.toString(),
                        data: type,
                    }))

                    setKnowTypeOptions(innerKnowTypeOptions)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while getting know types.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.knowMetaOptions
        )

        const subjectPromise = trackPromise(
            SubjectService.list(props.know.knowerCountry.id)
                .then((subjects) => {
                    innerSubjectsOptions = subjects
                        .sort((a, b) => (a.name < b.name ? -1 : 1))
                        .map((s) => ({ label: s.name, identifier: s.id.toString(), data: s }))

                    setSubjectOptions(innerSubjectsOptions)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while getting subjects.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.knowMetaOptions
        )

        const examBoardsPromise = trackPromise(
            CountriesService.listExamBoards(props.know.knowerCountry.id)
                .then((exams) => {
                    innerExamBoardsOptions = exams.map((exam) => ({
                        label: returnFormattedExamBoard(exam),
                        identifier: exam.uuid,
                        data: exam,
                    }))
                    setExamBoardsOptions(innerExamBoardsOptions)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while getting exam boards.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.knowMetaOptions
        )

        const schoolTypePromise = trackPromise(
            SchoolTypeService.list(props.know.knowerCountry.id)
                .then((schoolTypes) => {
                    innerSchoolTypesOptions = schoolTypes.map((st) => ({
                        label: st.name,
                        identifier: st.id.toString(),
                        data: st,
                    }))
                    setSchoolTypeOptions(innerSchoolTypesOptions)
                    if (props.know?.schoolTypes) {
                        fetchGradesBasedOnSchoolType(props.know?.schoolTypes, handleGradeOptionsUpdate)
                    } else {
                        fetchGradesBasedOnSchoolType([schoolTypes[0]], handleGradeOptionsUpdate)
                    }
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while getting school types.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.knowMetaOptions
        )

        Promise.all([knowTypePromise, subjectPromise, schoolTypePromise, examBoardsPromise]).then(() => {
            if (!props.know) return

            setSelectedSchoolTypes(props.know.schoolTypes)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const initialKnowData: any = {
                title: props.know.title,
                description: props.know.description,
                knowType: props.know?.knowType
                    ? { identifier: props.know.knowType.id.toString(), data: props.know.knowType }
                    : null,
                grades: props.know.grades?.map((g) => ({ identifier: g.id.toString(), data: g })),
                subjects: props.know.subjects?.map((s) => ({ identifier: s.id.toString(), data: s })),
                schoolTypes: props.know.schoolTypes?.map((st) => ({ identifier: st.id.toString(), data: st })),
                examBoards: props.know.examBoards?.map((e) => ({ identifier: e.uuid, data: e })),
            }

            setInitialData(initialKnowData)
        })
    }, [props.know])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    useEffect(() => {
        selectedSchoolTypes && fetchGradesBasedOnSchoolType(selectedSchoolTypes, handleGradeOptionsUpdate)
    }, [selectedSchoolTypes])

    const showExamBoardsSelect = true

    return (
        <LoadingArea area={loadingAreas.knowMetaOptions}>
            <Form ref={props.formRef} initialData={initialData} onSubmit={props.handleSubmit}>
                <Input name="title" placeholder="Title" tooltip="Choose a title that describes your know." />
                <p>
                    <strong>Important: </strong>The title must not contain the type of the know (z. B. presentation)!
                </p>
                <Textarea
                    name="description"
                    placeholder="Beschreibung"
                    maxLength="255"
                    tooltip="Describe briefly what your know is about. Also write down the structure of your content"
                />
                {schoolTypeOptions && (
                    <SelectMultiple<SchoolType>
                        name="schoolTypes"
                        options={schoolTypeOptions}
                        label="School type"
                        onUpdate={setSelectedSchoolTypes}
                    />
                )}

                {gradesOptions && <SelectMultiple name="grades" options={gradesOptions} label="Grade" />}

                {knowTypeOptions && <FormSelect name="knowType" options={knowTypeOptions} placeholder="Type" />}

                {subjectsOptions && <SelectMultiple name="subjects" options={subjectsOptions} label="Subject" />}

                {examBoardsOptions && showExamBoardsSelect && (
                    <SelectMultiple
                        name="examBoards"
                        options={examBoardsOptions}
                        label="Exam boards"
                        placeholder="Exam boards"
                        noMargin
                    />
                )}
                <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.knowMetaFormSubmit}>
                    Save
                </Button>
            </Form>
        </LoadingArea>
    )
}

export default KnowMetaForm
