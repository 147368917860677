import React from 'react'
import { Know } from 'interfaces/Know'
import styled, { css } from 'styled-components'
import theme from 'lib/constants/theme'
import { Body1, Body2, Headline5 } from 'components/elements/Text'
import { ReactComponent as KnowLikes } from 'assets/icons/know-likes-icon.svg'
import { ReactComponent as KnowViews } from 'assets/icons/know-views-icon.svg'
import { ReactComponent as PDFIcon } from 'assets/icons/pdf.svg'
import routes from 'lib/constants/routes'
import { Link } from 'react-router-dom'
import { SubjectBadge, GradeBadge } from './Badge'
import ProfilePicture from './ProfilePicturePreview'

interface ContainerProps {
    highlight?: boolean
    mobileHighlight?: boolean
    hoverColor?: string
    startSnapScroll?: boolean
}

const Container = styled.div<ContainerProps>`
    width: 300px;
    height: auto;
    min-height: 300px;
    margin: 10px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 2px solid ${theme.colors.knowunityBlue};
    background-color: ${theme.colors.white};
    border-radius: ${theme.borderRadius.card};
    cursor: pointer;
    ${({ startSnapScroll }) =>
        startSnapScroll
            ? css`
                  scroll-snap-align: start;
              `
            : ''}

    .top {
        padding: 15px;
        display: grid;
        grid-template-columns: 1fr auto;
    }

    .badges {
        display: flex;
        flex-direction: row;
        flex-flow: wrap;
        row-gap: 3px;
        color: ${theme.colors.knowunityBlue};
        align-items: center;
        justify-content: flex-start;
    }
    .title {
        display: grid;
        grid-gap: 5px;
    }
    .profile {
        display: grid;
        place-content: center;
        grid-gap: 10px;
    }

    .bottom {
        padding: 15px;
        display: grid;
        grid-gap: 10px;
        .stats {
            display: flex;
            justify-content: space-between;
            .icon {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }
    }
    a {
        text-decoration: none !important;
    }
    ${({ highlight, hoverColor }) =>
        highlight
            ? css`
                  position: relative;
                  bottom: 11px;
                  right: 9px;
                  margin-left: 9px;
                  width: 300px;
                  border: 3px solid ${hoverColor};
                  box-shadow: 4px 4px 0px ${hoverColor};
                  border-radius: ${theme.borderRadius.normal};
              `
            : ''};
    ${({ mobileHighlight, hoverColor }) =>
        mobileHighlight
            ? css`
                  @media (max-width: 750px) {
                      position: relative;
                      bottom: 11px;
                      right: 9px;
                      margin-left: 9px;
                      width: 306px;
                      border: 3px solid ${hoverColor};
                      box-shadow: 4px 4px 0px ${hoverColor};
                      border-radius: ${theme.borderRadius.normal};
                  }
              `
            : ''};
`

const KnowImage = styled.img`
    object-fit: cover;
    object-position: top;
`

const EmptyPreview = styled.div`
    height: 145px;
    width: 325px;
    background: ${theme.colors.secondaryRed};

    display: grid;
    place-content: center;

    svg {
        fill: white;
        width: 50px;
        height: 50px;
    }
`

const Description = styled(Body1)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const Headline = styled(Headline5)`
    max-width: 225px;
    overflow: hidden;
    text-overflow: ellipsis;
`

interface Props {
    know: Know
}

const ComplexKnowCard = ({ know }: Props) => {
    if (!know) return null
    return (
        <Link to={routes.knowRoute(know.uuid)}>
            <Container>
                <div className="top">
                    <div className="title">
                        <Headline color={theme.colors.accentBlue}>{know.title}</Headline>
                        <div className="badges">
                            <SubjectBadge key={know.subjects[0].id} subject={know.subjects[0]} />

                            <GradeBadge grades={know.grades} />
                        </div>
                    </div>
                    <div className="profile">
                        {know.knower.user ? <ProfilePicture user={know.knower.user} size={30} /> : null}
                    </div>
                </div>
                <div className="image">
                    {know.thumbnailLargeUrl ? (
                        <KnowImage
                            src={know.thumbnailLargeUrl}
                            alt={`Know ${know.title} thumbnail`}
                            width={300}
                            height={145}
                            loading="lazy"
                        />
                    ) : (
                        <EmptyPreview>
                            <PDFIcon />
                        </EmptyPreview>
                    )}
                </div>
                <div className="bottom">
                    <Description>{know.description}</Description>
                    <div className="stats">
                        <div className="icon">
                            <KnowViews />
                            <Body2>{know.views}</Body2>
                        </div>
                        <div className="icon">
                            <KnowLikes />
                            <Body2>{know.likes}</Body2>
                        </div>
                    </div>
                </div>
            </Container>
        </Link>
    )
}

export default ComplexKnowCard
