import theme from 'lib/constants/theme'
import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'

interface ContainerProps {
    isClickable?: boolean
    hoverHighlight?: boolean
}
interface Props extends ContainerProps {
    title: string | JSX.Element
    children?: ReactNode
    onClick?: () => void
}

const Container = styled.div<ContainerProps>`
    background: ${theme.colors.darkViolet};
    border-radius: ${theme.borderRadius.large};
    border: 1px solid var(--primary-color);
    cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'autoContainerProps')};
    ${({ hoverHighlight }) =>
        hoverHighlight
            ? css`
                  border: 1px solid var(--background-darker);
                  :hover {
                      border: 1px solid var(--primary-color);
                  }
              `
            : ''}
`

const Main = styled.div`
    padding: 15px;
    h3 {
        color: ${theme.colors.knowunityBlueDark};
        padding-bottom: 10px;
    }
`

const InformationCard = ({ title, children, onClick, isClickable, hoverHighlight }: Props) => (
    <Container onClick={onClick} isClickable={isClickable} hoverHighlight={hoverHighlight}>
        <Main>
            <h3>{title}</h3>
            {children}
        </Main>
    </Container>
)

export default InformationCard
