/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useRef, useState } from 'react'
import { ButtonLink } from 'components/elements/Button'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import RefactoredTable from 'components/modules/RefactoredTable'
import AdCustomerService from 'services/AdSystemService'
import routes from 'lib/constants/routes'
import { ControlWrapper, FilterFormGrid } from 'style'
import { storeActiveCampaignFilter } from 'lib/storage'
import FormSelect from 'components/inputs/FormSelect'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import theme from 'lib/constants/theme'
import Search from 'components/elements/Search'

const loadingAreas = {
    container: 'customersContainer',
}

const AdCustomers = () => {
    const [searchQuery, setSearchQuery] = useState<string>()
    const [isActive, setIsActive] = useState<boolean | null>(true)
    const formRef = useRef<FormHandles>(null)

    const handleUpdateActiveFilter = (filter: boolean) => {
        storeActiveCampaignFilter({ isActive: filter })
        setIsActive(filter)
    }

    const activeType = [
        {
            label: 'Show all',
            identifier: 'no-filter',
            data: null,
        },
        {
            label: 'Has active campaigns',
            identifier: 'has-active-campaigns',
            data: true,
        },
        {
            label: `Doesn't have active campaigns`,
            identifier: 'has-no-active-campaigns',
            data: false,
        },
    ]

    const fetchData = useCallback(
        (page: number) => {
            return trackPromise(
                AdCustomerService.listCustomers(page, isActive, searchQuery)
                    .then((data) => {
                        if (page !== data.page) return

                        return {
                            totalPages: data.totalPages,
                            totalElements: data.totalElements,
                            elements: data.ads.map((c) => ({
                                id: c.uuid,
                                columns: [c.uuid, c.name],
                                onClick: routes.adsCustomerRoute(c.uuid),
                            })),
                        }
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while getting customers.')
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.container
            )
        },
        [isActive, searchQuery]
    )

    return (
        <section>
            <h2>Ad Customers</h2>
            <FilterFormGrid gridAmount={3}>
                <ControlWrapper>
                    <ButtonLink
                        to={routes.adsCreateCustomersRoute}
                        fullWidth={false}
                        icon={<AddIcon />}
                        hoverColor={theme.colors.knowunityBlue}
                        color={theme.colors.knowunityBlueDark}
                    >
                        Create Customer
                    </ButtonLink>
                </ControlWrapper>
                <Form
                    ref={formRef}
                    onSubmit={() => {}}
                    initialData={{ isActive: activeType.find((type) => type.data === isActive) }}
                >
                    <FormSelect
                        placeholder="Filter by Running Campaigns"
                        noMargin
                        options={activeType}
                        onUpdate={handleUpdateActiveFilter}
                        name="isActive"
                    />
                </Form>
                <Search onSearch={setSearchQuery} fullWidth label="Search by customer name" />
            </FilterFormGrid>
            <RefactoredTable
                columns={['#', 'Name']}
                loadData={fetchData}
                loadingArea={loadingAreas.container}
                refetchKey={searchQuery}
            />
        </section>
    )
}

export default AdCustomers
