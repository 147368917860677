import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useField } from '@unform/core'
import styled from 'styled-components'
import FormSelect from './FormSelect'
import FormInput from './FormInput'
import { Tooltip, TooltipText } from 'style'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import theme from 'lib/constants/theme'
import Box from 'components/elements/Box'
import Search from 'components/elements/Search'

interface Props<Data> {
    name: string
    placeholder: string
    options?: SelectOptionWithLabel<Data>[]
    tooltip?: string
    // eslint-disable-next-line
    [propName: string]: any
    onUpdate?: (data: Data) => void
    noMargin?: boolean
    showCharactersCount?: boolean
}

export interface SelectOptionWithLabel<Data> {
    label: string
    identifier: string
    data: Data
}

export interface SelectOption<Data> {
    identifier: string
    data: Data
}

export const FormGroup = styled.div<{ noMargin?: boolean }>`
    margin: ${(props) => (props.noMargin ? 0 : 15)}px 0;

    & input,
    & textarea,
    & select,
    & .fake-input {
        display: block;
        width: 100%;
        margin: 8px 0;
        border: 3px solid ${theme.colors.lightViolet};
        border-radius: ${theme.borderRadius.large};
        padding: 12px 20px;
        background: var(--background-blue);
        color: white;
        ${Box} & {
            background: ${theme.colors.violet};
        }
    }

    & label {
        display: flex;
    }

    & textarea {
        resize: vertical;
    }

    & input:focus,
    & textarea:focus {
        border: 3px solid ${theme.colors.violet};
    }

    & input:required,
    & input:invalid {
        box-shadow: none;
    }

    @media (max-width: 750px) {
        & input,
        & textarea,
        & select {
            padding: 12px;
        }
    }
    input:focus,
    textarea:focus,
    select:focus {
        outline: none;
    }
`

export const InputErrorMessage = styled.p`
    color: var(--red) !important;
    text-align: left !important;
    margin: 0;
`

const Textarea = <Data,>(props: Props<Data>) => {
    const { name, ...rest } = props
    const inputRef = useRef(null)
    const { fieldName, defaultValue, registerField, error } = useField(name)
    const charactersCountRef = useRef<HTMLTextAreaElement>(null)

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        })
    }, [fieldName, registerField])

    const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (charactersCountRef.current && props.showCharactersCount) {
            charactersCountRef.current.innerText = `⠀(characters: ${event.target.value.length})`
        }
    }

    return (
        <FormGroup>
            <label>
                {props.placeholder} <b ref={charactersCountRef} />
                {props.tooltip && (
                    <Tooltip>
                        <InfoIcon />
                        <TooltipText>{props.tooltip}</TooltipText>
                    </Tooltip>
                )}
            </label>
            <textarea ref={inputRef} defaultValue={defaultValue} onInput={handleInput} {...rest} />
            <InputErrorMessage>{error}</InputErrorMessage>
        </FormGroup>
    )
}

const CheckboxGroup = styled.div`
    & .grid {
        display: grid;
        padding: 15px 0;
        grid-gap: 10px;
        grid-template-columns: auto 1fr;
        place-items: center start;
    }
`

const Checkbox = <Data,>(props: Props<Data>) => {
    const { name, ...rest } = props
    const inputRef = useRef(null)
    const { fieldName, defaultValue, registerField, error } = useField(name)

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'checked',
        })
    }, [fieldName, registerField])

    return (
        <CheckboxGroup>
            <div className="grid">
                <input ref={inputRef} defaultChecked={defaultValue} type="checkbox" id={name} />
                <label htmlFor={name} {...rest} dangerouslySetInnerHTML={{ __html: props.placeholder }}></label>
            </div>
            <InputErrorMessage>{error}</InputErrorMessage>
        </CheckboxGroup>
    )
}

const SelectMultipleWrapper = styled.div`
    position: relative;

    & .fake-input {
        min-height: 45px;
        user-select: none;
    }

    & .fake-input .placeholder {
        color: #9299b2;
    }

    & .dropdown {
        position: absolute;
        top: 45px;
        width: 100%;
        background: #54545a;
        visibility: hidden;
        max-height: 250px;
        overflow-y: scroll;
        z-index: 1;
    }

    & .dropdown.visible {
        visibility: visible;
    }

    & .option {
        display: grid;
        grid-template-columns: 16px 1fr;
        place-items: center start;
        padding-left: 10px;
        color: white;
    }

    & .option:hover {
        background: #7b7b83;
    }

    & .option label {
        width: 100%;
        padding: 10px;
        cursor: pointer;
        user-select: none;
    }
`

interface SelectMultipleProps<Data> {
    name: string
    label: string
    options?: SelectOptionWithLabel<Data>[]
    tooltip?: string
    // eslint-disable-next-line
    [propName: string]: any
    onUpdate?: (data: Data[]) => void
    noMargin?: boolean
    allowSelectAllOptions?: boolean
}

const SelectMultiple = <Data,>(props: SelectMultipleProps<Data>) => {
    const [searchQuery, setSearchQuery] = useState('')
    const { name, options } = props
    const dropdownRef = useRef<HTMLDivElement>(null)
    const fakeInputRef = useRef<HTMLDivElement>(null)
    const { fieldName, defaultValue, registerField, error } = useField(name)
    const [selectedOptions, setSelectedOptions] = useState<SelectOptionWithLabel<Data>[]>([])
    const [showDropdown, setShowDropdown] = useState(false)

    const setValue = (newOptions: SelectOption<Data>[]) => {
        if (!options) return

        const newOptionsIdentifiers = newOptions.map((o) => o.identifier)
        const newSelectedOptions = options.filter((option) => newOptionsIdentifiers.includes(option.identifier))

        setSelectedOptions(newSelectedOptions)

        const inputElements = dropdownRef.current?.getElementsByTagName('input')
        if (!inputElements) return
        const checkboxes = Array.from(inputElements)
        checkboxes.forEach((checkboxInputElement) => {
            const identifier = checkboxInputElement.dataset.identifier
            const isChecked = !!newSelectedOptions.find((option) => identifier === option.identifier)
            checkboxInputElement.checked = isChecked
        })
    }

    const setValueCallback = useCallback(setValue, [options])

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: undefined,
            path: undefined,
            getValue: () => {
                return selectedOptions
            },
            setValue: (value: SelectOption<Data>[]) => {
                setValueCallback(value)
            },
        })
    }, [setValueCallback, fieldName, registerField, options, selectedOptions])

    useEffect(() => {
        if (defaultValue) {
            setValueCallback(defaultValue)
        }
    }, [setValueCallback, defaultValue])

    const toggleShowDropdown = () => {
        setShowDropdown(!showDropdown)
    }

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, option: SelectOptionWithLabel<Data>) => {
        if (!options) return

        const isChecked = event.target.checked

        let updatedOptions = selectedOptions.map((o) => o)
        if (isChecked) {
            const newOption = options.find((o) => o.identifier === option.identifier)
            if (newOption) {
                updatedOptions.push(newOption)
            }
        } else {
            updatedOptions = selectedOptions.filter((o) => o.identifier !== option.identifier)
        }

        setSelectedOptions(updatedOptions)

        const updatedValues = updatedOptions.map((option) => option.data)
        props.onUpdate && props.onUpdate(updatedValues)
    }

    const selectedOptionsSorted = selectedOptions.sort((a, b) => {
        return a.label < b.label ? -1 : 1
    })

    const selectedPreview = selectedOptionsSorted.length ? (
        selectedOptionsSorted.map((value) => value.label).join(', ')
    ) : (
        <span className="placeholder">{props.label}</span>
    )

    useEffect(() => {
        document.addEventListener('mousedown', handleMouseDown)
        return () => {
            document.removeEventListener('mousedown', handleMouseDown)
        }
    }, [])

    // eslint-disable-next-line
    const handleMouseDown = (event: any) => {
        const isClickOutside = !dropdownRef.current?.contains(event.target)
        const isClickOnFakeInput = fakeInputRef.current?.contains(event.target)
        if (isClickOutside && !isClickOnFakeInput) {
            setShowDropdown(false)
        }
    }

    const onSubmit = (query: string) => setSearchQuery(query)

    const onSearchDebounce = (query: string) => setSearchQuery(query)

    const filterOptions = (options: SelectOptionWithLabel<Data>[]) =>
        options.filter((option) => option.label.toLowerCase().includes(searchQuery.toLowerCase()))

    const handleSelectAllOptionClicked = (action: 'SELECT' | 'DESELECT') => {
        if (!options) return

        const isDeselect = action === 'DESELECT'
        const inputElements = dropdownRef.current?.getElementsByTagName('input')

        if (isDeselect) {
            setSelectedOptions([])
        } else {
            setSelectedOptions(options)
        }

        if (!inputElements) return
        const checkboxes = Array.from(inputElements)
        checkboxes.forEach((checkboxInputElement) => {
            const isChecked = action === 'SELECT' ? true : false
            checkboxInputElement.checked = isChecked
        })
    }

    const areAllOptionsSelected = selectedOptions.length === options?.length

    return (
        <FormGroup>
            <label>{props.label}</label>
            <SelectMultipleWrapper>
                <p ref={fakeInputRef} className="fake-input" onClick={toggleShowDropdown}>
                    {selectedPreview}
                </p>
                <div ref={dropdownRef} className={`dropdown ${showDropdown ? 'visible' : ''}`}>
                    <Search
                        label="⠀"
                        onSearch={onSubmit}
                        fullWidth
                        placeholder="Search by label..."
                        onDebounce={onSearchDebounce}
                        hideButton
                    />
                    {props.allowSelectAllOptions ? (
                        <div className="option">
                            <input
                                type="checkbox"
                                data-identifier="selectAll"
                                onChange={() => handleSelectAllOptionClicked(areAllOptionsSelected ? 'DESELECT' : 'SELECT')}
                                checked={areAllOptionsSelected}
                            />
                            <label htmlFor={'selectAll'}>{areAllOptionsSelected ? 'Deselect' : 'Select'} all options</label>
                        </div>
                    ) : null}
                    {options &&
                        filterOptions(options).map((option, i) => (
                            <div className="option" key={`${option.label}-${i}`}>
                                <input
                                    type="checkbox"
                                    id={option.identifier}
                                    data-identifier={option.identifier}
                                    onChange={(e) => handleCheckboxChange(e, option)}
                                    checked={!!selectedOptions.find((x) => x.identifier === option.identifier)}
                                />
                                <label htmlFor={option.label}>{option.label}</label>
                            </div>
                        ))}
                </div>
            </SelectMultipleWrapper>

            <InputErrorMessage>{error}</InputErrorMessage>
        </FormGroup>
    )
}

const Input = FormInput

export { Input, Textarea, Checkbox, FormSelect, SelectMultiple }
