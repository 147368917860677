import { AdCampaign } from './AdSystem'

export interface AdsAsset {
    uuid: string
    url: string
    name: string
    campaign: AdCampaign
    clicks: number
    views: number
    currentPriority: number
    imageUrl: string
    format: AdsAssetFormat
    contentType: AdsAssetContentType
    knowUuid: string
    boostedForUserUuids: string[]
    stats: {
        timeProgress: number
        idealViews: number
        targetViews: number
        viewDifference: number
    }
    ctaText: string | null
    hasAttachment: boolean
    showCta: boolean
    useInAppBrowser: boolean
}

export interface AdsAssetRequest {
    name: string
    imageFilename: string | null
    url: string
    contentType: AdsAssetContentType
    boostedForUserUuids: string[]
    ctaText: string | null
    format: AdsAssetFormat
    knowUuid?: string | null
    attachments?: string[] | null
    showCTA: boolean
    useInAppBrowser: boolean
}

export enum AdsAssetFormat {
    Banner = 'BANNER',
    FullScreen = 'FULL_SCREEN',
}

export enum AdsAssetContentType {
    Image = 'IMAGE',
    Video = 'VIDEO',
    Know = 'KNOW',
}
