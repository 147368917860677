import Spinner from 'components/elements/Spinner'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import UploadWrapper from './UploadWrapper'
import csv from 'csvtojson'
const loadingAreas = {
    upload: 'uploadContainer',
}

interface Props {
    title: string
    showHeader?: boolean
    handleCSVUploadComplete: (dataStringLines: CSVData[]) => void
}

export type CSVData = { [key: string]: string }

const CSVImporter = ({ title, handleCSVUploadComplete, showHeader = false }: Props) => {
    const [filename, setFilename] = useState('')
    const [loading, setLoading] = useState(false)

    const processData = async (data: CSVData[]) => {
        if (data?.length) {
            await handleCSVUploadComplete(data)
            setLoading(false)
        } else {
            toast.error('Upload CSV file is not valid! Try again.')
        }
    }

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files![0]
        setLoading(true)
        setFilename(file.name)

        const reader = new FileReader()
        reader.onload = async (evt) => {
            if (!evt.target) return
            const text = evt.target.result
            let data: CSVData[] = []

            await csv({
                noheader: !showHeader,
                output: 'json',
                delimiter: [',', ';'],
                trim: true,
            })
                .fromString(text as string)
                .then((row) => {
                    data = row
                })

            processData(data)
            e.target.value = ''
        }

        reader.readAsText(file)
    }

    return (
        <div>
            <p>{title}</p>
            <UploadWrapper area={loadingAreas.upload} handleFileUpload={handleFileUpload} acceptRules=".csv,.xlsx,.xls">
                <p id="title">Click here to select a CSV file or drag &amp; drop your file here.</p>&nbsp;
                {filename ? <p>Found file: {filename}</p> : null}
                {loading && <Spinner forceShow />}
            </UploadWrapper>
        </div>
    )
}

export default CSVImporter
