import ApiService from 'services/ApiService'
import { ManualEmail, ManualEmailRequest, UpdateEmailBody } from 'interfaces/ManualEmails'

const ManualEmailsService = {
    list(page: number, isAuthor?: boolean, isSent?: boolean | null) {
        return ApiService.get(
            `/manual-emails?page=${page}${isAuthor ? '&author=me' : ''}${isSent !== null ? `&sent=${isSent}` : ''}`
        ).then((response) => {
            return {
                emails: response.data.content as ManualEmail[],
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    create(notification: ManualEmailRequest) {
        return ApiService.post('/manual-emails', notification).then((response) => {
            return response.data as ManualEmail
        })
    },

    duplicate(uuid: string) {
        return ApiService.post(`/manual-emails/${uuid}/duplicate`).then((response) => {
            return response.data as ManualEmail
        })
    },

    update(uuid: string, email: ManualEmailRequest) {
        return ApiService.patch(`/manual-emails/${uuid}`, email).then((response) => {
            return response.data as ManualEmail
        })
    },

    updateEmailBody(uuid: string, email: UpdateEmailBody) {
        return ApiService.patch(`/manual-emails/${uuid}`, email).then((response) => {
            return response.data as ManualEmail
        })
    },

    get(uuid: string) {
        return ApiService.get(`/manual-emails/${uuid}`).then((response) => {
            return response.data as ManualEmail
        })
    },

    approve(uuid: string) {
        return ApiService.post(`/manual-emails/${uuid}/approve`).then((response) => {
            return response.data as ManualEmail
        })
    },

    revoke(uuid: string) {
        return ApiService.delete(`/manual-emails/${uuid}/approve`).then((response) => {
            return response.data as ManualEmail
        })
    },

    send(uuid: string) {
        return ApiService.post(`/manual-emails/${uuid}/send`).then((response) => {
            return response.data as ManualEmail
        })
    },

    sendDraft(uuid: string) {
        return ApiService.post(`/manual-emails/${uuid}/send-draft`).then((response) => {
            return response.data as ManualEmail
        })
    },

    getEstimatedReceiversCount(uuid: string) {
        return ApiService.get(`/manual-emails/${uuid}/estimated-receivers-count`).then((response) => {
            return response.data as number
        })
    },
}

export default ManualEmailsService
