import React, { useCallback, useEffect, useState } from 'react'
import Table, { TableData } from 'components/modules/Table'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import Stack from 'components/elements/Stack'
import routes from 'lib/constants/routes'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { ButtonLink } from 'components/elements/Button'
import { FilterFormGrid, ControlWrapper } from 'style'
import theme from 'lib/constants/theme'
import SeoTopicService from './SeoTopicService'

const loadingAreas = {
    container: 'seoTopicsContainer',
}

interface Props {
    seoSubjectUuid: string
}

const SeoTopics = ({ seoSubjectUuid }: Props) => {
    const [data, setTableData] = useState<TableData[]>([])

    const fetchData = useCallback(async () => {
        trackPromise(
            SeoTopicService.list(seoSubjectUuid)
                .then((topics) => {
                    const tableData = topics.map((t) => ({
                        id: t.uuid,
                        columns: [t.uuid, t.slug, t.title, t.isPublished ? 'Yes' : 'No'],
                        onClick: routes.seoTopicRoute(seoSubjectUuid, t.uuid),
                    }))

                    setTableData(tableData)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting topics.')
                }),
            loadingAreas.container
        )
    }, [seoSubjectUuid])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <Stack gutter={10}>
            <h2>SEO Topics</h2>
            <FilterFormGrid gridAmount={2}>
                <ControlWrapper>
                    <ButtonLink
                        to={routes.seoTopicsCreateRoute(seoSubjectUuid)}
                        fullWidth={false}
                        icon={<AddIcon />}
                        hoverColor={theme.colors.knowunityBlue}
                        color={theme.colors.knowunityBlueDark}
                    >
                        Add SEO Topic
                    </ButtonLink>
                </ControlWrapper>
            </FilterFormGrid>
            <Table
                columns={['#', 'Slug', 'Title', 'Is published']}
                data={data}
                page={0}
                totalPages={1}
                totalElements={1}
                setPage={() => null}
                area={loadingAreas.container}
            />
        </Stack>
    )
}

export default SeoTopics
