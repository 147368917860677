import React, { ReactNode, useEffect, useState } from 'react'
import styles from './Tabs.module.css'
import { setUrlParam, getUrlParam } from 'utils'
import styled from 'styled-components'

const TabContent = styled.div`
    padding: 10px 0;
`

interface Props {
    deactivateUrlParam?: boolean
    preventUnloadOnTab?: string
    onLabelClicked?: (label: string) => void

    children: {
        props: {
            children: ReactNode
            'data-label': string
            'data-title'?: string
        }
    }[]
}

const Tabs = (props: Props) => {
    const [activeTab, setActiveTab] = useState<string | null>(props.children[0].props['data-label'].toLowerCase())

    useEffect(() => {
        if (props.deactivateUrlParam) return

        const tabFromParams = getUrlParam('tab')
        if (tabFromParams) {
            setActiveTab(tabFromParams)
        } else {
            setUrlParam('tab', tabFromParams)
        }
    }, [props.deactivateUrlParam, setActiveTab])

    const onClickTabItem = (tabLabel: string) => {
        if (
            props.preventUnloadOnTab?.toLowerCase() === activeTab &&
            !window.confirm('Did you make sure to save the changes before leaving?')
        )
            return

        props.onLabelClicked && props.onLabelClicked(tabLabel)

        setActiveTab(tabLabel.toLowerCase())
        if (!props.deactivateUrlParam) {
            setUrlParam('tab', tabLabel.toLowerCase())
        }
    }

    return (
        <div>
            <ul className={styles.tabList}>
                {props.children.map((child, index) => (
                    <li
                        key={index}
                        className={`${styles.tabLabel} ${
                            child.props['data-label'].toLowerCase() === activeTab?.toLowerCase() ? styles.tabLabelActive : ''
                        }`}
                        onClick={onClickTabItem.bind(this, child.props['data-label'])}
                    >
                        {child.props['data-title'] ?? child.props['data-label']}
                    </li>
                ))}
            </ul>
            <TabContent>
                {props.children.map((child) => {
                    if (child.props['data-label'].toLowerCase() !== activeTab?.toLowerCase()) return null
                    return child.props.children
                })}
            </TabContent>
        </div>
    )
}

export default Tabs
