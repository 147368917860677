import React, { ReactNode, useRef, useState } from 'react'
import LoadingArea from 'components/elements/LoadingArea'
import styled, { css } from 'styled-components'
import theme from 'lib/constants/theme'

const FileInput = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
`

const Wrapper = styled.div<{ isHovering?: boolean }>`
    border: 1px dashed #ccc;
    margin: 8px 0;
    background: var(--background-blue);
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    padding: 12px 20px;
    min-height: 80px;
    display: grid;

    img {
        height: 100px;
    }

    p {
        margin: 0;
    }
    .grid {
        display: grid;
        grid-template-columns: 1fr auto;
        height: 100%;
        place-items: center start;
    }
    :hover {
        border-color: ${theme.colors.knowunityBlue};
    }
    ${({ isHovering }) =>
        isHovering
            ? css`
                  border-color: ${theme.colors.knowunityBlue};
              `
            : null}
`

interface Props {
    area: string
    handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void
    acceptRules: string
    children: ReactNode
}
const UploadWrapper = ({ children, area, acceptRules, handleFileUpload }: Props) => {
    const [isHovering, setIsHovering] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)

    return (
        <>
            <Wrapper isHovering={isHovering} onDragEnter={() => setIsHovering(true)} onDragEnd={() => setIsHovering(false)}>
                <LoadingArea area={area}>
                    <div className="grid">
                        {children}
                        <FileInput
                            ref={inputRef}
                            type="file"
                            name="hidden-input"
                            onChange={handleFileUpload}
                            accept={acceptRules}
                        />
                    </div>
                </LoadingArea>
            </Wrapper>
        </>
    )
}

export default UploadWrapper
