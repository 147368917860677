/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ReactNode, useRef, useState } from 'react'
import styles from './Table.module.css'
import { useHistory } from 'react-router-dom'
import { getUrlParam, setUrlParam } from 'utils'
import { usePromiseTracker } from 'react-promise-tracker'
import { useEffect } from 'react'
import Spinner from '../elements/Spinner'
import { usePagination } from 'utils/hooks/usePagination'
import usePrevious from 'utils/hooks/usePrevious'
import { FilterFormGrid, ControlWrapper } from 'style'
import Button from 'components/elements/Button'
import { toast } from 'react-toastify'
import FormSelect from 'components/inputs/FormSelect'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Body1, Body2 } from 'components/elements/Text'
import styled from 'styled-components'
import NumberInput from 'components/elements/NumberInput'
import theme from 'lib/constants/theme'

interface LoadDataValue {
    nextCursor?: string
    totalPages: number
    totalElements: number
    elements: RefactoredTableData[]
}

type ColumnType = string | number | ReactNode | null

const NavigationArrowText = styled(Body2)`
    cursor: pointer;
`
export interface RefactoredTableData {
    id: number | string
    columns: ColumnType[]
    onClick?: string
    onClickFunc?(id: number | string): void
    selected?: boolean
}

export type LoadDataFunction = (
    page: number,
    limit?: number,
    nextCursor?: string
) => Promise<LoadDataValue | void | undefined>

interface Props {
    columns: string[]
    loadData: LoadDataFunction
    loadingArea: string
    refetchKey?: number | string | boolean
    additionalRefetchKey?: string | boolean
    onAllEntries?: (elements: RefactoredTableData[]) => void
    allEntriesButtonText?: string
    allowTableLimitChange?: boolean
    defaultItemLimit?: number
    hideTotalElements?: boolean
    hidePaginationOnTop?: boolean
    openInNewTabOnRowClick?: boolean
}

const TextLink = styled.div`
    color: ${theme.colors.white} !important;
    height: 100%;
    width: 100%;
`
const RefactoredTable = (props: Props) => {
    const history = useHistory()
    const { promiseInProgress } = usePromiseTracker({ area: props.loadingArea })
    const [elements, setElements] = useState<RefactoredTableData[]>([])
    const [allEntriesCheckboxChecked, setAllEntriesCheckboxChecked] = useState(false)
    const [itemsLimit, setItemLimit] = useState(props?.defaultItemLimit ?? 20)
    const itemsLimitFormRef = useRef<FormHandles>(null)
    const [nextCursor, setNextCursor] = useState<string | undefined>()
    const pageParam = getUrlParam('page')

    const prevRefetchKey = usePrevious(props.refetchKey)
    const additionalRefetchKey = usePrevious(props.additionalRefetchKey)
    const {
        pagination,
        setTotalPages,
        setElementsCount,
        setCurrentPage,
        goToNextPage,
        goToPreviousPage,
        skipTwoNextPage,
        skipTwoPreviousPage,
    } = usePagination({
        initialPage: pageParam && pageParam !== '0' ? parseInt(pageParam) - 1 : 0,
    })

    const { pageNumber, totalElements, totalPages } = pagination
    const { loadData } = props

    const handleClick = (entry: RefactoredTableData) => {
        if (entry.onClick) {
            props.openInNewTabOnRowClick ? window.open(entry.onClick) : history.push(entry.onClick)
        }
        if (entry.onClickFunc) {
            entry.onClickFunc(entry.id)
        }
    }

    useEffect(() => {
        setNextCursor(undefined)
    }, [props.refetchKey])

    useEffect(() => {
        if (pageNumber === -1) return
        let tempNextCursor = nextCursor
        if (prevRefetchKey && prevRefetchKey !== props.refetchKey) {
            tempNextCursor = undefined
        }

        loadData(pageNumber, itemsLimit, tempNextCursor).then((data) => {
            if (!data) return

            setNextCursor(data?.nextCursor ?? undefined)
            setTotalPages(data.totalPages)
            setElementsCount(data.totalElements)
            setElements(data.elements)
            setAllEntriesCheckboxChecked(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.refetchKey, loadData, pageNumber, setTotalPages, setElementsCount, setCurrentPage, itemsLimit])

    useEffect(() => {
        if (
            ((prevRefetchKey && prevRefetchKey !== props.refetchKey) ||
                (additionalRefetchKey && additionalRefetchKey !== props.additionalRefetchKey)) &&
            pageNumber !== 0
        ) {
            setCurrentPage(0)
            setUrlParam('page', null)
        }
    }, [additionalRefetchKey, pageNumber, prevRefetchKey, props.additionalRefetchKey, props.refetchKey, setCurrentPage])

    const isValid = elements.length && !promiseInProgress

    const checkAllEntries = (checked: boolean) => {
        setAllEntriesCheckboxChecked(checked)
        setElements((elements) => elements.map((element) => ({ ...element, selected: checked })))
    }

    const updateSelectClicled = (entryIdentification: number | string) => {
        setElements((elements) =>
            elements.map((element) =>
                element?.id === entryIdentification ? { ...element, selected: !element.selected } : element
            )
        )
    }

    const updateItemsLimit = (value: number) => {
        setCurrentPage(0)
        setItemLimit(value)
    }

    const validateAndExecuteOnAllEntriesFunction = () => {
        if (!props.onAllEntries) return

        const selectedElements = elements.filter((element) => element.selected)
        if (selectedElements?.length > 0) {
            props.onAllEntries(selectedElements)
        } else {
            toast.warn('You need to select atleast one entry in order to use update function')
        }
    }

    const itemsLimitOptions = [
        {
            label: '20',
            identifier: '20',
            data: 20,
        },
        {
            label: '50',
            identifier: '50',
            data: 50,
        },
    ]

    const initialData = {
        limit: {
            data: itemsLimit ?? 20,
            label: itemsLimit.toString() ?? '20',
        },
    }

    const onCurrentPageNumberChanged = (page: number) => {
        if (page > 0 && page <= totalPages) setCurrentPage(+page - 1)
    }

    const Wrapper = (p: { children: ReactNode; onClick?: string }) =>
        p.onClick ? (
            <a href={p.onClick} target={props.openInNewTabOnRowClick ? '_blank' : undefined} rel="noreferrer">
                {p.children}
            </a>
        ) : (
            <>{p.children}</>
        )

    return (
        <div>
            <FilterFormGrid gridAmount={2}>
                {props?.onAllEntries && isValid ? (
                    <ControlWrapper>
                        <Button fullWidth onClick={validateAndExecuteOnAllEntriesFunction} noMargin>
                            {props.allEntriesButtonText ?? 'Bulk update of selected entries'}
                        </Button>
                    </ControlWrapper>
                ) : (
                    <div />
                )}
                {!props.hidePaginationOnTop && (
                    <div className={styles.pagination}>
                        <NavigationArrowText onClick={skipTwoPreviousPage}>❮❮</NavigationArrowText>
                        <NavigationArrowText onClick={goToPreviousPage}>❮</NavigationArrowText>

                        <NumberInput
                            min={pageNumber + 1}
                            max={totalPages}
                            defaultValue={pageNumber + 1}
                            onInput={onCurrentPageNumberChanged}
                        />

                        {!props.hideTotalElements ? <Body1>{` of⠀${totalPages}`}</Body1> : null}

                        <NavigationArrowText onClick={goToNextPage}>❯</NavigationArrowText>
                        <NavigationArrowText onClick={skipTwoNextPage}>❯❯</NavigationArrowText>
                    </div>
                )}
            </FilterFormGrid>
            <table className={styles.table}>
                <thead>
                    <tr key="header">
                        {props?.onAllEntries && (
                            <th key="all-checkbox">
                                <input
                                    key="all-checkbox"
                                    style={{ width: '20px', margin: '0 auto' }}
                                    type="checkbox"
                                    onChange={(e) => checkAllEntries(e.target.checked)}
                                    checked={allEntriesCheckboxChecked}
                                />
                            </th>
                        )}
                        {props.columns.map((column) => (
                            <th key={column}>{column}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {isValid
                        ? elements.map((entry, i) => (
                              <tr key={`${entry.id}-${i}`}>
                                  {props?.onAllEntries && (
                                      <td key="checkbox">
                                          <input
                                              key={`checkbox-${entry.id}`}
                                              style={{ width: '100%', margin: '0 auto' }}
                                              type="checkbox"
                                              onChange={() => updateSelectClicled(entry.id)}
                                              checked={entry.selected}
                                          />
                                      </td>
                                  )}
                                  {entry.columns.map((column, i) => (
                                      <td key={`cell-${entry.id}-${i}`} onClick={() => handleClick(entry)}>
                                          <Wrapper onClick={entry.onClick} key={`cell-${entry.id}-${i}`}>
                                              <TextLink>{column}</TextLink>
                                          </Wrapper>
                                      </td>
                                  ))}
                              </tr>
                          ))
                        : null}
                </tbody>
            </table>
            {!isValid && (
                <div className={styles.empty}>
                    {promiseInProgress ? <Spinner area={props.loadingArea} /> : 'No records found!'}
                </div>
            )}
            {isValid ? (
                <div className={styles.pagination}>
                    {props.allowTableLimitChange ? (
                        <>
                            <Body1>Elements per page: </Body1>
                            <Form ref={itemsLimitFormRef} onSubmit={() => {}} initialData={initialData}>
                                <FormSelect
                                    placeholder=""
                                    name="limit"
                                    options={itemsLimitOptions}
                                    onUpdate={updateItemsLimit}
                                    noMargin
                                />
                            </Form>
                        </>
                    ) : null}
                    {!props.hideTotalElements ? <Body1>{totalElements} elements</Body1> : null}
                    <NavigationArrowText onClick={skipTwoPreviousPage}>❮❮</NavigationArrowText>
                    <NavigationArrowText onClick={goToPreviousPage}>❮</NavigationArrowText>
                    <NumberInput
                        min={pageNumber + 1}
                        max={totalPages}
                        defaultValue={pageNumber + 1}
                        onInput={onCurrentPageNumberChanged}
                    />
                    {!props.hideTotalElements ? <Body1>{` of⠀${totalPages}`}</Body1> : null}
                    <NavigationArrowText onClick={goToNextPage}>❯</NavigationArrowText>
                    <NavigationArrowText onClick={skipTwoNextPage}>❯❯</NavigationArrowText>
                </div>
            ) : null}
        </div>
    )
}

export default RefactoredTable
