/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useRef, useState } from 'react'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import RefactoredTable from 'components/modules/RefactoredTable'
import AdCustomerService from 'services/AdSystemService'
import routes from 'lib/constants/routes'
import { FilterFormGrid } from 'style'
import FormSelect from 'components/inputs/FormSelect'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { AssetTimeframes } from 'interfaces/AdSystem'
import { formatDate } from 'utils'
import AdCampaignTargetedRegions from './AdCampaignTargetedRegions'
import { renderPreviewInTable, renderViewHealth, renderViewProgress } from 'lib/features/ads'

const loadingAreas = {
    container: 'assetsContainer',
}

const AdListAssets = () => {
    const [timeframe, setTimeframe] = useState<AssetTimeframes>(AssetTimeframes.Current)
    const formRef = useRef<FormHandles>(null)

    const handleUpdateActiveFilter = (timeframe: AssetTimeframes) => setTimeframe(timeframe)

    const timeframeOptions = Object.keys(AssetTimeframes).map((typeKey) => ({
        label: typeKey,
        identifier: typeKey,
        // eslint-disable-next-line
        // @ts-ignore
        data: AssetTimeframes[typeKey],
    }))

    const fetchData = useCallback(
        (page: number) => {
            return trackPromise(
                AdCustomerService.listCurrentAssets(page, timeframe)
                    .then((data) => {
                        if (page !== data.page) return

                        return {
                            totalPages: data.totalPages,
                            totalElements: data.totalElements,
                            elements: data.ads.map((a) => ({
                                id: a.uuid,
                                columns: [
                                    a.campaign.customer.name,
                                    <>{a.campaign.startOn ? formatDate(a.campaign.startOn) : 'Not specified'}</>,
                                    <>{a.campaign.endOn ? formatDate(a.campaign.endOn) : 'Not specified'}</>,
                                    renderViewProgress(a),
                                    renderViewHealth(a),
                                    a.format,
                                    <AdCampaignTargetedRegions
                                        key={a.campaign.userTargetingId}
                                        userTargetingId={a.campaign.userTargetingId}
                                    />,
                                    renderPreviewInTable(a),
                                ],
                                onClick: routes.adsAssetRoute(a.campaign.customer.uuid, a.campaign.uuid, a.uuid),
                            })),
                        }
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while getting customers.')
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.container
            )
        },
        [timeframe]
    )

    return (
        <section>
            <h2>Ad Assets</h2>
            <FilterFormGrid gridAmount={2}>
                <span />
                <Form ref={formRef} onSubmit={() => {}}>
                    <FormSelect
                        placeholder="Filter by timeframe"
                        noMargin
                        options={timeframeOptions}
                        onUpdate={handleUpdateActiveFilter}
                        name="timeframe"
                    />
                </Form>
            </FilterFormGrid>
            <RefactoredTable
                columns={[
                    'Customer',
                    'Starts on',
                    'Ends on',
                    'Views',
                    'View health',
                    'Format',
                    'Country and regions',
                    'Asset',
                ]}
                loadData={fetchData}
                loadingArea={loadingAreas.container}
                refetchKey={timeframe}
            />
        </section>
    )
}

export default AdListAssets
