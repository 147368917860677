import React, { useEffect, useRef, useState } from 'react'
import { useField } from '@unform/core'
import { FormGroup, InputErrorMessage, SelectOption } from 'components/inputs/Input'
import { useToggleState } from 'utils/hooks/useToggleState'
import Modal from 'components/modules/Modal'
import InformationCard from 'components/features/InformationCard'
import Stack from 'components/elements/Stack'

interface Props<Data> {
    name: string
    label: string
    options: SelectOptionWithLabel<Data>[]
    defaultValue?: string
    tooltip?: string
    noMargin?: boolean
    onUpdate?: (value: string) => void
}

export interface SelectOptionWithLabel<Data> {
    identifier: string
    description: string
    label: string
    data: Data
    children?: JSX.Element
}

function ModalSelect<Data>(props: Props<Data>) {
    const [selectedValue, setSelectedValue] = useState<SelectOption<Data> | null>()
    const [openModal, toggleModal] = useToggleState(false)
    const selectRef = useRef<HTMLInputElement>(null)
    const { name, options } = props
    const { fieldName, defaultValue, registerField, error } = useField(name)

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            path: undefined,
            getValue: (ref: HTMLInputElement) => {
                if (selectedValue === undefined) {
                    return options[0]
                }
                return selectedValue
            },
            setValue: (ref: HTMLSelectElement, value: SelectOption<Data> | null) => {
                setSelectedValue(value)
            },
        })
    }, [fieldName, registerField, options, selectedValue])

    useEffect(() => {
        setSelectedValue(defaultValue)
    }, [setSelectedValue, defaultValue])

    useEffect(() => {
        if (!selectRef.current) return
        if (!selectedValue) return

        const selectedOption = options.find((option) => option.identifier === selectedValue.identifier)
        if (!selectedOption) return
        selectRef.current.value = selectedOption.label
    }, [options, selectedValue])

    useEffect(() => {
        if (defaultValue) {
            setSelectedValue((prevValue) => prevValue ?? defaultValue)
            if (selectRef?.current) {
                selectRef.current.value = defaultValue
            }
        }
    }, [defaultValue])

    const selectModalValue = (option: SelectOptionWithLabel<Data>) => {
        if (selectRef?.current) {
            selectRef.current.value = option.identifier
            setSelectedValue(option)
            toggleModal()
        }
    }

    return (
        <FormGroup noMargin={props.noMargin}>
            <label>{props.label}</label>
            <input ref={selectRef} onClick={toggleModal} defaultValue={defaultValue} disabled={openModal} />

            <InputErrorMessage>{error}</InputErrorMessage>
            <Modal show={openModal} title={`Select ${props.label}`} onClose={toggleModal}>
                <Stack>
                    {options.map((option, i) => (
                        <InformationCard
                            title={option.label}
                            key={`${option.label}-${i}`}
                            isClickable
                            hoverHighlight
                            onClick={() => selectModalValue(option)}
                        >
                            {option.description}
                            {option?.children}
                        </InformationCard>
                    ))}
                </Stack>
            </Modal>
        </FormGroup>
    )
}

export default ModalSelect
