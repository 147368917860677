import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import GoBackButton from 'components/elements/GoBackButton'
import routes from 'lib/constants/routes'
import Meta from 'components/modules/Head'
import { SchoolFromSuggestion } from 'interfaces/SchoolSuggestion'
import SchoolsService from 'services/SchoolsService'
import SchoolCreateForm from './SchoolCreateForm'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import CopyIcon from 'components/elements/CopyIcon'

interface Props extends RouteComponentProps<{ uuid: string }> {}

const loadingAreas = {
    container: 'schoolSuggestionContainer',
}

const SchoolComponent = (props: Props) => {
    const [school, setSchool] = useState<SchoolFromSuggestion | null>(null)
    const uuid = props.match.params.uuid
    const isNew = uuid === 'create' || !uuid

    const fetchData = useCallback(() => {
        if (isNew) return

        trackPromise(
            SchoolsService.get(uuid)
                .then((school) => {
                    setSchool(school)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting competition.')
                }),
            loadingAreas.container
        )
    }, [isNew, uuid])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const updateSchool = (school: SchoolFromSuggestion) => setSchool(school)

    return (
        <section>
            <Meta title="School" />
            <h2>School</h2>
            <GoBackButton route={routes.schoolsRoute} routeName="Schools" />
            {school && (
                <Box>
                    <Stack>
                        <h3>Information</h3>
                        <p>
                            <strong>Uuid:</strong>&nbsp;
                            {school.uuid}
                            <CopyIcon text={school.uuid} />
                        </p>
                        <p>
                            <strong>Name:</strong>&nbsp;
                            {school.name}
                        </p>
                        <p>
                            <strong>Location:</strong>&nbsp;
                            {`${school.streetWithNumber} ${school.city}`}
                        </p>
                        <p>
                            <strong>Coords:</strong>&nbsp;
                            {`lat: ${school.latitude}, lng: ${school.longitude}`}
                        </p>
                        <p>
                            <strong>Icon:</strong>&nbsp;
                        </p>
                        <img width={30} height="auto" src={school.iconUrl} alt="school icon" />
                    </Stack>
                </Box>
            )}
            <SchoolCreateForm school={school} schoolUuid={school?.uuid} isNew={isNew} updateSchool={updateSchool} />
        </section>
    )
}

export default SchoolComponent
