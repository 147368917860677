import theme from 'lib/constants/theme'
import styled from 'styled-components'

const Box = styled.div`
    display: block;
    background: ${theme.colors.darkViolet};
    padding: 15px;
    margin-bottom: 10px;
    border-radius: ${theme.borderRadius.card};
`

export default Box
