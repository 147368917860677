import { CSVData } from 'components/FileUpload/CSVImport'
import { CurriculumAnalysisRequest } from 'interfaces/CurriculumAnalysis'

export const STANDARD_HEADERS = [
    'searchTerm',
    'contentLanguageCode',
    'subjectName',
    'schoolTypeName',
    'gradeLevel',
    'countryCode',
]

export enum HeaderLabels {
    SearchTerm = 'Search term',
    LangaugeCode = 'Language code',
    SubjectName = 'Subject',
    SchoolTypeName = 'School type',
    GradeLevel = 'Grade level',
    CountryCode = 'Country code',
}

const validateCell = (cell: string | undefined) => cell && cell?.length > 0

export const returnMissingToastMessage = (cellData: string, columnName: string) =>
    `Cell data: (${cellData?.length > 0 ? cellData : 'empty'}) have no mathching values in ${columnName} row`

export const processCurriculumCSVData = (
    cvsData: CSVData[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toast: any
) => {
    const array: CurriculumAnalysisRequest[] = []
    for (const row of Object.values(cvsData)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const formattedRow: any = {}

        let i = 0
        for (const [columnName, cellData] of Object.entries(row)) {
            switch (columnName) {
                case HeaderLabels.SubjectName:
                case HeaderLabels.SchoolTypeName:
                case HeaderLabels.GradeLevel:
                case HeaderLabels.CountryCode:
                    formattedRow[STANDARD_HEADERS[i]] = cellData?.length ? cellData : null
                    i++
                    break
                default:
                    if (validateCell(cellData)) {
                        formattedRow[STANDARD_HEADERS[i]] = cellData
                    } else {
                        toast.error(returnMissingToastMessage(cellData, columnName))
                        return
                    }
                    i++
                    break
            }
        }

        array.push(formattedRow)
    }
    return array
}
