import { Country } from 'interfaces/Country'
import React, { useState, useEffect } from 'react'
import { ApiError } from 'services/ApiService'
import UserTargetingService from 'services/UserTargetingService'

interface Props {
    userTargetingId: number
}

const FeedbackDialogTargetingCountry = ({ userTargetingId }: Props) => {
    const [userTargetingCountries, setUserTargetingCountries] = useState<Country[]>()

    useEffect(() => {
        UserTargetingService.get(userTargetingId)
            .then((targeting) => {
                setUserTargetingCountries(targeting.countries)
            })
            .catch((error: ApiError) => {
                error.handleUnknown('An error occurred while fetching feedback dialog country.')
            })
    }, [userTargetingId])

    return <p>{userTargetingCountries?.length ? userTargetingCountries.map((country) => country.code).join(', ') : '-'}</p>
}

export default FeedbackDialogTargetingCountry
