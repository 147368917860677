import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Know } from 'interfaces/Know'
import { ReactComponent as StarIcon } from 'assets/icons/star.svg'
import { ReactComponent as StarOutlineIcon } from 'assets/icons/star-outline.svg'
import KnowService from 'services/KnowService'
import LoadingArea from 'components/elements/LoadingArea'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'

const StarsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    & svg {
        fill: white;
        width: 40px;
        height: 40px;
        cursor: pointer;
    }

    & svg:hover {
        fill: var(--primary-color);
    }
`

interface Props {
    know: Know
    onUpdateKnow(know: Know, successMessage?: string): void
}

const loadingAreas = {
    container: 'knowInternalRating',
}

const KnowInternalRating = (props: Props) => {
    const internalRating = props.know.internalRating ?? 0

    const handleUpdate = useCallback(
        async (rating: number) => {
            let know: Know
            try {
                know = await trackPromise(KnowService.updateInternalRating(props.know.uuid, rating))
            } catch (error) {
                if (error instanceof ApiError) {
                    error.handleUnknown('Unable to update internal rating.')
                } else {
                    throw error
                }
                return
            }

            props.onUpdateKnow(know, `Know has been published with Internal Rating of ${rating} Stars`)
        },
        [props]
    )

    return (
        <LoadingArea area={loadingAreas.container}>
            <StarsWrapper>
                {[1, 2, 3].map((starNumber, index) => (
                    <span onClick={() => handleUpdate(starNumber)} title={`Give ${starNumber} star`} key={index}>
                        {internalRating >= starNumber ? <StarIcon /> : <StarOutlineIcon />}
                    </span>
                ))}
            </StarsWrapper>
        </LoadingArea>
    )
}

export default KnowInternalRating
