import ApiService from 'services/ApiService'
import { IconName, Subject, SubjectCategory, UpdateSubject } from 'interfaces/Subject'
import { InlineAdRecord } from 'interfaces/AdSystem'
import { LanguageCode } from 'interfaces/Language'
import { CountryCode } from 'interfaces/Country'

const SubjectService = {
    list(countryId: number | CountryCode | null) {
        if (!countryId) return Promise.resolve([])
        return ApiService.get(`countries/${countryId}/subjects`).then((response) => {
            return response.data.content as Subject[]
        })
    },

    get(id: number) {
        return ApiService.get(`/subjects/${id}/admin`).then((response) => {
            return response.data as Subject
        })
    },

    create(subject: UpdateSubject) {
        return ApiService.post('/subjects', subject).then((response) => {
            return response.data as Subject
        })
    },

    update(id: number, subject: UpdateSubject) {
        return ApiService.patch(`/subjects/${id}`, subject).then((response) => {
            return response.data as Subject
        })
    },

    updatePartially(id: number, data: InlineAdRecord) {
        return ApiService.patch(`/subjects/${id}`, data).then((response) => {
            return response.data as Subject
        })
    },

    getCategories() {
        return ApiService.get('/subjects/categories').then((response) => {
            return response.data.content as SubjectCategory[]
        })
    },

    getIconNames() {
        return ApiService.get('/subjects/icons').then((response) => {
            return response.data.content as IconName[]
        })
    },

    searchSubjects(query: string, contentLanguageCode?: LanguageCode) {
        return ApiService.get(`/search/subjects?query=${query}&contentLanguageCode=${contentLanguageCode}`).then(
            (response) => {
                return response.data.content as Subject[]
            }
        )
    },
}

export default SubjectService
