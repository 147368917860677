import React from 'react'
import styled from 'styled-components'
import { DataCenters, getDataCenterRegion, switchDataCenter } from 'utils/dataCenter'
import TransparentSelect from 'components/inputs/TransparentSelect'
import USFlag from 'assets/icons/flags/usa.png'
import EuroFlag from 'assets/icons/flags/euro.png'
import { Tooltip, TooltipText } from 'style'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'

const Container = styled.div`
    display: flex;
    place-content: center;
    gap: 15px;
    align-items: center;
    width: 100%;
    height: 50px;
`

const StyledLabel = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
`

const FlagIcon = styled.img`
    display: grid;
    place-content: center;
`

function getCountryFlagIcon(dataCenter: DataCenters) {
    switch (dataCenter) {
        case DataCenters.EU:
            return (
                <StyledLabel>
                    <FlagIcon src={EuroFlag} alt="euro flag" width={30} height={30} />
                </StyledLabel>
            )
        case DataCenters.US:
            return (
                <StyledLabel>
                    <FlagIcon src={USFlag} alt="usa flag" width={30} height={30} />
                </StyledLabel>
            )
    }
}

const DataCenterSwitcher = () => {
    const region = getDataCenterRegion()

    const countryOptions = Object.values(DataCenters).map((v) => ({
        data: v,
        label: v,
    }))

    const handleUpdateLocale = async (dataCenter: DataCenters) => {
        switchDataCenter(dataCenter)
    }

    return (
        <Container>
            <Tooltip>
                <InfoIcon />
                <TooltipText width={150}>
                    Choose the right data center region to experience shorter loading times. All data is available in all
                    regions.
                </TooltipText>
            </Tooltip>
            <TransparentSelect
                options={countryOptions}
                label={<div>{getCountryFlagIcon(region)}</div>}
                onUpdate={handleUpdateLocale}
                defaultValue={region}
            />
        </Container>
    )
}

export default DataCenterSwitcher
