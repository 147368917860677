import React, { useRef } from 'react'
import { Form } from '@unform/web'
import { FormHandles, SubmitHandler } from '@unform/core'
import Button from 'components/elements/Button'
import { Input } from 'components/inputs/Input'
import LoadingArea from 'components/elements/LoadingArea'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import ReportService from 'services/ReportService'

const loadingAreas = {
    save: 'saveKnow',
    form: 'knowForm',
}

interface Props {
    onSuccess: () => void
    reportUuids: string[]
}

type FormData = {
    comment: string
}

const ReportStatusForm = ({ reportUuids, onSuccess }: Props) => {
    const formRef = useRef<FormHandles>(null)

    const handleBulkReportsAccept = async (comment: string) => {
        await reportUuids.map((id) =>
            trackPromise(
                ReportService.accept(id, comment || null, '').catch((error: ApiError) => {
                    if (error instanceof ApiError) {
                        //error.handleUnknown('An error occurred while accepting the report.')
                        console.error(error)
                    } else {
                        throw error
                    }
                }),
                loadingAreas.save
            )
        )
        toast.success(`${reportUuids.length} reports have been accepted!`)
        onSuccess()
    }

    const handleSubmit: SubmitHandler<FormData> = async ({ comment }) => {
        formRef.current!.setErrors({})

        await handleBulkReportsAccept(comment)
    }

    return (
        <div>
            <LoadingArea area={loadingAreas.form}>
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <Input name="comment" placeholder="Add a comment for internal usage (or leave empty)" />
                    <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                        Accept reports
                    </Button>
                </Form>
            </LoadingArea>
        </div>
    )
}

export default ReportStatusForm
