import { useState, useEffect } from 'react'
import { LanguageCode } from 'interfaces/Language'
import LanguageService from 'services/LanguageService'
import { ApiError } from 'services/ApiService'

interface LanguageOption {
    label: string
    identifier: string
    data: LanguageCode | null
}

interface UseLanguageOptionsProps {
    includeNoFilter?: boolean
    showNotLaunched?: boolean
}

export const useLanguageOptions = ({ includeNoFilter = false, showNotLaunched = false }: UseLanguageOptionsProps = {}) => {
    const [languageOptions, setLanguageOptions] = useState<LanguageOption[]>([])

    useEffect(() => {
        LanguageService.list(showNotLaunched)
            .then((languages) => {
                const options: LanguageOption[] = languages.map((lang) => ({
                    label: lang.name,
                    identifier: lang.id.toString(),
                    data: lang.code,
                }))

                if (includeNoFilter) {
                    options.unshift({
                        label: 'No Filter',
                        identifier: 'no-filter',
                        data: null,
                    })
                }

                setLanguageOptions(options)
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('Unable to list languages.')
                    return
                }
                throw error
            })
    }, [includeNoFilter, showNotLaunched])

    return languageOptions
}
