import React, { useCallback } from 'react'
import { User, UserModerationHistory } from 'interfaces/User'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import styled from 'styled-components'
import RefactoredTable from 'components/modules/RefactoredTable'
import UserService from 'services/UserService'
import { NoWrapText } from 'style'
import { formatDateTime } from 'utils'
import { Link } from 'react-router-dom'
import routes from 'lib/constants/routes'

const loadingAreas = {
    userModerationHistory: 'userModerationHistory',
}

const TitleRow = styled.div`
    display: flex;
    justify-content: space-between;
`

interface Props {
    user: User
}

const UserModerationHistoryComponent = ({ user }: Props) => {
    const userUuid = user.uuid

    const formatSourceItemCell = (moderationHistory: UserModerationHistory) => {
        switch (true) {
            case !!moderationHistory.sourceKnowUuid:
                return <Link to={routes.knowRoute(moderationHistory.sourceKnowUuid!)}>Know</Link>
            case !!moderationHistory.sourceReportItemUuid:
                return <Link to={routes.reportRoute(moderationHistory.sourceReportItemUuid!)}>Report</Link>
            case !!moderationHistory.associatedSourceUserUuid:
                return <Link to={routes.userRoute(moderationHistory.associatedSourceUserUuid!)}>User</Link>
            default:
                return 'No item'
        }
    }

    const fetchData = useCallback(
        (page: number) => {
            return trackPromise(
                UserService.getModerationHistory(userUuid)
                    .then((data) => {
                        if (page !== data.page) return

                        return {
                            totalPages: data.totalPages,
                            totalElements: data.totalElements,
                            elements: data.moderationHistory.map((m) => ({
                                id: m.uuid,
                                columns: [
                                    <NoWrapText key={`type-${m.uuid}`}>{m.type}</NoWrapText>,
                                    m.violationLevel,
                                    m.banDurationAdded,
                                    m.penaltyPointsAdded ?? 0,
                                    m.reasonVisibleToUser ?? '-',
                                    m.employeeUserUuid,
                                    formatDateTime(m.createdOn),
                                    formatSourceItemCell(m),
                                ],
                            })),
                        }
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while getting user moderation history.')
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.userModerationHistory
            )
        },
        [userUuid]
    )

    return (
        <Box>
            <Stack>
                <TitleRow>
                    <h3>User moderation history</h3>
                </TitleRow>

                <RefactoredTable
                    columns={[
                        'Type',
                        'Violation Level',
                        'Ban Duration Added',
                        'Penalty Points Added',
                        'Reason',
                        'Employee',
                        'Created on',
                        'Source Item',
                    ]}
                    loadData={fetchData}
                    loadingArea={loadingAreas.userModerationHistory}
                    refetchKey={undefined}
                />
            </Stack>
        </Box>
    )
}

export default UserModerationHistoryComponent
