/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react'
import { Form } from '@unform/web'
import { FormHandles, SubmitHandler } from '@unform/core'
import Button from 'components/elements/Button'
import LoadingArea from 'components/elements/LoadingArea'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import KnowService from 'services/KnowService'
import { FormSelect } from 'components/inputs/Input'
import { Know, KnowStatus } from 'interfaces/Know'

const loadingAreas = {
    save: 'saveKnow',
    form: 'knowForm',
}

type FormData = {
    rating: {
        label: string
        identifier: string
        data: number
    }
}

interface Props {
    onSuccess: () => void
    knowUuids: string[]
}

const KnowInternalRatingForm = ({ knowUuids, onSuccess }: Props) => {
    const formRef = useRef<FormHandles>(null)

    const handleKnowsTransfer = async (rating: number) => {
        const promises: Promise<void | Know>[] = []

        await knowUuids.forEach((uuid) => {
            const promise = trackPromise(
                KnowService.updateInternalRating(uuid, rating)
                    .then(() => {
                        KnowService.updateStatus(uuid, KnowStatus.Public, null).catch((error) => {
                            if (error instanceof ApiError) {
                                error.handleUnknown('An error occurred while publishing the know.')
                            } else {
                                throw error
                            }
                        })
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('Unable to update internal rating.')
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.save
            )
            promises.push(promise)
        })
        await Promise.all(promises).then(() => {
            toast.success(`${knowUuids.length} knows have been published with ${rating} Stars!`)
            onSuccess()
        })
    }

    const handleSubmit: SubmitHandler<FormData> = async ({ rating }) => {
        formRef.current!.setErrors({})

        handleKnowsTransfer(rating.data)
    }

    const ratingOptions = [
        {
            label: '3',
            identifier: '3',
            data: 3,
        },
        {
            label: '2',
            identifier: '2',
            data: 2,
        },
        {
            label: '1',
            identifier: '1',
            data: 1,
        },
    ]

    return (
        <div>
            <LoadingArea area={loadingAreas.form}>
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <FormSelect name="rating" placeholder="Rating" options={ratingOptions} noMargin />

                    <Button fullWidth loadingArea={loadingAreas.save}>
                        {`Publish ${knowUuids.length} know/knows`}
                    </Button>
                </Form>
            </LoadingArea>
        </div>
    )
}

export default KnowInternalRatingForm
