import { CountryCode } from 'interfaces/Country'
import { AcceptReportReason, ReportItem, ReportStatus } from 'interfaces/Report'
import ApiService from 'services/ApiService'

const ReportService = {
    listReports(page: number, reportType: string | null, countryCode?: CountryCode | null, status?: ReportStatus | null) {
        const url = `reports/items?page=${page}${reportType ? `&type=${reportType}` : ''}${
            countryCode ? `&country=${countryCode}` : ''
        }${status ? `&status=${status}` : ''}`

        return ApiService.get(url).then((response) => {
            return {
                content: response.data.content as Array<ReportItem>,
                page: response.data.page as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    getReport(uuid: string) {
        return ApiService.get(`/reports/items/${uuid}`).then((response) => {
            return response.data as ReportItem
        })
    },

    rejectReport(uuid: string, employeeReason: string) {
        return ApiService.post(`/reports/items/${uuid}/reject`, { employeeReason }).then((response) => {
            return response.data as ReportItem
        })
    },

    accept(uuid: string, employeeComment: string | null, employeeReason: string) {
        return ApiService.post(`/reports/items/${uuid}/accept`, {
            employeeComment,
            employeeReason,
        })
    },

    putOnHold(uuid: string) {
        return ApiService.post(`/reports/items/${uuid}/put-on-hold`).then((response) => {
            return response.data as ReportItem
        })
    },

    listUserReports(userUuid: string, page: number) {
        const url = `/users/${userUuid}/reports/items?page=${page}`
        return ApiService.get(url).then((response) => {
            return {
                content: response.data.content as Array<ReportItem>,
                page: response.data.page as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    getReportReasons() {
        return ApiService.get(`/reports/reasons`).then((response) => {
            return response.data.content as AcceptReportReason[]
        })
    },
}

export default ReportService
