import React, { useCallback, useEffect, useRef, useState } from 'react'
import { User } from 'interfaces/User'
import Button from 'components/elements/Button'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { toast } from 'react-toastify'
import { badRequestFormErrors } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import UserRoleService from 'services/UserRoleService'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { Checkbox } from 'components/inputs/Input'
import { Roles, UserRolesUpdateRequest } from 'interfaces/UserRole'
import LoadingArea from 'components/elements/LoadingArea'
import Box from 'components/elements/Box'

interface Props {
    user: User
    fetchData: () => void
}

const loadingAreas = {
    container: 'rolesContainer',
    save: 'saveRoles',
}

export interface FormData {
    admin: boolean
    content: boolean
    customerSupport: boolean
    report: boolean
    sales: boolean
    expansion: boolean
    experimentationPlatform: boolean
}

const UserRoles = ({ user, fetchData }: Props) => {
    const [initialFormData, setInitialUserRoles] = useState<FormData>()
    const formRef = useRef<FormHandles>(null)

    const checkUserRole = (array: string[]) =>
        Object.entries(Roles).reduce((acc, [k, v]) => {
            return { ...acc, [k]: array.includes(v) }
        }, {})

    const getUserRoles = useCallback(() => {
        trackPromise(
            UserRoleService.get(user.uuid)
                .then((response) => setInitialUserRoles(checkUserRole(response) as UserRolesUpdateRequest))
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.FormValidation) {
                            badRequestFormErrors(error, formRef.current!)
                        } else {
                            error.handleUnknown('An error occurred while getting user roles.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.container
        )
    }, [user.uuid])

    useEffect(() => {
        getUserRoles()
    }, [getUserRoles])

    const handleUserRoleChangeSubmit: SubmitHandler<FormData> = (data) => {
        formRef.current!.setErrors({})

        trackPromise(
            UserRoleService.patch(user.uuid, data)
                .then(() => {
                    toast.success('User roles has been changed')
                    fetchData()
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.FormValidation) {
                            badRequestFormErrors(error, formRef.current!)
                        } else {
                            error.handleUnknown('An error occurred while changing user roles.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.save
        )
    }

    return (
        <Box>
            <LoadingArea area={loadingAreas.container}>
                <Form ref={formRef} onSubmit={handleUserRoleChangeSubmit} initialData={initialFormData}>
                    <Checkbox name="admin" placeholder="Admin" />
                    <Checkbox name="content" placeholder="Content" />
                    <Checkbox name="customerSupport" placeholder="Customer support" />
                    <Checkbox name="report" placeholder="Report" />
                    <Checkbox name="sales" placeholder="Sales" />
                    <Checkbox name="product" placeholder="Product" />
                    <Checkbox name="finance" placeholder="Finance" />
                    <Checkbox name="expansion" placeholder="Expansion" />
                    <Checkbox name="experimentationPlatform" placeholder="Experimentation Platform" />
                    <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                        Save user roles
                    </Button>
                </Form>
            </LoadingArea>
        </Box>
    )
}

export default UserRoles
