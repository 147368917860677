import React, { useCallback, useEffect, useState } from 'react'
import Button from 'components/elements/Button'
import Modal from 'components/modules/Modal'
import styled from 'styled-components'
import Stack from 'components/elements/Stack'
import { Know } from 'interfaces/Know'
import { useToggleState } from 'utils/hooks/useToggleState'
import KnowService from 'services/KnowService'
import { ApiError } from 'services/ApiService'
import { KnowRejectionReason } from 'interfaces/KnowRejectionReasons'
import { filterRejectionReasonsByKnowFormat, filterRejectionReasonsByVialionLevel } from 'lib/features/knows'

const ReasonBox = styled.div`
    cursor: pointer;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 15px;
    place-items: center start;
    background: var(--background-light);
    padding: 5px;

    & input {
        width: 100%;
    }

    &:hover {
        background: var(--background-blue-darker);
    }
`

const Number = styled.p`
    background: var(--primary-color);
    padding: 10px;
    width: 30px;
    height: 30px;
    display: grid;
    place-content: center;
`

interface Props {
    know: Know
    show: boolean
    onClose(): void
    onReject(reason: KnowRejectionReason): void
}

const KnowRejectModal = ({ onReject, onClose, know, show }: Props) => {
    const { knowFormat } = know

    const [showContentSafetyOptions, toggleContentSafetyOptions] = useToggleState(false)
    const [contentQualityRejectionReasons, setQualityRejectionReasons] = useState<KnowRejectionReason[]>([])
    const [contentSafetyRejectionReasons, setSafetyRejectionReasons] = useState<KnowRejectionReason[]>([])

    useEffect(() => {
        KnowService.getRejectionReasons()
            .then((rejectionReasons) => {
                setQualityRejectionReasons(filterRejectionReasonsByVialionLevel(rejectionReasons, 'QUALITY'))
                setSafetyRejectionReasons(filterRejectionReasonsByVialionLevel(rejectionReasons, 'SAFETY'))
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('An error occurred while getting rejection reasons.')
                } else {
                    throw error
                }
            })
    }, [])

    const getContentQualityRejectionReason = useCallback(
        (index: number) => {
            if (showContentSafetyOptions) {
                return {
                    ...Object.values(contentSafetyRejectionReasons)[index],
                }
            } else {
                return {
                    ...Object.values(filterRejectionReasonsByKnowFormat(contentQualityRejectionReasons, knowFormat))[index],
                }
            }
        },
        [showContentSafetyOptions, knowFormat, contentSafetyRejectionReasons, contentQualityRejectionReasons]
    )

    useEffect(() => {
        const handler = (event: KeyboardEvent) => {
            if ((event.target && (event.target as Element).nodeName === 'INPUT') || showContentSafetyOptions) {
                return
            }

            const number = parseInt(event.key)
            if (isNaN(number)) return
            if (number <= contentQualityRejectionReasons.length) {
                onReject(contentQualityRejectionReasons[number - 1])
            }
        }
        document.addEventListener('keyup', handler)
        return () => {
            document.removeEventListener('keyup', handler)
        }
    }, [showContentSafetyOptions, getContentQualityRejectionReason, onReject, contentQualityRejectionReasons])

    const returnReasonOptions = () =>
        showContentSafetyOptions ? contentSafetyRejectionReasons : contentQualityRejectionReasons

    const handleReasonClicked = (knowRejection: KnowRejectionReason) => {
        onReject(knowRejection)
    }

    const onCloseRejectModal = () => {
        showContentSafetyOptions && toggleContentSafetyOptions()
        onClose()
    }

    return (
        <Modal show={show} title="Reject know" onClose={onCloseRejectModal}>
            <Stack>
                <h3>Tell the knower why you want to reject the </h3>
                {returnReasonOptions().map((message, index) => (
                    <ReasonBox
                        key={index}
                        onClick={() => handleReasonClicked(message)}
                        title={`Press '${index + 1}' to reject with this message (keyboard shortcut)`}
                    >
                        <Number>{showContentSafetyOptions ? '•' : `${index + 1}`}</Number>
                        <p>{message.name}</p>
                    </ReasonBox>
                ))}
                <Button fullWidth noMargin onClick={toggleContentSafetyOptions} hoverColor="#3a5791">
                    {`Show Content ${showContentSafetyOptions ? 'Quality' : 'Safety'} Options`}
                </Button>
            </Stack>
        </Modal>
    )
}

export default KnowRejectModal
