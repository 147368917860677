import { CountryCode } from 'interfaces/Country'
import { ApiError } from 'services/ApiService'
import UserService from 'services/UserService'

export enum DataCenters {
    EU = 'eu-central-1',
    US = 'us-east-1',
}

const DATA_CENTER_KEY = 'DATA_CENTER'

let cachedDataCenterRegion: DataCenters | null = null

export function getDataCenterRegion(): DataCenters {
    if (cachedDataCenterRegion) {
        return cachedDataCenterRegion
    }

    const storedDataCenter = getStoredDataCenter()

    cachedDataCenterRegion = storedDataCenter

    return storedDataCenter
}

export function getStoredDataCenter(): DataCenters {
    const item = localStorage.getItem(DATA_CENTER_KEY)
    if (!item) {
        return DataCenters.EU
    }

    return item as DataCenters
}

export const storeDataCenter = (dataCenter: DataCenters) => {
    cachedDataCenterRegion = dataCenter
    localStorage.setItem(DATA_CENTER_KEY, dataCenter)
}

export const switchDataCenter = (dataCenter: DataCenters) => {
    const countryCode = dataCenter === DataCenters.US ? CountryCode.UnitedStates : CountryCode.Germany
    UserService.getMe()
        .then((account) =>
            UserService.changeDataCenter(account.uuid, countryCode)
                .then(() => {
                    storeDataCenter(dataCenter)
                    window.location.reload()
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while getting the user information.')
                    } else {
                        throw error
                    }
                })
        )
        .catch((error) => {
            if (error instanceof ApiError) {
                error.handleUnknown('An error occurred while changing user data center.')
            } else {
                throw error
            }
        })
}
