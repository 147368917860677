import React, { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { SpinnerElement } from 'components/elements/Spinner'
import { usePromiseTracker } from 'react-promise-tracker'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ButtonText } from './Text'
import theme from 'lib/constants/theme'

interface Props {
    fullWidth: boolean
    // eslint-disable-next-line
    icon?: any
    loadingArea?: string
    type?: string
    color?: string
    hoverColor?: string
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    children?: ReactNode
    noMargin?: boolean
    title?: string
    padding?: string
    disabled?: boolean
    height?: number
    width?: number
}

interface ContainerProps {
    readonly fullWidth: boolean
    readonly noMargin: boolean
}

const Container = styled.div<ContainerProps>`
    ${(props) =>
        props.fullWidth &&
        css`
            width: 100%;
            text-align: center;
        `}

    margin: ${(props) => (props.fullWidth && !props.noMargin ? 15 : 0)}px 0;
`

const ButtonElement = styled.button<
    React.ButtonHTMLAttributes<HTMLButtonElement> & {
        fullWidth: boolean
        hasIcon: boolean
        loading: boolean
        color?: string
        hoverColor?: string
        type: string
        noMargin: boolean
        disabled: boolean
        padding?: string
        height?: number
        width?: number
    }
>`
    display: inline-block;
    background: ${(props) => (props.color ? props.color : 'var(--primary-color)')};
    color: white;
    padding: ${(props) => (props?.padding ? props.padding : props.loading ? '4.5px' : '15px')};
    border-radius: 5px;
    cursor: ${(props) => (props.loading ? 'auto' : 'pointer')};
    border: none;
    width: ${({ width }) => (width ? `${width}px` : 'auto')};
    height: ${({ height }) => (height ? `${height}px` : 'auto')};
    overflow: visible;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;

    ${(props) =>
        props.fullWidth
            ? `
        width: 100%;
        text-align: center;
    `
            : ''}

    display: grid;
    place-content: center;
    ${(props) =>
        props.hasIcon
            ? `
        grid-template-columns: auto auto;
        grid-gap: 5px;`
            : ''}

    margin: ${(props) => (props.fullWidth && !props.noMargin ? 15 : 0)}px 0;

    &:hover {
        ${(props) => !props.loading && 'background: var(--primary-color-dark)'};
        background: ${(props) => (props.hoverColor ? props.hoverColor : 'var(--primary-color-dark)')};
    }

    ${({ disabled }) =>
        disabled
            ? css`
                  background: var(--background-light);
                  cursor: auto;
                  &:hover {
                      background: var(--background-light);
                  }
              `
            : ''}
    & svg {
        fill: white;
    }
`

const Button: FunctionComponent<Props> = (props) => {
    const { promiseInProgress } = usePromiseTracker({
        area: props.loadingArea,
    })

    return (
        <Container fullWidth={props.fullWidth} noMargin={!!props.noMargin} title={props.title}>
            <ButtonElement
                type={props.type === 'button' ? 'button' : 'submit'}
                fullWidth={props.fullWidth}
                hasIcon={!!props.icon}
                loading={promiseInProgress}
                color={props.color}
                hoverColor={props.hoverColor}
                disabled={!!(promiseInProgress || props.disabled)}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick={props.disabled ? () => {} : props.onClick}
                noMargin={!!props.noMargin}
                padding={props.padding}
                height={props.height}
                width={props.width}
            >
                {promiseInProgress ? (
                    <SpinnerElement />
                ) : (
                    <ButtonText
                        fontWeigth={550}
                        style={{ display: 'flex', gap: 10 }}
                        color={theme.colors.white}
                        textAlign="center"
                    >
                        {props.icon}
                        {props.children}
                    </ButtonText>
                )}
            </ButtonElement>
        </Container>
    )
}

Button.propTypes = {
    children: PropTypes.node.isRequired,
    fullWidth: PropTypes.bool.isRequired,
    icon: PropTypes.any,
    loadingArea: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.string,
}

interface ButtonLinkProps extends Props {
    to: string
    target?: string
}

export const ButtonLink: FunctionComponent<ButtonLinkProps> = (props) => {
    const { promiseInProgress } = usePromiseTracker({
        area: props.loadingArea,
    })

    return (
        <Link to={props.to}>
            <ButtonElement
                type={'submit'}
                fullWidth={props.fullWidth}
                hasIcon={!!props.icon}
                loading={promiseInProgress}
                color={props.color}
                hoverColor={props.hoverColor}
                disabled={promiseInProgress}
                onClick={props.onClick}
                noMargin={!!props.noMargin}
            >
                {promiseInProgress ? (
                    <SpinnerElement />
                ) : (
                    <ButtonText
                        fontWeigth={550}
                        style={{ display: 'flex', gap: 10 }}
                        color={theme.colors.white}
                        textAlign="center"
                    >
                        {props.icon}
                        {props.children}
                    </ButtonText>
                )}
            </ButtonElement>
        </Link>
    )
}
ButtonLink.propTypes = {
    children: PropTypes.node.isRequired,
    fullWidth: PropTypes.bool.isRequired,
    icon: PropTypes.any,
    loadingArea: PropTypes.string,
    onClick: PropTypes.func,
    to: PropTypes.string.isRequired,
    target: PropTypes.string,
}

export const ButtonA: FunctionComponent<ButtonLinkProps> = (props) => {
    const { promiseInProgress } = usePromiseTracker({
        area: props.loadingArea,
    })

    return (
        <a href={props.to} target={props.target}>
            <ButtonElement
                type={'submit'}
                fullWidth={props.fullWidth}
                hasIcon={!!props.icon}
                loading={promiseInProgress}
                color={props.color}
                hoverColor={props.hoverColor}
                disabled={promiseInProgress}
                onClick={props.onClick}
                noMargin={!!props.noMargin}
            >
                {promiseInProgress ? (
                    <SpinnerElement />
                ) : (
                    <ButtonText
                        fontWeigth={550}
                        style={{ display: 'flex', gap: 10 }}
                        color={theme.colors.white}
                        textAlign="center"
                    >
                        {props.icon}
                        {props.children}
                    </ButtonText>
                )}
            </ButtonElement>
        </a>
    )
}
ButtonA.propTypes = {
    children: PropTypes.node.isRequired,
    fullWidth: PropTypes.bool.isRequired,
    icon: PropTypes.any,
    loadingArea: PropTypes.string,
    onClick: PropTypes.func,
    to: PropTypes.string.isRequired,
    target: PropTypes.string,
}

export default Button
