import React, { useCallback, useEffect, useState } from 'react'
import Table, { TableData } from 'components/modules/Table'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import Stack from 'components/elements/Stack'
import routes from 'lib/constants/routes'
import SeoSubjectService from './SeoSubjectService'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { CountryCode } from 'interfaces/Country'
import { ButtonLink } from 'components/elements/Button'
import { FilterFormGrid, ControlWrapper } from 'style'
import theme from 'lib/constants/theme'
import SelectElement from 'components/inputs/SelectElement'
import Meta from 'components/modules/Head'

const loadingAreas = {
    container: 'seoSubjectsContainer',
}

const SeoSubjects = () => {
    const [country, setCountry] = useState<CountryCode>(CountryCode.Germany)
    const [data, setTableData] = useState<TableData[]>([])

    const fetchData = useCallback(async () => {
        trackPromise(
            SeoSubjectService.list(country)
                .then((subjects) => {
                    const tableData = subjects.map((s) => ({
                        id: s.uuid,
                        columns: [s.uuid, s.slug, s.title, s.isPublished ? 'Yes' : 'No'],
                        onClick: routes.seoSubjectRoute(s.uuid),
                    }))

                    setTableData(tableData)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting subjects.')
                }),
            loadingAreas.container
        )
    }, [country])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleUpdateCountry = (value: CountryCode) => setCountry(value)

    const countryOptions = Object.keys(CountryCode).map((countryKey) => ({
        label: countryKey,
        // eslint-disable-next-line
        // @ts-ignore
        data: CountryCode[countryKey],
    }))

    return (
        <section>
            <Meta title="SEO Subjects" />
            <Stack gutter={10}>
                <h2>SEO Subjects</h2>
                <FilterFormGrid gridAmount={2}>
                    <ControlWrapper>
                        <ButtonLink
                            to={routes.seoSubjectCreateRoute}
                            fullWidth={false}
                            icon={<AddIcon />}
                            hoverColor={theme.colors.knowunityBlue}
                            color={theme.colors.knowunityBlueDark}
                        >
                            Add SEO Subject
                        </ButtonLink>
                    </ControlWrapper>

                    <SelectElement label="Country" noMargin options={countryOptions} onUpdate={handleUpdateCountry} />
                </FilterFormGrid>
                <Table
                    columns={['#', 'Slug', 'Title', 'Is published']}
                    data={data}
                    page={0}
                    totalPages={1}
                    totalElements={1}
                    setPage={() => null}
                    area={loadingAreas.container}
                />
            </Stack>
        </section>
    )
}

export default SeoSubjects
