import React, { useRef } from 'react'
import { Form } from '@unform/web'
import { FormHandles, SubmitHandler } from '@unform/core'
import Button from 'components/elements/Button'
import { Input } from 'components/inputs/Input'
import LoadingArea from 'components/elements/LoadingArea'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { badRequestFormErrors, isUUID } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import KnowService from 'services/KnowService'
import { Know } from 'interfaces/Know'

const loadingAreas = {
    save: 'saveKnow',
    form: 'knowForm',
}

interface Props {
    onSuccess: () => void
    knowUuids: string[]
}

type FormData = {
    knowerUuid: string
}

const KnowTransferForm = ({ knowUuids, onSuccess }: Props) => {
    const formRef = useRef<FormHandles>(null)

    const handleKnowsTransfer = async (knowerUuid: string) => {
        const promises: Promise<void | Know>[] = []

        await knowUuids.forEach((id) => {
            const promise = trackPromise(
                KnowService.move(id, knowerUuid).catch((error: ApiError) => {
                    if (error.type === ErrorTypes.FormValidation) {
                        badRequestFormErrors(error, formRef.current!)
                    } else {
                        error.handleUnknown('An error occurred while transfering knows to another knower.')
                    }
                }),
                loadingAreas.save
            )
            promises.push(promise)
        })
        await Promise.all(promises).then(() => {
            toast.success(`${knowUuids.length} knows have been moved!`)
            onSuccess()
        })
    }

    const handleSubmit: SubmitHandler<FormData> = async (data) => {
        formRef.current!.setErrors({})

        if (!data.knowerUuid) {
            toast.warning('You need to specify a knower uuid')
            return
        }

        if (!isUUID(data.knowerUuid)) {
            toast.warning('Enter a valid knower uuid')
            return
        }

        await handleKnowsTransfer(data.knowerUuid)
    }

    return (
        <div>
            <LoadingArea area={loadingAreas.form}>
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <Input name="knowerUuid" placeholder="Knower uuid (to whom the knows will be moved)" />

                    <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                        Transfer knows
                    </Button>
                </Form>
            </LoadingArea>
        </div>
    )
}

export default KnowTransferForm
