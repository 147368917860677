import React from 'react'
import Modal from './Modal'
import Stack from '../elements/Stack'
import styled from 'styled-components'

const Container = styled.div`
    @media (max-width: 750px) {
        display: none;
    }
`

interface Props {
    showDialog: boolean
    toggleDialog: () => void
}
const SuggestionDialog = ({ showDialog, toggleDialog }: Props) => {
    return (
        <Container>
            <Modal
                show={showDialog}
                title="Do you need support or have an idea on how to improve Admin dashboard?"
                onClose={toggleDialog}
            >
                <Stack>
                    <p>
                        Feel free to visit{' '}
                        <a
                            href="https://know-co.slack.com/messages/C039CM88AMS"
                            target="_blank"
                            style={{ color: 'var(--primary-color-dark)' }}
                            rel="noreferrer"
                        >
                            #admin-suggestions
                        </a>{' '}
                        {`channel on Slack and let us know what's the problem or how we can change the dashboard to your liking. We will try to address every idea to make it
                        easier for you to use this internal tool :)`}
                    </p>
                    <p>
                        {`To make it easier for us to go through the suggestions remember to include which admin section does
                        the suggestion relate to and describe on what should be change in details. Attaching a screenshots
                        can be also helpful.`}
                    </p>
                </Stack>
            </Modal>
        </Container>
    )
}

export default SuggestionDialog
