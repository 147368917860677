/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import RefactoredTable, { RefactoredTableData } from 'components/modules/RefactoredTable'
import { FilterFormGrid, NoWrapText } from 'style'
import Stack from 'components/elements/Stack'
import routes from 'lib/constants/routes'
import PayoutRequestService from 'services/PayoutRequestService'
import { formatAmount, formatDateTime } from 'utils'
import { CountryCode } from 'interfaces/Country'
import Search from 'components/elements/Search'
import { PayoutDestination, PayoutRequest } from 'interfaces/PayoutRequest'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { getPayoutRequestFilter, storePayoutRequestFilter } from 'lib/storage'
import Modal from 'components/modules/Modal'
import { useToggleState } from 'utils/hooks/useToggleState'
import PayoutRequestActionDialog from './PayoutRequestActionDialog'
import SelectElement from 'components/inputs/SelectElement'
import Meta from 'components/modules/Head'
import { useCountryOptions } from 'hooks/useCountryOptions'

const loadingAreas = {
    container: 'payoutRequestsContainer',
}

export type PayoutFilter = {
    isOpen: boolean | null
    countryCode: CountryCode | null
    destination: PayoutDestination | null
    invoiceId: string | null
}

export type PayoutBulkData = {
    name: string
    recipientEmail: string
    slug: string
    status: PayoutRequest['status']
    invoiceId: string
    filePath: string | null
    iban: string
    amount: number
    currency: string
    sortCode: string | null
    accountNumber: string | null
}

const PayoutRequests = () => {
    const filters = getPayoutRequestFilter()
    const [isOpen, setIsOpen] = useState<boolean | null>(filters?.isOpen ?? null)
    const [countryCode, setCountryCode] = useState<CountryCode | null>(filters?.countryCode ?? null)
    const [destination, setDestination] = useState<PayoutDestination | null>(filters?.destination ?? null)
    const [invoiceId, setInvoiceId] = useState<string | null>(filters?.invoiceId ?? null)
    const [payoutBulkData, setPayoutBulkData] = useState<PayoutBulkData[]>()
    const [showPayoutActionModal, togglePayoutActionModal] = useToggleState(false)
    const [refetch, setRefetch] = useState(false)

    const formRef = useRef<FormHandles>(null)
    const fetchData = useCallback(
        (page: number) => {
            return trackPromise(
                PayoutRequestService.list(page, isOpen, countryCode, invoiceId, destination)
                    .then((data) => {
                        if (page !== data.page) return

                        setRefetch(false)

                        const redirectIds = isOpen ? data?.payouts?.map((k) => k.uuid) ?? [] : []
                        return {
                            elementsArray: data.payouts,
                            totalPages: data.totalPages,
                            totalElements: data.totalElements,
                            elements: data.payouts.map((p, i) => ({
                                id: p.uuid,
                                columns: [
                                    `${p.countryCode}${p.invoiceID}`,
                                    <p key={`${p.recipientEmail ?? ' '}-${i}`}>{p.fullName ?? ''}</p>,
                                    formatDateTime(p.createdOn),
                                    p.closedOn && !p?.isOpen ? formatDateTime(p.closedOn) : 'Not yet',
                                    <NoWrapText key={`${p.amount}-${p.currency}-${i}`}>
                                        {p?.amount ? formatAmount(p.amount, p.currency) : 'Everything'}
                                    </NoWrapText>,
                                    p.iban,
                                    <NoWrapText key={p.status === 'DONE' ? p.filePath : p.uuid}>{p.status}</NoWrapText>,
                                    p.destination,
                                    <p key={`${p.accountNumber ?? ' '}-${i}`}>{p.sortCode ?? ''}</p>,
                                ],
                                onClick: routes.payoutRequestRoute(p.uuid, { redirectIds }),
                            })),
                        }
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while getting payout requests.')
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.container
            )
        },
        [countryCode, destination, invoiceId, isOpen]
    )

    const countryTypeOptions = useCountryOptions({ includeNoFilter: true })

    const destinationTypeOptions = [
        {
            label: 'No filter',
            identifier: 'no-filter',
            data: null,
        },
        ...Object.keys(PayoutDestination).map((typeKey) => ({
            // eslint-disable-next-line
            // @ts-ignore
            label: PayoutDestination[typeKey],
            identifier: typeKey,
            // eslint-disable-next-line
            // @ts-ignore
            data: PayoutDestination[typeKey],
        })),
    ]

    const payoutRequestsOptions = [
        {
            label: 'No filter',
            identifier: 'no-filter',
            data: null,
        },
        {
            label: 'Opened',
            identifier: 'opened',
            data: true,
        },
        {
            label: 'Closed',
            identifier: 'closed',
            data: false,
        },
    ]

    const setFilters = useCallback(() => {
        const filter = {
            isOpen: isOpen,
            countryCode: countryCode,
            destination: destination,
            invoiceId: invoiceId,
        }
        storePayoutRequestFilter(filter)
    }, [countryCode, destination, isOpen, invoiceId])

    useEffect(() => {
        setFilters()
    }, [setFilters])

    const changeLanguage = (data: CountryCode) => setCountryCode(data)
    const handleUpdateCountry = (value: boolean) => setIsOpen(value)
    const changeDestination = (data: PayoutDestination) => setDestination(data)
    const formatIsOpenInitialData = (isOpen: boolean | null) =>
        isOpen !== null ? (isOpen ? 'opened' : 'closed') : 'no-filter'

    const initialData = {
        isOpen: {
            label: formatIsOpenInitialData(isOpen),
            data: isOpen ?? null,
        },
        destination: {
            label: destination ?? 'No filter',
            data: destination ?? null,
        },
        countryCode: {
            label: countryCode ?? 'No filter',
            data: countryCode ?? null,
        },
        invoiceId: invoiceId,
    }

    const handleBulkStatusClick = useCallback((elements: RefactoredTableData[]) => {
        setPayoutBulkData(
            elements.map(({ id, columns }) => ({
                slug: id.toString(),
                invoiceId: columns[0] as string,
                name: (columns[1] as any).props.children,
                status: (columns[6] as any).props.children,
                filePath: (columns[6] as any).props.children === 'DONE' ? (columns[6] as any).key : null,
                amount: (columns[4] as any).key.split('-')[0],
                currency: (columns[4] as any).key.split('-')[1],
                recipientEmail: (columns[1] as any).key.split('-')[0],
                iban: columns[5] as string,
                sortCode: (columns[8] as any).props.children,
                accountNumber: (columns[8] as any).key?.split('-')[0],
            }))
        )

        togglePayoutActionModal()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onCloseActionModal = () => {
        setPayoutBulkData(undefined)
        fetchData(0)
        setRefetch(true)
        togglePayoutActionModal()
    }

    return (
        <section>
            <Meta title="Payout Requests" />
            <Stack gutter={5}>
                <h2>Payout Requests</h2>
                <Form ref={formRef} onSubmit={() => {}} initialData={initialData}>
                    <FilterFormGrid gridAmount={2}>
                        <SelectElement
                            label="Is Open"
                            noMargin
                            options={payoutRequestsOptions}
                            defaultValue={payoutRequestsOptions.findIndex((option) => option.data === isOpen)}
                            onUpdate={handleUpdateCountry}
                        />
                        <SelectElement
                            label="Country code"
                            noMargin
                            options={countryTypeOptions}
                            defaultValue={countryTypeOptions.findIndex((option) => option.data === countryCode)}
                            onUpdate={changeLanguage}
                        />
                    </FilterFormGrid>
                    <FilterFormGrid gridAmount={2}>
                        <SelectElement
                            label="Destination"
                            noMargin
                            options={destinationTypeOptions}
                            defaultValue={destinationTypeOptions.findIndex((option) => option.data === destination)}
                            onUpdate={changeDestination}
                        />
                        <Search onSearch={setInvoiceId} fullWidth label="Invoice Id" defaultValue={invoiceId} />
                    </FilterFormGrid>
                </Form>

                <RefactoredTable
                    columns={[
                        'Invoice Id',
                        'Full name',
                        'Created On',
                        'Closed On',
                        'Amount',
                        'IBAN',
                        'Destination',
                        'Status',
                        'Sort Code',
                    ]}
                    loadData={fetchData}
                    loadingArea={loadingAreas.container}
                    refetchKey={`${isOpen}_${countryCode}_${destination}_${invoiceId}_${refetch}`}
                    allEntriesButtonText="Show payout actions"
                    onAllEntries={(elements) => handleBulkStatusClick(elements)}
                />
            </Stack>
            {payoutBulkData && (
                <Modal show={showPayoutActionModal} onClose={onCloseActionModal} title="Payout actions">
                    <PayoutRequestActionDialog payoutBulkData={payoutBulkData} />
                </Modal>
            )}
        </section>
    )
}

export default PayoutRequests
