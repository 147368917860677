import React from 'react'
import { ChatMessage } from 'interfaces/Chat'
import { BubbleWrapper, ChatWrapper, LeftChatBubble, UserText, RightChatBubble, UsersTitle } from './ChatComponent.css'
import { previewMessage } from 'utils/chat'

interface Props {
    chatMessages: ChatMessage[]
    reportedUserUuid: string
}

const ChatModalComponent = ({ chatMessages, reportedUserUuid }: Props) => {
    return (
        <ChatWrapper>
            <UsersTitle>
                <UserText $isReportedUser={false}>Reporting User</UserText>
                <UserText $isReportedUser>Reported User</UserText>
            </UsersTitle>
            {chatMessages?.length &&
                chatMessages?.map((chat) => {
                    return (
                        <BubbleWrapper key={chat.uuid} $isReportedUser={chat.user.uuid === reportedUserUuid}>
                            {chat.user.uuid === reportedUserUuid ? (
                                <RightChatBubble>
                                    {chat.user.name} ({chat.user.username}){previewMessage(chat)}
                                </RightChatBubble>
                            ) : (
                                <LeftChatBubble>
                                    {chat.user.name} ({chat.user.username}){previewMessage(chat)}
                                </LeftChatBubble>
                            )}
                        </BubbleWrapper>
                    )
                })}
        </ChatWrapper>
    )
}

export default ChatModalComponent
