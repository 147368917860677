import React, { useEffect } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import Button from 'components/elements/Button'
import { Body1, Body2, ButtonText, Headline5 } from 'components/elements/Text'
import { Input } from 'components/inputs/Input'
import Modal from 'components/modules/Modal'
import { useRef, useState } from 'react'
import { useToggleState } from 'utils/hooks/useToggleState'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import CollapsibleCard from 'components/features/CollapsibleCard'
import theme from 'lib/constants/theme'
import styled from 'styled-components'
import { AdditionCareerPartnerInformation } from 'interfaces/CareerPartner'
import Row from 'components/elements/Row'
import InputElement from 'components/inputs/InputElement'

const ButtonRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    button {
        height: 30px;
    }
`

const InputRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
`

type AdditionalInformationUpdate = AdditionCareerPartnerInformation & { index: number }

type AdditionalInformationFormData = {
    title: string
    sortId: number
}

interface Props {
    additionalInformation: AdditionCareerPartnerInformation[]
    setCareerPartnersadditionalInformation: React.Dispatch<React.SetStateAction<AdditionCareerPartnerInformation[]>>
}

const AdditionalCareerPartnerInformationForm = ({
    additionalInformation,
    setCareerPartnersadditionalInformation,
}: Props) => {
    const [selectedAdditionalInformation, setSelectedAdditionalInformation] = useState<AdditionalInformationUpdate | null>(
        null
    )
    const [showAdditionalInformationForm, toggleAdditionalInformationForm] = useToggleState(false)
    const [contents, setContents] = useState<AdditionCareerPartnerInformation['content']>([])
    const additionalInformationFormRef = useRef<FormHandles>(null)

    useEffect(() => {
        if (!selectedAdditionalInformation) return

        setContents(selectedAdditionalInformation.content)
    }, [selectedAdditionalInformation])

    const updateCompetitionadditionalInformation = ({ sortId, ...info }: AdditionCareerPartnerInformation) =>
        setCareerPartnersadditionalInformation((prevadditionalInformation) => [
            ...prevadditionalInformation,
            { sortId: +sortId, ...info },
        ])

    const handleEditadditionalInformation = ({ sortId, ...info }: AdditionCareerPartnerInformation, index: number) => {
        setSelectedAdditionalInformation({ ...info, index, sortId: +sortId })
        toggleAdditionalInformationForm()
    }

    const handleDeleteadditionalInformation = (indexToDelete: number) =>
        setCareerPartnersadditionalInformation((additionalInformations) =>
            additionalInformations.filter((_, i) => i !== indexToDelete)
        )

    const addContentItem = () =>
        setContents((prevState) => (prevState ? [...prevState, { text: '', link: '' }] : [{ text: '', link: '' }]))

    const handleContentTextUpdate = (index: number, value: string) => {
        setContents((prevAnswers) => {
            prevAnswers[index].text = value
            return prevAnswers.map((answer) => answer)
        })
    }

    const handleContentLinkUpdate = (index: number, value: string) => {
        setContents((prevAnswers) => {
            prevAnswers[index].link = value
            return prevAnswers.map((answer) => answer)
        })
    }

    const handleadditionalInformationFormSubmit: SubmitHandler<AdditionalInformationFormData> = (additionalInformation) => {
        // if (!additionalInformation.link) {
        //     additionalInformationFormRef.current!.setFieldError('link', 'This field is required!')
        //     return
        // }

        if (!additionalInformation.title) {
            additionalInformationFormRef.current!.setFieldError('title', 'This field is required!')
            return
        }

        if (!additionalInformation.sortId) {
            additionalInformationFormRef.current!.setFieldError('sortId', 'This field is required!')
            return
        }

        const newAdditionalInformationObject: AdditionCareerPartnerInformation = {
            content: contents,
            title: additionalInformation.title,
            sortId: additionalInformation.sortId,
        }

        if (selectedAdditionalInformation) {
            const { index } = selectedAdditionalInformation

            setCareerPartnersadditionalInformation((prevadditionalInformations) => {
                const tempadditionalInformation = prevadditionalInformations
                tempadditionalInformation[index] = newAdditionalInformationObject
                return tempadditionalInformation
            })
        } else {
            updateCompetitionadditionalInformation(newAdditionalInformationObject)
        }

        setContents([])
        setSelectedAdditionalInformation(null)
        toggleAdditionalInformationForm()
    }

    return (
        <>
            <Modal
                show={showAdditionalInformationForm}
                title={
                    selectedAdditionalInformation ? 'Update Additional Information item' : 'Add Additional Information item'
                }
                onClose={toggleAdditionalInformationForm}
            >
                <Form
                    ref={additionalInformationFormRef}
                    onSubmit={handleadditionalInformationFormSubmit}
                    initialData={{ ...selectedAdditionalInformation }}
                >
                    <Input name="title" placeholder="Title" />
                    <Body1 color={theme.colors.knowunityBlue}>Content</Body1>
                    <br />
                    {contents?.length
                        ? contents?.map((content, index) => (
                              <Box key={`answer-box-${index + 1}`}>
                                  <Stack gutter={0}>
                                      <Body2 color={theme.colors.white} marginBottom={10}>{`Content ${index + 1}`}</Body2>
                                      <InputRow>
                                          <InputElement
                                              label="Text"
                                              noMargin
                                              onInput={(value) => handleContentTextUpdate(index, value)}
                                              defaultValue={content.text}
                                          />
                                          <InputElement
                                              label="Link"
                                              noMargin
                                              onInput={(value) => handleContentLinkUpdate(index, value)}
                                              defaultValue={content.link || ''}
                                          />
                                      </InputRow>
                                  </Stack>
                              </Box>
                          ))
                        : null}
                    <Button fullWidth icon={<AddIcon />} loadingArea="none" type="button" onClick={addContentItem}>
                        Add additional content item
                    </Button>
                    <Input name="sortId" placeholder="Sort Id" type="number" />
                    <Button
                        fullWidth
                        icon={<SaveIcon />}
                        type="button"
                        onClick={() => additionalInformationFormRef.current?.submitForm()}
                    >
                        Save
                    </Button>
                </Form>
            </Modal>
            <Box>
                <Stack>
                    <ButtonRow>
                        <Headline5 color={theme.colors.white} marginBottom={10}>
                            Additional Information
                        </Headline5>
                        <Button fullWidth={false} onClick={toggleAdditionalInformationForm} icon={<AddIcon />} type="button">
                            <ButtonText color={theme.colors.white}>Add fact</ButtonText>
                        </Button>
                    </ButtonRow>

                    {additionalInformation.length ? (
                        <>
                            {additionalInformation.map((info, i) => (
                                <CollapsibleCard
                                    key={i}
                                    title={
                                        <ButtonRow>
                                            <Body2 color={theme.colors.white}>{`${i + 1}: ${info.title}`}</Body2>
                                            <Row gap={20}>
                                                <Button
                                                    fullWidth={false}
                                                    onClick={() => handleDeleteadditionalInformation(i)}
                                                    type="button"
                                                    icon={<DeleteIcon />}
                                                    color={theme.colors.secondaryRed}
                                                    hoverColor={theme.colors.secondaryRedDarker}
                                                >
                                                    Delete
                                                </Button>
                                                <Button
                                                    fullWidth={false}
                                                    onClick={() => handleEditadditionalInformation(info, i)}
                                                    type="button"
                                                    icon={<EditIcon />}
                                                >
                                                    Edit
                                                </Button>
                                            </Row>
                                        </ButtonRow>
                                    }
                                    openByDefault={false}
                                >
                                    <Stack>
                                        {info.content.map(({ link, text }, i) => (
                                            <Stack key={i}>
                                                <Body1 color={theme.colors.knowunityBlue}>{`Content ${++i}`}</Body1>
                                                <Body2 color={theme.colors.white} fontWeigth={500}>{`Text: ${text}`}</Body2>
                                                <Body2 color={theme.colors.white} fontWeigth={500}>{`Link: ${link}`}</Body2>
                                            </Stack>
                                        ))}
                                    </Stack>
                                    <br />
                                    <Body2 color={theme.colors.white} fontWeigth={500}>{`Sort Id: ${
                                        info.sortId ?? 0
                                    }`}</Body2>
                                </CollapsibleCard>
                            ))}
                        </>
                    ) : (
                        <Body2>No Additional Information items were added</Body2>
                    )}
                </Stack>
            </Box>
        </>
    )
}

export default AdditionalCareerPartnerInformationForm
