export interface Country {
    id: number
    name: string
    code: CountryCode
    localizedName: string
    englishName: string
}

export enum CountryCode {
    Germany = 'DE',
    Switzerland = 'CH',
    Austria = 'AT',
    France = 'FR',
    Poland = 'PL',
    Italy = 'IT',
    UnitedKingdom = 'GB',
    UnitedStates = 'US',
    Spain = 'ES',
    Colombia = 'CO',
    Turkey = 'TR',
    Mexico = 'MX',
    Peru = 'PE',
    Chile = 'CL',
    Argentina = 'AR',
    Romania = 'RO',
    Greece = 'GR',
}

export interface CountryOption {
    label: string
    identifier: string
    data: Country
}

export interface CountryIdOption {
    label: string
    data: number
}
