import React from 'react'
import styled from 'styled-components'
import { ReactComponent as FavoriteBorderIcon } from 'assets/icons/favorite-border.svg'
import { Body2, Subtitle1 } from 'components/elements/Text'
import theme from 'lib/constants/theme'
import routes from 'lib/constants/routes'
import { DiscussionForumQuestion } from 'interfaces/DiscussionForumQuestion'
import Stack from 'components/elements/Stack'
import { Link } from 'react-router-dom'

const Container = styled.div<{ showBorder?: boolean }>`
    * {
        color: ${theme.colors.body1Black} !important;
    }
    width: auto;
    cursor: pointer;
    height: auto;
    width: 100%;
    max-width: auto;
    background: ${theme.colors.white};

    border-radius: 15px;
    padding: 10px;
    overflow: hidden;
    border: 3px solid ${theme.colors.accentBlue};
    box-shadow: 4px 4px 0px ${theme.colors.accentBlue};
    .subject {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 5px;
    }
`

const ViewsLikesWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 5px;
    padding-left: 5px;
    gap: 5px;
    & svg {
        width: 22px;
        fill: #c2c7cc;
    }
`

const HorizontalLine = styled.div`
    content: '';
    position: relative;
    border-top: 1px solid ${theme.colors.accentBlue};
`

const LikesCount = styled.span`
    color: #c2c7cc;
`

interface Props {
    discussionForumQuestion: DiscussionForumQuestion
}

const DiscussionForumQuestionCard = ({ discussionForumQuestion }: Props) => {
    return (
        <Link to={routes.discussionForumQuestionRoute(discussionForumQuestion.uuid)}>
            <Container>
                <Stack gutter={10}>
                    <div className="subject">
                        <img
                            src={discussionForumQuestion.subject.iconUrl}
                            alt={discussionForumQuestion.subject.longName}
                            height={16}
                            width={16}
                        />
                        <Body2 color={discussionForumQuestion.subject.color}>{discussionForumQuestion.subject.name}</Body2>
                    </div>
                    <Subtitle1 color={theme.colors.white}>
                        {discussionForumQuestion.title && `${discussionForumQuestion.title}: `}
                        {discussionForumQuestion.text}
                    </Subtitle1>
                    <HorizontalLine />
                </Stack>
                <ViewsLikesWrapper>
                    <FavoriteBorderIcon />
                    <LikesCount>{discussionForumQuestion.likes}</LikesCount>
                </ViewsLikesWrapper>
            </Container>
        </Link>
    )
}

export default DiscussionForumQuestionCard
