import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import GoBackButton from 'components/elements/GoBackButton'
import routes from 'lib/constants/routes'
import Meta from 'components/modules/Head'
import SchoolSuggestionCreateForm from './SchoolSuggestionCreateForm'
import SchoolCreateService from 'services/SchoolCreateService'
import { SchoolSuggestion } from 'interfaces/SchoolSuggestion'

interface Props extends RouteComponentProps<{ uuid: string }> {}

const loadingAreas = {
    container: 'schoolSuggestionContainer',
}

const SchoolSuggestionComponent = (props: Props) => {
    const [schoolSuggestion, setSchoolSuggestion] = useState<SchoolSuggestion | null>(null)
    const uuid = props.match.params.uuid

    const fetchData = useCallback(() => {
        if (!uuid) return

        trackPromise(
            SchoolCreateService.get(uuid)
                .then((suggestion) => {
                    setSchoolSuggestion(suggestion)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting competition.')
                }),
            loadingAreas.container
        )
    }, [uuid])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <section>
            <Meta title="School suggestion" />
            <h2>School suggestion</h2>
            <GoBackButton route={routes.schoolsSuggestionsRoute} routeName="School suggesions" />
            <SchoolSuggestionCreateForm schoolSuggestion={schoolSuggestion} schoolSuggestionUuid={uuid} />
        </section>
    )
}

export default SchoolSuggestionComponent
