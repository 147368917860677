import theme from 'lib/constants/theme'
import React, { useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Body2 } from 'components/elements/Text'
import { useToggleState } from 'utils/hooks/useToggleState'
import { useProSidebar } from 'react-pro-sidebar'

const CustomSelect = styled.div`
    display: flex;
    gap: 10px;
    cursor: pointer;
    align-items: center;
    position: relative;
    .value {
        padding-right: 10px;
    }
    .select {
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        animation: enter-menu 0.3s linear forwards;
        @keyframes enter-menu {
            from {
                opacity: 0;
                bottom: 40px;
            }
            to {
                opacity: 1;
                bottom: 30px;
            }
        }
        position: absolute;
        left: 20px;

        padding: 10px;
        width: auto;
        display: grid;
        gap: 10px;
        background-color: ${theme.colors.white};
        border-radius: ${theme.borderRadius.normal};
    }
`

interface Props<T> {
    label: JSX.Element
    options: SelectOption<T>[]
    defaultValue?: SelectOption<T>['data']
    onUpdate(value: T): void
}

export interface SelectOption<T> {
    label: string
    data: T
}

const TransparentSelect = React.forwardRef(<T,>(props: Props<T>, ref: React.LegacyRef<HTMLSelectElement>) => {
    const [show, toggle] = useToggleState(false)
    const { collapsed, collapseSidebar } = useProSidebar()
    const menuRef = useRef<HTMLDivElement>(null)

    const handleMouseDown = useCallback(
        (event: MouseEvent) => {
            const isClickOutside = !menuRef.current?.contains(event.target as Node)
            if (isClickOutside && show) {
                toggle()
            }
        },
        [show, toggle]
    )

    useEffect(() => {
        document.addEventListener('mousedown', handleMouseDown)
        return () => {
            document.removeEventListener('mousedown', handleMouseDown)
        }
    }, [handleMouseDown])

    const onInput = (value: SelectOption<T>['data']) => {
        if (!value) return

        if (props.onUpdate) {
            props.onUpdate(value)
        }
    }

    const handleClick = () => {
        collapsed && collapseSidebar()
        toggle()
    }

    return (
        <CustomSelect ref={menuRef} onClick={handleClick}>
            {props.label}
            {!collapsed ? (
                <Body2 color={theme.colors.captionGray} className="value">
                    {props.defaultValue}
                </Body2>
            ) : null}

            {show ? (
                <div className="select">
                    {props.options.map((option, index) => (
                        <Body2 key={index} onClick={() => onInput(option.data)}>
                            {option.label}
                        </Body2>
                    ))}
                </div>
            ) : null}
        </CustomSelect>
    )
})

TransparentSelect.displayName = 'TransparentSelect'

export default TransparentSelect
