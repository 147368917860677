import React, { useCallback, useState } from 'react'
import { ButtonLink } from 'components/elements/Button'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import RefactoredTable from 'components/modules/RefactoredTable'
import routes from 'lib/constants/routes'
import { ControlWrapper, FilterFormGrid } from 'style'
import SelectElement from 'components/inputs/SelectElement'
import CompetitionService from './CompetitionService'
import { formatDateTime } from 'utils'
import { CountryCode } from 'interfaces/Country'

const loadingAreas = {
    container: 'feedbackDialogsContainer',
}

const Competitions = () => {
    const [isActive, setIsActive] = useState<boolean | null>(null)
    const [selectedCountry, setSelectedCountry] = useState<CountryCode>(CountryCode.Germany)

    const handleUpdateActiveFilter = (filter: boolean) => setIsActive(filter)

    const activeType = [
        {
            label: 'All',
            data: null,
        },
        {
            label: 'Active',
            data: true,
        },
        {
            label: 'Not active',
            data: false,
        },
    ]

    const countryTypeOptions = Object.keys(CountryCode).map((typeKey) => ({
        label: typeKey,
        // eslint-disable-next-line
        // @ts-ignore
        data: CountryCode[typeKey],
    }))

    const updateCountryCode = (countryCode: CountryCode) => setSelectedCountry(countryCode)

    const fetchData = useCallback(
        (page: number) => {
            return trackPromise(
                CompetitionService.list(page, isActive, selectedCountry)
                    .then((data) => {
                        if (page !== data.page) return

                        return {
                            totalPages: data.totalPages,
                            totalElements: data.totalElements,
                            elements: data.competitions.map((c) => ({
                                id: c.uuid,
                                columns: [c.uuid, c.name, c.type, formatDateTime(c.startsOn), formatDateTime(c.endsOn)],
                                onClick: routes.competitionRoute(c.uuid),
                            })),
                        }
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while getting competitions.')
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.container
            )
        },
        [isActive, selectedCountry]
    )

    return (
        <section>
            <h2>Competitions</h2>

            <FilterFormGrid gridAmount={3}>
                <ControlWrapper>
                    <ButtonLink to={routes.competitionCreateRoute} fullWidth={false} icon={<AddIcon />}>
                        Create Competition
                    </ButtonLink>
                </ControlWrapper>
                <SelectElement label="Filter" noMargin options={activeType} onUpdate={handleUpdateActiveFilter} />
                <SelectElement label="Country code" noMargin options={countryTypeOptions} onUpdate={updateCountryCode} />
            </FilterFormGrid>

            <RefactoredTable
                columns={['#', 'Name', 'Type', 'Starts on', 'Ends on']}
                loadData={fetchData}
                loadingArea={loadingAreas.container}
                refetchKey={`${isActive}`}
            />
        </section>
    )
}

export default Competitions
