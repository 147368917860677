import { useCallback, useEffect, useMemo, useState } from 'react'
import KnowService from 'services/KnowService'
import { Know as KnowInterface, KnowFormat, KnowStatus } from 'interfaces/Know'
import { ApiError } from 'services/ApiService'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { Flashcard } from 'interfaces/Flashcard'
import { useToggleState } from 'utils/hooks/useToggleState'
import { loadingAreas } from 'lib/constants/LoadingAreas'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'

export const useKnowPage = (initialKnowUuid: string) => {
    const [know, setKnow] = useState<KnowInterface>()
    const [flashCards, setFlashCards] = useState<Flashcard[] | null>(null)
    const [showEditKnowModal, toggleEditKnowModal] = useToggleState(false)

    const fetchFlashCards = useCallback(() => {
        KnowService.getFlashcards(initialKnowUuid)
            .then((cards) => {
                setFlashCards(cards)
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('No flashcards available for this know type.')
                } else {
                    throw error
                }
            })
    }, [initialKnowUuid])

    const fetchData = useCallback(() => {
        trackPromise(
            KnowService.getAdmin(initialKnowUuid)
                .then((know) => {
                    setKnow(know)
                    if (know.knowFormat === KnowFormat.Flashcards) {
                        fetchFlashCards()
                    } else {
                        setFlashCards(null)
                    }
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.NotFound) {
                            return
                        } else {
                            error.handleUnknown('An error occurred while getting know')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.save
        )
    }, [fetchFlashCards, initialKnowUuid])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const updateFlashcards = (flashcards: Flashcard[]) => setFlashCards(flashcards)

    const submitFlashcardsUpdate = () => {
        if (!flashCards?.length) return

        const flashcardRequest = flashCards.map(({ answerImageFilePath, questionImageFilePath, ...flash }) => ({
            ...flash,
            answerImageFilename: answerImageFilePath ? answerImageFilePath?.split('/')?.pop() ?? null : null,
            questionImageFilename: questionImageFilePath ? answerImageFilePath?.split('/')?.pop() ?? null : null,
        }))

        KnowService.updateFlashcards(initialKnowUuid, flashcardRequest)
            .then((updatedCards) => {
                updateFlashcards(updatedCards)
                toast.success('Flashcard changes were saved')
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    if (error.type === ErrorTypes.NotFound) {
                        return
                    } else {
                        error.handleUnknown('An error occurred while updating flashcards for know')
                    }
                } else {
                    throw error
                }
            })
    }

    const isKnowInReview = useMemo(
        () => know?.status === KnowStatus.InReview || know?.status === KnowStatus.InExtendedReview,
        [know]
    )

    const onSuccessSubmitKnowEdit = (know: KnowInterface) => {
        setKnow(know)
        toggleEditKnowModal()
    }

    return {
        fetchData,
        flashCards,
        isKnowInReview,
        know,
        knowUuid: initialKnowUuid,
        onSuccessSubmitKnowEdit,
        showEditKnowModal,
        toggleEditKnowModal,
        updateKnow: setKnow,
        submitFlashcardsUpdate,
        updateFlashcards,
    }
}
