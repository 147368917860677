import React, { useEffect, useState } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import Button from 'components/elements/Button'
import { Input, Checkbox, SelectMultiple, FormSelect, SelectOption } from 'components/inputs/Input'
import { AdCampaign, AdsInterest, AdsGoalType } from 'interfaces/AdSystem'
import theme from 'lib/constants/theme'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import AdSystemService from 'services/AdSystemService'
import { ApiError } from 'services/ApiService'

interface Props {
    isNew: boolean
    refetchData: () => void
    adCampaign?: AdCampaign
    handleSubmit: SubmitHandler<FormData>
    formRef: React.RefObject<FormHandles>
    renderTargetingButton: () => React.ReactNode
    userTargetingId: number | null
}

const loadingAreas = {
    save: 'saveAdCustomer',
}

const goalTypeOptions = [
    {
        label: 'Views',
        identifier: 'views',
        data: AdsGoalType.Views,
    },
    {
        label: 'Clicks',
        identifier: 'clicks',
        data: AdsGoalType.Clicks,
    },
]

export interface FormData {
    name: string
    goalType: SelectOption<AdsGoalType>
    goal?: number
    startOn: string
    endOn: string
    isActive: boolean
    interests: SelectOption<AdsInterest>[]
    frequencyCap: string
    salesDealId: string
}

const UpdateCampaignForm = ({ isNew, adCampaign, handleSubmit, formRef, renderTargetingButton }: Props) => {
    const [adInterestOptions, setAdInterestOptions] = useState<{ data: AdsInterest; identifier: string; label: string }[]>()
    const [initialData, setInitialData] = useState<FormData>({
        name: '',
        goalType: { data: AdsGoalType.Views, identifier: AdsGoalType.Views },
        goal: undefined,
        startOn: '',
        endOn: '',
        isActive: true,
        interests: [],
        frequencyCap: '',
        salesDealId: '',
    })

    useEffect(() => {
        if (!adCampaign) return

        setInitialData({
            name: adCampaign.name,
            goalType: { data: adCampaign.goalType, identifier: adCampaign.goalType },
            goal: adCampaign.goal,
            startOn: adCampaign.startOn ? adCampaign.startOn.slice(0, 10) : '',
            endOn: adCampaign.endOn ? adCampaign.endOn.slice(0, 10) : '',
            isActive: adCampaign.isActive,
            interests: adCampaign.interests.map((interest) => ({ data: interest, identifier: interest.uuid })),
            frequencyCap: adCampaign.frequencyCap?.toString() ?? '',
            salesDealId: adCampaign.salesDealId ?? '',
        })
    }, [adCampaign])

    useEffect(() => {
        AdSystemService.listAdsInterests()
            .then((interests) => {
                const options = interests?.map((interest) => ({
                    data: interest,
                    identifier: interest.uuid,
                    label: interest.tag,
                }))
                options?.length && setAdInterestOptions(options)
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('An error occurred while getting ads interests.')
                } else {
                    throw error
                }
            })
    }, [])

    return (
        <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
            <Input name="name" placeholder="Campaign name" />
            <FormSelect name="goalType" placeholder="Goal Type" options={goalTypeOptions} />
            <Input name="goal" placeholder="Goal" type="number" />
            <Input name="startOn" placeholder="Start date" type="date" />
            <Input name="endOn" placeholder="End date" type="date" />
            <Input name="frequencyCap" placeholder="Frequency Cap (per hour)" type="number" />
            <Input name="salesDealId" placeholder="Sales/CRM Deal ID" />
            <SelectMultiple name="interests" options={adInterestOptions} label="Interests" />
            <Checkbox name="isActive" placeholder="Is Active" />
            {renderTargetingButton()}
            <Button
                fullWidth
                icon={<SaveIcon />}
                loadingArea={loadingAreas.save}
                hoverColor={theme.colors.knowunityBlue}
                color={theme.colors.knowunityBlueDark}
            >
                {isNew ? 'Save' : 'Update'}
            </Button>
        </Form>
    )
}
export default UpdateCampaignForm
