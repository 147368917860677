import React from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import Button from 'components/elements/Button'
import { Body1, Body2, ButtonText, Headline5 } from 'components/elements/Text'
import { Checkbox, FormSelect, Input, SelectOptionWithLabel } from 'components/inputs/Input'
import Modal from 'components/modules/Modal'
import { useRef, useState } from 'react'
import { useToggleState } from 'utils/hooks/useToggleState'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import CollapsibleCard from 'components/features/CollapsibleCard'
import theme from 'lib/constants/theme'
import styled from 'styled-components'
import Row from 'components/elements/Row'
import { ApplicationSurveyType, SurveyQuestion } from 'interfaces/AdAttachmentSet'

const ButtonRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    button {
        height: 30px;
    }
`

type SurveyQuestionType = ApplicationSurveyType & {
    label: 'NONE'
    data: undefined
    identifier: 'NONE'
}

type SurveyFormData = {
    question: string
    answerOptions: string
    isRequired: boolean
    type?: SelectOptionWithLabel<SurveyQuestionType>
}

type SurveyQuestionUpdate = SurveyQuestion & { index: number }

interface Props {
    applicationSurveyQuestions: SurveyQuestion[]
    setApplicationSurveyQuestions: React.Dispatch<React.SetStateAction<SurveyQuestion[]>>
}

const AdAttachmentSurveyForm = ({ applicationSurveyQuestions, setApplicationSurveyQuestions }: Props) => {
    const surveyFormRef = useRef<FormHandles>(null)
    const [selectedSurveyQuestion, setSelectedSurveyQuestion] = useState<SurveyQuestionUpdate | null>(null)

    const [showAdditionalQuestionForm, toggleAdditionalQuestionForm] = useToggleState(false)

    const updateAttachmentSurvey = (surveyQuestion: SurveyQuestion) =>
        setApplicationSurveyQuestions((prevQuestions) => [...prevQuestions, surveyQuestion])

    const handleAdditionalQuestionDelete = (indexToDelete: number) =>
        setApplicationSurveyQuestions((questions) => questions.filter((_, i) => i !== indexToDelete))

    const handleAdditionalQuestionEdit = (surveyQuestion: SurveyQuestion, index: number) => {
        setSelectedSurveyQuestion({ ...surveyQuestion, index })
        toggleAdditionalQuestionForm()
    }

    const handleAdditionalQuestionFormSubmit: SubmitHandler<SurveyFormData> = (additionalQuestion) => {
        const { type, question, answerOptions, ...rest } = additionalQuestion

        if (!question) {
            surveyFormRef.current!.setFieldError('question', 'This field is required!')
            return
        }

        const currentAdditionalQuestion: SurveyQuestion = {
            question,
            answerOptions: answerOptions
                .split(',')
                .map((answer) => answer.trim())
                .filter((x) => x),
            type: type?.data,
            ...rest,
        }
        if (type?.label === 'NONE') {
            delete currentAdditionalQuestion.type
        }
        if (selectedSurveyQuestion) {
            const { index } = selectedSurveyQuestion

            setApplicationSurveyQuestions((prevSurveyQuestions) => {
                const tempQuestions = prevSurveyQuestions
                tempQuestions[index] = currentAdditionalQuestion
                return tempQuestions
            })
        } else {
            updateAttachmentSurvey(currentAdditionalQuestion)
        }
        setSelectedSurveyQuestion(null)
        toggleAdditionalQuestionForm()
    }

    const surveyTypeOptions = [
        {
            label: 'NONE',
            data: undefined,
            identifier: 'NONE',
        },
        ...Object.keys(ApplicationSurveyType).map((typeKey) => ({
            // eslint-disable-next-line
            // @ts-ignore
            label: ApplicationSurveyType[typeKey],
            // eslint-disable-next-line
            // @ts-ignore
            identifier: ApplicationSurveyType[typeKey],
            // eslint-disable-next-line
            // @ts-ignore
            data: ApplicationSurveyType[typeKey],
        })),
    ]

    const initialData = {
        ...selectedSurveyQuestion,
        type: {
            label: selectedSurveyQuestion?.type,
            identifier: selectedSurveyQuestion?.type,
            data: selectedSurveyQuestion?.type,
        },
    }
    return (
        <>
            <Modal
                show={showAdditionalQuestionForm}
                title={selectedSurveyQuestion ? 'Update Additional Question' : 'Add Additional Question'}
                onClose={toggleAdditionalQuestionForm}
            >
                <Form ref={surveyFormRef} onSubmit={handleAdditionalQuestionFormSubmit} initialData={initialData}>
                    <Input name="question" placeholder="Question" />
                    <Input name="answerOptions" placeholder="Answer options (separated by commas)" />
                    {surveyTypeOptions && <FormSelect name="type" options={surveyTypeOptions} placeholder="Survey Type" />}
                    <Checkbox name="isRequired" placeholder="Is active" />
                    <Button fullWidth icon={<SaveIcon />} type="button" onClick={() => surveyFormRef.current?.submitForm()}>
                        Save
                    </Button>
                </Form>
            </Modal>
            <Box>
                <Stack>
                    <ButtonRow>
                        <Headline5 color={theme.colors.white} marginBottom={10}>
                            Additional Questions
                        </Headline5>
                        <Button fullWidth={false} onClick={toggleAdditionalQuestionForm} icon={<AddIcon />} type="button">
                            <ButtonText color={theme.colors.white}>Add Additional Question</ButtonText>
                        </Button>
                    </ButtonRow>

                    {applicationSurveyQuestions.length ? (
                        <>
                            {applicationSurveyQuestions.map((additionalQuestion, i) => (
                                <CollapsibleCard
                                    key={i}
                                    title={
                                        <ButtonRow>
                                            <Body2 color={theme.colors.white}>{`${i + 1}: ${
                                                additionalQuestion.question
                                            }`}</Body2>
                                            <Row gap={20}>
                                                <Button
                                                    fullWidth={false}
                                                    onClick={() => handleAdditionalQuestionDelete(i)}
                                                    type="button"
                                                    icon={<DeleteIcon />}
                                                    color={theme.colors.secondaryRed}
                                                    hoverColor={theme.colors.secondaryRedDarker}
                                                >
                                                    Delete
                                                </Button>
                                                <Button
                                                    fullWidth={false}
                                                    onClick={() => handleAdditionalQuestionEdit(additionalQuestion, i)}
                                                    type="button"
                                                    icon={<EditIcon />}
                                                >
                                                    Edit
                                                </Button>
                                            </Row>
                                        </ButtonRow>
                                    }
                                    openByDefault={false}
                                >
                                    <Stack>
                                        <Body2 color={theme.colors.white}>{`Type: ${
                                            additionalQuestion.type ?? 'Not specified'
                                        }`}</Body2>
                                        <Body2 color={theme.colors.white}>{`Is required: ${
                                            additionalQuestion.isRequired ? 'yes' : 'no'
                                        }`}</Body2>
                                        <hr />
                                        <Body1 color={theme.colors.white} fontWeigth={500}>
                                            {`Answers (${additionalQuestion.answerOptions?.length ?? 0}):`}
                                        </Body1>
                                        {additionalQuestion.answerOptions?.length
                                            ? additionalQuestion.answerOptions.map((answer, i) => (
                                                  <Body2 key={i} color={theme.colors.white}>
                                                      {`- ${answer}`}
                                                  </Body2>
                                              ))
                                            : null}
                                    </Stack>
                                </CollapsibleCard>
                            ))}
                        </>
                    ) : (
                        <Body2>No additional survey question were added</Body2>
                    )}
                </Stack>
            </Box>
        </>
    )
}

export default AdAttachmentSurveyForm
