import React, { useRef } from 'react'
import styled from 'styled-components'
import theme from 'lib/constants/theme'
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as UndoIcon } from 'assets/icons/undo.svg'
import { TopicActionRequest, TopicCell as TopicCellInterface } from 'interfaces/Taxonomy'
import { ButtonText } from 'components/elements/Text'
import { useToggleState } from 'utils/hooks/useToggleState'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import Row from 'components/elements/Row'
import { Input } from 'components/inputs/Input'
import Button from 'components/elements/Button'
import { toast } from 'react-toastify'

const Cell = styled.div<{ selectedAction?: 'EDIT' | 'DELETE' }>`
    padding: 10px;

    background-color: ${theme.colors.hoverViolet};
    border-radius: ${theme.borderRadius.small};
    display: flex;
    justify-content: space-between;
    p {
        font-weight: 400;
        color: ${theme.colors.white};
    }
    border: 2px solid
        ${({ selectedAction }) =>
            selectedAction
                ? selectedAction === 'DELETE'
                    ? theme.colors.secondaryRed
                    : theme.colors.knowunityBlue
                : theme.colors.hoverViolet};

    .actions {
        display: flex;
        align-items: center;
        gap: 10px;
        visibility: hidden;
        svg {
            cursor: pointer;
        }
    }
    &:hover {
        border: 2px solid ${theme.colors.knowunityBlue}77;
        .actions {
            visibility: visible;
        }
    }
`

interface Props {
    topicLevel: number
    topicCell: TopicCellInterface
    updatedTopicCell?: TopicActionRequest
    addTopicCellToRequestArray: (topicCell: TopicActionRequest) => void
    removeTopicCellFromRequestArray: (topicCell: TopicActionRequest) => void
}

const TopicCell = ({
    topicCell,
    topicLevel,
    addTopicCellToRequestArray,
    removeTopicCellFromRequestArray,
    updatedTopicCell,
}: Props) => {
    const [isEditModeToggled, toggleEditMode] = useToggleState(false)
    const formRef = useRef<FormHandles>(null)

    const handleUpdateTopicCellWarning = (action: 'EDIT' | 'DELETE') => {
        if (topicLevel === 3) return

        const topicLabel = topicLevel === 1 ? 'Topic' : 'Subtopic'
        const warningMessage = `
           Friendly reminder: if you ${action.toLowerCase()} a ${topicLabel} all instances of it would be affected 🙂. You can undo the changes by hovering over the item and clicking the undo button.
        `
        toast.warning(warningMessage, { autoClose: 10000 })
    }

    const handleTopicCellToRequestArrayAction = (action: 'EDIT' | 'DELETE', newTopicName?: string) => {
        handleUpdateTopicCellWarning(action)
        addTopicCellToRequestArray({ action, newTopicName: newTopicName ?? topicCell.topicName, uuid: topicCell.uuid })
    }

    const handleEditTopicSubmit = (newTopicName: string) => handleTopicCellToRequestArrayAction('EDIT', newTopicName)

    const handleDeleteTopicAction = () => handleTopicCellToRequestArrayAction('DELETE')

    const handleRemoveTopicCellFromRequestArray = () => removeTopicCellFromRequestArray(updatedTopicCell!)

    const handleSubmit: SubmitHandler<{ topicName: string }> = ({ topicName }) => {
        formRef.current!.setErrors({})

        if (!topicName) {
            formRef.current?.setFieldError('topicName', 'Please enter correct topic name')
            return
        }

        handleEditTopicSubmit(topicName)
        toggleEditMode()
    }

    return (
        <Cell key={topicCell.uuid} selectedAction={updatedTopicCell?.action}>
            {isEditModeToggled ? (
                <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    initialData={{ topicName: updatedTopicCell?.newTopicName || topicCell.topicName }}
                >
                    <Row gap={5}>
                        <Input name="topicName" placeholder="" noMargin />
                        <Button fullWidth={false} noMargin icon={<CheckIcon />} color={theme.colors.darkViolet} />
                        <Button
                            onClick={toggleEditMode}
                            type="button"
                            fullWidth={false}
                            noMargin
                            icon={<CloseIcon />}
                            color={theme.colors.secondaryRedDarker}
                            hoverColor={theme.colors.secondaryRed}
                        />
                    </Row>
                </Form>
            ) : (
                <ButtonText>{updatedTopicCell?.newTopicName ?? topicCell.topicName}</ButtonText>
            )}
            {!isEditModeToggled ? (
                <div className="actions">
                    {updatedTopicCell ? (
                        <UndoIcon onClick={handleRemoveTopicCellFromRequestArray} />
                    ) : (
                        <>
                            <EditIcon onClick={toggleEditMode} />
                            <DeleteIcon onClick={handleDeleteTopicAction} />
                        </>
                    )}
                </div>
            ) : null}
        </Cell>
    )
}

export default TopicCell
