import React, { useRef } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import Button from 'components/elements/Button'
import { Checkbox, Input } from 'components/inputs/Input'
import Modal from 'components/modules/Modal'
import { ReactComponent as BlockIcon } from 'assets/icons/close.svg'
import UserService from 'services/UserService'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { badRequestFormErrors } from 'utils'
import Box from 'components/elements/Box'
import { Body1 } from 'components/elements/Text'
import theme from 'lib/constants/theme'

interface Props {
    userUuid: string
    show: boolean
    onClose(): void
    onCreatedBan?(employeeComment: string | null): void
    defaultBlockDuration?: number
    textExplanation?: string
}

type FormData = {
    blockedDuration: string
    reasonVisibleToUser: string
    blockDevices: boolean
    isForeverBanned: boolean
    resetBio: boolean
    resetProfilePicture: boolean
    resetUsername: boolean
    resetName: boolean
}

const loadingAreas = {
    save: 'blockSubmitModal',
}
const DEFAULT_BLOCK_DURATION = 7

const UserCreateBanModal = (props: Props) => {
    const blockFormRef = useRef<FormHandles>(null)

    const handleBlockSubmit: SubmitHandler<FormData> = (data) => {
        blockFormRef.current!.setErrors({})
        const blockedDuration = parseInt(data.blockedDuration)
        if (blockedDuration <= 0) {
            toast.error(`Blocked duration should be at least 1 day!`)
            return
        }

        if (!data.reasonVisibleToUser) {
            blockFormRef.current!.setFieldError('reasonVisibleToUser', 'This field is required!')
            return
        }

        trackPromise(
            UserService.createBan(props.userUuid, {
                blockedDuration,
                reasonVisibleToUser: data.reasonVisibleToUser,
                blockDevices: data.blockDevices,
                isForeverBanned: data.isForeverBanned,
            })
                .then(() => {
                    if (data.blockDevices) {
                        toast.success(`User and their devices have been bannd.`)
                    } else {
                        toast.success(`User has been banned for ${blockedDuration} days.`)
                    }
                    if (props.onCreatedBan) props.onCreatedBan(data.reasonVisibleToUser)
                    props.onClose()
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.FormValidation) {
                            badRequestFormErrors(error, blockFormRef.current!)
                        } else {
                            error.handleUnknown('An error occurred while banning the user.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.save
        )
    }

    return (
        <Modal show={props.show} title="Create ban" onClose={props.onClose}>
            <Box>
                {props.textExplanation ? <Body1 color={theme.colors.knowunityBlue}>{props.textExplanation}</Body1> : null}
                <Form
                    ref={blockFormRef}
                    onSubmit={handleBlockSubmit}
                    initialData={{ blockedDuration: props.defaultBlockDuration ?? DEFAULT_BLOCK_DURATION }}
                >
                    <Input name="blockedDuration" placeholder="Ban days duration" type="number" />
                    <Input name="reasonVisibleToUser" placeholder="Reason visible to user" />
                    <Checkbox name="blockDevices" placeholder="Ban user from creating a new account on the same device" />
                    <Checkbox name="isForeverBanned" placeholder="Ban user forever" />
                    <Button fullWidth icon={<BlockIcon />} loadingArea={loadingAreas.save}>
                        Ban user
                    </Button>
                </Form>
            </Box>
        </Modal>
    )
}

export default UserCreateBanModal
