import React, { useCallback, useEffect, useState } from 'react'
import LoadingArea from 'components/elements/LoadingArea'
import { RouteComponentProps } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import styled from 'styled-components'
import { Region } from 'interfaces/Region'
import RegionService from 'services/RegionService'
import GoBackButton from 'components/elements/GoBackButton'
import routes from 'lib/constants/routes'
import Meta from 'components/modules/Head'

interface Props extends RouteComponentProps<{ id: string }> {}

const loadingAreas = {
    container: 'regionContainer',
}

const Container = styled.section`
    padding-bottom: 25px;
`

const RegionComponent = (props: Props) => {
    const [region, setRegion] = useState<Region | null>(null)

    const id = props.match.params.id

    const fetchData = useCallback(() => {
        if (!id) return

        trackPromise(
            RegionService.get(+id)
                .then((region) => {
                    setRegion(region)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting region.')
                }),
            loadingAreas.container
        )
    }, [id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <Container>
            <Meta title={`${region?.name || ''} Region`} />
            <h2>Region</h2>
            <GoBackButton route={routes.regionsRoute} routeName="Regions" />
            {region && (
                <Box>
                    <Stack>
                        <h3>Information</h3>
                        <LoadingArea area={loadingAreas.container}>
                            {region && (
                                <>
                                    <p>
                                        <strong>Id:</strong>&nbsp;
                                        {region.id}
                                    </p>
                                    <p>
                                        <strong>Name:</strong>&nbsp;
                                        {region.name}
                                    </p>
                                    <p>
                                        <strong>Code:</strong>&nbsp;
                                        {region.code}
                                    </p>
                                    <p>
                                        <strong>Region name:</strong>&nbsp;
                                        {region.regionName}
                                    </p>
                                    <p>
                                        <strong>Languages:</strong>&nbsp;
                                        {region.languages.map((language) => language.englishName).join(', ')}
                                    </p>
                                    <p>
                                        <strong>Country:</strong>&nbsp;
                                        {region.country.englishName}
                                    </p>
                                    <p>
                                        <strong>Is default:</strong>&nbsp;
                                        {region.isDefault ? 'Yes' : 'No'}
                                    </p>
                                </>
                            )}
                        </LoadingArea>
                    </Stack>
                </Box>
            )}
        </Container>
    )
}

export default RegionComponent
