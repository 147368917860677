import React from 'react'
import { Helmet } from 'react-helmet'

interface Props {
    title: string
}

const Meta = ({ title }: Props) => {
    return (
        <Helmet>
            <title>{`${title} - Knowunity Admin`}</title>
            <meta property="og:title" content={title} />
        </Helmet>
    )
}

export default Meta
