import React from 'react'
import { PDFDocument, degrees } from 'pdf-lib'
import { useEffect, useRef, useCallback, useState, ChangeEvent } from 'react'
import Modal from 'components/modules/Modal'
import { KnowDocument } from 'interfaces/Know'
import styled from 'styled-components'
import Button from 'components/elements/Button'
import { Body1, ButtonText } from 'components/elements/Text'
import theme from 'lib/constants/theme'
import { FormGroup } from 'components/inputs/Input'
import { toast } from 'react-toastify'
import FileUploadService from 'services/FileUploadService'
import { ContentPrefixes } from 'utils/constants/ContentPrefix'
import { ApiError } from 'services/ApiService'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import KnowService from 'services/KnowService'
import { trackPromise } from 'react-promise-tracker'
import { ReactComponent as RotateLeftIcon } from 'assets/icons/rotate_left.svg'
import { ReactComponent as RotateRightIcon } from 'assets/icons/rotate_right.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
const Container = styled.div`
    display: grid;
    gap: 15px;
    .toolbar {
        display: flex;
        gap: 10px;
        justify-content: space-between;
    }
    iframe {
        width: 100%;
        min-height: 750px;
    }
    .button-container {
        align-items: center;
        justify-content: end;
        display: flex;
        gap: 10px;
        input {
            width: 150px;
            margin: 0;
        }
    }
`

interface Props {
    show: boolean
    onClose: () => void
    content: KnowDocument
    knowUuid: string
    documentsCount: number
}

const loadingAreas = {
    upload: 'uploadDocument',
}

const PDFEditorModal = ({ show, onClose, content, knowUuid, documentsCount }: Props) => {
    const [pdfDoc, setPdfDoc] = useState<PDFDocument>()
    const [pageNumbers, setPageNumbers] = useState<string>('0')
    const inputRef = useRef<HTMLInputElement>(null)
    const iframe = useRef<HTMLIFrameElement>(null)

    const renderInIframe = (pdfBytes: Uint8Array) => {
        //In case of blob
        const blob = new Blob([pdfBytes], { type: 'application/pdf' })
        const blobUrl = URL.createObjectURL(blob)
        if (!iframe.current) return

        iframe.current.src = blobUrl
    }

    const generateDoc = useCallback(async () => {
        let blob: ArrayBuffer = new ArrayBuffer(0)
        await fetch(content.contentUrl)
            .then((response) => response.blob())
            .then(async (myBlob) => {
                await myBlob.arrayBuffer().then((res) => {
                    blob = res
                })
            })
        // Create a new PDFDocument
        const doc = await PDFDocument.load(blob)
        setPdfDoc(doc)

        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await doc.save()

        renderInIframe(pdfBytes)
    }, [content.contentUrl])

    useEffect(() => {
        generateDoc()
    }, [generateDoc])

    useEffect(() => {
        if (!inputRef.current || !pdfDoc) return
        const pagesRange = `1-${pdfDoc?.getPages().length}`
        inputRef.current.value = pagesRange
        setPageNumbers(pagesRange)
    }, [pdfDoc])
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPageNumbers(event.target.value)
    }

    const repaintDoc = async () => {
        if (!pdfDoc) return

        const pdfBytes = await pdfDoc.save()
        renderInIframe(pdfBytes)
    }

    const isNumber = (text: string) => !Number.isNaN(+text)

    const handleDocumentAction = async (documentAction: (index: number) => void, o?: { isDelete?: boolean }) => {
        const { isDelete = false } = o || {}
        if (!pdfDoc || !pageNumbers) {
            toast.warning('You need to select a page/pages first!')
            return
        }
        if (isNumber(pageNumbers)) {
            await documentAction(+pageNumbers - 1) // index starts from 0
        } else {
            const range = pageNumbers.split('-')
            if (!(isNumber(range[0] ?? '') && isNumber(range[1] ?? ''))) {
                toast.warning('You need to select a page/pages first!')
                return
            }
            await (async () => {
                for (let i = +range[0]; i <= +range[1]; i++) {
                    await documentAction(isDelete ? +range[0] - 1 : i - 1)
                }
            })()
        }

        await repaintDoc()
    }

    const removeAction = (pageNumber: number) => {
        if (!pdfDoc) return
        pdfDoc.removePage(pageNumber)
    }

    const removePage = async () => {
        if (!pdfDoc) return
        await handleDocumentAction(removeAction, { isDelete: true })
    }

    const rotateAction = (pageNumber: number, type: 'right' | 'left') => {
        if (!pdfDoc) return
        const page = pdfDoc.getPage(pageNumber)
        page.setRotation(degrees(page.getRotation().angle + (type === 'left' ? 90 : -90)))
    }

    const rotatePage = async (type: 'left' | 'right') => {
        if (!pdfDoc) return

        await handleDocumentAction((pageNumber) => rotateAction(pageNumber, type))
    }

    const handleDeleteDocument = async () => {
        const confirmResponse = window.confirm(
            `Do you really want to delete this document? Once deleted, you will not be able to restore it.`
        )
        if (!confirmResponse) {
            return
        }
        try {
            await KnowService.deleteDocument(knowUuid, content.uuid).then(() => {
                toast.success('The document has been deleted!')
                onClose()
            })
        } catch (error) {
            if (error instanceof ApiError) {
                toast.error('There was an error during deleting a document from know.')
            } else {
                throw error
            }
        }
    }

    const handleDeletePreviousDocument = async () => {
        try {
            await KnowService.deleteDocument(knowUuid, content.uuid).then(() => {
                toast.success('The document has been replaced!')
                onClose()
            })
        } catch (error) {
            if (error instanceof ApiError) {
                toast.error('There was an error during deleting the previous document from know.')
            } else {
                throw error
            }
        }
    }

    const handleUploadNewDocumentToKnow = async (filename: string) => {
        try {
            await KnowService.createDocument(knowUuid, filename, content.title, documentsCount).then(() =>
                handleDeletePreviousDocument()
            )
        } catch (error) {
            if (error instanceof ApiError) {
                toast.error('There was an error adding the file to the know. Try again!')
            } else {
                throw error
            }
        }
    }

    const replaceDocument = async () => {
        if (!pdfDoc) return

        const pdfBytes = await pdfDoc.save()

        const blob = new Blob([pdfBytes], { type: 'application/pdf' })
        trackPromise(
            FileUploadService.upload(blob, ContentPrefixes.Content)
                .then((data) => {
                    handleUploadNewDocumentToKnow(data.filename)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.InvalidMIMEType) {
                            toast.error(`The uploaded file needs to be a PDF!`)
                        } else {
                            toast.error('There was an error during uploading the file. Try again!')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.upload
        )
    }

    return (
        <Modal show={show} onClose={onClose} title="Edit PDF" fullWidth>
            <Container>
                <div className="toolbar">
                    <Button
                        onClick={handleDeleteDocument}
                        fullWidth={false}
                        icon={<DeleteIcon />}
                        color="var(--red)"
                        hoverColor="var(--red-dark)"
                    >
                        <ButtonText color={theme.colors.white}>Remove document</ButtonText>
                    </Button>
                    <FormGroup noMargin className="button-container">
                        <Body1
                            color={theme.colors.body2Black}
                        >{`Selected page numbers (also accepts range i.e. 1-3) :`}</Body1>
                        <input ref={inputRef} min={0} max={5} placeholder="Page numbers" onChange={handleInputChange} />
                        <Button
                            onClick={removePage}
                            fullWidth={false}
                            hoverColor={theme.colors.knowunityBlue}
                            icon={<DeleteIcon />}
                        >
                            <ButtonText color={theme.colors.white}>Remove Page</ButtonText>
                        </Button>
                        <Button
                            onClick={() => rotatePage('left')}
                            fullWidth={false}
                            hoverColor={theme.colors.knowunityBlue}
                            icon={<RotateLeftIcon />}
                        >
                            <ButtonText color={theme.colors.white}>90° Right</ButtonText>
                        </Button>
                        <Button
                            onClick={() => rotatePage('right')}
                            fullWidth={false}
                            hoverColor={theme.colors.knowunityBlue}
                            icon={<RotateRightIcon />}
                        >
                            <ButtonText color={theme.colors.white}>90° Left</ButtonText>
                        </Button>
                        <Button
                            onClick={replaceDocument}
                            fullWidth={false}
                            color={theme.colors.knowunityBlueDark}
                            hoverColor={theme.colors.knowunityBlue}
                            loadingArea={loadingAreas.upload}
                        >
                            <ButtonText color={theme.colors.white}>Replace document</ButtonText>
                        </Button>
                    </FormGroup>
                </div>
                <iframe id="iframe" ref={iframe} title="PDF viewer" />
            </Container>
        </Modal>
    )
}

export default PDFEditorModal
