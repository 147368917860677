import { toast } from 'react-toastify'

// Slug must consist of only lower case letters (a-z), and dashes (-) and numbers
export const isSlugInvalid = (slug: string) => {
    if (slug.length <= 0) return true
    return /[^a-z-0-9]/g.test(slug)
}

const doesHTMLContainsConversionElement = (text: string) => text.includes('{{conversion}}')

const doesHTMLContainsHeading1 = (text: string) => text.includes('<h1>')

export const isConversionElementPresent = (text: string) => {
    if (!doesHTMLContainsConversionElement(text)) {
        toast.error('Each article should contain a conversion elements. Add {{conversion}} to the content!')
        return false
    }
    return true
}

export const isHeading1Present = (text: string) => {
    if (doesHTMLContainsHeading1(text)) {
        toast.error(`There shouldn't be any Heading 1 elements in your article. Please change it!`)
        return true
    }
    return false
}
