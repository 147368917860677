import { useState, useEffect } from 'react'
import { CountryCode } from 'interfaces/Country'
import CountriesService from 'services/CountriesService'
import { ApiError } from 'services/ApiService'

interface CountryOption {
    label: string
    identifier: string
    data: CountryCode | number | null
}

interface UseCountryOptionsProps {
    includeNoFilter?: boolean
    useId?: boolean
}

export const useCountryOptions = ({ includeNoFilter = false, useId = false }: UseCountryOptionsProps = {}) => {
    const [countryOptions, setCountryOptions] = useState<CountryOption[]>([])

    useEffect(() => {
        CountriesService.list()
            .then((countries) => {
                const options: CountryOption[] = countries.map((c) => ({
                    label: c.name,
                    identifier: c.id.toString(),
                    data: useId ? c.id : c.code,
                }))

                if (includeNoFilter) {
                    options.unshift({
                        label: 'No Filter',
                        identifier: 'no-filter',
                        data: null,
                    })
                }

                setCountryOptions(options)
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('Unable to list countries.')
                    return
                }
                throw error
            })
    }, [includeNoFilter, useId])

    return countryOptions
}
