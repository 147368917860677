import { KnowFormat, KnowStatus } from './Know'
import { Subject } from 'interfaces/Subject'
import { LanguageCode } from './Language'

export enum ReviewGroup {
    GroupA = 0,
    GroupB = 1,
    GroupC = 2,
}

export type InternalRatingType = 1 | 2 | 3 | null

export interface ReviewSettings {
    countryId: number | null
    reviewGroup: ReviewGroup | null
    format: KnowFormat | null
    status: KnowStatus
    internalRating: InternalRatingType | null
    isAmbassador: boolean | null
    subject: Subject | null
    createdAfter: string | null
    language: LanguageCode | null
    createdBefore: string | null
}
