import React from 'react'
import Box from 'components/elements/Box'
import { Body2, Headline5 } from 'components/elements/Text'
import { useEffect, useState } from 'react'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import PayoutRequestService from 'services/PayoutRequestService'
import LoadingArea from 'components/elements/LoadingArea'
import theme from 'lib/constants/theme'
import Table, { TableData } from 'components/modules/Table'
import routes from 'lib/constants/routes'
import { NoWrapText } from 'style'
import { formatDateTime, formatAmount } from 'utils'
import Stack from 'components/elements/Stack'

const loadingAreas = {
    fraudDetection: 'fraudDetection',
}

interface Props {
    payoutRequestUuid: string
}

const FraudDetectionResult = ({ payoutRequestUuid }: Props) => {
    const [data, setTableData] = useState<TableData[]>([])

    useEffect(() => {
        trackPromise(
            PayoutRequestService.getFraudDetectionResult(payoutRequestUuid)
                .then((fraudResults) => {
                    setTableData(
                        fraudResults.map((p, i) => ({
                            id: p.uuid,
                            columns: [
                                p.uuid,
                                p.recipientEmail,
                                formatDateTime(p.createdOn),
                                <NoWrapText key={`${p.amount}-${p.currency}-${i}`}>
                                    {formatAmount(p.amount, p.currency)}
                                </NoWrapText>,
                                p.iban,
                                <NoWrapText key={`status-${p.uuid}`}>{p.status}</NoWrapText>,
                                p.destination,
                            ],
                            onClick: routes.payoutRequestRoute(p.uuid),
                        }))
                    )
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting fraud detection results.')
                }),
            loadingAreas.fraudDetection
        )
    }, [payoutRequestUuid])

    return (
        <Box>
            <Stack>
                <Headline5 color={theme.colors.white} fontWeigth={600}>
                    Potencial Fraud Detection results
                </Headline5>
                <LoadingArea area={loadingAreas.fraudDetection}>
                    {data?.length ? (
                        <Table
                            columns={['#', 'Recipient Email', 'Created On', 'Amount', 'IBAN', 'Status', 'Destination']}
                            data={data}
                            page={0}
                            totalPages={1}
                            totalElements={1}
                            setPage={() => null}
                            area={loadingAreas.fraudDetection}
                        />
                    ) : (
                        <Body2>No possible fraud detection results were found</Body2>
                    )}
                </LoadingArea>
            </Stack>
        </Box>
    )
}

export default FraudDetectionResult
