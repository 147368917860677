import { LanguageCode } from './Language'

export enum ModerationKeywordType {
    BlockedWords = 'BLACKLIST',
    ReportedWords = 'GRAYLIST',
}

export interface ModerationKeyword {
    uuid: string
    name: string
    languageId: number
    type: ModerationKeywordType
}

export interface ModerationKeywordUpdateRequest {
    name: string
    languageId: LanguageCode | number | null
    type: ModerationKeywordType
}
