import React, { useCallback, useEffect, useState } from 'react'
import LoadingArea from 'components/elements/LoadingArea'
import { ButtonLink } from 'components/elements/Button'
import { Link, RouteComponentProps, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import AdSystemService from 'services/AdSystemService'
import { AdsAsset as AdAssetInterface, AdsAssetFormat, AdsAssetRequest, AdsAssetContentType } from 'interfaces/AdsAsset'
import Box from 'components/elements/Box'
import CopyIcon from 'components/elements/CopyIcon'
import routes from 'lib/constants/routes'
import { ReactComponent as BackIcon } from 'assets/icons/chefron-left.svg'
import ContentPreview from 'components/modules/ContentPreview/ContentPreview'
import { ContentTypes } from 'interfaces/Know'
import Stack from 'components/elements/Stack'
import { ControlsWrapper, ControlWrapper, EditButton, MetaBox } from 'style'
import { useToggleState } from 'utils/hooks/useToggleState'
import Modal from 'components/modules/Modal'
import { Body1, ButtonText } from 'components/elements/Text'
import theme from 'lib/constants/theme'
import ExplanationBanner from 'components/elements/ExplanationBanner'
import AdAssetScreenshot from './AdAssetScreenshot'
import AdsAssetForm from './AdsAssetForm'
import Meta from 'components/modules/Head'
import Table, { TableData } from 'components/modules/Table'
import { returnAttachmentContent } from 'utils/adAttachments'
import { AdAttachment } from 'interfaces/AdAttachmentSet'

interface Props extends RouteComponentProps<{ customerUuid: string; campaignUuid: string; uuid: string }> {}

const loadingAreas = {
    save: 'saveAdAsset',
    information: 'informationAdAssets',
}

const AdAsset = (props: Props) => {
    const [assetAttachments, setAssetAttachments] = useState<AdAttachment[]>()
    const [attachmentsTableData, setAttachmentsTableData] = useState<TableData[]>([])
    const [adAsset, setAdAsset] = useState<AdAssetInterface>()
    const [showEditAssetModal, toggleEditAssetModal] = useToggleState(false)
    const history = useHistory()
    const { uuid, campaignUuid, customerUuid } = props.match.params
    const [formApiError, setFormApiError] = useState<ApiError>()

    const isNew = uuid === 'create'

    // Replace URL if Campaign Uuid or Customer Uuid doesn't match Asset info
    useEffect(() => {
        const { customerUuid, campaignUuid } = props.match.params
        if (!adAsset || !customerUuid || !campaignUuid) return
        const {
            uuid: AdAssetUuid,
            campaign: {
                customer: { uuid: AdCustomerUuid },
                uuid: AdCampaignUuid,
            },
        } = adAsset
        if (AdCampaignUuid !== campaignUuid || AdCustomerUuid !== customerUuid) {
            history.replace(routes.adsAssetRoute(AdCustomerUuid, AdCampaignUuid, AdAssetUuid))
        }
    }, [adAsset, history, props.match.params])

    const fetchAdAttachments = useCallback(() => {
        AdSystemService.getAssetAttachments(uuid)
            .then((attachments) => {
                const tableData = attachments.map((a) => ({
                    id: a.uuid,
                    columns: [a.uuid, a.type, returnAttachmentContent(a)],
                }))

                setAssetAttachments(attachments)
                setAttachmentsTableData(tableData)
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('An error occurred while getting the asset attachments.')
                } else {
                    throw error
                }
            })
    }, [uuid])

    const fetchData = useCallback(() => {
        if (isNew) return
        if (!uuid) return

        trackPromise(
            AdSystemService.getAsset(uuid)
                .then((asset) => {
                    setAdAsset(asset)

                    if (!asset.hasAttachment) return
                    fetchAdAttachments()
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while getting the asset.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.information
        )
    }, [isNew, uuid, fetchAdAttachments])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleSubmit = (data: AdsAssetRequest) => {
        let handleFunc
        if (isNew) {
            handleFunc = AdSystemService.createAsset(campaignUuid, data)
        } else {
            handleFunc = AdSystemService.updateAsset(uuid, data)
        }
        trackPromise(
            handleFunc
                .then((asset) => {
                    setAdAsset(asset)
                    if (asset.hasAttachment) fetchAdAttachments()
                    toast.success('Asset data has been saved.')
                    history.push(routes.adsAssetRoute(customerUuid, campaignUuid, asset.uuid))
                    !isNew && toggleEditAssetModal()
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.FormValidation) {
                            setFormApiError(error)
                        } else {
                            error.handleUnknown(`An error occurred while ${isNew ? 'creating' : 'updating'} an asset.`)
                        }
                    }
                }),
            loadingAreas.save
        )
    }

    return (
        <section>
            <Meta title="Ad Asset" />
            {!isNew ? (
                <ExplanationBanner
                    title="Updates delay"
                    text="Changes about the ads might take up to 1 hour and 10 minutes to become present for the users in the app"
                />
            ) : null}
            <h2>Ad Asset</h2>

            <ControlsWrapper>
                <ControlWrapper>
                    <ButtonLink
                        to={routes.adsCampaignRoute(customerUuid, campaignUuid)}
                        fullWidth={false}
                        icon={<BackIcon />}
                    >
                        Go back to campaign
                    </ButtonLink>
                </ControlWrapper>
            </ControlsWrapper>
            {!isNew ? (
                <LoadingArea>
                    <Box>
                        <Stack>
                            <MetaBox>
                                <h3>Information</h3>
                                <EditButton onClick={toggleEditAssetModal} />
                            </MetaBox>

                            {adAsset && (
                                <>
                                    <p>
                                        <strong>UUID:</strong>&nbsp;
                                        {adAsset.uuid}
                                        <CopyIcon text={adAsset.uuid} />
                                    </p>
                                    <p>
                                        <strong>Name:</strong>&nbsp;
                                        {adAsset.name}
                                    </p>
                                    <p>
                                        <strong>Content Type:</strong>&nbsp;
                                        {adAsset.contentType}
                                    </p>
                                    <p>
                                        <strong>Format:</strong>&nbsp;
                                        {adAsset.format}
                                    </p>
                                    <p>
                                        <strong>URL:</strong>&nbsp;
                                        <a href={adAsset.url} target="_blank" rel="noreferrer">
                                            {adAsset.url}
                                        </a>
                                        <CopyIcon text={adAsset.url} />
                                    </p>
                                    <p>
                                        <strong>Call To Action Text:</strong>&nbsp;
                                        {adAsset.ctaText ?? 'Not specified'}
                                        {` (shown: ${adAsset?.showCta ? '✅' : '❌'})`}
                                    </p>
                                    <p>
                                        <strong>Use in app browser:</strong>&nbsp;
                                        {adAsset.useInAppBrowser ? '✅' : '❌'}
                                    </p>
                                    <p>
                                        <ButtonText color={theme.colors.secondaryRed}>
                                            {`(Numbers and changes can be delayed by up to 1 hour)`}
                                        </ButtonText>
                                        <strong>Views</strong>&nbsp;
                                        {adAsset.views}
                                    </p>
                                    <p>
                                        <ButtonText color={theme.colors.secondaryRed}>
                                            {`(Numbers and changes can be delayed by up to 1 hour)`}
                                        </ButtonText>
                                        <strong>Clicks:</strong>&nbsp;
                                        {adAsset.clicks}
                                    </p>
                                    {adAsset.currentPriority ? (
                                        <p>
                                            <strong>Priority:</strong>&nbsp;
                                            {adAsset.currentPriority}
                                        </p>
                                    ) : null}
                                    <p>
                                        <strong>Boosted for user:</strong>&nbsp;
                                        {adAsset.boostedForUserUuids.map((userUuid, index) => (
                                            <>
                                                {!!index && <span>, </span>}
                                                <Link key={userUuid} to={routes.userRoute(userUuid)}>
                                                    {userUuid}
                                                </Link>
                                            </>
                                        ))}
                                    </p>
                                    <Stack gutter={0}>
                                        <Body1 color={theme.colors.white}>Connected Attachments:</Body1>
                                        <Box>
                                            {attachmentsTableData?.length ? (
                                                <Table
                                                    columns={['#', 'Type', 'Content']}
                                                    data={attachmentsTableData}
                                                    page={0}
                                                    totalPages={1}
                                                    totalElements={attachmentsTableData.length}
                                                />
                                            ) : (
                                                <Body1 color={theme.colors.white} fontWeigth={400}>
                                                    You can connect attachments in the asset update form!
                                                </Body1>
                                            )}
                                        </Box>
                                    </Stack>
                                    {adAsset.contentType === AdsAssetContentType.Know ? (
                                        <p>
                                            <strong>Connected know:</strong>&nbsp;
                                            <Link to={routes.knowRoute(adAsset.knowUuid)}>{adAsset.knowUuid}</Link>
                                            <CopyIcon text={adAsset.url} />
                                        </p>
                                    ) : (
                                        <>
                                            {adAsset?.imageUrl ? (
                                                <>
                                                    <strong>Asset preview:</strong>
                                                    <ContentPreview
                                                        contentType={
                                                            adAsset.contentType === AdsAssetContentType.Image
                                                                ? ContentTypes.image
                                                                : ContentTypes.video
                                                        }
                                                        previewUrl={adAsset.imageUrl}
                                                    />
                                                </>
                                            ) : null}
                                        </>
                                    )}
                                </>
                            )}
                        </Stack>
                    </Box>
                    {adAsset?.contentType === AdsAssetContentType.Image ? (
                        <AdAssetScreenshot
                            adAsset={adAsset}
                            isFullScreenFormat={adAsset?.format === AdsAssetFormat.FullScreen}
                        />
                    ) : null}
                </LoadingArea>
            ) : (
                <Box>
                    {<AdsAssetForm isNew customerUuid={customerUuid} onSubmit={handleSubmit} apiError={formApiError} />}
                </Box>
            )}
            <Modal show={showEditAssetModal} title={'Update Asset information'} onClose={toggleEditAssetModal}>
                {adAsset && (
                    <AdsAssetForm
                        asset={adAsset}
                        isNew={false}
                        assetAttachments={assetAttachments}
                        onSubmit={handleSubmit}
                        apiError={formApiError}
                        customerUuid={customerUuid}
                    />
                )}
            </Modal>
        </section>
    )
}

export default AdAsset
