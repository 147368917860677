/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef } from 'react'
import Spinner from 'components/elements/Spinner'
import Button from 'components/elements/Button'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import Box from 'components/elements/Box'
import { ManualEmail, UpdateEmailBody } from 'interfaces/ManualEmails'
import ManualEmailsService from 'services/ManualEmailsService'
import Stack from 'components/elements/Stack'
import EmailEditor, {
    Design,
    DisplayConditionCallback,
    EventCallback,
    ExportHtmlCallback,
    FileUploadCallback,
    MergeTag,
    SaveDesignCallback,
} from 'react-email-editor'
import { getDefaultEmailBody, handleClickRateHelpersCheck } from 'utils/email'
import { Prompt } from 'react-router-dom'

interface Props {
    email: ManualEmail
    fetchData: () => void
}

const loadingAreas = {
    save: 'sendEmail',
    reload: 'reloadEmail',
    container: 'unlayerEditorContainer',
}

type EmailEditorProps = {
    registerCallback(type: 'image', callback: FileUploadCallback): void
    registerCallback(type: 'displayCondition', callback: DisplayConditionCallback): void
    addEventListener(type: string, callback: EventCallback): void
    loadDesign(design: Design): void
    saveDesign(callback: SaveDesignCallback): void
    exportHtml(callback: ExportHtmlCallback): void
    setMergeTags(mergeTags: ReadonlyArray<MergeTag>): void
}

const ManualEmailEditor = ({ email, fetchData }: Props) => {
    const emailEditor = useRef<EmailEditorProps>(null)
    const emailUuid = email.uuid

    const handleSave = useCallback(
        (body: UpdateEmailBody) => {
            trackPromise(
                ManualEmailsService.updateEmailBody(emailUuid, body)
                    .then(() => {
                        toast.success('Manual email body has been updated')
                        fetchData()
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while sending the manual email.')
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.save
            )
        },
        [emailUuid, fetchData]
    )

    const saveDesign = useCallback(() => {
        emailEditor.current!.exportHtml(({ html, design }) => {
            handleClickRateHelpersCheck(html)
            const body = {
                bodyHtml: html,
                bodyDesign: JSON.stringify(design),
            }
            handleSave(body)
        })
    }, [handleSave])

    const loadBody = () => {
        if (!email) return

        const bodyDesign = getDefaultEmailBody()
        emailEditor.current?.loadDesign((email.bodyDesign !== 'empty' ? JSON.parse(email.bodyDesign) : bodyDesign) as Design)
    }

    useEffect(() => {
        return () => {
            saveDesign()
        }
    }, [saveDesign])

    if (!email) return <Spinner forceShow />

    return (
        <Box>
            <Stack>
                <h3>Email editor</h3>
                <p>
                    Using Drag-n-Drop editor you can customise your emails before sending them out. Use the toolbar on the
                    right to add Text, images, links. Remember to save your design after finishing your work.
                </p>
                <p>{`{{name}} will be replaced with the first name of the user`}</p>
                <p>{`{{unsubscribe}} will be replaced with a link to unsubscribe from our emails`}</p>
            </Stack>
            <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save} onClick={saveDesign}>
                Save your design
            </Button>
            <EmailEditor
                ref={emailEditor as any}
                appearance={{
                    theme: 'dark',
                }}
                onReady={loadBody}
                minHeight={800}
            />
            <Prompt message="Did you make sure to save the changes before leaving?" />
        </Box>
    )
}

export default ManualEmailEditor
