import React, { useCallback } from 'react'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import RefactoredTable from 'components/modules/RefactoredTable'
import Stack from 'components/elements/Stack'
import Meta from 'components/modules/Head'
import CareerPartnerService from 'services/CareerPartnerService'
import routes from 'lib/constants/routes'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { ButtonLink } from 'components/elements/Button'
import { FilterFormGrid, ControlWrapper } from 'style'

const loadingAreas = {
    container: 'careersPartnersContainer',
    delete: 'careersPartnersDelete',
    edit: 'careersPartnersEdit',
}

const CareersPartnersComponent = () => {
    const fetchData = useCallback(() => {
        return trackPromise(
            CareerPartnerService.listCareerPartners()
                .then((data) => {
                    return {
                        totalPages: 0,
                        totalElements: data.length,
                        elements: data.map((partner, i) => ({
                            id: partner.uuid,
                            columns: [
                                partner.uuid,
                                partner.name,
                                partner?.iconUrl ? (
                                    <img
                                        key={`${partner.name}-icon`}
                                        src={partner.iconUrl}
                                        width="auto"
                                        height={50}
                                        alt={`${partner.name}-icon`}
                                    />
                                ) : (
                                    '-'
                                ),
                            ],
                            onClick: routes.careerPartnerRoute(partner.uuid),
                        })),
                    }
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while getting career partners.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.container
        )
    }, [])

    return (
        <section>
            <Meta title="Career Partners" />

            <Stack gutter={5}>
                <h2>Career Partners</h2>
                <FilterFormGrid gridAmount={3}>
                    <ControlWrapper>
                        <ButtonLink to={routes.careerPartnersCreateRoute} fullWidth={false} icon={<AddIcon />}>
                            Create Career Partner
                        </ButtonLink>
                    </ControlWrapper>
                </FilterFormGrid>
                <RefactoredTable
                    hidePaginationOnTop
                    hideTotalElements
                    columns={['#', 'Name', 'Icon']}
                    loadData={fetchData}
                    loadingArea={loadingAreas.container}
                />
            </Stack>
        </section>
    )
}

export default CareersPartnersComponent
