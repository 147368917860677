import { CSVData } from 'components/FileUpload/CSVImport'
import { TopicActionRequest } from 'interfaces/Taxonomy'

export const UPDATE_STANDARD_HEADERS = ['uuid', 'action', 'newTopicName']

export enum UpdateHeaderLabels {
    Uuid = 'Uuid',
    Action = 'Action',
    NewName = 'New name',
}

const validateCell = (cell: string | undefined) => cell && cell?.length > 0

export const returnMissingToastMessage = (cellData: string, columnName: string) =>
    `Cell data: (${cellData?.length > 0 ? cellData : 'empty'}) have no mathching values in ${columnName} row`

const validateUpdateAction = (cellData: string) => cellData === 'DELETE' || cellData === 'EDIT'

export const processTaxonomyUpdateCSVData = (
    cvsData: CSVData[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toast: any
) => {
    const array: TopicActionRequest[] = []
    for (const row of Object.values(cvsData)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const formattedRow: any = {}
        for (const [columnName, cellData] of Object.entries(row)) {
            switch (true) {
                case columnName === UpdateHeaderLabels.Uuid:
                    if (validateCell(cellData)) {
                        formattedRow['uuid'] = cellData
                    } else {
                        toast.error(returnMissingToastMessage(cellData, columnName))
                        return
                    }
                    break
                case columnName === UpdateHeaderLabels.NewName:
                    if (row[UpdateHeaderLabels.Action] === 'DELETE') {
                        // do nothing
                    } else if (validateCell(cellData)) {
                        formattedRow['newTopicName'] = cellData
                    } else {
                        toast.error(returnMissingToastMessage(cellData, columnName))
                        return
                    }
                    break
                case columnName === UpdateHeaderLabels.Action:
                    if (validateUpdateAction(cellData)) {
                        formattedRow['action'] = cellData
                    } else {
                        toast.error(returnMissingToastMessage(cellData, columnName))
                        return
                    }
                    break

                default:
                    break
            }
        }

        array.push(formattedRow)
    }
    return array
}
