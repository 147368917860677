import React, { useState } from 'react'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import { Body1, Body2, Headline4 } from 'components/elements/Text'
import { AdsAsset } from 'interfaces/AdsAsset'
import theme from 'lib/constants/theme'
import SelectElement from 'components/inputs/SelectElement'
import styled from 'styled-components'

// FullScreen
import FullScreenBase from 'assets/ads/full_screen/fullscreen_white_base.png'
import FullScreenDarkBase from 'assets/ads/full_screen/fullscreen_dark_base.png'
import FullScreenNavigation from 'assets/ads/full_screen/partner_label.png'
import FullScreenMoreInfoChip from 'assets/ads/full_screen/more_info_chip.png'

// Banner
import BannerBase from 'assets/ads/banner/banner_base.png'
import BannerClose from 'assets/ads/banner/banner_close.png'
import BannerContent from 'assets/ads/banner/banner_content.png'
import BannerNameOverlay from 'assets/ads/banner/name_overlay.png'

// Add Info 1
import AddInfoBase from 'assets/ads/additional/additional_fullscreen_base.png'
import AddInfoSubjectBase from 'assets/ads/additional/subject_fullscreen_base.png'
import InputElement from 'components/inputs/InputElement'

const Container = styled.div`
    height: 100%;
    padding: 20px;
    display: grid;
    gap: 30px;
    background-color: ${theme.colors.white};
`

const ContainerFullScreenDark = styled.div`
    width: 375px;
    height: 812px;
    position: relative;
    overflow: hidden;
    .base {
        position: absolute;
        top: 0;
        left: 0;
    }
    .more-info {
        position: absolute;
        top: 701.5px;
        left: 25px;
    }

    .navigation {
        position: absolute;
        top: 0px;
        left: 0;
        z-index: 100;
    }
    .navigation-blur {
        position: absolute;
        top: 50px;
        left: 0;
        z-index: 999;
        width: 375px;
        height: 60px;
        background: #090c0f80;
    }
    .content {
        position: absolute;
        top: 130px;
        left: 40px;
        border-radius: 15px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    }
    .content-blur {
        position: absolute;
        top: 65px;
        left: 0px;
        width: 375px;
        border-radius: 0 0 5px 5px;
        overflow: hidden;
        filter: blur(10px);
        -webkit-filter: blur(10px);
        img {
            filter: blur(10px);
            -webkit-filter: blur(10px);
            opacity: 0.3;
        }
    }

    .company-name {
        position: absolute;
        top: 30px;
        left: 28px;
    }
`

const ContainerFullScreenWhite = styled.div`
    width: 375px;
    height: 812px;
    position: relative;
    .base {
        position: absolute;
        top: 0;
        left: 0;
    }
    .close {
        position: absolute;
        top: 160px;
        right: 20px;
        opacity: 0.85;
    }
    .content {
        position: absolute;
        top: 165px;
        left: 70px;
        border-radius: 15px;
    }
    .content-blur {
        position: absolute;
        top: 150px;
        left: 4px;
        border-radius: 15px;
        overflow: hidden;
        img {
            filter: blur(10px);
            -webkit-filter: blur(10px);
            opacity: 0.5;
        }
    }
    .name-overlay {
        position: absolute;
        top: 600px;
        left: 4px;
    }
    .company-name {
        position: absolute;
        top: 620px;
        left: 65px;
    }
    .slogan {
        position: absolute;
        top: 645px;
        left: 65px;
    }
`

const ContainerBanner = styled.div`
    width: 375px;
    height: 812px;
    position: relative;
    .base {
        position: absolute;
        top: 0;
        left: 0;
    }
    .close {
        position: absolute;
        top: 307.5px;
        right: 20px;
        opacity: 0.75;
    }
    .content {
        position: absolute;
        top: 290px;
        left: 4px;
        border-radius: 15px;
    }
    .real {
        border-radius: 15px 15px 0 0;
    }
    .name-overlay {
        position: absolute;
        top: 500px;
        left: 4px;
    }
    .company-name {
        position: absolute;
        top: 520px;
        left: 65px;
    }
    .slogan {
        position: absolute;
        top: 545px;
        left: 65px;
    }
`

const ContainerAddInfo = styled.div`
    width: 375px;
    height: 812px;
    position: relative;
    .base {
        position: absolute;
        top: 0;
        left: 0;
    }
    .company-name {
        position: absolute;
        top: 520px;
        left: 65px;
    }
    .slogan {
        position: absolute;
        top: 545px;
        left: 65px;
    }
`

const ContainerAddInfo2 = styled.div`
    width: 375px;
    height: 812px;
    position: relative;
    .base {
        position: absolute;
        top: 0;
        left: 0;
    }
    .company-name {
        position: absolute;
        top: 447.5px;
        left: 5px;
        transform: rotate(-6deg);
        width: 100%;

        /* Legacy vendor prefixes that you probably don't need... */

        /* Safari */
        -webkit-transform: rotate(-6deg);

        /* Firefox */
        -moz-transform: rotate(-6deg);

        /* IE */
        -ms-transform: rotate(-6deg);

        /* Opera */
        -o-transform: rotate(-6deg);
    }
`

enum Templates {
    Fullscreen = 'FULLSCREEN',
    Banner = 'BANNER',
    AdditonalInfo = 'ADDITIONAL_INFO',
}

const templatesOptions = [
    {
        label: 'Fullscreen',
        data: Templates.Fullscreen,
    },
    {
        label: 'Banner',
        data: Templates.Banner,
    },
    {
        label: 'Additonal info',
        data: Templates.AdditonalInfo,
    },
]

interface Props {
    adAsset: AdsAsset
    isFullScreenFormat: boolean
}

const AdAssetScreenshot = ({ adAsset, isFullScreenFormat }: Props) => {
    const {
        imageUrl: adAssetImageUrl,
        campaign: {
            customer: { name },
        },
    } = adAsset

    const [selectedTemplate, setSelectedTemplate] = useState<string>(
        isFullScreenFormat ? templatesOptions[0].data : templatesOptions[1].data
    )
    const [companyName, setCompanyName] = useState(name)
    const [companySlogan, setCompanySlogan] = useState('Company slogan')

    const updateSelectionOption = (template: string) => setSelectedTemplate(template)

    const filterableOption = isFullScreenFormat ? 'BANNER' : 'FULLSCREEN'
    const options = templatesOptions.filter((option) => option.data !== filterableOption)

    return (
        <Box>
            <Stack>
                <h3>Screenshot</h3>
                <Body1
                    color={theme.colors.body2Black}
                >{`Right click on the image below and select 'Save the image as...' to download the screenshot`}</Body1>
                <SelectElement
                    label="Select Ad Screenshot template"
                    noMargin
                    options={options}
                    onUpdate={updateSelectionOption}
                />
                <InputElement
                    defaultValue={companyName}
                    placeholder="Company"
                    label={'Company name'}
                    noMargin
                    onInput={(value) => setCompanyName(value)}
                />
                <InputElement
                    defaultValue={companySlogan}
                    placeholder="Company slogan"
                    label={'Company slogan'}
                    noMargin
                    onInput={(value) => setCompanySlogan(value)}
                />
                <Container>
                    {selectedTemplate === Templates.Fullscreen ? (
                        <>
                            <Headline4 color={theme.colors.deepSeaBlue}>Full screen</Headline4>
                            <ContainerFullScreenDark>
                                <img className="base" src={FullScreenDarkBase} width={375} height={812} alt="banner base" />
                                <div className="content-blur">
                                    <img src={adAssetImageUrl} width={365} height={665} alt="banner content" />
                                </div>
                                <img
                                    className="content"
                                    src={adAssetImageUrl}
                                    width={300}
                                    height={530}
                                    alt="banner content"
                                />
                                <div className="navigation-blur">
                                    <img
                                        className="navigation"
                                        src={FullScreenNavigation}
                                        width={375}
                                        height={60}
                                        alt="banner navigation"
                                    />
                                    <Body2 className="company-name" fontWeigth={400} color="#A0ADB5" fontFamily="Inter">
                                        {companyName}
                                    </Body2>
                                </div>

                                <img
                                    className="more-info"
                                    src={FullScreenMoreInfoChip}
                                    width={327}
                                    height={52}
                                    alt="banner navigation"
                                />
                            </ContainerFullScreenDark>
                            <ContainerFullScreenWhite>
                                <img className="base" src={FullScreenBase} width={375} height={812} alt="banner base" />
                                <div className="content-blur">
                                    <img src={adAssetImageUrl} width={365} height={535} alt="banner content" />
                                </div>
                                <img
                                    className="content"
                                    src={adAssetImageUrl}
                                    width={230}
                                    height={'auto'}
                                    alt="banner content"
                                />
                                <img
                                    className="name-overlay"
                                    src={BannerNameOverlay}
                                    width={360}
                                    height={84}
                                    alt="banner name"
                                />
                                <img className="close" src={BannerClose} width={32} height={33} alt="banner close" />
                                <Body1 className="company-name" fontWeigth={600} color="#090C0F" fontFamily="Inter">
                                    {companyName}
                                </Body1>
                                <Body2 className="slogan" fontWeigth={400} color="#3A434A" fontFamily="Inter">
                                    {companySlogan}
                                </Body2>
                            </ContainerFullScreenWhite>
                        </>
                    ) : null}
                    {selectedTemplate === Templates.Banner ? (
                        <>
                            <Headline4 color={theme.colors.deepSeaBlue}>Banner</Headline4>
                            <ContainerBanner>
                                <img className="base" src={BannerBase} width={375} height={812} alt="banner base" />
                                <img className="content" src={BannerContent} width={367} height={261} alt="banner content" />
                                <img
                                    className="content real"
                                    src={adAssetImageUrl}
                                    width={367}
                                    height={212}
                                    alt="banner content"
                                />
                                <img
                                    className="name-overlay"
                                    src={BannerNameOverlay}
                                    width={367}
                                    height={84}
                                    alt="banner name"
                                />
                                <img className="close" src={BannerClose} width={32} height={33} alt="banner close" />
                                <Body1 className="company-name" fontWeigth={600} color="#090C0F" fontFamily="Inter">
                                    {companyName}
                                </Body1>
                                <Body2 className="slogan" fontWeigth={400} color="#3A434A" fontFamily="Inter">
                                    {companySlogan}
                                </Body2>
                            </ContainerBanner>
                        </>
                    ) : null}
                    {selectedTemplate === Templates.AdditonalInfo ? (
                        <>
                            <Headline4 color={theme.colors.deepSeaBlue}>Additional Info</Headline4>
                            <ContainerAddInfo>
                                <img className="base" src={AddInfoBase} width={375} height={812} alt="banner base" />
                                <Body1 className="company-name" fontWeigth={600} color="#090C0F" fontFamily="Inter">
                                    {companyName}
                                </Body1>
                                <Body2 className="slogan" fontWeigth={400} color="#3A434A" fontFamily="Inter">
                                    {companySlogan}
                                </Body2>
                            </ContainerAddInfo>
                            <ContainerAddInfo2>
                                <img className="base" src={AddInfoSubjectBase} width={375} height={812} alt="banner base" />
                                <Body1
                                    className="company-name"
                                    fontSize={29}
                                    fontWeigth={550}
                                    color={`${theme.colors.white}ee`}
                                    fontFamily="Poppins"
                                    textAlign="center"
                                >
                                    {companyName}
                                </Body1>
                            </ContainerAddInfo2>
                        </>
                    ) : null}
                </Container>
            </Stack>
        </Box>
    )
}

export default AdAssetScreenshot
