import React, { useRef } from 'react'
import { Form } from '@unform/web'
import { FormHandles, SubmitHandler } from '@unform/core'
import Button from 'components/elements/Button'
import { FormSelect, Input, SelectOptionWithLabel } from 'components/inputs/Input'
import LoadingArea from 'components/elements/LoadingArea'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { ModerationKeyword, ModerationKeywordType } from 'interfaces/ModerationKeyword'
import ModerationKeywordsService from 'services/ModerationKeywordsService'
import { generateInitialValue } from 'utils/forms'
import { LanguageInterface } from 'interfaces/Language'

const loadingAreas = {
    save: 'saveModerationKeyword',
    form: 'knowForm',
}

type FormData = {
    name: string
    languageId: SelectOptionWithLabel<LanguageInterface>
    type: SelectOptionWithLabel<ModerationKeywordType>
}

interface Props {
    onSuccess: () => void
    moderationKeyword: ModerationKeyword | null
    languageOptions: SelectOptionWithLabel<LanguageInterface | null>[] | null
}

const ModerationKeywordsForm = ({ moderationKeyword, onSuccess, languageOptions }: Props) => {
    const formRef = useRef<FormHandles>(null)
    const isEdit = !!moderationKeyword

    const generateModerationKeywordRequest = ({ type, languageId, ...rest }: FormData) => ({
        type: type.data,
        languageId: languageId?.data ? +languageId.data.id : null,
        ...rest,
    })

    const handleBulkModerationKeywordCreate = async (moderationKeywordRequests: FormData[]) => {
        await moderationKeywordRequests.forEach((moderationKeywordRequest) =>
            trackPromise(
                ModerationKeywordsService.create(generateModerationKeywordRequest(moderationKeywordRequest)).catch(
                    (error: ApiError) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while adding moderation keyword.')
                        } else {
                            throw error
                        }
                    }
                ),
                loadingAreas.save
            )
        )

        toast.success(
            moderationKeywordRequests?.length > 0
                ? `${moderationKeywordRequests.length} Keywords have been added`
                : 'Keyword has been added'
        )
        onSuccess()
    }

    const handleModerationKeywordUpdate = (uuid: string, moderationKeywordRequest: FormData) => {
        trackPromise(
            ModerationKeywordsService.update(uuid, generateModerationKeywordRequest(moderationKeywordRequest))
                .then(() => {
                    toast.success('Keywords has been updated')
                    onSuccess()
                })
                .catch((error: ApiError) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while updating moderation keyword.')
                        console.error(error)
                    } else {
                        throw error
                    }
                }),
            loadingAreas.save
        )
    }

    const handleSubmit: SubmitHandler<FormData> = async (data) => {
        formRef.current!.setErrors({})

        if (isEdit) {
            handleModerationKeywordUpdate(moderationKeyword.uuid, data)
            return
        }
        await handleBulkModerationKeywordCreate(generateModerationKeywordRequestArray(data))
    }

    const generateModerationKeywordRequestArray = ({ name, ...rest }: FormData) =>
        name.split(',').map((name) => ({
            name: name.trim(),
            ...rest,
        }))

    const moderationTypeOptions = Object.keys(ModerationKeywordType).map((typeKey) => ({
        label: typeKey,
        identifier: typeKey,
        // eslint-disable-next-line
        // @ts-ignore
        data: ModerationKeywordType[typeKey],
    }))

    const initialData = {
        name: moderationKeyword?.name,
        languageId:
            moderationKeyword && languageOptions?.length
                ? !moderationKeyword?.languageId
                    ? languageOptions.find(({ identifier }) => identifier === 'global')
                    : languageOptions.find(({ data }) => data?.id === moderationKeyword?.languageId)
                : null,
        type: generateInitialValue(moderationTypeOptions, moderationKeyword?.type),
    }

    return (
        <div>
            <LoadingArea area={loadingAreas.form}>
                <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
                    <Input
                        name="name"
                        placeholder={`Name ${
                            !isEdit ? '(separated by commas in order to add multiple keywords at once)' : ''
                        }`}
                    />
                    {languageOptions ? (
                        <FormSelect name="languageId" options={languageOptions} placeholder="Language" />
                    ) : null}
                    <FormSelect name="type" options={moderationTypeOptions} placeholder="Moderation keyword type" />
                    <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                        Save
                    </Button>
                </Form>
            </LoadingArea>
        </div>
    )
}

export default ModerationKeywordsForm
