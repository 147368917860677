import React from 'react'
import isValidHttpUrl from 'utils/isValidHttpUrl'
import { Link } from 'react-router-dom'
import ContentPreview from 'components/modules/ContentPreview/ContentPreview'
import { ContentTypes } from 'interfaces/Know'
import routes from 'lib/constants/routes'
import config from 'utils/config'
import { ChatMessage } from 'interfaces/Chat'
import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'
import Stack from 'components/elements/Stack'

export const textBasedOnMessage = (message: string) => {
    if (isValidHttpUrl(message)) {
        if (message.startsWith(config.getContentBaseUrl())) {
            return <ContentPreview contentType={ContentTypes.image} previewUrl={message} />
        } else {
            return (
                <a href={message} target="_blank" rel="noreferrer">
                    {message}
                </a>
            )
        }
    } else {
        return <p>{message}</p>
    }
}

export const previewMessage = (chatMessage: ChatMessage) => {
    if (chatMessage?.know) {
        return <Link to={routes.knowRoute(chatMessage.know.uuid)}>Know: {chatMessage.know.title}</Link>
    } else if (chatMessage?.attachmentUrl) {
        const isPDF = chatMessage.attachmentUrl.split('.').pop() === 'pdf'
        return (
            <>
                <ContentPreview
                    contentType={isPDF ? ContentTypes.pdf : ContentTypes.image}
                    previewUrl={chatMessage.attachmentUrl}
                />
                <p>{chatMessage.message}</p>
            </>
        )
    } else if (chatMessage?.voiceMessagePath) {
        return (
            <Stack>
                <audio src={chatMessage.voiceMessagePath} controls />
                <AudioPlayer src={chatMessage.voiceMessagePath} />
            </Stack>
        )
    } else if (chatMessage.type === 'USERS_JOINED') {
        return <p>{`➜ user joined the chat`}</p>
    } else if (chatMessage?.sharedChatUuid) {
        return (
            <Stack>
                <p>{`➜ shared chat: ${chatMessage.sharedChatUuid}`}</p>
                <p>{chatMessage.message}</p>
            </Stack>
        )
    } else {
        return textBasedOnMessage(chatMessage.message)
    }
}
