import React, { useCallback, useEffect, useState } from 'react'
import LoadingArea from 'components/elements/LoadingArea'
import { RouteComponentProps } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import styled from 'styled-components'
import GoBackButton from 'components/elements/GoBackButton'
import routes from 'lib/constants/routes'
import { SeoSubject } from 'interfaces/SeoSubject'
import SeoSubjectService from './SeoSubjectService'
import SeoSubjectsForm from './SeoSubjectsForm'
import { EditButton, MetaBox } from 'style'
import { useToggleState } from 'utils/hooks/useToggleState'
import Modal from 'components/modules/Modal'
import CopyIcon from 'components/elements/CopyIcon'
import HtmlContentPreview from '../HtmlContentPreview'
import SeoTopics from '../SeoTopic/SeoTopics'
import Meta from 'components/modules/Head'

interface Props extends RouteComponentProps<{ id: string }> {}

const loadingAreas = {
    container: 'subjectContainer',
}

const Container = styled.section`
    padding-bottom: 25px;
`

const SeoSubjectComponent = (props: Props) => {
    const [seoSubject, setSeoSubject] = useState<SeoSubject | null>(null)
    const [showEditForm, toggleEditForm] = useToggleState(false)
    const uuid = props.match.params.id
    const isNew = !uuid || uuid === 'create'

    const updateSeoSubject = (seoSubject: SeoSubject) => setSeoSubject(seoSubject)

    const handleFormUpdate = (seoSubject: SeoSubject) => {
        updateSeoSubject(seoSubject)
        showEditForm && toggleEditForm()
    }

    const fetchData = useCallback(() => {
        if (!uuid || isNew) return

        trackPromise(
            SeoSubjectService.get(uuid)
                .then((seoSubject) => {
                    updateSeoSubject(seoSubject)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting subject.')
                }),
            loadingAreas.container
        )
    }, [uuid, isNew])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <Container>
            <Meta title="SEO Subject" />
            <h2>SEO Subject</h2>
            <GoBackButton route={routes.seoSubjectsRoute} routeName="SEO Subjects" />
            {!isNew ? (
                <>
                    <Box>
                        <Stack>
                            <MetaBox>
                                <h3>Information</h3>
                                <EditButton onClick={toggleEditForm} />
                            </MetaBox>
                            <LoadingArea area={loadingAreas.container}>
                                {seoSubject && (
                                    <>
                                        <p>
                                            <strong>Uuid:</strong>&nbsp;
                                            {seoSubject.uuid}
                                            <CopyIcon text={seoSubject.uuid} />
                                        </p>
                                        <p>
                                            <strong>Slug:</strong>&nbsp;
                                            {seoSubject.slug}
                                        </p>
                                        <p>
                                            <strong>Is published:</strong>&nbsp;
                                            {seoSubject.isPublished ? 'Yes' : 'No'}
                                        </p>
                                        <p>
                                            <strong>Country:</strong>&nbsp;
                                            {seoSubject.country.englishName}
                                        </p>
                                        <p>
                                            <strong>Meta Title:</strong>&nbsp;
                                            {seoSubject.title}
                                        </p>
                                        <p>
                                            <strong>Keywords:</strong>&nbsp;
                                            {seoSubject.description}
                                        </p>
                                        <HtmlContentPreview htmlContent={seoSubject.contentHtml} />
                                    </>
                                )}
                            </LoadingArea>
                        </Stack>
                        <Modal show={showEditForm} title="Edit SEO Subject" onClose={toggleEditForm}>
                            <SeoSubjectsForm seoSubject={seoSubject} isNew={false} updateSeoSubject={handleFormUpdate} />
                        </Modal>
                    </Box>
                    {seoSubject ? (
                        <Box>
                            <SeoTopics seoSubjectUuid={seoSubject.uuid} />
                        </Box>
                    ) : null}
                </>
            ) : (
                <SeoSubjectsForm seoSubject={seoSubject} isNew={isNew} updateSeoSubject={updateSeoSubject} />
            )}
        </Container>
    )
}

export default SeoSubjectComponent
