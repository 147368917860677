import { AiChatBot, AiChatRequest, Category } from 'interfaces/AiChatBot'
import ApiService from './ApiService'

const AiChatBotService = {
    get(uuid: string) {
        return ApiService.get(`/ai-chats/bots/${uuid}`).then((response) => {
            return response.data as AiChatBot
        })
    },

    update(uuid: string, data: AiChatRequest) {
        return ApiService.patch(`/ai-chats/bots/${uuid}/admin`, data).then((response) => {
            return response.data as AiChatBot
        })
    },

    getCategories(countryId: number) {
        return ApiService.get(`/ai-chats/bots/categories?countryId=${countryId}`).then((response) => {
            return response.data.content as Category[]
        })
    },

    delete(uuid: string) {
        return ApiService.delete(`/ai-chats/bots/${uuid}/admin`)
    },
}

export default AiChatBotService
