import React from 'react'
import ReportContentPreview from 'components/modules/ContentPreview/ReportContentPreview'
import DiscussionForumQuestionCard from 'components/previews/DiscussionForumQuestionCard'
import ProfilePicture from 'components/previews/ProfilePicturePreview'
import theme from 'lib/constants/theme'
import CommentPreview from 'components/previews/CommentPreview'
import ComplexKnowCard from 'components/previews/ComplexKnowCard'
import DiscussionAnswerCard from 'components/previews/DiscussionAnswerCard'
import { Link } from 'react-router-dom'
import { ReportItem as ReportInterface, ReportType } from 'interfaces/Report'
import routes from 'lib/constants/routes'
import CopyIcon from 'components/elements/CopyIcon'
import Button from 'components/elements/Button'
import { AttachmentWrapper } from 'pages/Reports/Reports.css'
import { ReportedChatSnippet } from 'pages/Reports/ReportedChatMessages'
import ReportedChatInformation from './ReportedChatSnippet'
import Stack from 'components/elements/Stack'
import { formatDateTime } from 'utils'
import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'

export const renderContentSpecificParts = (report: ReportInterface | null, acceptReport: () => void) => {
    const emptyResponse = { information: null, actions: null }

    const areAllReportsAutomatic = !!report?.reports.every((report) => report.reportingUser === null)

    if (report == null) {
        return emptyResponse
    }

    switch (report.type) {
        case ReportType.know:
            if (!report.reportedKnow) return emptyResponse

            return {
                information: (
                    <>
                        <h3>Reported Know </h3>
                        <p>
                            <strong>ID:</strong>&nbsp;
                            <Link to={routes.knowRoute(report.reportedKnow.uuid)}>{report.reportedKnow.uuid}</Link>
                            <CopyIcon text={report.reportedKnow.uuid} />
                        </p>
                        <p>
                            <strong>Title:</strong>&nbsp;
                            {report.reportedKnow?.title ?? ''}
                        </p>
                        <p>
                            <strong>Description: </strong>
                            {report.reportedKnow?.description ?? ''}&nbsp;
                        </p>
                        <p>
                            <strong>Status: </strong> {report.reportedKnow?.status ?? ''}&nbsp;
                        </p>
                        <p>
                            <strong>Knower: </strong>
                            <Link to={routes.knowerRoute(report.reportedKnow.knower.uuid)}>
                                {`${report.reportedKnow.knower.user.name} (${report.reportedKnow.knower.uuid})`}
                            </Link>
                            <CopyIcon text={report.reportedKnow.knower.uuid} />
                        </p>
                        <ComplexKnowCard know={report.reportedKnow} />
                    </>
                ),
                actions: (
                    <>
                        {report.reportedKnow.status !== 'DRAFT' ? (
                            <>
                                <Button
                                    onClick={acceptReport}
                                    fullWidth={false}
                                    noMargin
                                    color="var(--red)"
                                    hoverColor="var(--red-dark)"
                                >
                                    Accept report (Set know as draft)
                                </Button>
                            </>
                        ) : (
                            <Button disabled fullWidth={false} noMargin>
                                The know has already been set as draft.
                            </Button>
                        )}
                    </>
                ),
            }

        case ReportType.question:
            if (!report.reportedDiscussionForumQuestion) return emptyResponse

            return {
                information: (
                    <>
                        <h3>Reported Discussion Forum Question</h3>
                        <p>
                            <strong>Uuid:</strong>&nbsp;
                            <Link to={routes.discussionForumQuestionRoute(report.reportedDiscussionForumQuestion.uuid)}>
                                {report.reportedDiscussionForumQuestion.uuid}
                            </Link>
                            <CopyIcon text={report.reportedDiscussionForumQuestion.uuid} />
                        </p>
                        <p>
                            <strong>User: </strong>&nbsp;
                            <Link to={routes.userRoute(report.reportedDiscussionForumQuestion.user.uuid)}>
                                {`${report.reportedDiscussionForumQuestion.user.name} (${report.reportedDiscussionForumQuestion.user.uuid})`}
                            </Link>
                            <CopyIcon text={report.reportedDiscussionForumQuestion.user.uuid} />
                        </p>
                        <p>
                            <strong>Attached Know: </strong>&nbsp;
                            {report.reportedDiscussionForumQuestion.know ? (
                                <>
                                    <Link to={routes.knowRoute(report.reportedDiscussionForumQuestion.know.uuid)}>
                                        {`${report.reportedDiscussionForumQuestion.know.title} (${report.reportedDiscussionForumQuestion.know.uuid})`}
                                    </Link>
                                    <CopyIcon text={report.reportedDiscussionForumQuestion.know.uuid} />
                                </>
                            ) : (
                                'None'
                            )}
                        </p>
                        {report.reportedDiscussionForumQuestion.attachments && (
                            <div>
                                <h3>Attachments</h3>
                                <AttachmentWrapper>
                                    {report.reportedDiscussionForumQuestion.attachments.map((attachment, index) => (
                                        <ReportContentPreview
                                            key={index}
                                            baseUuid={report.reportedDiscussionForumQuestion!.uuid}
                                            attachment={attachment}
                                            type="QUESTION"
                                        />
                                    ))}
                                </AttachmentWrapper>
                            </div>
                        )}
                        <DiscussionForumQuestionCard discussionForumQuestion={report.reportedDiscussionForumQuestion} />
                    </>
                ),
                actions: (
                    <Button
                        onClick={acceptReport}
                        fullWidth={false}
                        noMargin
                        color="var(--red)"
                        hoverColor="var(--red-dark)"
                    >
                        Accept report (Delete question)
                    </Button>
                ),
            }

        case ReportType.answer:
            if (!report.reportedDiscussionForumAnswer) return emptyResponse

            return {
                information: (
                    <>
                        <h3>Reported Discussion Forum Answer</h3>
                        <p>
                            <strong>User: </strong>&nbsp;
                            <Link to={routes.userRoute(report.reportedDiscussionForumAnswer.user.uuid)}>
                                {`${report.reportedDiscussionForumAnswer.user.name} (${report.reportedDiscussionForumAnswer.user.uuid})`}
                            </Link>
                            <CopyIcon text={report.reportedDiscussionForumAnswer.user.uuid} />
                        </p>
                        <p>
                            <strong>Question: </strong>
                            <Link
                                to={routes.discussionForumQuestionRoute(report.reportedDiscussionForumAnswer.questionUuid)}
                            >
                                {report.reportedDiscussionForumAnswer.questionUuid}
                            </Link>
                            <CopyIcon text={report.reportedDiscussionForumAnswer.questionUuid} />
                        </p>
                        <p>
                            <strong>Attached Know: </strong>&nbsp;
                            {report.reportedDiscussionForumAnswer.know ? (
                                <>
                                    <Link to={routes.knowRoute(report.reportedDiscussionForumAnswer.know.uuid)}>
                                        {`${report.reportedDiscussionForumAnswer.know.title} (${report.reportedDiscussionForumAnswer.know.uuid})`}
                                    </Link>
                                    <CopyIcon text={report.reportedDiscussionForumAnswer.know.uuid} />
                                </>
                            ) : (
                                'None'
                            )}
                        </p>
                        <div>
                            <h3>Attachments</h3>
                            <AttachmentWrapper>
                                {report.reportedDiscussionForumAnswer.attachments.map((attachment, index) => (
                                    <ReportContentPreview
                                        key={index}
                                        baseUuid={report.reportedDiscussionForumAnswer!.uuid}
                                        attachment={attachment}
                                        type="ANSWER"
                                    />
                                ))}
                            </AttachmentWrapper>
                        </div>
                        <DiscussionAnswerCard
                            answer={report.reportedDiscussionForumAnswer}
                            forumQuestionUuid={report.reportedDiscussionForumAnswer.questionUuid}
                        />
                    </>
                ),
                actions: (
                    <Button
                        onClick={acceptReport}
                        fullWidth={false}
                        noMargin
                        color="var(--red)"
                        hoverColor="var(--red-dark)"
                    >
                        Accept report (Delete answer)
                    </Button>
                ),
            }

        case ReportType.answerComment:
            if (!report.reportedDiscussionForumAnswerComment) return emptyResponse

            return {
                information: (
                    <>
                        <h3>Reported Answer Comment</h3>
                        <p>
                            <strong>Comment: </strong> {report.reportedDiscussionForumAnswerComment?.text ?? ''}
                            &nbsp;
                        </p>
                        <p>
                            <strong>Created on: </strong>
                            {report.reportedDiscussionForumAnswerComment.createdOn
                                ? formatDateTime(report.reportedDiscussionForumAnswerComment.createdOn)
                                : 'n/a'}
                            &nbsp;
                        </p>
                        <p>
                            <strong>User: </strong>&nbsp;
                            <Link to={routes.userRoute(report.reportedDiscussionForumAnswerComment.user.uuid)}>
                                {`${report.reportedDiscussionForumAnswerComment.user.name ?? ''} (${
                                    report.reportedDiscussionForumAnswerComment.user.uuid
                                })`}
                            </Link>
                            <CopyIcon text={report.reportedDiscussionForumAnswerComment.user.uuid} />
                        </p>
                    </>
                ),
                actions: (
                    <Button
                        onClick={acceptReport}
                        fullWidth={false}
                        noMargin
                        color="var(--red)"
                        hoverColor="var(--red-dark)"
                    >
                        Accept report (Delete comment)
                    </Button>
                ),
            }
        case ReportType.aiChatBot:
            if (!report.reportedAiChatBot) return emptyResponse

            return {
                information: (
                    <>
                        <h3>Reported AI Chat Bot</h3>
                        <p>
                            <strong>Name: </strong> {report.reportedAiChatBot.name}
                            &nbsp;
                        </p>
                        <p>
                            <strong>Description: </strong> {report.reportedAiChatBot.description}
                            &nbsp;
                        </p>
                        <p>
                            <strong>Creator of the bot: </strong>&nbsp;
                            <Link to={routes.userRoute(report.reportedAiChatBot.creatorUserUuid)}>
                                {report.reportedAiChatBot.creatorUserUuid}
                            </Link>
                            <CopyIcon text={report.reportedAiChatBot.creatorUserUuid} />
                        </p>
                        <p>
                            <strong>Avatar: </strong>&nbsp;
                            <br />
                        </p>
                        <img src={report.reportedAiChatBot.avatarImageUrl} width={250} height={250} alt="Chat bot avatar" />
                    </>
                ),
                actions: (
                    <Button
                        onClick={acceptReport}
                        fullWidth={false}
                        noMargin
                        color="var(--red)"
                        hoverColor="var(--red-dark)"
                    >
                        Accept report (Delete bot)
                    </Button>
                ),
            }

        case ReportType.comment:
            if (!report.reportedComment) return emptyResponse

            return {
                information: (
                    <>
                        <h3>Reported Comment</h3>
                        <p>
                            <strong>Content: </strong> {report.reportedComment.content ?? ''}
                            &nbsp;
                        </p>
                        <p>
                            <strong>ID: </strong>
                            {report.reportedComment.id ?? ''}
                            &nbsp;
                        </p>
                        <p>
                            <strong>User: </strong>&nbsp;
                            <Link to={routes.userRoute(report.reportedComment.user.uuid)}>
                                {`${report.reportedComment.user.name ?? ''} (${report.reportedComment.user.uuid})`}
                            </Link>
                            <CopyIcon text={report.reportedComment.user.uuid} />
                        </p>
                        <CommentPreview comment={report.reportedComment} />
                    </>
                ),
                actions: (
                    <Button
                        onClick={acceptReport}
                        fullWidth={false}
                        noMargin
                        color="var(--red)"
                        hoverColor="var(--red-dark)"
                    >
                        Accept report (Delete comment)
                    </Button>
                ),
            }

        case ReportType.chatMessage:
            if (!report.reportedChatMessage) return emptyResponse

            return {
                information: (
                    <>
                        <h3>Reported Chat Message</h3>

                        <p>
                            <strong>ID: </strong>
                            {report.reportedChatMessage.uuid}
                            &nbsp;
                        </p>

                        {report.reportedChatMessage.voiceMessagePath ? (
                            <p>
                                <Stack>
                                    <strong>Reported voice message: </strong>
                                    <audio src={report.reportedChatMessage.voiceMessagePath} controls />
                                    <AudioPlayer src={report.reportedChatMessage.voiceMessagePath} />
                                </Stack>
                                &nbsp;
                            </p>
                        ) : null}

                        {report?.reportedUser ? (
                            <p>
                                <strong>User: </strong>
                                <Link to={routes.userRoute(report.reportedUser.uuid)}>{report.reportedUser.uuid}</Link>
                                <CopyIcon text={report.reportedUser.uuid} />
                                &nbsp;
                            </p>
                        ) : null}
                        <p>
                            <ReportedChatSnippet
                                chatUUID={report.reportedChatMessage.chatUuid}
                                messageUUID={report.reportedChatMessage.uuid}
                                areAllReportsAutomatic={areAllReportsAutomatic}
                                reportedKnowerUUID={report.reportedChatMessage.user.uuid}
                            />
                        </p>
                    </>
                ),
                actions: (
                    <Button
                        onClick={acceptReport}
                        fullWidth={false}
                        noMargin
                        color="var(--red)"
                        hoverColor="var(--red-dark)"
                    >
                        Accept report (Delete comment)
                    </Button>
                ),
            }

        case ReportType.user:
            return {
                information: (
                    <>
                        <h3>Reported User</h3>
                        {report.reportedUser ? (
                            <>
                                <ProfilePicture user={report.reportedUser} size={75} borderColor={theme.colors.accentBlue} />
                                <p>
                                    <strong>Name: </strong> {report.reportedUser.name ?? ''}
                                    &nbsp;
                                </p>
                                <p>
                                    <strong>Username: </strong> {report.reportedUser.username ?? ''}
                                    &nbsp;
                                </p>
                                <p>
                                    <strong>Bio: </strong> {report.reportedUser.bio ?? '-'}
                                    &nbsp;
                                </p>
                                <p>
                                    <strong>ID: </strong>
                                    <Link to={routes.userRoute(report.reportedUser.uuid)}>{report.reportedUser.uuid}</Link>
                                    <CopyIcon text={report.reportedUser.uuid} />
                                    &nbsp;
                                </p>
                            </>
                        ) : (
                            <p>User was deleted</p>
                        )}
                    </>
                ),
                actions: (
                    <Button
                        onClick={acceptReport}
                        fullWidth={false}
                        noMargin
                        color="var(--red)"
                        hoverColor="var(--red-dark)"
                    >
                        Accept report
                    </Button>
                ),
            }

        case ReportType.chat:
            if (!report.reportedChatUuid) return emptyResponse

            return {
                information: (
                    <>
                        <h3>Reported Chat</h3>

                        <p>
                            <strong>Chat UUID: </strong>
                            {report.reportedChatUuid}
                        </p>
                        <p>
                            <strong>Reported user: </strong>
                            {report?.reportedUser ? (
                                <>
                                    <Link
                                        to={routes.userRoute(report.reportedUser.uuid)}
                                    >{`${report.reportedUser.name} (${report.reportedUser.uuid})`}</Link>
                                    <CopyIcon text={report.reportedUser.uuid} />
                                </>
                            ) : (
                                'User was deleted'
                            )}
                        </p>
                        <ReportedChatInformation
                            chatUuid={report.reportedChatUuid}
                            areAllReportsAutomatic={areAllReportsAutomatic}
                        />
                    </>
                ),
                actions: (
                    <Button
                        onClick={acceptReport}
                        fullWidth={false}
                        noMargin
                        color="var(--red)"
                        hoverColor="var(--red-dark)"
                    >
                        Accept report
                    </Button>
                ),
            }
    }

    return {
        information: <p>Report type not found</p>,
        actions: <p>Report type not found</p>,
    }
}
