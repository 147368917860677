import React, { useCallback, useEffect, useState } from 'react'
import LoadingArea from 'components/elements/LoadingArea'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import { formatDateTime, formatDateTimeUTC, formatTimeDifference, humanReadableTimeAgoLabel } from 'utils'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import ManualPushNotificationsService from 'services/ManualPushNotificationsService'
import { ManualPushNotification } from 'interfaces/ManualPushNotification'
import CopyIcon from 'components/elements/CopyIcon'
import Tabs from 'components/modules/Tabs'
import ManualPushNotificationsAction from './ManualPushNotificationsAction'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import CollapsibleCard from 'components/features/CollapsibleCard'
import ManualPushNotificationsForm from './ManualPushNotificationForm'
import { Tooltip, TooltipText } from 'style'
import routes from 'lib/constants/routes'
import ExplanationBanner from 'components/elements/ExplanationBanner'
import { useUserTargetingForm } from 'utils/hooks/useUserTargetingForm'
import ProgressBar from 'components/elements/ProgressBar'
import GoBackButton from 'components/elements/GoBackButton'
import Meta from 'components/modules/Head'
import ContentPreview from 'components/modules/ContentPreview/ContentPreview'
import { ContentTypes } from 'interfaces/Know'
import ManualCommunicationPlaceholder from 'pages/ManualCommunicationPlaceholder/ManualCommunicationPlaceholder'
import { ManualCommmunicationStatus } from 'interfaces/ManualCommunicationPlaceholder'

interface Props extends RouteComponentProps<{ id: string }> {}

const loadingAreas = {
    container: 'manualPushNotificationContainer',
    save: 'saveManualPushNotification',
}

const ManualPushNotificationComponent = (props: Props) => {
    const [manualPushNotification, setManualPushNotification] = useState<ManualPushNotification | null>(null)
    const { renderTargetingInformation } = useUserTargetingForm(manualPushNotification?.userTargetingId)

    const id = props.match.params.id
    const isNew = !id

    const fetchData = useCallback(() => {
        if (id) {
            trackPromise(
                ManualPushNotificationsService.get(id)
                    .then((notification) => {
                        setManualPushNotification(notification)
                    })
                    .catch((error: ApiError) => {
                        error.handleUnknown('An error occurred while getting manual push notifications.')
                    }),
                loadingAreas.container
            )
        }
    }, [id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const updateManualPushNotifications = (notification: ManualPushNotification) => setManualPushNotification(notification)

    const showProgress = manualPushNotification?.actualUsersCount && manualPushNotification.expectedUsersCount

    const manualPushNotificationsWorkflowSteps = [
        {
            label: '1. Fill in all the relevant information',
            target: routes.manualPushNotificationRoute(id, 'update'),
        },
        {
            label: '2. Ask someone from the team to approve it',
            target: routes.manualPushNotificationRoute(id, 'actions'),
        },
        {
            label: '3. Send it out yourself',
            target: routes.manualPushNotificationRoute(id, 'actions'),
        },
    ]

    return (
        <section>
            <Meta title="Manual Push Notification" />
            <h2>Manual Push Notification</h2>
            <GoBackButton route={routes.manualPushNotificationsRoute} routeName="Manual Push Notifications" />
            {!isNew && manualPushNotification && (
                <>
                    <ExplanationBanner
                        title="How does Manual Push Notifications work?"
                        text="In order to issue a manual push notification following steps have to be taken beforehand:"
                        options={manualPushNotificationsWorkflowSteps}
                    />
                    <Tabs>
                        <div data-label="General">
                            <Box>
                                <Stack>
                                    <h3>Information</h3>
                                    <LoadingArea>
                                        <p>
                                            <strong>Uuid:</strong>&nbsp;
                                            {manualPushNotification.uuid}
                                            <CopyIcon text={manualPushNotification.uuid} />
                                        </p>
                                        <p>
                                            <strong>Title:</strong>&nbsp;
                                            {manualPushNotification.title}
                                        </p>
                                        <p>
                                            <strong>Added by:</strong>&nbsp;
                                            {`${manualPushNotification.user.name} (${manualPushNotification.user.uuid})`}
                                            <CopyIcon text={manualPushNotification.user.uuid} />
                                        </p>
                                        <p>
                                            <strong>Approved by:</strong>&nbsp;
                                            {manualPushNotification.approvedByUser ? (
                                                <>
                                                    {`${manualPushNotification.approvedByUser.name} (${manualPushNotification.approvedByUser.uuid})`}
                                                    <CopyIcon text={manualPushNotification.approvedByUser.uuid} />
                                                </>
                                            ) : (
                                                'Not yet approved'
                                            )}
                                        </p>
                                        <p>
                                            <strong>Scheduled on:</strong>&nbsp;
                                            {manualPushNotification.scheduledOn
                                                ? `${formatDateTimeUTC(manualPushNotification.scheduledOn)}`
                                                : 'Not scheduled'}
                                        </p>
                                        <p>
                                            <strong>Approved on:</strong>&nbsp;
                                            {manualPushNotification.approvedOn
                                                ? formatDateTime(manualPushNotification.approvedOn)
                                                : 'Not yet approved'}
                                        </p>
                                        <p>
                                            <strong>Sent on:</strong>&nbsp;
                                            {manualPushNotification.sentOn
                                                ? formatDateTime(manualPushNotification.sentOn)
                                                : 'Not yet sent'}
                                        </p>
                                        <p>
                                            <strong>Type:</strong>&nbsp;
                                            {manualPushNotification.type}
                                            <Tooltip>
                                                <InfoIcon style={{ fill: 'white' }} />
                                                <TooltipText>Used for tracking</TooltipText>
                                            </Tooltip>
                                        </p>
                                        <p>
                                            <strong>Click path:</strong>&nbsp;
                                            {manualPushNotification.clickPath}
                                        </p>
                                        <p>
                                            <strong>Thumbnail Image:</strong>&nbsp;
                                            {manualPushNotification.thumbnailImageUrl ? (
                                                <ContentPreview
                                                    contentType={ContentTypes.image}
                                                    previewUrl={manualPushNotification.thumbnailImageUrl}
                                                />
                                            ) : (
                                                'none'
                                            )}
                                        </p>
                                        <p>
                                            <strong>Language:</strong>&nbsp;
                                            {manualPushNotification.language.englishName} (
                                            {manualPushNotification.language.code})
                                        </p>
                                        {manualPushNotification?.experimentVariantIdentifier ? (
                                            <p>
                                                <strong>Experiment Variant Identifier:</strong>&nbsp;
                                                {manualPushNotification.experimentVariantIdentifier}
                                            </p>
                                        ) : null}
                                        {manualPushNotification?.sound ? (
                                            <p>
                                                <strong>Sound:</strong>&nbsp;
                                                {manualPushNotification.sound}
                                            </p>
                                        ) : null}
                                        {showProgress ? (
                                            <p>
                                                <strong>{`Send Progress: (${manualPushNotification.status})`}</strong>&nbsp;
                                                <ProgressBar
                                                    max={manualPushNotification.expectedUsersCount!}
                                                    currentValue={manualPushNotification.actualUsersCount!}
                                                />
                                                {manualPushNotification.status === ManualCommmunicationStatus.Processing &&
                                                manualPushNotification.lastProcessedOn ? (
                                                    <p>
                                                        <strong>{`Last processed on:`}</strong>&nbsp;
                                                        {formatTimeDifference(manualPushNotification.lastProcessedOn, true)}
                                                        <br />
                                                        {humanReadableTimeAgoLabel(manualPushNotification.lastProcessedOn)}
                                                    </p>
                                                ) : null}
                                            </p>
                                        ) : null}
                                        <CollapsibleCard title="Manual notification body" openByDefault>
                                            <p>{manualPushNotification.body}</p>
                                        </CollapsibleCard>
                                    </LoadingArea>
                                </Stack>
                            </Box>
                            {renderTargetingInformation()}
                        </div>
                        <div data-label="Update">
                            <Box>
                                <ManualPushNotificationsForm
                                    manualPushNotification={manualPushNotification}
                                    isNew={isNew}
                                    updateManualPushNotification={updateManualPushNotifications}
                                />
                            </Box>
                        </div>
                        <div data-label="Actions">
                            <ManualPushNotificationsAction notification={manualPushNotification} fetchData={fetchData} />
                        </div>
                        <div data-label="Communication Placeholder">
                            <ManualCommunicationPlaceholder manualPushNotificationUuid={manualPushNotification.uuid} />
                        </div>
                    </Tabs>
                </>
            )}

            {isNew && (
                <ManualPushNotificationsForm
                    manualPushNotification={manualPushNotification}
                    isNew={isNew}
                    updateManualPushNotification={updateManualPushNotifications}
                />
            )}
        </section>
    )
}

export default withRouter(ManualPushNotificationComponent)
