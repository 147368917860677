import React, { useEffect, useRef, useState } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { badRequestFormErrors } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { useHistory } from 'react-router-dom'
import Button from 'components/elements/Button'
import { Checkbox, Input } from 'components/inputs/Input'
import routes from 'lib/constants/routes'
import Editor from 'components/modules/Editor/Editor'
import { UpdateSeoSubTopic, SeoSubTopic } from 'interfaces/SeoSubTopic'
import SeoSubTopicService from './SeoSubTopicService'
import { isConversionElementPresent, isHeading1Present, isSlugInvalid } from 'lib/features/glossary'

interface FormData {
    description: string
    slug: string
    isPublished: boolean
}

interface InitialSeoSubTopicData {
    title?: string | null
    slug?: string | null
    description?: string | null
}

interface Props {
    seoSubTopic: SeoSubTopic | null
    isNew: boolean
    updateSeoSubTopic: (seoSubTopic: SeoSubTopic) => void
    seoTopicUuid: string
    seoSubjectUuid: string
}

const loadingAreas = {
    form: 'subTopicsForm',
    save: 'saveSubTopic',
}

const SeoSubTopicForm = ({ isNew, seoSubTopic, updateSeoSubTopic, seoTopicUuid, seoSubjectUuid }: Props) => {
    const [initialData, setInitialData] = useState<InitialSeoSubTopicData>()
    const [htmlContent, setHtmlContent] = useState('')
    const formRef = useRef<FormHandles>(null)
    const history = useHistory()

    useEffect(() => {
        if (!seoSubTopic) return

        const initialData: InitialSeoSubTopicData = {
            ...seoSubTopic,
        }
        setHtmlContent(seoSubTopic.contentHtml)
        setInitialData(initialData)
    }, [seoSubTopic])

    const handleSubmit: SubmitHandler<FormData> = (data) => {
        formRef.current!.setErrors({})

        if (!htmlContent) {
            toast.error('Please enter the the content of the subtopic!')
            return
        }

        if (!isConversionElementPresent(htmlContent)) return

        if (isHeading1Present(htmlContent)) return

        if (isSlugInvalid(data.slug)) {
            formRef.current?.setFieldError(
                'slug',
                'Slug must consist of only lower case letters (a-z), numbers [0-9], and dashes (-)'
            )
            return
        }

        const requestBody: UpdateSeoSubTopic = {
            contentHtml: htmlContent,
            seoTopicUuid,
            title: seoSubTopic?.title ?? '',
            ...data,
        }

        let handleFunc
        if (!isNew && seoSubTopic?.uuid) {
            handleFunc = SeoSubTopicService.update(seoSubTopic.uuid, requestBody)
        } else {
            handleFunc = SeoSubTopicService.create(requestBody)
        }

        trackPromise(
            handleFunc
                .then((topic) => {
                    updateSeoSubTopic(topic)
                    toast.success('The changes to seo subtopic have been saved!')
                    history.push(routes.seoSubTopicRoute(seoSubjectUuid, seoTopicUuid, topic.uuid))
                })
                .catch((error: ApiError) => {
                    if (error.type === ErrorTypes.FormValidation) {
                        badRequestFormErrors(error, formRef.current!)
                    } else {
                        error.handleUnknown('An error occurred while saving subtopic.')
                    }
                }),
            loadingAreas.save
        )
    }

    return (
        <div>
            <h3>{isNew ? 'Create new' : 'Update'} Seo Subtopic</h3>
            <Form ref={formRef} initialData={initialData} onSubmit={handleSubmit}>
                <Input name="slug" placeholder="Slug (used for linking)" />
                <Checkbox name="isPublished" placeholder="Is published" />
                <Input name="description" placeholder="Keywords (separated by commas)" />
                <Editor value={htmlContent} onChange={setHtmlContent} />
                <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                    {isNew ? 'Save' : 'Update'}
                </Button>
            </Form>
        </div>
    )
}

export default SeoSubTopicForm
