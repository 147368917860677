import ApiService from 'services/ApiService'
import { DiscussionForumQuestion } from 'interfaces/DiscussionForumQuestion'
import { DiscussionForumAnswer } from 'interfaces/DiscussionForumAnswer'

const DiscussionForumQuestionService = {
    get(uuid: string) {
        return ApiService.get(`/discussion-forum/questions/${uuid}`).then((response) => {
            return response.data as DiscussionForumQuestion
        })
    },
    getAttachment(uuid: string, attachmentUuid: string) {
        return ApiService.get(`/discussion-forum/questions/${uuid}/attachments/${attachmentUuid}`, {
            responseType: 'blob',
        }).then((response) => {
            const blob = new Blob([response.data], {
                type: response.headers['content-type'],
            })
            return URL.createObjectURL(blob)
        })
    },
    getAnswers(uuid: string) {
        return ApiService.get(`/discussion-forum/questions/${uuid}/answers`).then((response) => {
            return response.data.content as DiscussionForumAnswer[]
        })
    },
    getAnswersAttachment(uuid: string, attachmentUuid: string) {
        return ApiService.get(`/discussion-forum/answers/${uuid}/attachments/${attachmentUuid}`, {
            responseType: 'blob',
        }).then((response) => {
            const blob = new Blob([response.data], {
                type: response.headers['content-type'],
            })
            return URL.createObjectURL(blob)
        })
    },
}

export default DiscussionForumQuestionService
