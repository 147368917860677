import React, { useCallback } from 'react'
import { formatAmount, formatDate } from 'utils'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import RefactoredTable from 'components/modules/RefactoredTable'
import TransactionService from 'services/TransactionService'
import routes from 'lib/constants/routes'
import Meta from 'components/modules/Head'

interface Props {}

const loadingAreas = {
    container: 'transactionContainer',
}

const Transactions = (props: Props) => {
    const fetchData = useCallback((page: number) => {
        return trackPromise(
            TransactionService.list(page)
                .then((data) => {
                    if (page !== data.page) return
                    return {
                        elements: data.transactions.map((t, i) => ({
                            id: `transaction-knower-${t.knower.uuid}-${i}`,
                            columns: [
                                t.type,
                                `@${t.knower.user.username}`,
                                formatAmount(t.amount, t.currency),
                                formatDate(t.createdOn),
                            ],
                            onClick: routes.knowerRoute(t.knower.uuid),
                        })),
                        totalPages: data.totalPages,
                        totalElements: data.totalElements,
                    }
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while getting transactions.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.container
        )
    }, [])

    return (
        <section>
            <Meta title="Transactions" />
            <h2>Transactions</h2>
            <RefactoredTable
                columns={['Type', 'Knower', 'Amount', 'Created on']}
                loadData={fetchData}
                loadingArea={loadingAreas.container}
                refetchKey={undefined}
            />
        </section>
    )
}

export default Transactions
