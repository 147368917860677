import theme from 'lib/constants/theme'
import React from 'react'
import ReactQuill from 'react-quill'
import styled from 'styled-components'
import { modules } from './constants'
import 'react-quill/dist/quill.snow.css'
import { Body2 } from 'components/elements/Text'
import Stack from 'components/elements/Stack'

interface Props {
    value: string
    onChange: (value: string) => void
}

const Container = styled.div`
    position: relative;
    .ql-toolbar .ql-stroke {
        fill: none;
        stroke: ${theme.colors.white};
    }

    .ql-toolbar .ql-fill {
        fill: ${theme.colors.white};
        stroke: none;
    }

    .ql-toolbar .ql-picker {
        color: ${theme.colors.black};
    }
    .ql-picker-label {
        color: ${theme.colors.white};
    }
    .ql-toolbar {
        border-radius: 10px 10px 0 0 !important;
    }
    .ql-container {
        border-radius: 0 0 10px 10px !important;
    }
    .ql-snow {
        border: 2px solid ${theme.colors.violet} !important;
        background-color: ${theme.colors.lightViolet};
    }
    * {
        text-align: unset;
    }
    .word-counter {
        position: absolute;
        right: 10px;
        top: 10px;
        color: ${theme.colors.placeholderGray};
    }
`

const ConversionExplanation = styled.div`
    border: 2px solid ${theme.colors.buttonTextBlack} !important;
    box-shadow: 4px 4px 0px ${theme.colors.buttonTextBlack};
    background-color: ${theme.colors.lightViolet};
    border-radius: ${theme.borderRadius.normal};
    padding: 5px 10px;
`

const Editor = ({ value, onChange }: Props) => {
    const getWordCount = (text: string) =>
        text
            .replace(/[-,;:!?]/g, ' ')
            .trim()
            .replace(/&nbsp;/g, '')
            .split(/\s+/g).length

    const extractKeywords = () => {
        const matches = value.matchAll(/<strong>(.*?)<\/strong>/g)

        return Array.from(matches)
            .map((value) => value[1])
            .join(' ')
    }

    const getKeywordsDensityString = () => {
        const keywordCount = getWordCount(extractKeywords())
        const wordCount = getWordCount(value)
        const density = ((keywordCount / wordCount) * 100).toFixed(2)
        return `Keywords: ${keywordCount}/${wordCount} (${density}%)`
    }

    return (
        <Stack>
            <ConversionExplanation>
                <Body2 color={theme.colors.placeholderGray}>
                    <b>Reminder:</b>{' '}
                    {`each article should contain a conversion banner. You can add this element by placing {{conversion}} in the editor. This element should be placed right before the 3rd Heading 2 element.`}
                </Body2>
            </ConversionExplanation>
            <Container className="text-editor">
                <Body2 className="word-counter">{getKeywordsDensityString()}</Body2>
                <ReactQuill theme="snow" value={value} onChange={onChange} modules={modules} />
            </Container>
        </Stack>
    )
}

export default Editor
