import React from 'react'
import { AdsAsset, AdsAssetContentType } from 'interfaces/AdsAsset'

function colorScale(value: number) {
    if (value < -5000) {
        return 'red'
    } else if (value > 5000) {
        return 'green'
    }

    return undefined
}

export function renderViewHealth(asset: AdsAsset) {
    const roundedTimeProgress = Math.round(asset.stats.timeProgress * 100)
    const title = `Time progress: ${roundedTimeProgress} %\nIdeal views: ${asset.stats.idealViews}\nActual views: ${asset.views}`

    return (
        <span key={asset.uuid} style={{ color: colorScale(asset.stats.viewDifference) }} title={title}>
            {asset.stats.viewDifference}
        </span>
    )
}

export function renderViewProgress(asset: AdsAsset) {
    const title = `Actual views: ${asset.views}\nTarget views: ${asset.stats.targetViews}`

    return (
        <span title={title}>
            {asset.views} / {asset.stats.targetViews}
        </span>
    )
}

export function renderPreviewInTable(asset: AdsAsset) {
    if (asset.contentType === AdsAssetContentType.Image && asset.imageUrl) {
        return <img src={asset.imageUrl} width={80} alt={asset.name} key={asset.uuid} />
    } else if (asset.contentType === AdsAssetContentType.Video && asset.imageUrl) {
        return (
            <video autoPlay playsInline muted height="100%" width={80} loop>
                <source src={asset.imageUrl} type="video/mp4" />
            </video>
        )
    } else if (asset.contentType === AdsAssetContentType.Know && asset.knowUuid) {
        return 'Contains know'
    } else {
        return 'Empty'
    }
}
