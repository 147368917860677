import React, { useEffect } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import Button from 'components/elements/Button'
import { Body2, ButtonText, Headline5 } from 'components/elements/Text'
import { Input } from 'components/inputs/Input'
import Modal from 'components/modules/Modal'
import { useRef, useState } from 'react'
import { useToggleState } from 'utils/hooks/useToggleState'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import CollapsibleCard from 'components/features/CollapsibleCard'
import theme from 'lib/constants/theme'
import styled from 'styled-components'
import Row from 'components/elements/Row'
import { CareerPartnerPost, CareerPartnerPostRequest } from 'interfaces/CareerPartner'
import CareerPartnerService from 'services/CareerPartnerService'
import { ApiError } from 'services/ApiService'
import { badRequestFormErrors, formatDateTime } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import UploadWrapper from 'components/FileUpload/UploadWrapper'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import FileUploadService from 'services/FileUploadService'
import { ContentPrefixes } from 'utils/constants/ContentPrefix'

const ButtonRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    button {
        height: 30px;
    }
`

const loadingAreas = {
    imageUpload: 'imageUpload',
}

interface Props {
    careerPartnerUuid: string
}

const CareerPartnerPostsForm = ({ careerPartnerUuid }: Props) => {
    const postFormRef = useRef<FormHandles>(null)
    const [selectedPost, setSelectedPost] = useState<(CareerPartnerPostRequest & { uuid: string }) | null>(null)
    const [showPostForm, togglePostForm] = useToggleState(false)
    const [posts, setPosts] = useState<CareerPartnerPost[]>()
    const [previewLink, setPreviewLink] = useState<string | null>()

    useEffect(() => {
        CareerPartnerService.getPosts(careerPartnerUuid)
            .then((data) => setPosts(data.posts))
            .catch((error: ApiError) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('An error occurred while getting career partner posts.')
                } else {
                    throw error
                }
            })
    })

    const handleEditPost = (post: CareerPartnerPost, index: number) => {
        const request: CareerPartnerPostRequest & { uuid: string } = {
            clickPath: post.clickPath,
            title: post.title,
            previewImageFilename: post.previewImageFilePath,
            uuid: post.uuid,
        }
        setPreviewLink(post.previewImageFilePath)
        setSelectedPost(request)
        togglePostForm()
    }

    const handleDeletePost = (uuidToDelete: string) => {
        CareerPartnerService.deletePost(careerPartnerUuid, uuidToDelete)
            .then(() => {
                toast.success(`Post was deleted`)
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('An error occurred while deleting ai chat bot.')
                } else {
                    throw error
                }
            })
    }

    const onCareerIconChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const innerFile = event.target.files![0]
        if (!innerFile) {
            toast.error('You need to upload an image!')
            return
        }
        const maxFileSize = 25 * 1000000

        if (!['image/png', 'image/webp', 'image/jpeg'].includes(innerFile.type)) {
            toast.error('Please upload your file as a .png, .jpg, or .webp!')
            return
        }

        if (innerFile.size > maxFileSize) {
            toast.error('Sorry, the file is too big. The maximum file size is 25 MB.')
            return
        }

        trackPromise(
            FileUploadService.upload(innerFile, ContentPrefixes.CustomerPostPreviewImage)
                .then(({ url }) => {
                    setPreviewLink(url)
                    postFormRef.current?.setFieldValue('previewImageFilename', url)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.InvalidMIMEType) {
                            toast.error('Please choose a different file format.')
                        } else {
                            error.handleUnknown('An error occurred while uploading the image')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.imageUpload
        )
    }

    const handlePostFormSubmit: SubmitHandler<CareerPartnerPostRequest> = ({ previewImageFilename, ...postData }) => {
        postFormRef.current!.setErrors({})

        if (!postData.title) {
            postFormRef.current!.setFieldError('title', 'This field is required!')
            return
        }

        if (!previewImageFilename) {
            postFormRef.current!.setFieldError('previewImageFilename', 'This field is required!')
            return
        }

        const requestData = {
            previewImageFilename: previewImageFilename.split('/').pop() as string,
            ...postData,
        }

        let handleFunc

        if (selectedPost) {
            handleFunc = CareerPartnerService.updatePost(careerPartnerUuid, selectedPost.uuid, requestData)
        } else {
            handleFunc = CareerPartnerService.createPost(careerPartnerUuid, requestData)
        }

        handleFunc
            .then(() => {
                onClose()
                toast.success('Post was added')
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    if (error.type === ErrorTypes.FormValidation) {
                        badRequestFormErrors(error, postFormRef.current!)
                    } else {
                        error.handleUnknown('An error occurred while adding a career partner post.')
                    }
                } else {
                    throw error
                }
            })
    }

    const onClose = () => {
        togglePostForm()
        setSelectedPost(null)
        setPreviewLink(null)
    }

    return (
        <>
            <Modal show={showPostForm} title={selectedPost ? 'Update post item' : 'Add post item'} onClose={onClose}>
                <Form ref={postFormRef} onSubmit={handlePostFormSubmit} initialData={{ ...selectedPost }}>
                    <Input name="title" placeholder="Title" />
                    <Input name="clickPath" placeholder="Click path" />
                    <Stack>
                        <UploadWrapper
                            handleFileUpload={onCareerIconChangeFile}
                            area={loadingAreas.imageUpload}
                            acceptRules="image/png, image/webp, image/jpeg"
                        >
                            <p>Click here to select Career Partner logo.</p>
                        </UploadWrapper>
                        {previewLink ? <img width={200} height="auto" src={previewLink} alt="post preview" /> : null}
                        <Input name="previewImageFilename" placeholder="Preview image filepath" />
                    </Stack>

                    <Button fullWidth icon={<SaveIcon />} type="submit">
                        Save
                    </Button>
                </Form>
            </Modal>
            <Box>
                <Stack>
                    <ButtonRow>
                        <Headline5 color={theme.colors.white} marginBottom={10}>
                            {`Posts`}
                        </Headline5>
                        <Button fullWidth={false} onClick={togglePostForm} icon={<AddIcon />} type="button">
                            <ButtonText color={theme.colors.white}>Add post</ButtonText>
                        </Button>
                    </ButtonRow>

                    {posts?.length ? (
                        <>
                            {posts.map((post, i) => (
                                <CollapsibleCard
                                    key={i}
                                    title={
                                        <ButtonRow>
                                            <Body2 color={theme.colors.white}>{`${i + 1}. Title: ${post.title}`}</Body2>
                                            <Row gap={20}>
                                                <Button
                                                    fullWidth={false}
                                                    onClick={() => handleDeletePost(post.uuid)}
                                                    type="button"
                                                    icon={<DeleteIcon />}
                                                    color={theme.colors.secondaryRed}
                                                    hoverColor={theme.colors.secondaryRedDarker}
                                                >
                                                    Delete
                                                </Button>
                                                <Button
                                                    fullWidth={false}
                                                    onClick={() => handleEditPost(post, i)}
                                                    type="button"
                                                    icon={<EditIcon />}
                                                >
                                                    Edit
                                                </Button>
                                            </Row>
                                        </ButtonRow>
                                    }
                                    openByDefault={false}
                                >
                                    <Body2
                                        color={theme.colors.white}
                                        fontWeigth={500}
                                    >{`Clickpath: ${post.clickPath}`}</Body2>
                                    <Body2 color={theme.colors.white} fontWeigth={500}>{`Created on: ${formatDateTime(
                                        post.createdOn
                                    )}`}</Body2>
                                    <br />
                                    {post.previewImageFilePath ? (
                                        <img src={post.previewImageFilePath} height={200} alt="File path" />
                                    ) : null}
                                </CollapsibleCard>
                            ))}
                        </>
                    ) : (
                        <Body2>No post items were added</Body2>
                    )}
                </Stack>
            </Box>
        </>
    )
}

export default CareerPartnerPostsForm
