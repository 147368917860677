import ApiService from './ApiService'
import {
    CareerPartner,
    CareerPartnerList,
    CareerPartnerPost,
    CareerPartnerPostRequest,
    CareerPartnerRequest,
} from 'interfaces/CareerPartner'

const CareerPartnerService = {
    listCareerPartners() {
        return ApiService.get(`/careers/partners`).then((response) => {
            return response.data.content as CareerPartnerList[]
        })
    },

    get(uuid: string) {
        return ApiService.get(`/careers/partners/${uuid}/admin`).then((response) => {
            return response.data as CareerPartner
        })
    },

    create(careerPartner: CareerPartnerRequest) {
        return ApiService.post('/careers/partners', careerPartner).then((response) => {
            return response.data as CareerPartner
        })
    },

    update(uuid: string, data: CareerPartnerRequest) {
        return ApiService.patch(`/careers/partners/${uuid}`, data).then((response) => {
            return response.data as CareerPartner
        })
    },

    delete(uuid: string) {
        return ApiService.delete(`/careers/partners/${uuid}`)
    },

    // posts
    getPosts(partnerUuid: string) {
        return ApiService.get(`/careers/partners/${partnerUuid}/posts`).then((response) => {
            return {
                posts: response.data.content as CareerPartnerPost[],
                nextCursor: response.data.nextCursor as string,
            }
        })
    },

    createPost(partnerUuid: string, postRequest: CareerPartnerPostRequest) {
        return ApiService.post(`/careers/partners/${partnerUuid}/posts`, postRequest).then((response) => {
            return response.data as CareerPartnerPost
        })
    },

    updatePost(partnerUuid: string, postUuid: string, postRequest: CareerPartnerPostRequest) {
        return ApiService.patch(`/careers/partners/${partnerUuid}/posts/${postUuid}`, postRequest).then((response) => {
            return response.data as CareerPartnerPost
        })
    },

    deletePost(partnerUuid: string, postUuid: string) {
        return ApiService.delete(`/careers/partners/${partnerUuid}/posts/${postUuid}`)
    },
}

export default CareerPartnerService
