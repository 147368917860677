export interface Competition {
    countryId: number
    createdOn: string
    customKey: string | null
    daysRemaining: number
    detailsPageHeading: string
    detailsPageText: string | null
    detailsPageTextHeading: string | null
    detailsPageButtonText: string
    detailsPageButtonLink: string
    enableLeaderboard: false
    endsOn: string
    faqs: CompetitionFAQ[]
    name: string
    previewCardButtonText: string
    previewCardHeading: string | null
    previewCardText: string | null
    previewCardLink: string
    prizes: CompetitionPrize[]
    shortDescription: string
    showContentInHomeScreen: boolean
    showTop3: boolean
    startsOn: string
    uuid: string
    visibleFrom: string
    visibleUntil: string
    weeklyReset: boolean
    contentType: CompetitionContentType | null
    type: CompetitionType
    uploadButtonType: CompetitionUploadButtonType | null
    userTargetingId: number | null
    winners: CompetitionWinners[]
    unit: string
}

export interface CompetitionRequest {
    contentType: CompetitionContentType | null
    countryId: number
    customKey: string | null
    name: string
    startsOn: string
    endsOn: string
    visibleFrom: string
    visibleUntil: string
    type: CompetitionType
    userTargetingID: number | null

    detailsPageHeading: string
    detailsPageText: string | null
    detailsPageTextHeading: string | null
    detailsPageButtonText: string
    detailsPageButtonLink: string

    previewCardButtonText: string
    previewCardHeading: string | null
    previewCardText: string | null

    showContentInHomeScreen: boolean
    showTop3: boolean
    weeklyReset: boolean
    enableLeaderboard: boolean

    prizes: CompetitionPrize[]
    faqs: CompetitionFAQ[]
    winners: CompetitionWinners[]
}

export enum CompetitionContentType {
    //KnowContentCompetition = 'KNOW',
    KnowerContentCompetition = 'KNOWER',
    //UserContentCompetition = 'USER',
}

export enum CompetitionType {
    KnowUploads = 'KNOW_UPLOADS',
    SchoolGroupChat = 'SCHOOL_GROUP_CHAT',
}

export enum CompetitionUploadButtonType {
    KnowCreate = 'KNOW_CREATE',
}

export type CompetitionFAQ = {
    answer: string
    question: string
}

export type CompetitionPrize = {
    podiumText: string
    rankingPosition: number
    shortText: string
    text: string
    title: string
    type: string
}

export type CompetitionWinners = {
    podiumText: string
    rankingPosition: number
    shortText: string
    text: string
    title: string
    type: string
}
