import React, { useEffect, useRef, useState } from 'react'
import { Form } from '@unform/web'
import CSVImporter, { CSVData } from 'components/FileUpload/CSVImport'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { FormHandles } from '@unform/core'
import { CurriculumAnalysisType } from 'interfaces/CurriculumAnalysis'
import { HeaderLabels, processCurriculumCSVData, STANDARD_HEADERS } from 'lib/features/curriculum'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import Button from 'components/elements/Button'
import { ButtonText } from 'components/elements/Text'
import { NoWrapText } from 'style'
import Table, { TableData } from 'components/modules/Table'
import SearchService from 'services/SearchService'
import theme from 'lib/constants/theme'
import Meta from 'components/modules/Head'

const CurriculumAnalysisComponent = () => {
    const [curriculum, setCurriculum] = useState<CurriculumAnalysisType[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const curriculumFormRef = useRef<FormHandles>(null)
    const [tableData, setTableData] = useState<TableData[]>()

    const handleCSVUploadComplete = async (csvData: CSVData[]) => {
        setIsLoading(true)
        const headers = Object.keys(csvData[0])
        const headerLabels = Object.values(HeaderLabels)

        if (headers.length !== STANDARD_HEADERS.length) {
            toast.warn(
                'Make sure to put correct headers (Search term, Language code, ...) as the first row of the CSV file!'
            )
            return
        }

        for (let i = 0; i < headerLabels.length; i++) {
            if (headers[i] !== headerLabels[i]) {
                toast.warn(
                    `${headers[i]} is not part of the standard headers (Search term, Language code, ...), check for typos!`
                )
                return
            }
        }

        const formattedCurriculumArray = await processCurriculumCSVData(csvData, toast)

        if (!formattedCurriculumArray || !formattedCurriculumArray.length) {
            toast.info('No curriculum data found')
            return
        }

        const progressText = document.getElementById('title')
        let count = 0
        const chunkSize = 50
        formattedCurriculumArray.unshift()
        for (let i = 0; i < formattedCurriculumArray.length; i += chunkSize) {
            const chunk = formattedCurriculumArray.slice(i, i + chunkSize)

            await SearchService.bulkCurriculumSearch(chunk, 'curriculumAnalysisOnAdmin')
                // eslint-disable-next-line no-loop-func
                .then((curriculum) => {
                    setCurriculum((prevCurriculum) => (prevCurriculum ? [...prevCurriculum, ...curriculum] : curriculum))
                    count += curriculum.length
                })
                .catch((error: ApiError) => {
                    error.handleUnknown(`An error occurred while adding search terms to Curriculum Analysis.`)
                })

            if (progressText) progressText.textContent = `Loading... Progress: ${i}/${csvData.length - 1}`
        }
        const text = `Imported ${count} curriculum rows.`
        if (progressText) progressText.textContent = text
        toast.info(text)
        setIsLoading(false)
    }

    const handleCreateTargetingSubmit = () => {
        let csv = Object.values(HeaderLabels).join(',') + ',Results Count\n'

        curriculum.forEach((row) => {
            csv += Object.values(row).join(',')
            csv += '\n'
        })

        const csvFile = new Blob([csv], { type: 'text/csv' })

        const link = document.createElement('a')
        link.setAttribute('download', 'csv.csv')
        link.href = window.URL.createObjectURL(csvFile)
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

    useEffect(() => {
        if (!curriculum?.length) return
        const table = curriculum.map((c, id) => ({
            id,
            columns: [
                <NoWrapText key={`term-${id}`}>{c.searchTerm}</NoWrapText>,
                c.contentLanguageCode,
                c.subjectName,
                c.schoolTypeName,
                c.gradeLevel,
                c.countryCode ?? 'not specified',
                <NoWrapText key={`results-${id}`}>{c.resultCount}</NoWrapText>,
            ],
        }))
        setTableData(table)
    }, [curriculum, setTableData])

    return (
        <section>
            <Meta title="Curriculum Analysis" />
            <Stack>
                <h2>Curriculum Analysis</h2>
                <Box>
                    <strong>Requirements:</strong>
                    <ul>
                        <li>
                            {`First row of the CSV file must contain correct Column Name: ${Object.values(HeaderLabels).join(
                                ', '
                            )} (in this order)`}
                        </li>
                        <li>{`All cell data values must be an exact match with values stored in the database`}</li>
                    </ul>
                    <br />
                    <strong>Example document:</strong>&nbsp;
                    <a href="/files/CurriculumAnalysisDemo.csv" download="example_curriculum.csv">
                        Download
                    </a>
                    <Form ref={curriculumFormRef} onSubmit={handleCreateTargetingSubmit}>
                        <br />

                        <CSVImporter
                            title="CSV Upload area for user curriculum data (not replaced)"
                            handleCSVUploadComplete={handleCSVUploadComplete}
                            showHeader
                        />
                        {curriculum.length && !isLoading ? (
                            <Button fullWidth>
                                <ButtonText color={theme.colors.white}>Download Curriculum Results CSV</ButtonText>
                            </Button>
                        ) : null}
                    </Form>
                    {tableData?.length ? (
                        <Table
                            columns={[
                                'Search term',
                                'Language code',
                                'Subject',
                                'School type',
                                'Grade level',
                                'Country code',
                                'Result count',
                            ]}
                            data={tableData}
                            page={0}
                            totalPages={1}
                            totalElements={tableData.length}
                        />
                    ) : null}
                </Box>
            </Stack>
        </section>
    )
}

export default CurriculumAnalysisComponent
