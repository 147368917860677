import React, { useCallback, useEffect, useState } from 'react'
import LoadingArea from 'components/elements/LoadingArea'
import { RouteComponentProps } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import GoBackButton from 'components/elements/GoBackButton'
import routes from 'lib/constants/routes'
import { Competition } from 'interfaces/Competition'
import CompetitionService from './CompetitionService'
import CompetitionForm from './CompetitionForm'
import CopyIcon from 'components/elements/CopyIcon'
import Meta from 'components/modules/Head'

interface Props extends RouteComponentProps<{ uuid: string }> {}

const loadingAreas = {
    container: 'competitionContainer',
}

const CompetitionComponent = (props: Props) => {
    const [competition, setCompetition] = useState<Competition | null>(null)
    const uuid = props.match.params.uuid
    const isNew = !uuid

    const updateFeedbackDialog = (feedback: Competition) => setCompetition(feedback)

    const fetchData = useCallback(() => {
        if (!uuid) return

        trackPromise(
            CompetitionService.get(uuid)
                .then((competition) => {
                    setCompetition(competition)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting competition.')
                }),
            loadingAreas.container
        )
    }, [uuid])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <section>
            <Meta title="Competition" />
            <h2>Competition</h2>
            <GoBackButton route={routes.competitionsRoute} routeName="Competitions" />
            {!isNew && (
                <Box>
                    <Stack>
                        <h3>Information</h3>
                        <LoadingArea area={loadingAreas.container}>
                            {competition && (
                                <>
                                    <p>
                                        <strong>Uuid:</strong>&nbsp;
                                        {competition.uuid}
                                        <CopyIcon text={competition.uuid} />
                                    </p>
                                    <p>
                                        <strong>Name:</strong>&nbsp;
                                        {competition.name}
                                    </p>
                                    <p>
                                        <strong>Starts on:</strong>&nbsp;
                                        {new Date(competition.startsOn).toUTCString()}
                                    </p>
                                    <p>
                                        <strong>Ends on:</strong>&nbsp;
                                        {new Date(competition.endsOn).toUTCString()}
                                    </p>
                                    <p>
                                        <strong>Content Type:</strong>&nbsp;
                                        {competition.contentType}
                                    </p>
                                </>
                            )}
                        </LoadingArea>
                    </Stack>
                </Box>
            )}
            <CompetitionForm
                competitionUuid={uuid}
                isNew={isNew}
                competition={competition}
                updateCompetition={updateFeedbackDialog}
            />
        </section>
    )
}

export default CompetitionComponent
