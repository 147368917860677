import React, { useEffect, useRef, useState } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { badRequestFormErrors } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import Button from 'components/elements/Button'
import { FormSelect, SelectMultiple, SelectOption } from 'components/inputs/Input'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import { AiChatBot, AiChatRequest } from 'interfaces/AiChatBot'
import AiChatBotService from 'services/AiChatBotService'

const loadingAreas = {
    delete: 'deleteAiBot',
    save: 'saveAiBot',
}

export interface BotFormData {
    isApproved: SelectOption<boolean | null>
    categories: SelectOption<string>[]
}

interface Props {
    bot: AiChatBot
    botUuid: string
    updateBot: (bot: AiChatBot) => void
}

const AiChatBotForm = ({ bot, botUuid, updateBot }: Props) => {
    const [categoriesOption, setCategoryOptions] = useState<{ label: string; identifier: string; data: string }[]>([])
    const formRef = useRef<FormHandles>(null)

    useEffect(() => {
        if (!bot) return

        AiChatBotService.getCategories(bot.countryId)
            .then((categories) => {
                if (!categories) return
                setCategoryOptions(categories.map((c) => ({ label: c.name, identifier: c.uuid, data: c.uuid })))
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('Unable to list bot categories.')
                    return
                } else {
                    throw error
                }
            })
    }, [bot])

    const handleSubmit: SubmitHandler<BotFormData> = ({ categories, isApproved }) => {
        formRef.current!.setErrors({})

        const requestBody: AiChatRequest = {
            isApproved: isApproved.data,
            categoryUuids: categories?.length ? categories.map((c) => c.data) : [],
        }

        trackPromise(
            AiChatBotService.update(botUuid, requestBody)
                .then((bot) => {
                    toast.success(`Bot has been updated`)
                    updateBot(bot)
                })
                .catch((error: ApiError) => {
                    if (error.type === ErrorTypes.FormValidation) {
                        badRequestFormErrors(error, formRef.current!)
                    } else {
                        error.handleUnknown('An error occurred while updating the chat bot.')
                    }
                }),
            loadingAreas.save
        )
    }

    const approveOptions = [
        { label: 'Not set', identifier: 'not-set', data: null },
        { label: 'Yes (approved)', identifier: 'approved', data: true },
        { label: 'No (rejected)', identifier: 'rejected', data: false },
    ]

    return (
        <Box>
            <Stack>
                <h3>Update Chat Bot</h3>
                <Form
                    ref={formRef}
                    initialData={{
                        isApproved: approveOptions.find((option) => option.data === bot.isApproved),
                        categories: bot.categories.map((c) => ({ label: c.name, identifier: c.uuid, data: c.uuid })),
                    }}
                    onSubmit={handleSubmit}
                >
                    {categoriesOption && (
                        <SelectMultiple<string> name="categories" options={categoriesOption} label="Categories" />
                    )}
                    <FormSelect name="isApproved" options={approveOptions} placeholder="" noMargin={false} />
                    <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                        {'Save'}
                    </Button>
                </Form>
            </Stack>
        </Box>
    )
}

export default AiChatBotForm
