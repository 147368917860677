import ApiService from 'services/ApiService'
import { KnowType, UpdateKnowType } from 'interfaces/Know'
import { LanguageCode } from 'interfaces/Language'

const KnowTypeService = {
    list(language: number | LanguageCode) {
        return ApiService.get(`/languages/${language}/knowtypes`).then((response) => {
            return response.data.content as KnowType[]
        })
    },

    getAll() {
        return ApiService.get(`/knowtypes`).then((response) => {
            return response.data.content as KnowType[]
        })
    },

    get(id: number) {
        return ApiService.get(`/knowtypes/${id}`).then((response) => {
            return response.data as KnowType
        })
    },

    create(knowType: UpdateKnowType) {
        return ApiService.post(`/knowtypes`, knowType).then((response) => {
            return response.data as KnowType
        })
    },

    update(id: number, knowType: UpdateKnowType) {
        return ApiService.patch(`/knowtypes/${id}`, knowType).then((response) => {
            return response.data as KnowType
        })
    },
}

export default KnowTypeService
