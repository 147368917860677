import React, { useCallback, useEffect, useState } from 'react'
import Table, { TableData } from 'components/modules/Table'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import Stack from 'components/elements/Stack'
import routes from 'lib/constants/routes'
import KnowTypeService from 'services/KnowTypeService'
import LanguageService from 'services/LanguageService'
import Meta from 'components/modules/Head'

interface Props {}

const loadingAreas = {
    container: 'subjectsContainer',
}

const KnowTypes = (props: Props) => {
    const [data, setTableData] = useState<TableData[]>([])

    const fetchData = useCallback(async () => {
        const languages = await trackPromise(
            LanguageService.list(true)
                .then((languages) => {
                    return languages
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting languages.')
                }),
            loadingAreas.container
        )
        if (!languages) return
        trackPromise(
            KnowTypeService.getAll()
                .then((knowTypes) => {
                    const tableData = knowTypes.map((types) => ({
                        id: types.id,
                        columns: [
                            types.id,
                            types.name,
                            languages.find((language) => language.id === types.languageId)?.englishName,
                        ],
                        onClick: routes.knowTypeRoute(types.id),
                    }))
                    setTableData(tableData)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting know types.')
                }),
            loadingAreas.container
        )
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <section>
            <Meta title="Know Types" />
            <Stack gutter={10}>
                <h2>Know Types</h2>
                <Table
                    columns={['#', 'Name', 'Language']}
                    data={data}
                    page={0}
                    totalPages={1}
                    totalElements={1}
                    setPage={() => null}
                    area={loadingAreas.container}
                />
            </Stack>
        </section>
    )
}

export default KnowTypes
