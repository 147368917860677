import styled from 'styled-components'

export const FormWrapper = styled.div`
    display: flex;
`

export const AttachmentWrapper = styled.div`
    display: flex;
`

export const SelectWrapper = styled.div`
    flex: 1;
    padding: 10px;
    select {
        height: 41px;
    }
`

export const SearchWrapper = styled.div`
    flex: 1;
    padding: 10px;
    margin-top: 27px;
`
