import { ModerationKeyword, ModerationKeywordType, ModerationKeywordUpdateRequest } from 'interfaces/ModerationKeyword'
import ApiService from './ApiService'
import { LanguageCode } from 'interfaces/Language'

const ModerationKeywordsService = {
    list(page: number, type: ModerationKeywordType, languageCode: LanguageCode | null) {
        return ApiService.get(
            `/moderation-keywords?page=${page}&type=${type}${languageCode ? `&languageCode=${languageCode}` : ''} `
        ).then((response) => {
            return {
                keywords: response.data.content as ModerationKeyword[],
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    get(uuid: number) {
        return ApiService.get(`/moderation-keywords/${uuid}`).then((response) => {
            return response.data as ModerationKeyword
        })
    },

    delete(uuid: string) {
        return ApiService.delete(`/moderation-keywords/${uuid}`)
    },

    create(moderationKeyword: ModerationKeywordUpdateRequest) {
        return ApiService.post(`/moderation-keywords`, moderationKeyword).then((response) => {
            return response.data as ModerationKeyword
        })
    },

    update(uuid: string, moderationKeyword: ModerationKeywordUpdateRequest) {
        return ApiService.patch(`/moderation-keywords/${uuid}`, moderationKeyword).then((response) => {
            return response.data as ModerationKeyword
        })
    },
}

export default ModerationKeywordsService
