import React from 'react'
import Button from 'components/elements/Button'
import Stack from 'components/elements/Stack'
import { UserTargetingTypes } from 'interfaces/UserTargeting'
import theme from 'lib/constants/theme'

interface Props {
    changeUserTargetingType: (type: UserTargetingTypes) => void
}

const UserTargetingTypeSelect = ({ changeUserTargetingType }: Props) => {
    const changeUserTargetingToUserList = () => changeUserTargetingType(UserTargetingTypes.UserList)

    const changeUserTargetingToAttributes = () => changeUserTargetingType(UserTargetingTypes.Attributes)

    return (
        <div>
            <Stack gutter={10}>
                <p>In order to create User Targeting you have to first select type of the User Targeting.</p>
                <p>
                    If you want to target users based on country, grades, regions, etc select the{' '}
                    <strong>Target Users based on Attributes</strong> options.
                </p>
                <p>
                    If you want to upload a CSV file select the <strong>Target User List</strong> option.
                </p>
            </Stack>
            <Button fullWidth onClick={changeUserTargetingToAttributes} hoverColor={theme.colors.darkBackground}>
                Target Users based on Attributes
            </Button>
            <Button fullWidth onClick={changeUserTargetingToUserList} hoverColor={theme.colors.darkBackground}>
                Target User List
            </Button>
        </div>
    )
}

export default UserTargetingTypeSelect
