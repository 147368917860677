/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useRef } from 'react'
import { Form } from '@unform/web'
import CSVImporter, { CSVData } from 'components/FileUpload/CSVImport'
import { toast } from 'react-toastify'
import { FormHandles } from '@unform/core'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import TaxonomyService from 'services/TaxonomyService'
import { ApiError } from 'services/ApiService'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { Body1, ButtonText } from 'components/elements/Text'
import Modal from 'components/modules/Modal'
import { useToggleState } from 'utils/hooks/useToggleState'
import { UpdateHeaderLabels, processTaxonomyUpdateCSVData } from 'lib/features/taxonomyUpdates'
import Button from 'components/elements/Button'
import theme from 'lib/constants/theme'

const TaxonomyCSVUpdate = () => {
    const [showUpdateTaxonomyModal, toggleTaxonomyUpdateModal] = useToggleState(false)
    const taxonomyUpdatesFormRef = useRef<FormHandles>(null)

    const handleCSVUploadComplete = async (csvData: CSVData[]) => {
        const formattedTaxonomyJson = await processTaxonomyUpdateCSVData(csvData, toast)

        const headers = Object.keys(csvData[0])
        const headerLabels = Object.values(UpdateHeaderLabels)

        for (let i = 0; i < headers.length; i++) {
            if (!headerLabels.includes(headers[i] as UpdateHeaderLabels)) {
                toast.warn(
                    `${headers[i]} is not part of the standard headers (${Object.values(UpdateHeaderLabels).join(
                        ', '
                    )}). Please check your csv file!`
                )
                return
            }
        }

        if (!formattedTaxonomyJson || !formattedTaxonomyJson.length) {
            toast.warning('No taxonomy data found')
            return
        }

        const progressText = document.getElementById('title')
        formattedTaxonomyJson.unshift()

        TaxonomyService.batchUpdateTopics(formattedTaxonomyJson)
            .then(() => {
                toast.success('The upload was successful')
                const text = `Imported ${formattedTaxonomyJson.length} taxonomy rows.`
                if (progressText) progressText.textContent = text
            })
            .catch((error: ApiError) => {
                if (error instanceof ApiError) {
                    if (error.type === ErrorTypes.BadRequest) {
                        toast.error(
                            <>
                                {error.message.split('\n')?.map((line, i) => (
                                    <Body1 fontWeigth={400} key={i}>
                                        {line}
                                    </Body1>
                                ))}
                            </>,
                            { progress: 'false' }
                        )
                    } else {
                        error.handleUnknown(`An error occurred while importing taxonomy.`)
                    }
                } else {
                    throw error
                }
                const text = `Import failed ❌`
                if (progressText) progressText.textContent = text
            })
    }

    return (
        <div>
            <Button onClick={toggleTaxonomyUpdateModal} fullWidth={false}>
                <ButtonText color={theme.colors.white}>Upload taxonomy update CSV</ButtonText>
            </Button>
            <Modal show={showUpdateTaxonomyModal} title={'Taxonomy Updates'} onClose={toggleTaxonomyUpdateModal}>
                <Stack>
                    <Box>
                        <strong>Requirements:</strong>
                        <ul>
                            <li>{`First row of the CSV file must contain correct Column Names: Uuid, Action, New name`}</li>
                            <li>{`Action defines what kind of changes would be applied. The options are: EDIT and DELETE`}</li>
                            <li>{`If the Action is DELETE you shiykd leave the New name row empty`}</li>
                        </ul>
                        <br />
                        <strong>Example document:</strong>&nbsp;
                        <a href="/files/ExampleTaxonomyUpdates.csv" download="example_taxonomy_updates.csv">
                            Download
                        </a>
                        <Form ref={taxonomyUpdatesFormRef} onSubmit={() => {}}>
                            <br />

                            <CSVImporter
                                title="CSV Upload area for Taxonomy Updates"
                                handleCSVUploadComplete={handleCSVUploadComplete}
                                showHeader
                            />
                        </Form>
                    </Box>
                </Stack>
            </Modal>
        </div>
    )
}

export default TaxonomyCSVUpdate
