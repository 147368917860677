import ApiService from './ApiService'
import { KnowerBonusPayment } from 'interfaces/KnowerBonusPayment'

const BonusPaymentsService = {
    payKnowers(knowerBonusPayouts: KnowerBonusPayment[]) {
        return ApiService.post(`/knowers/bonus-payout?sendNotification=false`, { knowersBonusPayout: knowerBonusPayouts })
    },
}

export default BonusPaymentsService
