import React from 'react'
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from 'react-pro-sidebar'
import theme from 'lib/constants/theme'
import styled, { css } from 'styled-components'
import { Body1, Body2, Headline4 } from 'components/elements/Text'
import { ReactComponent as Arrow } from 'assets/icons/navigation/arrows.svg'
import { ReactComponent as HelpIcon } from 'assets/icons/navigation/help.svg'
import { ReactComponent as LogoutIcon } from 'assets/icons/navigation/logout.svg'
import LogoImage from 'assets/icon.png'
import { Link, useHistory } from 'react-router-dom'
import { menuSidebarItems } from 'utils/menu'
import AuthService from 'services/AuthService'
import { useToggleState } from 'utils/hooks/useToggleState'
import SuggestionDialog from 'components/modules/SuggestionDialog'
import DataCenterSwitcher from 'components/modules/Navbar/DataCenterSwitcher'

const Container = styled.div`
    display: flex;
    height: 100vh;
    position: fixed;
    overflow: hidden !important;
    left: 0;
    aside {
        border-right: 1px solid rgba(0, 0, 0, 0.4);
        box-shadow: 0 2px 4px 5px rgba(0, 0, 0, 0.1);
    }
    * {
        z-index: ${theme.zIndex.sidebarMenu};
    }
`

const StyledSidebar = styled(Sidebar)`
    overflow: hidden !important;
    border: none;

    svg {
        fill: rgba(255, 255, 255, 0.35);
    }
`

const SubStyledMenu = styled(SubMenu)<{ hideExpandIcon?: boolean }>`
    overflow: hidden !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    * {
        &:hover {
            background-color: ${theme.colors.hoverViolet} !important;
        }
    }
    .expand-icon {
        display: ${({ hideExpandIcon }) => (hideExpandIcon ? 'none' : 'block')};
    }
    .sub-menu-content {
        z-index: ${theme.zIndex.sidebarMenuDialog};
        min-width: 255px;
    }
`

const StyledMenuItem = styled(MenuItem)`
    border-top: 1px solid rgba(0, 0, 0, 0.4);
    background-color: ${theme.colors.lightViolet};
    p {
        color: ${theme.colors.captionGray};
    }
    * {
        &:hover {
            background-color: ${theme.colors.hoverViolet} !important;
        }
    }
`

const ActionContainer = styled.div`
    display: grid;
    place-content: center;
    height: 50px;
    width: 100%;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    &:hover {
        background-color: ${theme.colors.hoverViolet} !important;
    }
`

const ArrowIcon = styled(Arrow)<{ isCollapsed: boolean }>`
    fill: ${theme.colors.body1Black};

    ${({ isCollapsed }) =>
        isCollapsed
            ? css`
                  transform: rotate(180deg);
              `
            : css`
                  transform: rotate(0);
              `}

    cursor: pointer;
`

const Logo = styled(Link)`
    display: flex;
    width: 100%;
    padding: 15px;
    gap: 10px;
    justify-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
`
const SidebarMenu = () => {
    const [showHelpDialog, toggleHelpDialog] = useToggleState(false)
    const { collapsed, collapseSidebar } = useProSidebar()
    const history = useHistory()

    const handleLogoutClicked = () => {
        AuthService.logout()
        history.push('/')
    }

    return (
        <Container>
            <StyledSidebar backgroundColor={theme.colors.darkViolet} width="300px" breakPoint="md">
                <Menu>
                    <Logo to="/">
                        <img src={LogoImage} alt="Knowunity Logo" />
                        {!collapsed && <Headline4 color={theme.colors.white}>Knowunity</Headline4>}
                    </Logo>
                    <ActionContainer onClick={() => collapseSidebar()}>
                        <ArrowIcon isCollapsed={collapsed} />
                    </ActionContainer>
                    <Menu>
                        {menuSidebarItems.map((menuItem) => (
                            <SubStyledMenu
                                icon={menuItem.icon}
                                key={menuItem.title}
                                label={
                                    menuItem.link.length ? (
                                        <Link to={menuItem.link}>
                                            <Body1 color={theme.colors.white}>{menuItem.title}</Body1>
                                        </Link>
                                    ) : (
                                        <Body1 color={theme.colors.white}>{menuItem.title}</Body1>
                                    )
                                }
                                hideExpandIcon={!!menuItem.link.length}
                            >
                                {menuItem.subItems?.length ? (
                                    <>
                                        {menuItem.subItems?.map((subItem) => (
                                            <StyledMenuItem key={subItem.title} suffix={subItem.icon}>
                                                <Link to={subItem.link}>
                                                    <Body2>{subItem.title}</Body2>
                                                </Link>
                                            </StyledMenuItem>
                                        ))}
                                    </>
                                ) : null}
                            </SubStyledMenu>
                        ))}
                    </Menu>
                    <SubStyledMenu
                        icon={<HelpIcon />}
                        label={<Body1 color={theme.colors.white}>Help</Body1>}
                        hideExpandIcon
                        onClick={toggleHelpDialog}
                    />
                    <SubStyledMenu
                        icon={<LogoutIcon />}
                        label={<Body1 color={theme.colors.white}>Logout</Body1>}
                        hideExpandIcon
                        onClick={handleLogoutClicked}
                    />

                    <DataCenterSwitcher />
                </Menu>
            </StyledSidebar>
            <SuggestionDialog showDialog={showHelpDialog} toggleDialog={toggleHelpDialog} />
        </Container>
    )
}

export default SidebarMenu
