import styled, { css } from 'styled-components'
import theme from 'lib/constants/theme'
import { Link } from 'react-router-dom'

export const ChatWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    border-radius: 15px;
    background: ${theme.colors.snowWhite};
    margin: 10px auto;
    overflow: auto;
    max-height: 500px;
`

export const MessageBubble = styled.div<{ isRightSide: boolean; isReportedMessage?: boolean; isHidden?: boolean }>`
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 15px;
    min-width: 100px;
    max-width: 400px;
    ${(props) => {
        if (props.isRightSide) {
            return css`
                border-bottom-right-radius: 0;
                background: ${props.isReportedMessage
                    ? theme.colors.secondaryRedDarker
                    : props.isHidden
                    ? theme.colors.accentOrange
                    : theme.colors.knowunityBlueDark};
            `
        } else {
            return css`
                border-bottom-left-radius: 0;
                background: ${props.isReportedMessage
                    ? theme.colors.secondaryRedDarker
                    : props.isHidden
                    ? theme.colors.accentOrange
                    : theme.colors.captionGray};
            `
        }
    }}
`

export const MessageRowWrapper = styled.div<{ isRightSide: boolean }>`
    display: flex;
    flex-direction: ${({ isRightSide }) => (isRightSide ? 'row-reverse' : 'row')};
    align-items: flex-end;
    justify-content: flex-start;
`

export const MessageText = styled.p<{ isRightSide: boolean }>`
    color: ${({ isRightSide }) => (isRightSide ? theme.colors.white : theme.colors.subtitleBlack)};
`

export const MessageOwner = styled(Link)<{ isRightSide: boolean }>`
    font-weight: bold;
    color: ${({ isRightSide }) => (isRightSide ? theme.colors.white : theme.colors.subtitleBlack)} !important;
`
