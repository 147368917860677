import HCaptcha from './Hcaptcha'
import React, { useState } from 'react'

type UseCaptchaProps = [string | null, () => React.ReactNode]

export function useCaptcha(): UseCaptchaProps {
    const [captchaToken, setCaptchaToken] = useState<string | null>(null)

    const onVerify = (hCaptchaToken: string) => setCaptchaToken(hCaptchaToken)

    const renderCaptcha = () => {
        return <HCaptcha onVerify={onVerify} />
    }

    return [captchaToken, renderCaptcha]
}
