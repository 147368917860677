/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useState } from 'react'
import Button from 'components/elements/Button'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import { AdCustomer as AdCustomerInterface } from 'interfaces/AdSystem'
import Box from 'components/elements/Box'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { ControlWrapper, FilterFormGrid } from 'style'
import theme from 'lib/constants/theme'
import { useToggleState } from 'utils/hooks/useToggleState'
import Stack from 'components/elements/Stack'
import AdAttachmentService from 'services/AdAttachmentService'
import AdAttachmentForm from './AdAttachmentForm'
import { AdAttachment } from 'interfaces/AdAttachmentSet'
import Table, { TableData } from 'components/modules/Table'
import Row from 'components/elements/Row'
import { toast } from 'react-toastify'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg'
import { returnAttachmentContent } from 'utils/adAttachments'

const loadingAreas = {
    container: 'moderationKeywordsContainer',
    delete: 'moderationKeywordDelete',
    edit: 'moderationKeywordEdit',
}

interface Props {
    adCustomer: AdCustomerInterface
}

const AdCustomerAttachments = ({ adCustomer }: Props) => {
    const [selectedAttachmentItem, setSelectedAttachmentItem] = useState<AdAttachment>()
    const [attachmentsTableData, setAttachmentsTableData] = useState<TableData[]>([])
    const [showEditCustomerModal, toggleEditCustomerModal] = useToggleState(false)
    const [refetchTable, toggleRefetch] = useToggleState(false)

    const handleDeleteAttachment = useCallback(
        (attachment: AdAttachment) => {
            if (!window.confirm(`Do you really want to delete the attachment with uuid: ${attachment.uuid}?`)) return

            trackPromise(
                AdAttachmentService.deleteSet(attachment.uuid)
                    .then(() => {
                        toast.success('Attachment has been deleted')
                        toggleRefetch()
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            if (error.type === ErrorTypes.NotFound) {
                                toast.error("Attachment with this uuid doesn't exist! Try again.")
                                return
                            } else {
                                error.handleUnknown('An error occurred while deleting the attachment.')
                            }
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.delete
            )
        },
        [toggleRefetch]
    )

    const handleEditAttachment = useCallback(
        (attachment: AdAttachment) => {
            setSelectedAttachmentItem(attachment)
            toggleEditCustomerModal()
        },
        [toggleEditCustomerModal]
    )

    const fetchAdAttachments = useCallback(() => {
        AdAttachmentService.listAttachmentsByCustomers(adCustomer.uuid)
            .then((data) => {
                const tableData = data.map((a, i) => ({
                    id: a.uuid,
                    columns: [
                        a.uuid,
                        a.type,
                        returnAttachmentContent(a),
                        <Row key={i}>
                            <Button onClick={() => handleEditAttachment(a)} noMargin fullWidth icon={<EditIcon />}>
                                Edit
                            </Button>
                            <Button onClick={() => handleDeleteAttachment(a)} noMargin fullWidth icon={<DeleteIcon />}>
                                Delete
                            </Button>
                        </Row>,
                    ],
                }))

                setAttachmentsTableData(tableData)
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('An error occurred while getting customer attachments.')
                } else {
                    throw error
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adCustomer.uuid, handleDeleteAttachment, handleEditAttachment, refetchTable])

    useEffect(() => {
        fetchAdAttachments()
    }, [fetchAdAttachments])

    const onCloseDialog = () => {
        setSelectedAttachmentItem(undefined)
        toggleEditCustomerModal()
    }

    return (
        <>
            <Box>
                <Stack gutter={0}>
                    <h2>Ad Attachments</h2>
                    <FilterFormGrid gridAmount={2}>
                        <ControlWrapper>
                            <Button
                                fullWidth={false}
                                icon={<AddIcon />}
                                hoverColor={theme.colors.knowunityBlue}
                                color={theme.colors.knowunityBlueDark}
                                onClick={toggleEditCustomerModal}
                            >
                                Add Attachment
                            </Button>
                        </ControlWrapper>
                    </FilterFormGrid>

                    <Table
                        columns={['#', 'Type', 'Content', 'Actions']}
                        data={attachmentsTableData}
                        page={0}
                        totalPages={1}
                        totalElements={attachmentsTableData.length}
                    />
                </Stack>
            </Box>

            <AdAttachmentForm
                adCustomer={adCustomer}
                show={showEditCustomerModal}
                handleClose={onCloseDialog}
                adAttachment={selectedAttachmentItem}
            />
        </>
    )
}

export default AdCustomerAttachments
