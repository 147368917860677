import React, { useState } from 'react'
import { ApiError } from 'services/ApiService'
import ReportService from 'services/ReportService'
import { ReportItem, ReportStatus } from 'interfaces/Report'
import { trackPromise } from 'react-promise-tracker'
import RefactoredTable from 'components/modules/RefactoredTable'
import { CountryCode } from 'interfaces/Country'
import { FilterFormGrid, NoWrapText } from 'style'
import SelectElement from 'components/inputs/SelectElement'
import routes from 'lib/constants/routes'
import { useToggleState } from 'utils/hooks/useToggleState'
import Modal from 'components/modules/Modal'
import ReportStatusForm from './ReportStatusForm'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redux/reducer'
import { setReportSettings } from 'redux/actions'
import Meta from 'components/modules/Head'
import { useCountryOptions } from 'hooks/useCountryOptions'

export enum ReportFilterType {
    User = 'USER',
    Know = 'KNOW',
    Question = 'QUESTION',
    Answer = 'ANSWER',
    Comment = 'COMMENT',
    FlaggedChat = 'CHAT',
    ChatMessage = 'CHAT_MESSAGE',
    AiChatBot = 'AI_CHAT_BOT',
}

const loadingAreas = {
    container: 'reportsContainer',
}

const reportTypeOptions = [
    {
        label: 'No filter',
        data: null,
    },
    ...Object.keys(ReportFilterType).map((typeKey) => ({
        label: typeKey,
        // eslint-disable-next-line
        // @ts-ignore
        data: ReportFilterType[typeKey],
    })),
]

const reportStatusOptions = [
    {
        label: 'Open',
        data: 'OPEN',
    },
    {
        label: 'On hold',
        data: 'ON_HOLD',
    },
]

const Reports = () => {
    const reportSettings = useSelector((state: AppState) => state.reportSettings)
    const [reportType, setReportType] = useState<ReportFilterType | null>(reportSettings?.reportType ?? null)
    const [reportStatus, setReportStatus] = useState<ReportStatus | null>(reportSettings?.reportStatus ?? null)
    const [countryCode, setCountryCode] = useState<CountryCode | null>(reportSettings?.countryCode ?? null)
    const [showReportModal, toggleReportModal] = useToggleState(false)
    const [reportUuids, setReportUuids] = useState<string[]>()
    const [refetch, setRefetch] = useState(false)

    const countryTypeOptions = useCountryOptions({ includeNoFilter: true })

    const dispatch = useDispatch()

    const fetchData = (page: number) => {
        return trackPromise(
            ReportService.listReports(page, reportType, countryCode, reportStatus)
                .then((data) => {
                    if (page !== data.page) return

                    const reportsIds = data?.content?.map((k) => k.uuid) ?? []

                    return {
                        totalPages: data.totalPages,
                        totalElements: data.totalElements,
                        elements: data.content.map((report: ReportItem) => ({
                            id: report.uuid,
                            columns: [
                                report.uuid,
                                <NoWrapText key={`type-${report.uuid}`}>{report.type}</NoWrapText>,
                                report.reports?.length ?? 0,
                            ],
                            onClick: routes.reportRoute(report.uuid, { redirectIds: reportsIds }),
                        })),
                    }
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while getting reports.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.container
        )
    }

    const updateReportType = (data: ReportFilterType) => {
        setReportType(data)

        dispatch(
            setReportSettings({
                reportType: data,
                countryCode: reportSettings?.countryCode ?? null,
                reportStatus: reportSettings?.reportStatus ?? null,
            })
        )
    }

    const updateReportStatus = (data: ReportStatus) => {
        setReportStatus(data)

        dispatch(
            setReportSettings({
                reportStatus: data,
                countryCode: reportSettings?.countryCode ?? null,
                reportType: reportSettings?.reportType ?? null,
            })
        )
    }

    const changeLanguage = (data: CountryCode) => {
        setCountryCode(data)

        dispatch(
            setReportSettings({
                countryCode: data,
                reportType: reportSettings?.reportType ?? null,
                reportStatus: reportSettings?.reportStatus ?? null,
            })
        )
    }

    const onSuccess = () => {
        setReportUuids(undefined)
        toggleReportModal()
        setRefetch(true)
    }

    const reportTypeDefaultValue = reportTypeOptions.findIndex((o) => o.data === reportType)
    const countryCodeDefaultValue = countryTypeOptions.findIndex((o) => o.data === countryCode)
    const reportStatusDefaultValue = reportStatusOptions.findIndex((o) => o.data === reportStatus)

    return (
        <section>
            <Meta title="All Reports" />
            <h2>All Reports</h2>
            <FilterFormGrid gridAmount={3}>
                <SelectElement
                    label="Report type"
                    noMargin
                    options={reportTypeOptions}
                    onUpdate={updateReportType}
                    defaultValue={reportTypeDefaultValue}
                />
                <SelectElement
                    label="Report status"
                    noMargin
                    options={reportStatusOptions}
                    onUpdate={updateReportStatus}
                    defaultValue={reportStatusDefaultValue}
                />
                <SelectElement
                    label="Country code"
                    noMargin
                    options={countryTypeOptions}
                    onUpdate={changeLanguage}
                    defaultValue={countryCodeDefaultValue}
                />
            </FilterFormGrid>

            <RefactoredTable
                columns={['#', 'Type', 'Reports count']}
                loadData={fetchData}
                allowTableLimitChange
                loadingArea={loadingAreas.container}
                refetchKey={`${reportType}_${countryCode}_${refetch}`}
            />
            {reportUuids ? (
                <Modal
                    show={showReportModal}
                    onClose={toggleReportModal}
                    title={`Perform actions on ${reportUuids.length} selected Knows`}
                >
                    <ReportStatusForm reportUuids={reportUuids} onSuccess={onSuccess} />
                </Modal>
            ) : null}
        </section>
    )
}

export default Reports
