import React, { useCallback, useEffect, useState } from 'react'
import LoadingArea from 'components/elements/LoadingArea'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import { formatDateTime, formatDateTimeUTC, formatTimeDifference, humanReadableTimeAgoLabel } from 'utils'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import CopyIcon from 'components/elements/CopyIcon'
import Tabs from 'components/modules/Tabs'
import ManualEmailAction from './ManualEmailAction'
import ManualEmailForm from './ManualEmailForm'
import { ManualEmail } from 'interfaces/ManualEmails'
import ManualEmailsService from 'services/ManualEmailsService'
import ManualEmailEditor from './ManualEmailEditor'
import { Tooltip, TooltipText } from 'style'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import { returnCategoryDescription } from 'utils/email'
import ExplanationBanner from 'components/elements/ExplanationBanner'
import routes from 'lib/constants/routes'
import { useUserTargetingForm } from 'utils/hooks/useUserTargetingForm'
import ProgressBar from 'components/elements/ProgressBar'
import GoBackButton from 'components/elements/GoBackButton'
import Meta from 'components/modules/Head'
import ManualCommunicationPlaceholder from 'pages/ManualCommunicationPlaceholder/ManualCommunicationPlaceholder'
import { ManualCommmunicationStatus } from 'interfaces/ManualCommunicationPlaceholder'

interface Props extends RouteComponentProps<{ id: string }> {}

const loadingAreas = {
    container: 'manualEmailContainer',
    save: 'saveManualEmail',
}

const ManualEmailsComponent = (props: Props) => {
    const [manualEmail, setManualEmail] = useState<ManualEmail | null>(null)
    const { renderTargetingInformation } = useUserTargetingForm(manualEmail?.userTargetingId)

    const id = props.match.params.id
    const isNew = !id

    const fetchData = useCallback(() => {
        if (!id) return

        trackPromise(
            ManualEmailsService.get(id)
                .then((email) => {
                    setManualEmail(email)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting manual email.')
                }),
            loadingAreas.container
        )
    }, [id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const updateManualEmail = (email: ManualEmail) => setManualEmail(email)

    const isSent = !!manualEmail?.sentOn
    const canUpdate = !isSent
    const showProgress = manualEmail?.actualUsersCount && manualEmail.expectedUsersCount

    const manualEmailWorkflowSteps = [
        {
            label: '1. Fill in all the relevant information',
            target: routes.manualEmailRoute(id, 'update'),
        },
        {
            label: '2. Ask someone from the team to approve it',
            target: routes.manualEmailRoute(id, 'actions'),
        },
        {
            label: '3. Send it out yourself',
            target: routes.manualEmailRoute(id, 'actions'),
        },
    ]

    return (
        <section>
            <Meta title="Manual Email" />
            <h2>Manual Email</h2>
            <GoBackButton route={routes.manualEmailsRoute} routeName="Manual Emails" />
            {!isNew && manualEmail && (
                <>
                    <ExplanationBanner
                        title="How does Manual Emails work?"
                        text="In order to issue a manual email following steps have to be taken beforehand:"
                        options={manualEmailWorkflowSteps}
                    />
                    <Tabs preventUnloadOnTab="Email Editor">
                        <div data-label="General">
                            <Box>
                                <Stack>
                                    <h3>Information</h3>
                                    <LoadingArea>
                                        <p>
                                            <strong>Uuid:</strong>&nbsp;
                                            {manualEmail.uuid}
                                            <CopyIcon text={manualEmail.uuid} />
                                        </p>
                                        <p>
                                            <strong>Subject:</strong>&nbsp;
                                            {manualEmail.subject}
                                        </p>
                                        <p>
                                            <strong>Added by:</strong>&nbsp;
                                            {`${manualEmail.user.name} (${manualEmail.user.uuid})`}
                                            <CopyIcon text={manualEmail.user.uuid} />
                                        </p>
                                        {
                                            <p>
                                                <strong>ScheduledOn on:</strong>&nbsp;
                                                {manualEmail.scheduledOn
                                                    ? formatDateTime(manualEmail.scheduledOn)
                                                    : 'Not yet sent'}
                                            </p>
                                        }
                                        <p>
                                            <strong>Approved by:</strong>&nbsp;
                                            {manualEmail.approvedByUser ? (
                                                <>
                                                    {`${manualEmail.approvedByUser.name} (${manualEmail.approvedByUser.uuid})`}
                                                    <CopyIcon text={manualEmail.approvedByUser.uuid} />
                                                </>
                                            ) : (
                                                'Not yet approved'
                                            )}
                                        </p>
                                        <p>
                                            <strong>Approved on:</strong>&nbsp;
                                            {manualEmail.approvedOn
                                                ? formatDateTime(manualEmail.approvedOn)
                                                : 'Not yet approved'}
                                        </p>

                                        <p>
                                            <strong>Sent on:</strong>&nbsp;
                                            {manualEmail.sentOn ? formatDateTimeUTC(manualEmail.sentOn) : 'Not yet sent'}
                                        </p>

                                        <p>
                                            <strong>Type:</strong>&nbsp;
                                            {manualEmail.type}
                                            <Tooltip>
                                                <InfoIcon />
                                                <TooltipText>Used for tracking</TooltipText>
                                            </Tooltip>
                                        </p>
                                        <p>
                                            <strong>Category:</strong>&nbsp;
                                            {manualEmail.category}
                                            <Tooltip>
                                                <InfoIcon />
                                                <TooltipText>
                                                    <Stack>
                                                        <p>{returnCategoryDescription(manualEmail.category)}</p>
                                                    </Stack>
                                                </TooltipText>
                                            </Tooltip>
                                        </p>
                                        {manualEmail?.fromEmail ? (
                                            <p>
                                                <strong>From Email:</strong>&nbsp;
                                                {manualEmail.fromEmail}
                                            </p>
                                        ) : null}
                                        {manualEmail?.fromName ? (
                                            <p>
                                                <strong>From Name:</strong>&nbsp;
                                                {manualEmail.fromName}
                                            </p>
                                        ) : null}
                                        {manualEmail?.replyTo ? (
                                            <p>
                                                <strong>Reply to:</strong>&nbsp;
                                                {manualEmail.replyTo}
                                            </p>
                                        ) : null}
                                        <p>
                                            <strong>Language:</strong>&nbsp;
                                            {manualEmail.language.englishName} ({manualEmail.language.code})
                                        </p>
                                        {manualEmail?.experimentVariantIdentifier ? (
                                            <p>
                                                <strong>Experiment Variant Identifier:</strong>&nbsp;
                                                {manualEmail.experimentVariantIdentifier}
                                            </p>
                                        ) : null}
                                        {showProgress ? (
                                            <p>
                                                <strong>{`Send Progress: (${manualEmail.status})`}</strong>&nbsp;
                                                <ProgressBar
                                                    max={manualEmail.expectedUsersCount!}
                                                    currentValue={manualEmail.actualUsersCount!}
                                                />
                                                {manualEmail.status === ManualCommmunicationStatus.Processing &&
                                                manualEmail.lastProcessedOn ? (
                                                    <p>
                                                        <strong>{`Last processed on:`}</strong>&nbsp;
                                                        {formatTimeDifference(manualEmail.lastProcessedOn, true)}
                                                        <br />
                                                        {humanReadableTimeAgoLabel(manualEmail.lastProcessedOn)}
                                                    </p>
                                                ) : null}
                                            </p>
                                        ) : null}
                                    </LoadingArea>
                                </Stack>
                            </Box>
                            {renderTargetingInformation()}
                        </div>
                        <div data-label="Update">
                            {canUpdate ? (
                                <Box>
                                    <ManualEmailForm
                                        manualEmail={manualEmail}
                                        isNew={isNew}
                                        updateManualEmail={updateManualEmail}
                                    />
                                </Box>
                            ) : (
                                <p>You cannot update email that have already been sent.</p>
                            )}
                        </div>
                        <div data-label="Email Editor">
                            <ManualEmailEditor email={manualEmail} fetchData={fetchData} />
                        </div>
                        <div data-label="Actions">
                            <ManualEmailAction email={manualEmail} fetchData={fetchData} />
                        </div>
                        <div data-label="Communication Placeholder">
                            <ManualCommunicationPlaceholder manualEmailUuid={manualEmail.uuid} />
                        </div>
                    </Tabs>
                </>
            )}
            {isNew && <ManualEmailForm manualEmail={manualEmail} isNew={isNew} updateManualEmail={updateManualEmail} />}
        </section>
    )
}

export default withRouter(ManualEmailsComponent)
