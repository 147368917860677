import React from 'react'
import styles from './Table.module.css'
import { ReactComponent as ChefronLeftIcon } from 'assets/icons/chefron-left.svg'
import { ReactComponent as ChefronRightIcon } from 'assets/icons/chefron-right.svg'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { setUrlParam, getUrlParam } from 'utils'
import { usePromiseTracker } from 'react-promise-tracker'
import { useEffect } from 'react'
import Spinner from '../elements/Spinner'
import usePrevious from 'utils/hooks/usePrevious'

export interface TableData {
    id: number | string
    // eslint-disable-next-line
    columns: any[]
    onClick?: string
    onClickFunc?(id: number | string): void
}

interface Props extends RouteComponentProps {
    columns: string[]
    data: TableData[]
    page: number
    totalPages: number
    totalElements: number
    setPage?: (page: number) => void
    area?: string
}

const Table = (props: Props) => {
    const { page, data, columns, totalElements, totalPages, setPage, area } = props
    const { promiseInProgress } = usePromiseTracker({ area })
    const prevPage = usePrevious<number>(page)
    const newPage = getUrlParam('page') ? parseInt(getUrlParam('page')!) - 1 : 0

    useEffect(() => {
        if (newPage > 0 && newPage !== page && newPage !== prevPage && setPage) {
            setPage(newPage)
        }
        // disabled to unable rerenders
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (prevPage !== page) {
            setUrlParam('page', (page + 1).toString())
        } else {
            setUrlParam('page', page.toString())
        }
    }, [newPage, page, prevPage, setPage])

    const previousPage = () => {
        if (page !== 0 && setPage) {
            setPage(page - 1)
        }
    }

    const nextPage = () => {
        if (page + 1 !== totalPages && setPage) {
            setPage(page + 1)
        }
    }

    const handleClick = (entry: TableData) => {
        if (entry.onClick) {
            props.history.push(entry.onClick)
        }
        if (entry.onClickFunc) {
            entry.onClickFunc(entry.id)
        }
    }

    const isValid = data?.length && !promiseInProgress
    return (
        <div>
            <table className={styles.table}>
                <thead>
                    <tr>
                        {columns.map((column) => (
                            <th key={column}>{column}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {isValid
                        ? data.map((entry) => (
                              <tr key={entry.id} onClick={() => handleClick(entry)}>
                                  {entry.columns.map((column, i) => (
                                      <td key={`cell-${entry.id}-${i}`}>{column}</td>
                                  ))}
                              </tr>
                          ))
                        : null}
                </tbody>
            </table>
            {!isValid && (
                <div className={styles.empty}>{promiseInProgress ? <Spinner area={area} /> : 'No records found!'}</div>
            )}
            {isValid && setPage && totalPages !== 1 ? (
                <div className={styles.pagination}>
                    <p />
                    <p>
                        Page {page + 1} of {totalPages}
                    </p>
                    <p>{totalElements} elements</p>
                    <ChefronLeftIcon onClick={previousPage} />
                    <ChefronRightIcon onClick={nextPage} />
                </div>
            ) : null}
        </div>
    )
}

export default withRouter(Table)
