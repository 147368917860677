import ApiService from 'services/ApiService'
import { Experiment, ExperimentCohort, ExperimentStatuses, UpdateExperiment } from 'interfaces/Experiment'

const ExperimentsService = {
    list(page: number, status?: ExperimentStatuses, search?: string | null) {
        return ApiService.get(
            `/experiments?page=${page}${status !== ExperimentStatuses.All ? `&status=${status}` : ''}${
                search ? `&search=${search}` : ''
            }`
        ).then((response) => {
            return {
                experiments: response.data.content as Experiment[],
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    get(uuid: string) {
        return ApiService.get(`/experiments/${uuid}`).then((response) => {
            return response.data as Experiment
        })
    },

    create(experiment: UpdateExperiment) {
        return ApiService.post('/experiments', experiment).then((response) => {
            return response.data as Experiment
        })
    },

    update(uuid: string, experiment: UpdateExperiment) {
        return ApiService.patch(`/experiments/${uuid}`, experiment).then((response) => {
            return response.data as Experiment
        })
    },

    getCohorts() {
        return ApiService.get(`/experiments/cohorts`).then((response) => {
            return response.data.content as ExperimentCohort[]
        })
    },
}

export default ExperimentsService
