import React, { useRef } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import Button, { ButtonA } from 'components/elements/Button'
import { Input } from 'components/inputs/Input'
import UserService from 'services/UserService'
import { User } from 'interfaces/User'
import Box from 'components/elements/Box'
import { Body1 } from 'components/elements/Text'
import theme from 'lib/constants/theme'
import Stack from 'components/elements/Stack'
import { trackPromise } from 'react-promise-tracker'
import { ErrorTypes } from 'utils/constants/ErrorTypes'

const loadingAreas = {
    fetchSubscription: 'fetchSubscription',
    connectSubscription: 'connectSubscription',
}

interface Props {
    user: User
    onUpdate(user: User): void
}

const UserVerificationStatus = ({ user, onUpdate }: Props) => {
    const formRef = useRef<FormHandles>(null)

    const refetchSubscriptionDetails = () => {
        trackPromise(
            UserService.getSubscriptionInformation(user.uuid).catch((error) => {
                if (error instanceof ApiError) {
                    if (error.type === ErrorTypes.NotFound) {
                        toast.error("Subscription for this user doesn't exist")
                    } else {
                        error.handleUnknown('An error occurred while fetching the premium status of the user.')
                    }
                } else {
                    throw error
                }
            }),
            loadingAreas.fetchSubscription
        )
    }

    const handleSubmit: SubmitHandler<{ subscriptionCode: string }> = ({ subscriptionCode }) => {
        formRef.current!.setErrors({})

        if (!subscriptionCode) {
            formRef.current?.setFieldError('subscriptionCode', 'Please enter the subcription code')
            return
        }

        UserService.connectSubscriptionCode(user.uuid, subscriptionCode)
            .then(() => {
                toast.success('The user premium status was changed.')
            })
            .catch((error: ApiError) => {
                if (error instanceof ApiError) {
                    if (error.type === ErrorTypes.NotFound) {
                        toast.error("Subscription code doesn't exist")
                    } else {
                        error.handleUnknown('An error occurred while connecting subscription code to the user.')
                    }
                } else {
                    throw error
                }
            })
    }

    return (
        <Stack>
            <ButtonA fullWidth to={`https://app.revenuecat.com/customers/803990ee/${user.secretId}`} target="_blank">
                Open RevenueCat profile
            </ButtonA>
            <Button onClick={refetchSubscriptionDetails} fullWidth loadingArea={loadingAreas.fetchSubscription}>
                Refetch subscription information
            </Button>
            <Box>
                <Body1 color={theme.colors.white} marginBottom={15}>
                    Connect subscription
                </Body1>
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <Input name="subscriptionCode" placeholder="Subscription code" />
                    <br />
                    <Button fullWidth loadingArea={loadingAreas.connectSubscription}>
                        Save
                    </Button>
                </Form>
            </Box>
        </Stack>
    )
}

export default UserVerificationStatus
