import { Competition, CompetitionRequest } from 'interfaces/Competition'
import { CountryCode } from 'interfaces/Country'
import ApiService from 'services/ApiService'

const CompetitionService = {
    list(page: number, isActive?: boolean | null, selectedCountry?: CountryCode | null) {
        return ApiService.get(
            `/competitions?page=${page}${isActive !== null ? `&isActive=${isActive}` : ''}${
                selectedCountry ? `&countryCode=${selectedCountry}` : ''
            }`
        ).then((response) => {
            return {
                competitions: response.data.content as Competition[],
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    get(uuid: string) {
        return ApiService.get(`/competitions/${uuid}`).then((response) => {
            return response.data as Competition
        })
    },

    create(data: CompetitionRequest) {
        return ApiService.post(`/competitions`, data).then((response) => {
            return response.data as Competition
        })
    },

    update(uuid: string, data: CompetitionRequest) {
        return ApiService.patch(`/competitions/${uuid}`, data).then((response) => {
            return response.data as Competition
        })
    },
}

export default CompetitionService
