import React from 'react'
import Stack from 'components/elements/Stack'
import { Body2 } from 'components/elements/Text'
import { Flashcard } from 'interfaces/Flashcard'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import theme from 'lib/constants/theme'
import styled from 'styled-components'
import Button from 'components/elements/Button'
import { toast } from 'react-toastify'
import EditRecordInline from 'components/elements/EditRecordInline'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'

interface Props {
    flashCards: Flashcard[] | null
    updateFlashcards: (flashcards: Flashcard[]) => void
    submitFlashcardsUpdate: VoidFunction
}

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    svg {
        cursor: pointer;
    }
`

const SideBySideGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 10px;

    .left {
        border: 1px solid ${theme.colors.hoverViolet};
        padding: 15px 10px;
    }
    .right {
        border: 1px solid ${theme.colors.hoverViolet};
        padding: 10px;
    }
    p {
        overflow-wrap: anywhere;
    }
`

const FlashCardsDisplay = ({ flashCards, updateFlashcards, submitFlashcardsUpdate }: Props) => {
    const deleteFlashcard = (indexToDelete: string) => {
        if (!flashCards) return

        if (flashCards.length <= 5) {
            toast.warning('Flashcard set has to include atleast 5 cards')
            return
        }

        const newArray = [...flashCards]

        updateFlashcards(newArray.filter((card) => card.uuid !== indexToDelete))
    }

    const updateFlashcardPartially = (
        itemUuid: string,
        recordName: string,
        recordValue: string | number,
        hideForm: () => void,
        isArray?: boolean
    ) => {
        if (!flashCards || !recordValue) return

        const newArray = [...flashCards]
        const indexToEdit = newArray.findIndex((card) => card.uuid === itemUuid)

        if (!indexToEdit && indexToEdit !== 0) {
            hideForm()
            return
        }
        newArray[indexToEdit] = {
            ...newArray[indexToEdit],
            [`${recordName}`]: isArray
                ? recordValue
                      .toString()
                      .split(', ')
                      .map((x) => x.trim())
                : recordValue,
        }

        updateFlashcards(newArray)

        hideForm()
    }

    return (
        <Stack>
            {flashCards?.map((cards, i) => (
                <Stack key={`flashcard-${i}`} gutter={7}>
                    <Row>
                        <Body2 color={theme.colors.white}>{++i}</Body2>

                        <Button
                            height={30}
                            fullWidth={false}
                            icon={<DeleteIcon />}
                            onClick={() => deleteFlashcard(cards.uuid)}
                        >
                            <Body2 color={theme.colors.white}>Delete</Body2>
                        </Button>
                    </Row>
                    <SideBySideGrid>
                        <React.Fragment>
                            <Stack className="left">
                                {cards?.questionImageFilePath ? (
                                    <img
                                        src={cards.questionImageFilePath}
                                        width={128}
                                        height={128}
                                        alt="question flashcard attachment"
                                    />
                                ) : null}
                                <EditRecordInline
                                    name="questionText"
                                    value={cards.questionText}
                                    itemUuid={cards.uuid}
                                    onSubmit={updateFlashcardPartially}
                                >
                                    {cards.questionText}
                                </EditRecordInline>
                                {cards.correctAnswers?.length ? (
                                    <Stack>
                                        <Body2 color={theme.colors.accentOrange}>Correct answers:</Body2>
                                        <EditRecordInline
                                            name="correctAnswers"
                                            value={cards.correctAnswers.join(', ')}
                                            itemUuid={cards.uuid}
                                            onSubmit={(uuid, name, value, hide) =>
                                                updateFlashcardPartially(uuid, name, value, hide, true)
                                            }
                                            activeTooltip="You can only add 1 correct answer (dont separate with commas)"
                                        >
                                            <Row>
                                                {cards.correctAnswers.join(', ')}
                                                <EditIcon style={{ fill: 'white' }} />
                                            </Row>
                                        </EditRecordInline>
                                    </Stack>
                                ) : null}
                            </Stack>

                            <Stack className="right">
                                <EditRecordInline
                                    name="answerText"
                                    value={cards.answerText}
                                    itemUuid={cards.uuid}
                                    onSubmit={updateFlashcardPartially}
                                >
                                    {cards.answerText}
                                </EditRecordInline>
                                {cards?.answerImageFilePath ? (
                                    <img
                                        src={cards.answerImageFilePath}
                                        width={128}
                                        height={128}
                                        alt="answer flashcard attachment"
                                    />
                                ) : null}
                                {cards.incorrectAnswers?.length ? (
                                    <Stack>
                                        <Body2 color={theme.colors.accentOrange}>Incorrect answers:</Body2>
                                        <EditRecordInline
                                            name="incorrectAnswers"
                                            value={cards.incorrectAnswers.join(', ')}
                                            itemUuid={cards.uuid}
                                            onSubmit={(uuid, name, value, hide) =>
                                                updateFlashcardPartially(uuid, name, value, hide, true)
                                            }
                                            activeTooltip="Separate incorrect answers with commas"
                                        >
                                            <Row>
                                                {cards.incorrectAnswers.join(', ')}
                                                <EditIcon style={{ fill: 'white' }} />
                                            </Row>
                                        </EditRecordInline>
                                    </Stack>
                                ) : null}
                            </Stack>
                        </React.Fragment>
                    </SideBySideGrid>
                </Stack>
            ))}
            <Button fullWidth={false} onClick={submitFlashcardsUpdate}>
                <Body2 color={theme.colors.white}>Submit changes</Body2>
            </Button>
        </Stack>
    )
}

export default FlashCardsDisplay
