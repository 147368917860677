import { CountryCode } from 'interfaces/Country'
import {
    PayoutDestination,
    PayoutRejectionReason,
    PayoutRequest,
    PossiblePaymentRequestFraud,
} from 'interfaces/PayoutRequest'
import ApiService from 'services/ApiService'

const PayoutRequestService = {
    list(
        page: number,
        isOpened?: boolean | null,
        countryCode?: CountryCode | null,
        invoiceID?: string | null,
        destination?: PayoutDestination | null
    ) {
        return ApiService.get(
            `payout-requests?page=${page}${isOpened != null ? `&isOpen=${isOpened}` : ''}${
                countryCode ? `&countryCode=${countryCode}` : ''
            }${invoiceID ? `&invoiceID=${invoiceID}` : ''}${destination ? `&payoutDestination=${destination}` : ''}`
        ).then((response) => {
            return {
                payouts: response.data.content as PayoutRequest[],
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    get(uuid: string) {
        return ApiService.get(`payout-requests/${uuid}`).then((response) => {
            return response.data as PayoutRequest
        })
    },

    close(uuid: string) {
        return ApiService.patch(`payout-requests/${uuid}/done`).then((response) => {
            return response.data as PayoutRequest
        })
    },

    setPayoutAmountToKnowerBalance(uuid: string) {
        return ApiService.patch(`payout-requests/${uuid}/amount`, { setToKnowerBalance: true }).then((response) => {
            return response.data as PayoutRequest
        })
    },

    refuse(uuid: string, reason: PayoutRejectionReason) {
        return ApiService.patch(`payout-requests/${uuid}/refuse`, { reason }).then((response) => {
            return response.data as PayoutRequest
        })
    },

    createPayoutVoucher(uuid: string) {
        return ApiService.post(`/payout-requests/${uuid}/sevdesk-payout`).then((response) => {
            return response.data as PayoutRequest
        })
    },

    closePaypalPaymentAndCreatePayout(uuid: string) {
        return ApiService.post(`/payout-requests/${uuid}/paypal-payout`).then((response) => {
            return response.data as PayoutRequest
        })
    },

    listForKnower(uuid: string, page: number) {
        return ApiService.get(`knowers/${uuid}/payout-requests/admin?page=${page}`).then((response) => {
            return {
                payouts: response.data.content as Array<PayoutRequest>,
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    getFraudDetectionResult(uuid: string) {
        return ApiService.get(`payout-request/${uuid}/fraud-detection`).then((response) => {
            return response.data.content as PossiblePaymentRequestFraud[]
        })
    },
}

export default PayoutRequestService
