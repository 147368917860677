import ApiService from 'services/ApiService'
import { SeoTopic, UpdateSeoTopic } from 'interfaces/SeoTopic'

const SeoTopicService = {
    list(uuid: string) {
        return ApiService.get(`seo/subjects/${uuid}/topics`).then((response) => {
            return response.data.content as SeoTopic[]
        })
    },

    get(uuid: string) {
        return ApiService.get(`/seo/topics/${uuid}`).then((response) => {
            return response.data as SeoTopic
        })
    },

    create(seoSubject: UpdateSeoTopic) {
        return ApiService.post('/seo/topics', seoSubject).then((response) => {
            return response.data as SeoTopic
        })
    },

    update(uuid: string, seoSubject: UpdateSeoTopic) {
        return ApiService.patch(`/seo/topics/${uuid}`, seoSubject).then((response) => {
            return response.data as SeoTopic
        })
    },
}

export default SeoTopicService
