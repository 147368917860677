import React, { useRef } from 'react'
import { Form } from '@unform/web'
import Box from 'components/elements/Box'
import { Checkbox, Input } from 'components/inputs/Input'
import Modal from 'components/modules/Modal'
import { FormHandles, SubmitHandler } from '@unform/core'
import { trackPromise } from 'react-promise-tracker'
import UserService from 'services/UserService'
import { toast } from 'react-toastify'
import { RemoveBanOptions } from 'interfaces/UserBan'
import { ApiError } from 'services/ApiService'
import { badRequestFormErrors } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import Button from 'components/elements/Button'

interface Props {
    userUuid: string
    show: boolean
    onClose(): void
    onRemovedBan?: () => void
    showUnblockDevices: boolean
}

const loadingAreas = {
    save: 'userRemoveBanModal',
}

export const UserRemoveBanModal = ({ show, userUuid, onClose, showUnblockDevices, onRemovedBan }: Props) => {
    const formRef = useRef<FormHandles>(null)

    const handleSubmit: SubmitHandler<RemoveBanOptions> = (data) => {
        formRef.current!.setErrors({})
        trackPromise(
            UserService.removeBan(userUuid, {
                comment: data.comment,
                unblockUserDevices: showUnblockDevices ? data.unblockUserDevices : false,
            })
                .then(() => {
                    toast.success('Removed ban successfully!')
                    onRemovedBan && onRemovedBan()
                    onClose()
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.FormValidation) {
                            badRequestFormErrors(error, formRef.current!)
                        } else {
                            error.handleUnknown('An error occurred while removing the ban.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.save
        )
    }

    return (
        <Modal show={show} title="Remove ban" onClose={onClose}>
            <Box>
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <Input name="comment" placeholder="Comment (for internal use)" />
                    {showUnblockDevices && <Checkbox name="unblockUserDevices" placeholder="Unban devices" />}
                    <Button fullWidth loadingArea={loadingAreas.save}>
                        Remove ban
                    </Button>
                </Form>
            </Box>
        </Modal>
    )
}
