import ApiService from './ApiService'
import { SchoolType } from 'interfaces/SchoolType'
import { CountryCode } from 'interfaces/Country'

const SchoolTypeService = {
    list(country: number | CountryCode) {
        return ApiService.get(`/countries/${country}/schooltypes`).then((response) => {
            return response.data.content as SchoolType[]
        })
    },

    getAll() {
        return ApiService.get(`/schooltypes`).then((response) => {
            return response.data.content as SchoolType[]
        })
    },

    get(id: number) {
        return ApiService.get(`/schooltypes/${id}`).then((response) => {
            return response.data as SchoolType
        })
    },

    listSchoolTypesByRegion(regionId: number) {
        return ApiService.get(`/regions/${regionId}/school-types`).then((response) => {
            return response.data.content as SchoolType[]
        })
    },
}

export default SchoolTypeService
