import { CSVData } from 'components/FileUpload/CSVImport'
import { Taxonomy } from 'interfaces/Taxonomy'

export const IMPORT_STANDARD_HEADERS = ['subjectId', 'topic']

export enum ImportHeaderLabels {
    Subject = 'Subject',
    Topic = 'Topic',
    Subtopic = 'SubTopic',
    GradeLevel = 'Grade',
}

const validateCell = (cell: string | undefined) => cell && cell?.length > 0

export const returnMissingToastMessage = (cellData: string, columnName: string) =>
    `Cell data: (${cellData?.length > 0 ? cellData : 'empty'}) have no mathching values in ${columnName} row`

export const processTaxonomyImportCSVData = (
    cvsData: CSVData[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toast: any
) => {
    const array: Taxonomy[] = []
    for (const row of Object.values(cvsData)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const formattedRow: any = {}

        for (const [columnName, cellData] of Object.entries(row)) {
            switch (true) {
                case columnName === ImportHeaderLabels.Subject:
                    formattedRow['subjectId'] = cellData?.length ? +cellData : null
                    break
                case columnName === ImportHeaderLabels.Topic:
                case columnName.startsWith(ImportHeaderLabels.Subtopic):
                    if (validateCell(cellData)) {
                        const currentSubtopics = formattedRow['topicNames'] ?? []
                        formattedRow['topicNames'] = [...currentSubtopics, cellData]
                    } else {
                        toast.error(returnMissingToastMessage(cellData, columnName))
                        return
                    }
                    break
                case columnName === ImportHeaderLabels.GradeLevel:
                    if (validateCell(cellData)) {
                        formattedRow['gradeLevels'] = cellData.split(',').map((level) => +level)
                    } else {
                        toast.error(returnMissingToastMessage(cellData, columnName))
                        return
                    }
                    break
                default:
                    break
            }
        }

        array.push(formattedRow)
    }
    return array
}
