import React from 'react'
import { ReactComponent as CopySVG } from 'assets/icons/copy_icon.svg'
import styled from 'styled-components'
import { toast } from 'react-toastify'

interface Props {
    text: string
}

const Copy = styled(CopySVG)`
    display: inline-block;
    position: relative;
    left: 15px;
    top: 5px;
    cursor: pointer;
    :hover {
        fill: var(--primary-color);
    }
`
const CopyIcon = ({ text }: Props) => {
    //ref: https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript

    const fallbackCopyTextToClipboard = () => {
        const textArea = document.createElement('textarea')
        textArea.value = text

        // Avoid scrolling to bottom
        textArea.style.top = '0'
        textArea.style.left = '0'
        textArea.style.position = 'fixed'

        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()

        try {
            const successful = document.execCommand('copy')
            successful && toast.success('Copied text to clipboard')
        } catch (err) {
            console.error('Could not copied specified text:', err)
        }

        document.body.removeChild(textArea)
    }

    const copyTextToClipboard = () => {
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard()
            return
        }
        navigator.clipboard.writeText(text).then(() => toast.success('Copied text to clipboard'))
    }

    return <Copy onClick={copyTextToClipboard} />
}

export default CopyIcon
