import React, { useRef } from 'react'
import { Tooltip, TooltipText } from 'style'
import { FormGroup, InputErrorMessage } from './Input'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'

interface Props {
    label: string
    noMargin: boolean
    defaultValue?: string
    tooltip?: string
    onInput(value: string): void
    errorMessage?: string
    type?: string
    autoFocus?: boolean
    onEnter?: () => void
    className?: string
    showCharactersCount?: boolean
    disabled?: boolean
    placeholder?: string
    step?: string
}

const InputElement = React.forwardRef((props: Props, ref: React.LegacyRef<HTMLInputElement>) => {
    const charactersCountRef = useRef<HTMLInputElement>(null)

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onInput(event.target.value)
        if (charactersCountRef.current && props.showCharactersCount) {
            charactersCountRef.current.innerText = `⠀(characters: ${event.target.value.length})`
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && props.onEnter) {
            props.onEnter()
        }
    }

    return (
        <FormGroup noMargin={props.noMargin}>
            <label>
                {props.label} <b ref={charactersCountRef} />
                {props.tooltip && (
                    <Tooltip>
                        <InfoIcon />
                        <TooltipText
                            dangerouslySetInnerHTML={{
                                __html: props.tooltip,
                            }}
                        ></TooltipText>
                    </Tooltip>
                )}
            </label>
            <input
                placeholder={props.placeholder}
                disabled={!!props.disabled}
                ref={ref}
                defaultValue={props.defaultValue}
                onInput={handleInput}
                type={props.type}
                autoFocus={props.autoFocus}
                onKeyDown={handleKeyDown}
                className={props.className}
                step={props.step}
            />
            <InputErrorMessage>{props.errorMessage}</InputErrorMessage>
        </FormGroup>
    )
})
InputElement.displayName = 'InputElement'

export default InputElement
