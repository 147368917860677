import { ContentPrefixes } from 'utils/constants/ContentPrefix'
import ApiService from './ApiService'

const FileUploadService = {
    upload(file: Blob, contentPrefix: ContentPrefixes) {
        const formatData = new FormData()
        formatData.append('file', file)
        return ApiService.post(`/file-upload?contentPrefix=${contentPrefix}`, formatData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then((response) => {
            return {
                filename: response.data.filename as string,
                contentPrefix: response.data.contentPrefix as string,
                url: response.data.URL as string,
            }
        })
    },
}

export default FileUploadService
