import React from 'react'
import {
    AnswerContainer,
    AnswerText,
    BestAnswerWrapper,
    ActionWrapper,
    MobileActionsSection,
    ActionsSection,
    AttachmentWrapper,
    CommentSection,
    HeaderSection,
    ProfileCredentials,
    ProfileSection,
    Separator,
    ButtonWrapper,
    MobileCommentsCountWrapper,
} from './DiscussionForumAnswerCard.css'
import DiscussionForumContentPreview from './DiscussionForumContentPreview'
import { ReactComponent as LikeIcon } from 'assets/icons/like.svg'
import { ReactComponent as LikedIcon } from 'assets/icons/liked.svg'
import { ReactComponent as BestAnswerIcon } from 'assets/icons/best-answer.svg'
import { ReactComponent as CommentIcon } from 'assets/icons/comment.svg'
import { Body1 } from 'components/elements/Text'
import ProfilePicture from './ProfilePicturePreview'
import { DiscussionForumAnswer } from 'interfaces/DiscussionForumAnswer'
import ComplexKnowCard from './ComplexKnowCard'

interface AnswerCardProps {
    answer: DiscussionForumAnswer
    forumQuestionUuid: string
}

const AnswerCard = (props: AnswerCardProps) => {
    const { liked, likes, text, user, attachments, know, commentCount, isAcceptedAnswer } = props.answer

    return (
        <AnswerContainer>
            <HeaderSection>
                <ProfileSection>
                    <ProfilePicture user={user} size={55} boxShadow />
                    <ProfileCredentials>
                        <h3>{user.name}</h3>
                    </ProfileCredentials>
                </ProfileSection>
                {isAcceptedAnswer ? (
                    <BestAnswerWrapper>
                        <Body1>Best answer</Body1>
                        <BestAnswerIcon />
                    </BestAnswerWrapper>
                ) : null}
            </HeaderSection>
            {text ? <AnswerText>{text}</AnswerText> : null}
            {attachments ? (
                <AttachmentWrapper>
                    {attachments.map((attachment, index) => (
                        <React.Fragment key={index}>
                            <DiscussionForumContentPreview key={index} attachmentData={attachment} />
                        </React.Fragment>
                    ))}
                    {know ? <ComplexKnowCard know={know} /> : null}
                </AttachmentWrapper>
            ) : null}
            <MobileCommentsCountWrapper>
                <ButtonWrapper>
                    <ActionWrapper>
                        <strong>{`${commentCount} comments`}</strong>
                    </ActionWrapper>
                </ButtonWrapper>
                <MobileActionsSection>
                    <ActionWrapper>
                        <CommentIcon />
                        <Body1>{commentCount}</Body1>
                    </ActionWrapper>
                    <ActionWrapper>
                        {liked ? <LikedIcon /> : <LikeIcon />}
                        <Body1>{likes ?? 0}</Body1>
                    </ActionWrapper>
                </MobileActionsSection>
            </MobileCommentsCountWrapper>
            <Separator />
            <CommentSection>
                <ActionsSection>
                    <ActionWrapper>
                        <CommentIcon />
                        <p>{commentCount}</p>
                    </ActionWrapper>
                    <ActionWrapper>
                        {liked ? <LikedIcon /> : <LikeIcon />}
                        <Body1>{likes ?? 0}</Body1>
                    </ActionWrapper>
                </ActionsSection>
            </CommentSection>
        </AnswerContainer>
    )
}

export default AnswerCard
