import React, { useRef, useState } from 'react'
import { Form } from '@unform/web'
import Box from 'components/elements/Box'
import Button from 'components/elements/Button'
import { Body1 } from 'components/elements/Text'
import { Checkbox, Input } from 'components/inputs/Input'
import theme from 'lib/constants/theme'
import { FormHandles, SubmitHandler } from '@unform/core'
import { User, UserSocialRequest } from 'interfaces/User'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import UserService from 'services/UserService'
import { badRequestFormErrors } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import UploadWrapper from 'components/FileUpload/UploadWrapper'
import FileUploadService from 'services/FileUploadService'
import { ContentPrefixes } from 'utils/constants/ContentPrefix'

const loadingAreas = {
    delete: 'deleteUser',
    socialFields: 'socialFields',
    bannerUpload: 'bannerUpload',
}

interface Props {
    user: User
    fetchData: () => void
}

const UserManualSocialForm = ({ user, fetchData }: Props) => {
    const [uploadedBanner, setUploadedBanner] = useState(user.profileBannerFilePath)
    const userSocialFormRef = useRef<FormHandles>(null)
    const userUuid = user.uuid

    const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const innerFile = event.target.files![0]
        if (!innerFile) {
            toast.error('You need to upload an image!')
            return
        }
        const maxFileSize = 25 * 1000000
        if (!['image/png', 'image/webp', 'image/jpeg'].includes(innerFile.type)) {
            toast.error('Please upload your file as a .png, .jpg, or .webp!')
            return
        }

        if (innerFile.size > maxFileSize) {
            toast.error('Sorry, the file is too big. The maximum file size is 25 MB.')
            return
        }

        trackPromise(
            FileUploadService.upload(innerFile, ContentPrefixes.ProfileBanner)
                .then(({ url }) => {
                    setUploadedBanner(url)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.InvalidMIMEType) {
                            toast.error('Please choose a different file format.')
                        } else {
                            error.handleUnknown('An error occurred while uploading the image')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.bannerUpload
        )
    }

    const handleUserSocialFieldsUpdate: SubmitHandler<UserSocialRequest & { removeUserBanner: boolean }> = ({
        profileBannerFileName,
        removeUserBanner,
        ...rest
    }) => {
        userSocialFormRef.current!.setErrors({})

        const request: UserSocialRequest = {
            profileBannerFileName: !removeUserBanner && uploadedBanner ? uploadedBanner?.split('/').pop() ?? null : null,
            ...rest,
        }

        trackPromise(
            UserService.update(userUuid, request)
                .then(() => {
                    fetchData()
                    toast.success(`User social fields has been changed`)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.FormValidation) {
                            badRequestFormErrors(error, userSocialFormRef.current!)
                        } else {
                            error.handleUnknown('An error occurred while changing user social fields.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.socialFields
        )
    }

    return (
        <Box>
            <Body1 color={theme.colors.white} marginBottom={15}>
                Social Fields
            </Body1>
            <Form
                ref={userSocialFormRef}
                onSubmit={handleUserSocialFieldsUpdate}
                initialData={{
                    tiktokUsername: user.tiktokUsername,
                    instagramUsername: user.instagramUsername,
                    profileBannerFileName: user.profileBannerFilePath,
                }}
            >
                <Input name="tiktokUsername" placeholder="Tiktok username" />
                <Input name="instagramUsername" placeholder="Instagram username" />
                {uploadedBanner ? (
                    <img
                        width={800}
                        height={400}
                        src={uploadedBanner}
                        style={{ objectFit: 'scale-down' }}
                        alt="uploaded banner"
                    />
                ) : null}
                <UploadWrapper
                    handleFileUpload={onChangeFile}
                    area={loadingAreas.bannerUpload}
                    acceptRules="image/png, image/webp, image/jpeg"
                >
                    <p>Click here to select a User profile banner to be uploaded as the URL.</p>
                </UploadWrapper>
                <Checkbox name="removeUserBanner" placeholder="Remove user banner" />
                <Button fullWidth loadingArea={loadingAreas.socialFields}>
                    Save
                </Button>
            </Form>
        </Box>
    )
}

export default UserManualSocialForm
