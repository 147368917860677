import React from 'react'
import theme from 'lib/constants/theme'
import styled from 'styled-components'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'

interface Props {
    title: string
    text: string
    options?: {
        label: string
        target: string
    }[]
}

const Container = styled.div`
    width: 100%;
    padding: 15px;
    display: grid;
    gap: 15px;
    border-radius: ${theme.borderRadius.normal};
    border: 1px solid ${theme.colors.accentBlue};
    background-color: ${theme.colors.lightViolet};
    .options {
        display: flex;

        grid-gap: 15px;
        a {
            display: flex;
            grid-gap: 5px;
            color: ${theme.colors.accentBlue};
        }
        svg {
            fill: ${theme.colors.knowunityBlueDark};
            position: relative;
            top: 1px;
        }
        @media (max-width: 750px) {
            flex-direction: column;
        }
    }
    margin: 5px 0 15px 0;
`

const ExplanationBanner = ({ title, text, options }: Props) => {
    return (
        <Container>
            <h3>{title}</h3>
            <p>{text}</p>
            {options?.length ? (
                <div className="options">
                    {options.map((option, i) => (
                        <a href={option.target} key={`${option.label}-${i}`}>
                            <InfoIcon />
                            {option.label}
                        </a>
                    ))}
                </div>
            ) : null}
        </Container>
    )
}

export default ExplanationBanner
