import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Knower, PromoteKnower } from 'interfaces/Knower'
import { Link } from 'react-router-dom'
import KnowerService from 'services/KnowerService'
import { formatAmount, formatDateTime } from 'utils'
import { ApiError } from 'services/ApiService'
import KnowerVerificationStatus from 'components/features/knowers/KnowerVerificationStatus'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import Spinner from 'components/elements/Spinner'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import { toast } from 'react-toastify'
import Button from 'components/elements/Button'
import routes from 'lib/constants/routes'
import CopyIcon from 'components/elements/CopyIcon'
import { trackPromise } from 'react-promise-tracker'
import { useToggleState } from 'utils/hooks/useToggleState'
import Modal from 'components/modules/Modal'
import { Form } from '@unform/web'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Input } from 'components/inputs/Input'
import GradeSelection from 'components/forms/GradeSelection/GradeSelection'
import KnowerInfo from './KnowerInfo'

const loadingAreas = {
    form: 'promoteKnowerForm',
    save: 'promoteKnowerSave',
}

interface Props {
    knowerUuid: string
}

const KnowerGeneral = (props: Props) => {
    const [knower, setKnower] = useState<Knower | null>(null)
    const [showPromoteKnowerModal, togglePromoteKnowerModal] = useToggleState(false)
    const formRef = useRef<FormHandles>(null)

    const fetchKnower = useCallback(() => {
        KnowerService.get(props.knowerUuid)
            .then((knower) => {
                setKnower(knower)
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('An error occurred while getting knower.')
                } else {
                    throw error
                }
            })
    }, [props.knowerUuid])

    useEffect(() => {
        fetchKnower()
    }, [fetchKnower])

    const updateKnower = (knower: Knower) => setKnower(knower)

    const handleSubmit: SubmitHandler<PromoteKnower> = (data) => {
        formRef.current!.setErrors({})

        const { grades, startDate, endDate } = data

        if (!grades?.length) {
            formRef.current!.setFieldError('grades', 'This field is required')
            return
        }
        if (isNaN(new Date(startDate).getTime())) {
            formRef.current!.setFieldError('startDate', 'Please specify the correct date')
            return
        }
        if (isNaN(new Date(endDate).getTime())) {
            formRef.current!.setFieldError('endDate', 'Please specify the correct date')
            return
        }
        if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
            formRef.current!.setFieldError('endDate', 'The end date must be after the start date.')
            return
        }

        const requestBody = {
            grades: grades,
            startDate: new Date(startDate).toISOString(),
            endDate: new Date(endDate).toISOString(),
        }

        trackPromise(
            KnowerService.updatePromotedKnower(props.knowerUuid, requestBody)
                .then(() => {
                    toast.success('This Knower is now Promoted.')
                    togglePromoteKnowerModal()
                })
                .catch((error: ApiError) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while updating promoting Knower.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.save
        )
    }

    return (
        <>
            {knower ? (
                <>
                    <Box>
                        <Stack>
                            <h3>Information</h3>
                            <KnowerInfo knower={knower} />
                            <p>
                                <strong>UUID:</strong>&nbsp;
                                {knower.uuid}
                                <CopyIcon text={knower.uuid} />
                            </p>
                            <p>
                                <strong>User:</strong>&nbsp;
                                <Link to={routes.userRoute(knower.user.uuid)}>
                                    {knower.user.name} ({knower.user.uuid})
                                </Link>
                                <CopyIcon text={knower.user.uuid} />
                            </p>
                            <p>
                                <strong>Became knower on:</strong>&nbsp;
                                {formatDateTime(knower.createdOn)}
                            </p>
                            <p>
                                <strong>Balance:</strong>&nbsp;
                                {formatAmount(knower.balance ?? 0, knower.currency ?? '')}
                            </p>
                            <p>
                                <strong>Follower Count:</strong>&nbsp;
                                {knower.followerCount}
                            </p>
                        </Stack>
                    </Box>

                    <Box>
                        <h3>Verification Status</h3>
                        <KnowerVerificationStatus knower={knower} onUpdate={updateKnower} />
                    </Box>

                    <Box>
                        <h3>Actions</h3>
                        <Button type="button" onClick={togglePromoteKnowerModal} fullWidth>
                            Promote Knower
                        </Button>
                    </Box>
                </>
            ) : (
                <Spinner forceShow />
            )}
            <Modal show={showPromoteKnowerModal} onClose={togglePromoteKnowerModal} title="Promote knower">
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <GradeSelection name="grades" label="Grades" />
                    <Input name="startDate" placeholder="Start date" type="date" />
                    <Input name="endDate" placeholder="End date" type="date" />
                    <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                        Promote Knower
                    </Button>
                </Form>
            </Modal>
        </>
    )
}

export default KnowerGeneral
