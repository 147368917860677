import React, { useEffect, useRef, useState } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { badRequestFormErrors } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { useHistory } from 'react-router-dom'
import Button from 'components/elements/Button'
import { Checkbox, Input } from 'components/inputs/Input'
import routes from 'lib/constants/routes'
import Editor from 'components/modules/Editor/Editor'
import { SeoTopic, UpdateSeoTopic } from 'interfaces/SeoTopic'
import SeoTopicService from './SeoTopicService'
import { isConversionElementPresent, isHeading1Present, isSlugInvalid } from 'lib/features/glossary'

interface FormData {
    description: string
    slug: string
    isPublished: boolean
}

interface InitialSeoTopicData {
    title?: string | null
    slug?: string | null
    description?: string | null
}

interface Props {
    seoTopic: SeoTopic | null
    isNew: boolean
    updateSeoTopic: (seoTopic: SeoTopic) => void
    seoSubjectUuid: string
}

const loadingAreas = {
    form: 'topicsForm',
    save: 'saveTopic',
}

const SeoTopicForm = ({ isNew, seoTopic, updateSeoTopic, seoSubjectUuid }: Props) => {
    const [initialData, setInitialData] = useState<InitialSeoTopicData>()
    const [htmlContent, setHtmlContent] = useState('')
    const formRef = useRef<FormHandles>(null)
    const history = useHistory()

    useEffect(() => {
        if (!seoTopic) return

        const initialData: InitialSeoTopicData = {
            ...seoTopic,
        }
        setHtmlContent(seoTopic.contentHtml)
        setInitialData(initialData)
    }, [seoTopic])

    const handleSubmit: SubmitHandler<FormData> = (data) => {
        formRef.current!.setErrors({})

        if (!htmlContent) {
            toast.error('Please enter the the content of the topic!')
            return
        }

        if (!isConversionElementPresent(htmlContent)) return

        if (isHeading1Present(htmlContent)) return

        if (isSlugInvalid(data.slug)) {
            formRef.current?.setFieldError(
                'slug',
                'Slug must consist of only lower case letters (a-z), numbers [0-9], and dashes (-)'
            )
            return
        }

        const requestBody: UpdateSeoTopic = {
            contentHtml: htmlContent,
            seoSubjectUuid,
            title: seoTopic?.title ?? '',
            ...data,
        }

        let handleFunc
        if (!isNew && seoTopic?.uuid) {
            handleFunc = SeoTopicService.update(seoTopic.uuid, requestBody)
        } else {
            handleFunc = SeoTopicService.create(requestBody)
        }

        trackPromise(
            handleFunc
                .then((topic) => {
                    updateSeoTopic(topic)
                    toast.success('The changes to seo topic have been saved!')
                    history.push(routes.seoTopicRoute(seoSubjectUuid, topic.uuid))
                })
                .catch((error: ApiError) => {
                    if (error.type === ErrorTypes.FormValidation) {
                        badRequestFormErrors(error, formRef.current!)
                    } else {
                        error.handleUnknown('An error occurred while saving topic.')
                    }
                }),
            loadingAreas.save
        )
    }

    return (
        <div>
            <h3>{isNew ? 'Create new' : 'Update'} Seo Topic</h3>
            <Form ref={formRef} initialData={initialData} onSubmit={handleSubmit}>
                <Input name="slug" placeholder="Slug (used for linking)" />
                <Checkbox name="isPublished" placeholder="Is published" />
                <Input name="description" placeholder="Keywords (separated by commas)" />
                <Editor value={htmlContent} onChange={setHtmlContent} />
                <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                    {isNew ? 'Save' : 'Update'}
                </Button>
            </Form>
        </div>
    )
}

export default SeoTopicForm
