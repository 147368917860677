import { SchoolHoliday, SchoolHolidayRequest } from 'interfaces/SchoolHoliday'
import ApiService from './ApiService'

const SchoolHolidaysService = {
    list(page: number) {
        return ApiService.get(`/school-holidays?page=${page}`).then((response) => {
            return {
                schoolHolidays: response.data.content as SchoolHoliday[],
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    get(uuid: string) {
        return ApiService.get(`/school-holiday/${uuid}`).then((response) => {
            return response.data as SchoolHoliday
        })
    },

    create(data: SchoolHolidayRequest) {
        return ApiService.post(`/school-holiday`, data).then((response) => {
            return response.data as SchoolHoliday
        })
    },

    update(uuid: string, data: SchoolHolidayRequest) {
        return ApiService.patch(`/school-holiday/${uuid}`, data).then((response) => {
            return response.data as SchoolHoliday
        })
    },

    delete(uuid: string) {
        return ApiService.delete(`/school-holiday/${uuid}`).then((response) => {
            return response.data as SchoolHoliday
        })
    },
}

export default SchoolHolidaysService
