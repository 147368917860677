import React from 'react'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import FlashCardsDisplay from 'pages/Knows/FlashCardsDisplay'
import { Flashcard } from 'interfaces/Flashcard'
import { KnowFormat, Know as KnowInterface } from 'interfaces/Know'
import KnowContent from 'components/features/knows/KnowContent'
import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'

interface KnowContentSectionProps {
    know: KnowInterface
    flashCards: Flashcard[] | null
    fetchData: VoidFunction
    updateFlashcards: (flashcards: Flashcard[]) => void
    submitFlashcardsUpdate: VoidFunction
}

export const KnowContentSection = ({
    know,
    flashCards,
    fetchData,
    updateFlashcards,
    submitFlashcardsUpdate,
}: KnowContentSectionProps) => {
    const haveFlashcards = flashCards && flashCards?.length > 0
    const haveAudio = know?.audioFilePath && know.knowFormat === KnowFormat.Audio

    return (
        <Box>
            <Stack>
                {haveAudio ? (
                    <>
                        <audio src={know.audioFilePath!} controls />
                        <AudioPlayer src={know.audioFilePath!} />
                    </>
                ) : (
                    <>
                        {haveFlashcards ? (
                            <FlashCardsDisplay
                                flashCards={flashCards}
                                updateFlashcards={updateFlashcards}
                                submitFlashcardsUpdate={submitFlashcardsUpdate}
                            />
                        ) : (
                            <KnowContent know={know} refreshData={fetchData} />
                        )}
                    </>
                )}
            </Stack>
        </Box>
    )
}
