import ApiService from './ApiService'
import { SchoolType } from 'interfaces/SchoolType'
import { Country, CountryCode } from 'interfaces/Country'
import { Region } from 'interfaces/Region'
import { ExamBoard } from 'interfaces/ExamBoard'
import { ExamType } from 'interfaces/ExamType'
import { LanguageInterface } from 'interfaces/Language'

const CountriesService = {
    list() {
        return ApiService.get('countries?showUnlaunched=true').then((response) => {
            return response.data.content as Country[]
        })
    },

    getCountrySchoolTypes(id: number) {
        return ApiService.get(`countries/${id}/schooltypes`).then((response) => {
            return response.data.content as SchoolType[]
        })
    },

    listCountryRegions(id: number) {
        return ApiService.get(`countries/${id}/regions`).then((response) => {
            return response.data.content as Region[]
        })
    },

    listExamBoards(id: number) {
        return ApiService.get(`countries/${id}/exam-boards`).then((response) => {
            return response.data.content as ExamBoard[]
        })
    },

    listExamType(id: number) {
        return ApiService.get(`countries/${id}/exam-types`).then((response) => {
            return response.data.content as ExamType[]
        })
    },

    listLanguages(id: number | CountryCode) {
        return ApiService.get(`countries/${id}/content-languages`).then((response) => {
            return response.data.content as LanguageInterface[]
        })
    },
}

export default CountriesService
