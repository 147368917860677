import React, { FunctionComponent } from 'react'
import { usePromiseTracker } from 'react-promise-tracker'
import styled from 'styled-components'

const Wrapper = styled.div`
    display: grid;
    place-content: center;
    margin: 10px 0;
`

export const SpinnerElement = styled.div`
    pointer-events: none;
    width: 2.5em;
    height: 2.5em;
    border: 0.4em solid transparent;
    border-color: #eee;
    border-top-color: #3e67ec;
    border-radius: 50%;
    animation: loadingspin 1s linear infinite;

    @keyframes loadingspin {
        100% {
            transform: rotate(360deg);
        }
    }
`

interface Props {
    area?: string
    forceShow?: boolean
}

const Spinner: FunctionComponent<Props> = (props) => {
    const { promiseInProgress } = usePromiseTracker({ area: props.area })
    if (promiseInProgress || props.forceShow) {
        return (
            <Wrapper>
                <SpinnerElement />
            </Wrapper>
        )
    } else {
        return null
    }
}

export default Spinner
