import React, { ReactNode, useRef } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { Input } from 'components/inputs/Input'
import { useToggleState } from 'utils/hooks/useToggleState'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import Button from './Button'
import styled from 'styled-components'
import { Body2 } from './Text'
import theme from 'lib/constants/theme'

const Container = styled.div`
    overflow-wrap: anywhere;
`

const InputWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    max-width: 400px;
`

type FormData = {
    [x: string]: string
}

interface Props {
    name: string
    value: string | number
    itemUuid: string
    onSubmit: (uuid: string, name: string, value: string | number, hideForm: () => void) => void
    children?: ReactNode
    activeTooltip?: string
}
const EditRecordInline = ({ name, value, onSubmit: onSumbit, children, itemUuid, activeTooltip }: Props) => {
    const [showForm, toggleForm] = useToggleState(false)
    const formRef = useRef<FormHandles>(null)

    const handleSubmit: SubmitHandler<FormData> = (data) => {
        const { [`${name}`]: recordValue } = data
        onSumbit(itemUuid, name, recordValue, toggleForm)
    }

    const handleDoubleClickShowForm = () => {
        !showForm && toggleForm()
    }

    return (
        <Container onDoubleClick={handleDoubleClickShowForm}>
            {showForm ? (
                <Form ref={formRef} onSubmit={handleSubmit} initialData={{ [`${name}`]: value }}>
                    <InputWrapper>
                        <Input name={name} placeholder="" />
                        <Button fullWidth={false} icon={<SaveIcon />}></Button>
                    </InputWrapper>
                    {activeTooltip ? (
                        <Body2 fontFamily="Inter" color={theme.colors.white} fontWeigth={400}>
                            {activeTooltip}
                        </Body2>
                    ) : null}
                </Form>
            ) : (
                children
            )}
        </Container>
    )
}

export default EditRecordInline
