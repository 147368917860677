import React, { useState, useEffect } from 'react'
import { ApiError } from 'services/ApiService'
import ChatService from 'services/ChatService'
import { Chat } from 'interfaces/Chat'
import CopyIcon from 'components/elements/CopyIcon'
import routes from 'lib/constants/routes'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import theme from 'lib/constants/theme'

const AlertBanner = styled.div`
    border-radius: 5px;
    background-color: ${theme.colors.secondaryRed};
    padding: 10px;
`

interface Props {
    chatUuid: string
    areAllReportsAutomatic?: boolean
}

const ReportedChatInformation = ({ chatUuid, areAllReportsAutomatic }: Props) => {
    const [reportedChat, setReportedChat] = useState<Chat>()

    useEffect(() => {
        ChatService.get(chatUuid)
            .then((chat) => setReportedChat(chat))
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('An error occurred while getting reported chat.')
                } else {
                    throw error
                }
            })
    }, [chatUuid])

    return (
        <>
            {reportedChat ? (
                <>
                    <p>
                        <strong>Chat Type: </strong>
                        {`
                            ${
                                reportedChat.isGroupChat
                                    ? `${reportedChat.isPrivate ? 'private' : 'public'} group chat`
                                    : '1:1'
                            }
                        `}
                    </p>
                    {areAllReportsAutomatic && reportedChat.isPrivate ? (
                        <AlertBanner>
                            This report was automatically created and concerns a 1:1 chat or a private group chat. Please
                            only accept it if it concerns minor safety or adult nudity and sexual activities.
                        </AlertBanner>
                    ) : null}
                    {reportedChat.chatType === 'GROUP' ? (
                        <>
                            <p>
                                <strong>Name: </strong>
                                {reportedChat.name}
                            </p>
                            <p>
                                <strong>Description: </strong>
                                {reportedChat.description}
                            </p>
                        </>
                    ) : null}
                    <p>
                        <strong>Chat Owner: </strong>
                        <Link
                            to={routes.userRoute(reportedChat.chatOwnerUserUuid)}
                        >{`${reportedChat.chatOwnerUserUuid}`}</Link>
                        <CopyIcon text={reportedChat.chatOwnerUserUuid} />
                    </p>
                    {reportedChat?.subject ? (
                        <p>
                            <strong>Subject: </strong>
                            {reportedChat.subject.name}
                        </p>
                    ) : null}
                    {reportedChat?.grades?.length ? (
                        <p>
                            <strong>Grades: </strong>
                            {reportedChat.grades.map((grade) => grade.longName).join(', ')}
                        </p>
                    ) : null}
                    {reportedChat?.chatPictureUrl ? (
                        <p>
                            <strong>Picture: </strong>
                            <img src={reportedChat.chatPictureUrl} alt="Chat avatar" width={250} height={250} />
                        </p>
                    ) : null}
                </>
            ) : (
                <p>
                    <strong>Chat cannot be found (possibly deleted)</strong>
                </p>
            )}
        </>
    )
}

export default ReportedChatInformation
