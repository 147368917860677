import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Form } from '@unform/web'
import { formatAmount, formatDate } from 'utils'
import { Input } from 'components/inputs/Input'
import { SubmitHandler, FormHandles } from '@unform/core/typings/types'
import { ApiError } from 'services/ApiService'
import { TransactionType } from 'interfaces/Transaction'
import { Knower } from 'interfaces/Knower'
import KnowerService from 'services/KnowerService'
import { trackPromise } from 'react-promise-tracker'
import RefactoredTable from 'components/modules/RefactoredTable'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import Button from 'components/elements/Button'
import { useToggleState } from 'utils/hooks/useToggleState'
import TransactionService from 'services/TransactionService'
import Box from 'components/elements/Box'
import routes from 'lib/constants/routes'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { returnPayoutStatus } from 'lib/features/payouts'
import PayoutRequestService from 'services/PayoutRequestService'

interface Props {
    knowerUuid: string
}

interface FormData {
    amount: string
    description: string
}

const loadingAreas = {
    transactions: 'knowerTransactionContainer',
    requests: 'knowerPayoutRequestContainer',
    save: 'saveTransaction',
}

const KnowerPayments = ({ knowerUuid }: Props) => {
    const createTransactionFormRef = useRef<FormHandles>(null)
    const [knower, setKnower] = useState<Knower>()
    const [refreshData, toggleRefresh] = useToggleState(false)

    useEffect(() => {
        trackPromise(
            KnowerService.get(knowerUuid)
                .then((knower) => {
                    setKnower(knower)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting knower.')
                }),
            loadingAreas.save
        )
    }, [knowerUuid])

    const fetchTransactions = useCallback(
        (page: number) => {
            return trackPromise(
                TransactionService.listByKnowerAdmin(knowerUuid, page)
                    .then((data) => {
                        if (page !== data.page) return

                        return {
                            totalPages: data.totalPages,
                            totalElements: data.totalElements,
                            elements: data.transactions.map((t, i) => ({
                                id: `transaction-knower-${t.knower.uuid}-${i}`,
                                columns: [
                                    t.type,
                                    formatAmount(t.amount, t.currency),
                                    formatDate(t.createdOn),
                                    t.description,
                                    t?.employeeUserUuid ? (
                                        <Link key={t.employeeUserUuid} to={routes.userRoute(t.employeeUserUuid)}>
                                            {t.employeeUserUuid}
                                        </Link>
                                    ) : (
                                        'No one'
                                    ),
                                ],
                            })),
                        }
                    })
                    .catch((error: ApiError) => {
                        error.handleUnknown('An error occurred while getting transactions of knower.')
                    }),
                loadingAreas.transactions
            )
        },
        [knowerUuid]
    )

    const fetchPayoutRequests = useCallback(
        (page: number) => {
            return trackPromise(
                PayoutRequestService.listForKnower(knowerUuid, page)
                    .then((data) => {
                        if (page !== data.page) return

                        return {
                            totalPages: data.totalPages,
                            totalElements: data.totalElements,

                            elements: data.payouts.map((p) => ({
                                id: p.uuid,
                                columns: [
                                    p.uuid,
                                    p.fullName,
                                    returnPayoutStatus(p),
                                    p.destination,
                                    formatAmount(p.amount, p.currency),
                                    formatDate(p.createdOn),
                                ],
                                onClick: routes.payoutRequestRoute(p.uuid),
                            })),
                        }
                    })
                    .catch((error: ApiError) => {
                        error.handleUnknown('An error occurred while getting transactions of knower.')
                    }),
                loadingAreas.requests
            )
        },
        [knowerUuid]
    )

    const handleCreateTransactionSubmit: SubmitHandler<FormData> = (data) => {
        if (!knower) return

        const amount = parseInt(data.amount)
        if (!window.confirm(`Do you want to carry out this transaction for ${formatAmount(amount, knower.currency)} ?`))
            return

        createTransactionFormRef.current!.setErrors({})

        trackPromise(
            TransactionService.createTransaction(knowerUuid, amount, TransactionType.Manual, data.description)
                .then(() => {
                    toast.success('New transaction has been created!')
                    createTransactionFormRef.current?.reset()
                    toggleRefresh()
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while creating transaction.')
                }),
            loadingAreas.save
        )
    }

    return (
        <>
            <Box>
                <h3>Create transaction</h3>
                <Form ref={createTransactionFormRef} onSubmit={handleCreateTransactionSubmit}>
                    <p>Positive numbers increase the balance and negative numbers decrease the balance of the knower.</p>
                    <Input name="amount" type="number" placeholder="Amount in lowest currency unit (5 € → 500)" />
                    <Input name="description" placeholder="Description" />

                    <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                        Create transaction
                    </Button>
                </Form>
            </Box>
            <Box>
                <h3>Payout Requests</h3>
                <RefactoredTable
                    columns={['#', 'Name', 'Status', 'Type', 'Amount', 'Created on']}
                    loadData={fetchPayoutRequests}
                    loadingArea={loadingAreas.requests}
                    refetchKey={undefined}
                />
            </Box>
            <Box>
                <h3>Transactions</h3>
                <RefactoredTable
                    columns={['Type', 'Amount', 'Created on', 'Description', 'Initiated by']}
                    loadData={fetchTransactions}
                    loadingArea={loadingAreas.transactions}
                    refetchKey={refreshData}
                />
            </Box>
        </>
    )
}

export default KnowerPayments
