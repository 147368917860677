import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import theme from 'lib/constants/theme'
import { filterMenuItemsBySeachQuery, filterMenuSubItemsBySearchQuery } from 'utils/menu'
import { Body1, Headline4 } from 'components/elements/Text'
import TopSearchBar from './TopSearchBar'
import Meta from 'components/modules/Head'

const Container = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: start;
    transform: all 0.3s ease-in-out;
    gap: 15px;
    svg {
        fill: ${theme.colors.captionGray};
    }
`

const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;
    grid-gap: 15px;
    padding-bottom: 15px;
    p {
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        line-height: 24px;
        color: ${theme.colors.snowWhite};
    }
`

const Card = styled(Link)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    background: ${theme.colors.lightViolet};
    color: white;
    border-radius: ${theme.borderRadius.normal};
    padding: 20px 0;
    width: 285px;
    height: 150px;
    &:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        background: ${theme.colors.hoverViolet};
    }
`

const CategoryTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`

const Dashboard = () => {
    const [searchQuery, setSearchQuery] = useState('')

    const onSubmit = (query: string) => setSearchQuery(query)

    const onSearchDebounce = (query: string) => setSearchQuery(query)

    return (
        <Container>
            <Meta title="Admin Dashboard" />
            <TopSearchBar onSearch={onSubmit} onDebounce={onSearchDebounce} />
            {filterMenuItemsBySeachQuery(searchQuery).map(
                (menuItem) =>
                    !menuItem.hideOnDashboard && (
                        <>
                            <CategoryTitle>
                                {menuItem.icon}
                                <Headline4 color={theme.colors.captionGray}>{menuItem.title}</Headline4>
                            </CategoryTitle>
                            {menuItem.subItems?.length ? (
                                <Grid>
                                    {filterMenuSubItemsBySearchQuery(menuItem.subItems, searchQuery)?.map((subItem) => (
                                        <Card to={subItem.link} key={subItem.title}>
                                            {subItem.icon}
                                            <Body1>{subItem.title}</Body1>
                                        </Card>
                                    ))}
                                </Grid>
                            ) : (
                                <Card to={menuItem.link}>
                                    {menuItem.icon}
                                    <Body1 color={theme.colors.white}>{menuItem.title}</Body1>
                                </Card>
                            )}
                        </>
                    )
            )}
        </Container>
    )
}

export default Dashboard
