import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { Knower } from 'interfaces/Knower'
import React, { useRef } from 'react'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import KnowerService from 'services/KnowerService'
import Button from 'components/elements/Button'
import { Checkbox } from 'components/inputs/Input'

interface Props {
    knower: Knower
    onUpdate(knower: Knower): void
}

interface FormData {
    isVerified: boolean
    isInternal: boolean
    isDiscussionForumPartner: boolean
}

const KnowerVerificationStatus = (props: Props) => {
    const formRef = useRef<FormHandles>(null)

    const handleSubmit: SubmitHandler<FormData> = (data) => {
        formRef.current!.setErrors({})

        KnowerService.updateVerificationStatus(props.knower.uuid, data)
            .then(() => {
                props.onUpdate({
                    ...props.knower,
                    isDiscussionForumPartner: data.isDiscussionForumPartner,
                    verified: data.isVerified,
                    internal: data.isInternal,
                })
                toast.success('The verification status has been saved.')
            })
            .catch((error: ApiError) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('An error occurred while updating verification status.')
                } else {
                    throw error
                }
            })
    }

    const initialData = {
        isVerified: props.knower.verified,
        isInternal: props.knower.internal,
        isDiscussionForumPartner: props.knower.isDiscussionForumPartner,
    }

    return (
        <div>
            <Form ref={formRef} initialData={initialData} onSubmit={handleSubmit}>
                <Checkbox name="isVerified" placeholder="Verified" />
                <Checkbox name="isInternal" placeholder="Staff" />
                <Checkbox name="isDiscussionForumPartner" placeholder="Discussion Forum Partner" />
                <Button fullWidth>Save</Button>
            </Form>
        </div>
    )
}

export default KnowerVerificationStatus
