import React, { useEffect, useRef, useState } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { badRequestFormErrors } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { useHistory } from 'react-router-dom'
import Button from 'components/elements/Button'
import { Input, Textarea } from 'components/inputs/Input'
import routes from 'lib/constants/routes'
import Box from 'components/elements/Box'
import {
    AdditionCareerPartnerInformation,
    CareerPartner,
    CareerPartnerFacts,
    CareerPartnerRequest,
} from 'interfaces/CareerPartner'
import CareerPartnerService from 'services/CareerPartnerService'
import AdditionalCareerPartnerInformationForm from './AdditionalCareerPartnerInformationForm'
import CareerPartnerFactsForm from './CareerPartnerFactsForm'
import Stack from 'components/elements/Stack'
import FileUploadService from 'services/FileUploadService'
import { ContentPrefixes } from 'utils/constants/ContentPrefix'
import UploadWrapper from 'components/FileUpload/UploadWrapper'
import { Body1 } from 'components/elements/Text'
import theme from 'lib/constants/theme'

const loadingAreas = {
    form: 'careerPartnerForm',
    save: 'saveCareerPartner',
    imageUpload: 'careerPartnerImageUpload',
    backgroundImageUpload: 'backgroundImageUpload',
}

type FormData = Omit<CareerPartnerRequest, 'additionalInformation' | 'facts' | 'userTargetingId'>

interface Props {
    isNew: boolean
    careerPartner: CareerPartner | null
    updateCareerPartner: (careerPartner: CareerPartner) => void
    renderTargetingButton: () => React.ReactNode
    userTargetingId: number | null
}

const CareersPartnersForm = ({
    isNew,
    careerPartner,
    updateCareerPartner,
    renderTargetingButton,
    userTargetingId: requestUserTargetingId,
}: Props) => {
    const [initialData, setInitialData] = useState<FormData>()
    const [careerPartnersFacts, setCareerPartnersFacts] = useState<CareerPartnerFacts[]>(careerPartner?.facts ?? [])
    const [additionalCareerPartnerInformation, setAdditionalCareerPartnerInformation] = useState<
        AdditionCareerPartnerInformation[]
    >(careerPartner?.additionalInformation ?? [])
    const [iconLink, setIconLink] = useState(careerPartner?.iconUrl)
    const [backgroundImageLink, setBackgroundImageLink] = useState(careerPartner?.backgroundImageFilePath)

    const formRef = useRef<FormHandles>(null)
    const history = useHistory()

    useEffect(() => {
        if (!careerPartner) return

        const { customerUuid, iconUrl, backgroundImageFilePath, facts, additionalInformation, ...partner } = careerPartner

        setInitialData({
            adsCustomerUuid: customerUuid,
            iconFilename: iconUrl,
            backgroundImageFilePath: backgroundImageFilePath,
            ...partner,
        })
        setIconLink(iconUrl)
        setBackgroundImageLink(backgroundImageFilePath)
        setCareerPartnersFacts(facts)
        setAdditionalCareerPartnerInformation(additionalInformation)
    }, [careerPartner])

    const onCareerIconChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const innerFile = event.target.files![0]
        if (!innerFile) {
            toast.error('You need to upload an image!')
            return
        }
        const maxFileSize = 25 * 1000000

        if (!['image/png', 'image/webp', 'image/jpeg'].includes(innerFile.type)) {
            toast.error('Please upload your file as a .png, .jpg, or .webp!')
            return
        }

        if (innerFile.size > maxFileSize) {
            toast.error('Sorry, the file is too big. The maximum file size is 25 MB.')
            return
        }

        trackPromise(
            FileUploadService.upload(innerFile, ContentPrefixes.CareerPartner)
                .then(({ url }) => {
                    setIconLink(url)
                    formRef.current?.setFieldValue('iconFilename', url)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.InvalidMIMEType) {
                            toast.error('Please choose a different file format.')
                        } else {
                            error.handleUnknown('An error occurred while uploading the image')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.imageUpload
        )
    }

    const onCareerBackgroundChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const innerFile = event.target.files![0]
        if (!innerFile) {
            toast.error('You need to upload an image!')
            return
        }
        const maxFileSize = 25 * 1000000

        if (!['image/png', 'image/webp', 'image/jpeg'].includes(innerFile.type)) {
            toast.error('Please upload your file as a .png, .jpg, or .webp!')
            return
        }

        if (innerFile.size > maxFileSize) {
            toast.error('Sorry, the file is too big. The maximum file size is 25 MB.')
            return
        }

        trackPromise(
            FileUploadService.upload(innerFile, ContentPrefixes.CareerPartner)
                .then(({ url }) => {
                    setBackgroundImageLink(url)
                    formRef.current?.setFieldValue('backgroundImageFilePath', url)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.InvalidMIMEType) {
                            toast.error('Please choose a different file format.')
                        } else {
                            error.handleUnknown('An error occurred while uploading the image')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.backgroundImageUpload
        )
    }

    const handleSubmit: SubmitHandler<FormData> = ({
        sortId,
        adsCustomerUuid,
        knowerUuid,
        backgroundImageFilePath,
        iconFilename,
        ...data
    }) => {
        formRef.current!.setErrors({})

        if (!data.clickUrl) {
            formRef.current?.setFieldError('clickUrl', 'This field is required')
            return
        }

        if (!backgroundImageFilePath) {
            formRef.current?.setFieldError('backgroundImageFilePath', 'This field is required')
            return
        }

        if (careerPartnersFacts?.length > 3) {
            toast.warning(`You can't add more than 3 Career Partner Facts`)
            return
        }

        if (!additionalCareerPartnerInformation?.length) {
            toast.warning('You can do add atleast 1 Additional Career Partner Information')
            return
        }

        const stringifiedAdditionalInformation = additionalCareerPartnerInformation.map(({ content, sortId, ...rest }) => ({
            content: JSON.stringify(content),
            sortId: sortId ? +sortId : 0,
            ...rest,
        }))

        const requestBody: CareerPartnerRequest = {
            additionalInformation: stringifiedAdditionalInformation,
            facts: JSON.stringify(careerPartnersFacts),
            sortId: +sortId,
            adsCustomerUuid: adsCustomerUuid?.length ? adsCustomerUuid : null,
            knowerUuid: knowerUuid?.length ? knowerUuid : null,
            iconFilename: iconFilename?.length ? (iconFilename.split('/').pop() as string) : null,
            userTargetingId: requestUserTargetingId,
            backgroundImageFilePath: backgroundImageFilePath?.length
                ? (backgroundImageFilePath.split('/').pop() as string)
                : '',
            ...data,
        }

        let handleFunc
        if (!isNew && careerPartner?.uuid) {
            handleFunc = CareerPartnerService.update(careerPartner.uuid, requestBody)
        } else {
            handleFunc = CareerPartnerService.create(requestBody)
        }

        trackPromise(
            handleFunc
                .then((careerPartner) => {
                    updateCareerPartner(careerPartner)
                    toast.success('The changes to career partner have been saved!')
                    history.push(routes.careerPartnerRoute(careerPartner.uuid))
                })
                .catch((error: ApiError) => {
                    if (error.type === ErrorTypes.FormValidation) {
                        badRequestFormErrors(error, formRef.current!)
                    } else {
                        error.handleUnknown('An error occurred while saving career partner.')
                    }
                }),
            loadingAreas.save
        )
    }

    return (
        <Box>
            <h3>{isNew ? 'Create new' : 'Update'} Career Partner</h3>
            <Form ref={formRef} initialData={initialData} onSubmit={handleSubmit}>
                <Input name="name" placeholder="Name" />
                <Textarea name="description" placeholder="Description" />
                <CareerPartnerFactsForm facts={careerPartnersFacts} setCareerPartnersFacts={setCareerPartnersFacts} />
                <AdditionalCareerPartnerInformationForm
                    additionalInformation={additionalCareerPartnerInformation}
                    setCareerPartnersadditionalInformation={setAdditionalCareerPartnerInformation}
                />

                <Input name="clickUrl" placeholder="Click Url" />
                <Stack>
                    <UploadWrapper
                        handleFileUpload={onCareerIconChangeFile}
                        area={loadingAreas.imageUpload}
                        acceptRules="image/png, image/webp, image/jpeg"
                    >
                        <p>Click here to select Career Partner logo.</p>
                    </UploadWrapper>

                    {iconLink ? <img width={200} height="auto" src={iconLink} alt="company logo" /> : null}
                    <Input name="iconFilename" placeholder="Icon filename" onUpdate={(value) => setIconLink(value)} />
                </Stack>
                <Stack>
                    <UploadWrapper
                        handleFileUpload={onCareerBackgroundChangeFile}
                        area={loadingAreas.backgroundImageUpload}
                        acceptRules="image/png, image/webp, image/jpeg"
                    >
                        <p>Click here to select background image.</p>
                    </UploadWrapper>

                    {backgroundImageLink ? (
                        <img width={300} height="auto" src={backgroundImageLink} alt="background profile banner" />
                    ) : null}
                    <Input
                        name="backgroundImageFilePath"
                        placeholder="Background image"
                        onUpdate={(value) => setBackgroundImageLink(value)}
                    />
                </Stack>
                <Input name="adsCustomerUuid" placeholder="Ads Customer Uuid" />
                <Input name="knowerUuid" placeholder="Knower Uuid" />
                <Stack>
                    <Body1 color={theme.colors.white}>{`User Targeting (optional)`}</Body1>
                    {renderTargetingButton()}
                </Stack>
                <Input name="sortId" placeholder="Sort Id" type="number" />
                <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                    {isNew ? 'Save' : 'Update'}
                </Button>
            </Form>
        </Box>
    )
}

export default CareersPartnersForm
