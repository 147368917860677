import React from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import Button from 'components/elements/Button'
import { Body2, ButtonText, Headline5 } from 'components/elements/Text'
import { Input } from 'components/inputs/Input'
import Modal from 'components/modules/Modal'
import { useRef, useState } from 'react'
import { useToggleState } from 'utils/hooks/useToggleState'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit-white.svg'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import CollapsibleCard from 'components/features/CollapsibleCard'
import theme from 'lib/constants/theme'
import styled from 'styled-components'
import Row from 'components/elements/Row'
import { CareerPartnerFacts } from 'interfaces/CareerPartner'

const ButtonRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    button {
        height: 30px;
    }
`

type FactsUpdate = CareerPartnerFacts & { index: number }

interface Props {
    facts: CareerPartnerFacts[]
    setCareerPartnersFacts: React.Dispatch<React.SetStateAction<CareerPartnerFacts[]>>
}

const CareerPartnerFactsForm = ({ facts, setCareerPartnersFacts }: Props) => {
    const factsFormRef = useRef<FormHandles>(null)
    const [selectedfacts, setSelectedfacts] = useState<FactsUpdate | null>(null)
    const [showFactsForm, toggleFactsForm] = useToggleState(false)

    const updateCompetitionfacts = (facts: CareerPartnerFacts) =>
        setCareerPartnersFacts((prevfacts) => [...prevfacts, facts])

    const handleEditfacts = (facts: CareerPartnerFacts, index: number) => {
        setSelectedfacts({ ...facts, index })
        toggleFactsForm()
    }

    const handleDeletefacts = (indexToDelete: number) =>
        setCareerPartnersFacts((factss) => factss.filter((_, i) => i !== indexToDelete))

    const handlefactsFormSubmit: SubmitHandler<CareerPartnerFacts> = (facts) => {
        if (!facts.text) {
            factsFormRef.current!.setFieldError('text', 'This field is required!')
            return
        }

        if (!facts.description) {
            factsFormRef.current!.setFieldError('description', 'This field is required!')
            return
        }

        if (selectedfacts) {
            const { index } = selectedfacts

            setCareerPartnersFacts((prevfactss) => {
                const tempfacts = prevfactss
                tempfacts[index] = facts
                return tempfacts
            })
        } else {
            updateCompetitionfacts(facts)
        }
        setSelectedfacts(null)
        toggleFactsForm()
    }

    return (
        <>
            <Modal
                show={showFactsForm}
                title={selectedfacts ? 'Update facts item' : 'Add facts item'}
                onClose={toggleFactsForm}
            >
                <Form ref={factsFormRef} onSubmit={handlefactsFormSubmit} initialData={{ ...selectedfacts }}>
                    <Input name="text" placeholder="Text" />
                    <Input name="description" placeholder="Description" />
                    <Button fullWidth icon={<SaveIcon />} type="button" onClick={() => factsFormRef.current?.submitForm()}>
                        Save
                    </Button>
                </Form>
            </Modal>
            <Box>
                <Stack>
                    <ButtonRow>
                        <Headline5 color={theme.colors.white} marginBottom={10}>
                            {`Facts (Max 3 items and optional)`}
                        </Headline5>
                        <Button fullWidth={false} onClick={toggleFactsForm} icon={<AddIcon />} type="button">
                            <ButtonText color={theme.colors.white}>Add fact</ButtonText>
                        </Button>
                    </ButtonRow>

                    {facts.length ? (
                        <>
                            {facts.map((fact, i) => (
                                <CollapsibleCard
                                    key={i}
                                    title={
                                        <ButtonRow>
                                            <Body2 color={theme.colors.white}>{`${i + 1}. Text: ${fact.text}`}</Body2>
                                            <Row gap={20}>
                                                <Button
                                                    fullWidth={false}
                                                    onClick={() => handleDeletefacts(i)}
                                                    type="button"
                                                    icon={<DeleteIcon />}
                                                    color={theme.colors.secondaryRed}
                                                    hoverColor={theme.colors.secondaryRedDarker}
                                                >
                                                    Delete
                                                </Button>
                                                <Button
                                                    fullWidth={false}
                                                    onClick={() => handleEditfacts(fact, i)}
                                                    type="button"
                                                    icon={<EditIcon />}
                                                >
                                                    Edit
                                                </Button>
                                            </Row>
                                        </ButtonRow>
                                    }
                                    openByDefault={false}
                                >
                                    <Body2
                                        color={theme.colors.white}
                                        fontWeigth={500}
                                    >{`Description: ${fact.description}`}</Body2>
                                </CollapsibleCard>
                            ))}
                        </>
                    ) : (
                        <Body2>No Facts items were added</Body2>
                    )}
                </Stack>
            </Box>
        </>
    )
}

export default CareerPartnerFactsForm
