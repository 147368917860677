import { SelectOptionWithLabel } from 'components/inputs/Input'
import { Grade } from 'interfaces/Grade'
import { SchoolType } from 'interfaces/SchoolType'
import { ApiError } from 'services/ApiService'
import GradeService from 'services/GradeService'
import { uniqueBy } from 'utils'

export const returnGradeLabel = (grade: Grade) => grade.longName

export const fetchGradesBasedOnSchoolType = async (
    schoolTypes: SchoolType[],
    handleGradeOptions: (gradeOptions: SelectOptionWithLabel<Grade>[]) => void
) => {
    const promises: Promise<void>[] = []
    let gradesOptions: Grade[] = []
    schoolTypes.forEach((school) => {
        const promise = GradeService.listGradesBySchoolType(school.id)
            .then((grades) => {
                gradesOptions = [...gradesOptions, ...grades]
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('An error occurred while getting grades.')
                } else {
                    throw error
                }
            })

        promises.push(promise)
    })
    await Promise.all(promises).then(() => {
        const options = uniqueBy('id', gradesOptions)
            .sort((a, b) => a.id - b.id)
            .map((g) => ({
                label: returnGradeLabel(g),
                identifier: g.id.toString(),
                data: g,
            }))
        options && handleGradeOptions(options ?? [])
    })
}
