import React from 'react'
import { FormGroup, InputErrorMessage } from './Input'

interface Props<T> {
    label: string
    noMargin: boolean
    options: SelectOption<T>[]
    defaultValue?: string | number
    onUpdate(value: T): void
    errorMessage?: string
}

export interface SelectOption<T> {
    label: string
    data: T
}

const SelectElement = React.forwardRef(<T,>(props: Props<T>, ref: React.LegacyRef<HTMLSelectElement>) => {
    const onInput = (event: React.FormEvent<HTMLSelectElement>) => {
        if (!props.options) return
        // eslint-disable-next-line
        const selectedOption = props.options[parseInt((event.target as any).value)]
        if (props.onUpdate) {
            props.onUpdate(selectedOption.data)
        }
    }

    return (
        <FormGroup noMargin={props.noMargin}>
            <label>{props.label}</label>
            <select ref={ref} defaultValue={props.defaultValue} onInput={onInput}>
                {props.options.map((option, index) => (
                    <option value={index} key={index}>
                        {option.label}
                    </option>
                ))}
            </select>
            <InputErrorMessage>{props.errorMessage}</InputErrorMessage>
        </FormGroup>
    )
})
SelectElement.displayName = 'SelectElement'

export default SelectElement
