import React, { useCallback, useEffect, useState } from 'react'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import { ButtonLink } from 'components/elements/Button'
import Stack from 'components/elements/Stack'
import { FilterFormGrid, NoWrapText } from 'style'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import routes from 'lib/constants/routes'
import ExperimentationPlatformService from './ExperimentsService'
import RefactoredTable from 'components/modules/RefactoredTable'
import theme from 'lib/constants/theme'
import { formatTimeDifference, getUrlParam } from 'utils'
import Tabs from 'components/modules/Tabs'
import { ExperimentStatuses } from 'interfaces/Experiment'
import Search from 'components/elements/Search'
import Meta from 'components/modules/Head'

const loadingAreas = {
    container: 'experimentsContainer',
}

const Experiments = () => {
    const [status, setStatus] = useState<ExperimentStatuses>(ExperimentStatuses.All)
    const [searchQuery, setSearchQuery] = useState<string>()

    useEffect(() => {
        const experimentStatus = getUrlParam('tab')
        if (!experimentStatus) return
        setStatus(experimentStatus?.toUpperCase() as ExperimentStatuses)
    }, [])

    const changeStatus = (status: string) => setStatus(status as ExperimentStatuses)

    const onSearchDebounce = (query: string) => setSearchQuery(query)

    const fetchData = useCallback(
        (page: number) => {
            return trackPromise(
                ExperimentationPlatformService.list(page, status, searchQuery)
                    .then((data) => {
                        if (page !== data.page) return

                        return {
                            totalPages: data.totalPages,
                            totalElements: data.totalElements,

                            elements: data.experiments.map((e) => ({
                                id: e.uuid,
                                columns: [
                                    e.uuid,
                                    e.name,
                                    <NoWrapText key={e.uuid}>{e.status}</NoWrapText>,
                                    e.cohortCode,
                                    e.startOn ? formatTimeDifference(e.startOn) : '',
                                    e.endOn ? formatTimeDifference(e.endOn) : '',
                                ],
                                onClick: routes.experimentRoute(e.uuid),
                            })),
                        }
                    })
                    .catch((error: ApiError) => {
                        error.handleUnknown('An error occurred while getting manual chat messages.')
                    }),
                loadingAreas.container
            )
        },
        [status, searchQuery]
    )

    return (
        <section>
            <Meta title="Experiments" />
            <Stack gutter={5}>
                <h2>Experiments</h2>
                <Tabs onLabelClicked={changeStatus}>
                    <div data-label={ExperimentStatuses.All} onClick={() => changeStatus(ExperimentStatuses.All)} />
                    <div data-label={ExperimentStatuses.Running} onClick={() => changeStatus(ExperimentStatuses.Running)} />
                    <div
                        data-label={ExperimentStatuses.Scheduled}
                        onClick={() => changeStatus(ExperimentStatuses.Scheduled)}
                    />
                    <div
                        data-label={ExperimentStatuses.Completed}
                        onClick={() => changeStatus(ExperimentStatuses.Completed)}
                    />
                </Tabs>
                <FilterFormGrid gridAmount={2}>
                    <ButtonLink
                        to={routes.experimentCreateRoute}
                        fullWidth={false}
                        icon={<AddIcon />}
                        hoverColor={theme.colors.knowunityBlue}
                        color={theme.colors.knowunityBlueDark}
                    >
                        Add Experiment
                    </ButtonLink>
                    <Search
                        onSearch={setSearchQuery}
                        onDebounce={onSearchDebounce}
                        fullWidth
                        label="Search by experiment name"
                    />
                </FilterFormGrid>
                <RefactoredTable
                    columns={['#', 'Name', 'Status', 'Cohort', 'Starts on', 'Ends on']}
                    loadData={fetchData}
                    loadingArea={loadingAreas.container}
                    refetchKey={status}
                />
            </Stack>
        </section>
    )
}

export default Experiments
