import styled, { css } from 'styled-components'
import { Body2 } from 'components/elements/Text'
import theme from 'lib/constants/theme'

export const AnswerContainer = styled.div`
    width: 100%;
    border: 2px solid ${theme.colors.knowunityBlue};
    padding: 15px;
    border-radius: ${theme.borderRadius.normal};
    background-color: ${theme.colors.white};
    * {
        color: ${theme.colors.body1Black} !important;
    }
`

export const AnswerText = styled(Body2)`
    width: 100%;
    word-break: break-word;
    color: ${theme.colors.body2Black};
    min-height: 50px;
    padding-top: 15px;
    @media (max-width: 750px) {
        padding-right: 5%;
    }
`

export const BestAnswerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 15px;
    p {
        font-family: 'Open Sans', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: var(--secondary-color);
        padding-right: 10px;
    }
    @media (max-width: 750px) {
        margin-right: 15px;
        margin-top: 0;
        p {
            display: none;
        }
    }
`

export const ActionWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 5px;
    p,
    strong {
        font-family: 'Open Sans', sans-serif;
        font-size: 15px !important;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        margin: 0;
    }
    strong {
        color: var(--secondary-color);
        font-weight: 600;
        margin-bottom: 10px;
        font-size: 16px;
        cursor: pointer;
        white-space: nowrap;
    }
    svg {
        cursor: pointer;
    }
`

export const AttachmentWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
    @media (max-width: 750px) {
        & > * {
            margin: 5px;
        }
    }
`

export const AnswersWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    height: auto;
`

export const ProfileSection = styled.div`
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-gap: 25px;
    align-items: center;
    .button {
        padding: 0px;
        font-size: 12px;
    }
`

export const ProfileCredentials = styled.div`
    h3 {
        margin: 0;
        font-family: 'Open Sans', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
    }
    p {
        margin: 3px 0;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
    }
`

export const HeaderSection = styled.div`
    display: flex;
    justify-content: space-between;
`

export const Separator = styled.div<{ isBottom?: boolean }>`
    margin: 0 -15px;
    padding: 0;
    border-top: 1px solid ${theme.colors.knowunityBlue};
    ${({ isBottom }) =>
        isBottom
            ? css`
                  position: relative;
                  top: 15px;
              `
            : ''}
`

export const CommentSection = styled.div`
    height: 70px;
    display: flex;
    justify-content: end;
    margin: 10px 0 -15px 0;
    grid-gap: 20px;
    @media (max-width: 750px) {
        grid-template-columns: 1fr;
        grid-gap: 20px;
        margin: 0px;
    }
`

export const MobileActionsSection = styled.div`
    display: flex;
    justify-content: flex-end;
    @media (min-width: 751px) {
        display: none;
    }
`

export const ActionsSection = styled.div`
    display: flex;
    justify-content: flex-end;

    @media (max-width: 750px) {
        display: none;
    }
`

export const MobileCommentsCountWrapper = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    justify-content: center;
    margin-top: -15px;
    margin-bottom: 15px;
    @media (min-width: 751px) {
        display: none;
    }
`

export const ButtonWrapper = styled.div`
    button {
        div {
            border-radius: 16px !important;
            height: 50px;
        }
        margin-bottom: 10px;
    }
    strong {
        margin-top: 5px;
    }
    @media (min-width: 751px) {
        display: none;
    }
`

export const LeaveCommentSection = styled.div`
    border: 2px solid #c2c7cc;
    border-radius: 15px;
    margin: 0 10px;
    width: 100%;
    padding: 0 15px;
    cursor: pointer;
    * {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px !important;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: var(--secondary-color);
        @media (max-width: 750px) {
            font-size: 16px;
        }
    }
    span {
        color: var(--text-gray);
    }
    @media (max-width: 750px) {
        width: 90%;
        margin: 0 15px;
        padding: 0 15px;
    }
`

export const QuestionCommentSection = styled.div`
    height: 70px;
    display: grid;
    grid-template-columns: 3fr 1fr;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 -15px 0;
    grid-gap: 20px;
`
