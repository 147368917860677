import React, { useCallback, useEffect, useState } from 'react'
import { ApiError } from 'services/ApiService'
import { Link, RouteComponentProps } from 'react-router-dom'
import { ReportItem as ReportInterface, ReportType } from 'interfaces/Report'
import Button from 'components/elements/Button'
import { formatDate, getNextRedirectUuid } from 'utils'
import { trackPromise } from 'react-promise-tracker'
import LoadingArea from 'components/elements/LoadingArea'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { toast } from 'react-toastify'
import ChatService from 'services/ChatService'
import { ChatMessage } from 'interfaces/Chat'
import ReportService from 'services/ReportService'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import routes from 'lib/constants/routes'
import UserAdminNotes from 'components/features/users/UserAdminNotes'
import theme from 'lib/constants/theme'
import GoBackButton from 'components/elements/GoBackButton'
import { NoWrapText } from 'style'
import { renderContentSpecificParts } from 'lib/features/reports/report'
import { useToggleState } from 'utils/hooks/useToggleState'
import ChatModalComponent from 'components/modules/ChatComponent/ChatModalComponent'
import { Body1 } from 'components/elements/Text'
import ReportReasonModal from './ReportReasonModal'
import styled from 'styled-components'
import getReportReasonsKeysBasedOnReportType, { formatReportReasonTitle } from 'lib/features/reports/reportsReasons'
import Table, { TableData } from 'components/modules/Table'
import Meta from 'components/modules/Head'
import ResetUserProfileModal from 'components/features/users/ResetUserProfileModal'
import RefactoredTable from 'components/modules/RefactoredTable'

const Section = styled.section`
    padding-bottom: 100px;
`

const ActionsGrid = styled.div`
    position: fixed;
    z-index: 20;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    bottom: 15px;
    right: 50px;

    .buttons {
        background-color: ${theme.colors.darkViolet};
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: fit-content;
        grid-gap: 10px;
        row-gap: 10px;
        padding: 10px;
        border-radius: ${theme.borderRadius.normal};
        border: 2px solid ${theme.colors.lightViolet};
    }
    button {
        width: 250px;
        height: 100%;
        margin: 0;
    }
    p {
        text-align: center;
    }
`

interface Props extends RouteComponentProps<{ id: string }> {}

const loadingAreas = {
    container: 'reportContainer',
    acceptedChatReports: 'acceptedChatReports',
}

const Report = (props: Props) => {
    const [reportItem, setReportItem] = useState<ReportInterface | null>(null)
    const [reportsTableData, setReportsTable] = useState<TableData[]>([])
    const [chatMessages, setChatMessages] = useState<ChatMessage[]>()
    const [showResetUserProfileParamsModal, toggleResetUserProfileParamsModal] = useToggleState(false)
    const [showRejectModal, toggleRejectModal] = useToggleState(false)
    const [selectedReaon, setSelectedReason] = useState<string | null>(null)
    const id = props.match.params.id

    const noReportReasons = getReportReasonsKeysBasedOnReportType(reportItem?.type ?? ReportType.user)

    const goToNextReviewKnow = useCallback(() => {
        const nextRedirect = getNextRedirectUuid(id)
        if (nextRedirect === undefined) {
            return false
        } else if (nextRedirect === null) {
            props.history.push(routes.reportsRoute)
            return true
        }

        props.history.push(routes.reportRoute(nextRedirect.nextUuid, { redirectIds: nextRedirect.remainingUuids }))
        return true
    }, [id, props.history])

    const fetchAcceptedChatReports = useCallback(
        (page: number) => {
            if (!reportItem?.reportedChatUuid) {
                return new Promise<undefined>(() => {
                    return undefined
                })
            }

            return trackPromise(
                ChatService.getReportItems(reportItem.reportedChatUuid, page)
                    .then((data) => {
                        if (page !== data.page) return

                        return {
                            totalPages: data.totalPages,
                            totalElements: data.totalElements,
                            elements: data.reports.map((report) => ({
                                id: report.uuid,
                                columns: [
                                    <Link target="_blank" to={routes.reportRoute(report.uuid)} key={`user-${report.uuid}`}>
                                        {report.uuid}
                                    </Link>,
                                    <NoWrapText key={`type-${report.uuid}`}>{report.type}</NoWrapText>,
                                    report.reports?.length ?? 0,
                                    report.reportedUser?.username,
                                ],
                            })),
                        }
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while getting reported chat reported messages.')
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.acceptedChatReports
            )
        },
        [reportItem]
    )

    const fetchData = useCallback(() => {
        trackPromise(
            ReportService.getReport(id)
                .then((reportItem) => {
                    setReportItem(reportItem)

                    const reportsTable = reportItem.reports.map((report) => ({
                        id: report.id,
                        columns: [
                            report.id,
                            report.status,
                            `${report.platform}${report?.isAnonymous ? ` (anonymous)` : ''}`,
                            `${report.description}${report?.userReason ? ` ,reason: ${report.userReason}` : ''}`,
                            report.employeeReason,
                            report?.createdOn ? formatDate(report.createdOn) : '',
                            report?.closedByUser ? (
                                <Link
                                    target="_blank"
                                    to={routes.userRoute(report.closedByUser.uuid)}
                                    key={`user-${report.id}`}
                                >
                                    {`${report.closedByUser.name} (${report.closedByUser.uuid})`}
                                </Link>
                            ) : (
                                'not found'
                            ),
                            report?.reportingUser ? (
                                <Link
                                    target="_blank"
                                    to={routes.userRoute(report.reportingUser.uuid)}
                                    key={`user-${report.id}`}
                                >
                                    {`${report.reportingUser.name} (${report.reportingUser.uuid})`}
                                </Link>
                            ) : (
                                'not found'
                            ),
                        ],
                    }))

                    setReportsTable(reportsTable)

                    if (reportItem.type === ReportType.chat && reportItem?.reportedChatUuid) {
                        ChatService.getMessages(reportItem.reportedChatUuid)
                            .then((chat) => setChatMessages(chat.reverse()))
                            .catch((error) => {
                                if (error instanceof ApiError) {
                                    error.handleUnknown('An error occurred while getting reported chat.')
                                } else {
                                    throw error
                                }
                            })
                    } else {
                        setChatMessages(undefined)
                    }
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.NotFound) {
                            if (goToNextReviewKnow()) {
                                toast.warn('The report will be skipped since was not found.')
                            } else {
                                toast.error('The report was not found.')
                            }
                        } else {
                            error.handleUnknown('An error occurred while getting report.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.container
        )
    }, [goToNextReviewKnow, id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const rejectReport = (reportReason?: string) => {
        if (!window.confirm('Do you really want to reject the report?')) return
        trackPromise(
            ReportService.rejectReport(id, reportReason || 'NO_REPORT_REASON')
                .then(() => {
                    showRejectModal && toggleRejectModal()
                    goToNextReviewKnow()
                })
                .catch((error: ApiError) => {
                    if (error instanceof ApiError) {
                        //error.handleUnknown('An error occurred while rejecting report.')
                        console.error(error)
                    } else {
                        throw error
                    }
                }),
            loadingAreas.container
        )
    }

    const putReportOnHold = () => {
        trackPromise(
            ReportService.putOnHold(id)
                .then(() => goToNextReviewKnow())
                .catch((error: ApiError) => {
                    if (error instanceof ApiError) {
                        //error.handleUnknown('An error occurred while puting report on hold.')
                        console.error(error)
                    } else {
                        throw error
                    }
                }),
            loadingAreas.container
        )
    }

    const acceptReport = (reason: string, _?: string) => {
        if (!reportItem) return

        if (reportItem.type === ReportType.user && !selectedReaon) {
            setSelectedReason(reason)
            toggleResetUserProfileParamsModal()
            return
        }

        trackPromise(
            ReportService.accept(reportItem.uuid, reason, reason)
                .then(() => {
                    showRejectModal && toggleRejectModal()
                    goToNextReviewKnow()
                })
                .catch((error: ApiError) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('')
                        console.error(error)
                    } else {
                        throw error
                    }
                }),
            loadingAreas.container
        )
    }

    const onResetParamsModalClose = () => {
        if (!selectedReaon) return

        acceptReport(selectedReaon, selectedReaon)
        setSelectedReason(null)
        toggleResetUserProfileParamsModal()
    }

    const ChatSection = () =>
        reportItem && chatMessages?.length ? (
            <ChatModalComponent chatMessages={chatMessages} reportedUserUuid={reportItem.reportedUser?.uuid ?? ''} />
        ) : (
            <Body1 color={theme.colors.secondaryRed} textAlign="center">
                Chat was deleted
            </Body1>
        )

    const contentSpecificParts = renderContentSpecificParts(reportItem, toggleRejectModal)

    const hasAllReportsBeenAccepted = reportItem?.reports.every((report) => report.status === 'ACCEPTED')

    return (
        <Section>
            <Meta title="Report" />
            <h2>Report Items</h2>
            <GoBackButton route={routes.reportsRoute} routeName="Reports" />
            <LoadingArea area={loadingAreas.container}>
                <Box>
                    <Stack>
                        <h3>Report Information</h3>
                        {reportItem && (
                            <>
                                <p>
                                    <strong>ID:</strong> {reportItem.uuid}&nbsp;
                                </p>

                                <NoWrapText>
                                    <strong>Type:</strong> {reportItem.type}&nbsp;
                                </NoWrapText>
                            </>
                        )}
                    </Stack>
                </Box>

                <Box>
                    <Stack>
                        {contentSpecificParts.information} {chatMessages?.length ? <ChatSection /> : null}
                    </Stack>
                </Box>
            </LoadingArea>
            {!hasAllReportsBeenAccepted ? (
                <ActionsGrid>
                    <div className="buttons">
                        {contentSpecificParts.actions}
                        {noReportReasons.map((reason) => (
                            <Button
                                key={reason}
                                onClick={() => rejectReport(reason)}
                                fullWidth={false}
                                noMargin
                                color={theme.colors.deepSeaBlue}
                                hoverColor={theme.colors.deepSeaBlue}
                            >
                                {`Reject (${formatReportReasonTitle(reason)})`}
                            </Button>
                        ))}

                        <Button onClick={putReportOnHold} fullWidth={false} noMargin hoverColor={theme.colors.hoverViolet}>
                            Put on hold
                        </Button>
                    </div>
                </ActionsGrid>
            ) : null}

            {reportItem ? (
                <ReportReasonModal
                    reportItem={reportItem}
                    show={showRejectModal}
                    onClose={toggleRejectModal}
                    onReject={acceptReport}
                />
            ) : null}
            <Box>
                <Stack>
                    <h3>Accepted Chat Reports</h3>

                    <RefactoredTable
                        columns={['#', 'Type', 'Reports count', 'Reported user']}
                        loadData={fetchAcceptedChatReports}
                        loadingArea={loadingAreas.acceptedChatReports}
                        refetchKey={`${reportItem?.reportedChatUuid}`}
                    />
                </Stack>
            </Box>
            <Box>
                <Stack>
                    <h3>Reports</h3>
                    {reportsTableData?.length > 0 ? (
                        <Table
                            columns={[
                                '#',
                                'Status',
                                'Platform',
                                'Description',
                                'Employee Reason',
                                'Created on',
                                'Closed by',
                                'Reporting user',
                            ]}
                            data={reportsTableData}
                            page={0}
                            totalPages={1}
                            totalElements={reportsTableData.length}
                        />
                    ) : (
                        <Body1 color={theme.colors.white} textAlign="center" marginBottom={20}>
                            The are no related reports
                        </Body1>
                    )}
                </Stack>
            </Box>
            {reportItem?.reportedUser?.uuid ? (
                <ResetUserProfileModal
                    userUuid={reportItem.reportedUser.uuid}
                    show={showResetUserProfileParamsModal}
                    onClose={onResetParamsModalClose}
                />
            ) : null}
            {reportItem && reportItem.reportedUser ? <UserAdminNotes user={reportItem.reportedUser} /> : null}
        </Section>
    )
}

export default Report
