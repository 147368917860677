import React, { useCallback, useEffect, useRef, useState } from 'react'
import { NewsletterUserSettings, User } from 'interfaces/User'
import Button from 'components/elements/Button'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { toast } from 'react-toastify'
import { badRequestFormErrors } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { Checkbox } from 'components/inputs/Input'
import LoadingArea from 'components/elements/LoadingArea'
import Box from 'components/elements/Box'
import UserService from 'services/UserService'

interface Props {
    user: User
}

const loadingAreas = {
    container: 'newsletterContainer',
    save: 'saveNewsletterSettings',
}

const UserNewsletterSettings = ({ user }: Props) => {
    const [initialUserNewsletterSettings, setInitialNewsletterSettings] = useState<NewsletterUserSettings>()
    const formRef = useRef<FormHandles>(null)

    const getUserNewsletterSettings = useCallback(() => {
        trackPromise(
            UserService.getUserNewsletterSettings(user.uuid)
                .then((settings) => setInitialNewsletterSettings(settings))
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.FormValidation) {
                            badRequestFormErrors(error, formRef.current!)
                        } else {
                            error.handleUnknown('An error occurred while getting user newsletter settings.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.container
        )
    }, [user.uuid])

    useEffect(() => {
        getUserNewsletterSettings()
    }, [getUserNewsletterSettings])

    const handleUserNewsletterSettingsSubmit: SubmitHandler<NewsletterUserSettings> = (data) => {
        formRef.current!.setErrors({})

        trackPromise(
            UserService.updateUserNewsletterSettings(user.uuid, data)
                .then(() => {
                    toast.success('User newsletter settings has been changed')
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.FormValidation) {
                            badRequestFormErrors(error, formRef.current!)
                        } else {
                            error.handleUnknown('An error occurred while changing user newsletter settings.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.save
        )
    }

    return (
        <Box>
            <LoadingArea area={loadingAreas.container}>
                <Form
                    ref={formRef}
                    onSubmit={handleUserNewsletterSettingsSubmit}
                    initialData={initialUserNewsletterSettings}
                >
                    <Checkbox name="feedbackEmail" placeholder="Feedback Email" />
                    <Checkbox name="knowerEmail" placeholder="Knower Email" />
                    <Checkbox name="advertisementEmail" placeholder="Advertisement Email" />
                    <Checkbox name="featureAnnouncementEmail" placeholder="Feature Announcement Email" />
                    <Checkbox name="standardEmail" placeholder="Standard Email" />
                    <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                        Save user newsletter settings
                    </Button>
                </Form>
            </LoadingArea>
        </Box>
    )
}

export default UserNewsletterSettings
