import axios, { AxiosRequestConfig } from 'axios'
import store from 'redux/store'
import { toast } from 'react-toastify'
import * as Sentry from '@sentry/react'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import AuthService from './AuthService'
import config from 'utils/config'
import { subscribeToOauthTokenRefreshes } from 'utils/oauthTokenRefreshes'
import { getMediaHeader } from 'utils/api-media-header'

const baseURL = config.getApiBaseUrl()

let isGettingRefreshToken = false
let gettingRefreshTokenFailed = false

const service = axios.create({ baseURL })

// Subscribing to Brodcast Token Refreshes
subscribeToOauthTokenRefreshes()

// Global Authentication
const globalAuthUrl = config.getGlobalAuthenticationBaseUrl()
export const TokenApiService = axios.create({ baseURL: globalAuthUrl })

export const GlobalAuthApiService = axios.create({ baseURL: globalAuthUrl })

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addAccessBearerTokenToConfig = (config: AxiosRequestConfig<any>) => {
    const token = store.getState().oauthToken

    if (config.headers !== undefined) {
        const xMediaHeader = 'aideM-X'.split('').reverse().join('')
        config.headers[xMediaHeader] = getMediaHeader(config)

        if (token) {
            config.headers['Authorization'] = `Bearer ${token.accessToken}`
        }
    }
    return config
}

service.interceptors.request.use((config) => {
    if (typeof window === 'undefined') {
        return config
    }

    return addAccessBearerTokenToConfig(config)
})

GlobalAuthApiService.interceptors.request.use((config) => {
    if (typeof window === 'undefined') {
        return config
    }

    return addAccessBearerTokenToConfig(config)
})

// eslint-disable-next-line
const errorHandler = (error: any) => {
    const isNetworkError = !!error.isAxiosError && !error.response
    if (isNetworkError) {
        toast.error('Please check your network connectivity!')
        return Promise.reject(new ApiError(error, true))
    }

    const originalRequest = error.config

    console.error(error)
    switch (error.response.status) {
        case 401: {
            const url = error.response.config.url
            const errorType = error.response?.data?.error?.type
            if (url !== 'oauth/token' && errorType === 'AuthorizationError' && !originalRequest._retry) {
                if (!isGettingRefreshToken) {
                    isGettingRefreshToken = true
                    originalRequest._retry = true
                    console.log('Refreshing token')
                    return AuthService.refreshToken()
                        .then(() => {
                            isGettingRefreshToken = false
                            gettingRefreshTokenFailed = false
                            return service(originalRequest)
                        })
                        .catch((error) => {
                            console.error('Cannot refresh token.', error)
                            isGettingRefreshToken = false
                            gettingRefreshTokenFailed = true
                            if (error instanceof ApiError) {
                                if (error.type === ErrorTypes.Authorization) {
                                    AuthService.logout()
                                } else {
                                    toast.error('An error has occurred.')
                                }
                            }
                            return Promise.reject(new ApiError(error, true))
                        })
                } else {
                    // Prevent firing several token refresh requests at the same time
                    return new Promise((resolve, reject) => {
                        const interval = setInterval(() => {
                            if (!isGettingRefreshToken) {
                                clearInterval(interval)
                                return gettingRefreshTokenFailed ? reject : resolve(null)
                            }
                        }, 200)
                    })
                        .then(() => {
                            return service(originalRequest)
                        })
                        .catch((error) => {
                            console.error('An error occurred', error)
                        })
                }
            }

            return Promise.reject(new ApiError(error, false))
        }

        case 403:
            toast.error('Access denied!')
            return Promise.reject(new ApiError(error, true))

        case 500:
            //toast.error('An unknown error occurred on the server. Please try it again later!')
            return Promise.reject(new ApiError(error, false))

        case 502:
            toast.error('Our servers are currently not reachable. Please try it again later!')
            return Promise.reject(new ApiError(error, true))

        case 504:
            toast.error('The request timed out. Please try it again later!')
            return Promise.reject(new ApiError(error, true))

        default:
            return Promise.reject(new ApiError(error, false))
    }
}

service.interceptors.response.use((response) => response, errorHandler)

TokenApiService.interceptors.response.use((response) => response, errorHandler)
GlobalAuthApiService.interceptors.response.use((response) => response, errorHandler)

export class ApiError extends Error {
    hasAlreadyBeenHandled: boolean
    type: ErrorTypes
    data: Record<string, unknown>
    message: string
    // eslint-disable-next-line
    constructor(error: any, hasAlreadyBeenHandled: boolean) {
        super(error)
        this.name = 'ApiError'
        this.hasAlreadyBeenHandled = hasAlreadyBeenHandled
        if (error.response && error.response.data && error.response.data.error && error.response.data.error.type) {
            this.type = error.response.data.error.type
            this.data = error.response.data.error.data
            this.message = error.response.data.error.description
        } else {
            this.type = ErrorTypes.NoErrorType
            this.data = {}
            this.message = ''
        }
    }

    handleUnknown(message: string) {
        if (this.hasAlreadyBeenHandled) return
        const toastMessage = this.message ? `${message} ${this.message} (${this.type})` : `${message} (${this.type})`
        toast.error(toastMessage, { autoClose: 10000 })
        this.message = message
        console.error(message)

        Sentry.captureException(this, {
            tags: { message },
        })
    }
}
export default service
