import ApiService from './ApiService'
import { UserTargeting, UserTargetingRequest } from 'interfaces/UserTargeting'

const UserTargetingService = {
    create(userTargeting: UserTargetingRequest) {
        return ApiService.post('/user-targetings', userTargeting).then((response) => {
            return response.data as UserTargeting
        })
    },

    get(id: number) {
        return ApiService.get(`/user-targetings/${id}`).then((response) => {
            return response.data as UserTargeting
        })
    },

    update(id: number, userTargeting: UserTargetingRequest) {
        return ApiService.patch(`/user-targetings/${id}`, userTargeting).then((response) => {
            return response.data as UserTargeting
        })
    },

    batchUsers(id: number, users: string[]) {
        return ApiService.post(`/user-targetings/${id}/users`, { userUuids: users }).then((response) => {
            return response
        })
    },
}

export default UserTargetingService
