import ApiService from './ApiService'
import { Know, KnowType, KnowStatus, KnowFormat, KnowPlagiarismCandidate, KnowDocument } from 'interfaces/Know'
import { Grade } from 'interfaces/Grade'
import { Subject } from 'interfaces/Subject'
import { SchoolType } from 'interfaces/SchoolType'
import { KnowReviewHistory } from 'interfaces/KnowReviewHistory'
import { Flashcard, FlashcardRequest } from 'interfaces/Flashcard'
import { InternalRatingType } from 'interfaces/ReviewSettings'
import { LanguageCode } from 'interfaces/Language'
import { KnowRejectionReason } from 'interfaces/KnowRejectionReasons'

const KnowService = {
    getAdmin(uuid: string) {
        return ApiService.get(`knows/${uuid}/admin`).then((response) => {
            return response.data as Know
        })
    },

    listAllByKnower(knowerUuid: string) {
        return ApiService.get(`knowers/${knowerUuid}/knows`).then((response) => {
            return {
                knows: response.data.content as Array<Know>,
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    listByKnower(
        knowerUuid: string,
        nextCursor?: string,
        status?: KnowStatus,
        limit?: number,
        subjectId?: number,
        searchTitleQuery?: string | null,
        sortBy?: string | null
    ) {
        return ApiService.get(
            `knowers/${knowerUuid}/knows?page=${0}${nextCursor ? `&cursor=${nextCursor}` : ''}${
                limit ? `&limit=${limit}` : ''
            }${status ? `&status=${status}` : ''}${subjectId ? `&subjectId=${subjectId}` : ''}${
                searchTitleQuery ? `&title=${searchTitleQuery}` : ''
            }${sortBy ? `&orderBy=${sortBy}` : ''}`
        ).then((response) => {
            return {
                knows: response.data.content as Array<Know>,
                nextCursor: response.data.nextCursor as string,
            }
        })
    },

    list(
        page: number,
        search: string | null,
        status: KnowStatus | null,
        reviewGroup: number | null,
        format: KnowFormat | null,
        language: LanguageCode | null,
        internalRating: InternalRatingType,
        isAmbassador: boolean | null,
        subjectIds: number[],
        createdAfter: string | null,
        createdBefore: string | null,
        limit?: number
    ) {
        const dateRangeParams = () => {
            let s = ''
            if (createdAfter) {
                s += `&startDate=${createdAfter}`
            }
            if (createdBefore) {
                s += `&endDate=${createdBefore}`
            }
            return s
        }

        const subjectParams = (): string => {
            if (subjectIds.length === 0 || !Array.isArray(subjectIds)) return ''
            return subjectIds.map((subjectId) => `&subject=${subjectId}`).join('')
        }

        const request = `knows?page=${page}${status ? `&status=${status}` : ''}${search ? `&search=${search}` : ''}${
            language ? `&language=${language}` : ''
        }${reviewGroup ? `&reviewGroup=${reviewGroup.toString()}` : ''}${format ? `&format=${format}` : ''}${
            internalRating ? `&internalRating=${internalRating}` : ''
        }${isAmbassador ? `&isAmbassador=${isAmbassador}` : ''}${subjectParams()}${dateRangeParams()}${
            limit ? `&limit=${limit}` : ''
        }`

        return ApiService.get(request).then((response) => {
            return {
                knows: response.data.content as Array<Know>,
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    update(
        uuid: string,
        title: string,
        description: string,
        knowType: KnowType,
        grades: Grade[],
        subjects: Subject[],
        schoolTypes: SchoolType[],
        examBoards?: string[]
    ) {
        return ApiService.patch(`knows/${uuid}`, {
            title,
            description,
            knowType,
            grades,
            subjects,
            schoolTypes,
            examBoardUuids: examBoards ?? null,
        }).then((response) => {
            return response.data as Know
        })
    },

    updateStatus(uuid: string, status: KnowStatus, reason: string | null) {
        return ApiService.patch(`knows/${uuid}/status`, {
            status,
            reason,
        }).then((response) => {
            return response.data as Know
        })
    },

    move(knowUuid: string, knowerUuid: string) {
        return ApiService.post(`knows/${knowUuid}/move`, knowerUuid, {
            headers: {
                'Content-Length': '0',
                'Content-Type': 'text/plain',
            },
        }).then((response) => {
            return response.data as Know
        })
    },

    getFlashcards(knowUuid: string) {
        return ApiService.get(`knows/${knowUuid}/flashcards?includeQuizFlashcards=true`).then((response) => {
            return response.data.content as Flashcard[]
        })
    },

    updateFlashcards(knowUuid: string, flashcards: FlashcardRequest[]) {
        return ApiService.put(`knows/${knowUuid}/flashcards`, { flashcards }).then((response) => {
            return response.data.content as Flashcard[]
        })
    },

    listReviewHistory(knowUuid: string, page: number) {
        return ApiService.get(`knows/${knowUuid}/review-history?page=${page}`).then((response) => {
            return {
                content: response.data.content as Array<KnowReviewHistory>,
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    updateInternalRating(knowUuid: string, rating: number) {
        return ApiService.patch(`knows/${knowUuid}/internal-rating`, rating, {
            headers: {
                'Content-Length': '0',
                'Content-Type': 'text/plain',
            },
        }).then((response) => {
            return response.data as Know
        })
    },

    delete(knowUuid: string) {
        return ApiService.delete(`knows/${knowUuid}`)
    },

    getPlagiarismCandidates(knowUuid: string, page: number, limit?: number) {
        return ApiService.get(
            `knows/${knowUuid}/plagiarism-candidates?page=${page}${limit ? `&limit=${limit}` : ''}&useElasticsearch=true`
        ).then((response) => {
            return {
                content: response.data.content as KnowPlagiarismCandidate[],
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    createDocument(knowUuid: string, fileName: string, title: string, orderNumber: number) {
        return ApiService.post(`knows/${knowUuid}/documents`, {
            fileName,
            title,
            orderNumber,
        }).then((response) => {
            return response.data as KnowDocument
        })
    },

    deleteDocument(knowUuid: string, uuid: string) {
        return ApiService.delete(`knows/${knowUuid}/documents/${uuid}`)
    },

    getRejectionReasons() {
        return ApiService.get(`knows/rejection-reasons`).then((response) => {
            return response.data.content as KnowRejectionReason[]
        })
    },

    getVisuallySimilarKnows(knowUuid: string) {
        return ApiService.get(`/knows/${knowUuid}/visually-similar`).then((response) => {
            return response.data.similarImages as string[]
        })
    },
}

export default KnowService
