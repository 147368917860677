import React, { useRef } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import Button from 'components/elements/Button'
import { Checkbox } from 'components/inputs/Input'
import Modal from 'components/modules/Modal'
import UserService from 'services/UserService'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { badRequestFormErrors } from 'utils'
import Box from 'components/elements/Box'

interface Props {
    userUuid: string
    show: boolean
    onClose(): void
}

type FormData = {
    resetBio: boolean
    resetProfilePicture: boolean
    resetUsername: boolean
    resetName: boolean
}

const loadingAreas = {
    save: 'resetSubmitModal',
}

const ResetUserProfileModal = (props: Props) => {
    const resetFormRef = useRef<FormHandles>(null)

    const handleBlockSubmit: SubmitHandler<FormData> = (data) => {
        resetFormRef.current!.setErrors({})

        trackPromise(
            UserService.resetParams(props.userUuid, {
                resetBio: data.resetBio,
                resetProfilePicture: data.resetProfilePicture,
                resetUsername: data.resetUsername,
                resetName: data.resetName,
            })
                .then(() => {
                    toast.success(`Selected user parameters were reset`)

                    props.onClose()
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.FormValidation) {
                            badRequestFormErrors(error, resetFormRef.current!)
                        } else {
                            error.handleUnknown('An error occurred while banning the user.')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.save
        )
    }

    return (
        <Modal show={props.show} title="Reset user parameters" onClose={props.onClose}>
            <Box>
                <Form ref={resetFormRef} onSubmit={handleBlockSubmit}>
                    <Checkbox name="resetProfilePicture" placeholder="Reset Profile Picture" />
                    <Checkbox name="resetBio" placeholder="Reset bio" />
                    <Checkbox name="resetUsername" placeholder="Reset username" />
                    <Checkbox name="resetName" placeholder="Reset name" />
                    <Button fullWidth loadingArea={loadingAreas.save}>
                        Save
                    </Button>
                </Form>
            </Box>
        </Modal>
    )
}

export default ResetUserProfileModal
