import React, { Component } from 'react'
import { ContentTypes } from 'interfaces/Know'
import styles from './ContentPreview.module.css'
import { ReactComponent as PDFIcon } from 'assets/icons/pdf.svg'
import Modal from '../Modal'

interface Props {
    contentType: ContentTypes
    previewUrl: string
}

interface State {
    backgroundImage: string
    backgroundColor: string
    icon: JSX.Element | null
    showPreviewModal: boolean
}

class KnowContentPreview extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            backgroundImage: '',
            backgroundColor: '',
            icon: null,
            showPreviewModal: false,
        }
    }

    componentDidMount() {
        switch (this.props.contentType) {
            case ContentTypes.pdf:
                this.setState({
                    backgroundColor: '#ef5350',
                    icon: <PDFIcon className={styles.icon} />,
                })
                break
            case ContentTypes.image:
                this.setState({
                    backgroundColor: `#fff`,
                })
                break
        }
    }

    openModal = () => {
        this.setState({ showPreviewModal: true })
    }

    closeModal = () => {
        this.setState({ showPreviewModal: false })
    }

    render() {
        return (
            <div>
                <div
                    className={styles.container}
                    onClick={this.openModal}
                    style={{
                        backgroundImage:
                            this.props.contentType === ContentTypes.video ? '' : `url(${this.props.previewUrl})`,
                        backgroundColor: this.state.backgroundColor,
                        backgroundSize: 'cover',
                    }}
                >
                    {this.props.contentType === ContentTypes.video && (
                        <video autoPlay playsInline muted height="100%" width="100%" loop>
                            <source src={this.props.previewUrl} type="video/mp4" />
                            <source src={this.props.previewUrl} type="video/ogg" />
                            <source src={this.props.previewUrl} type="video/webm" />
                        </video>
                    )}
                    <p className={styles.type}>{this.props.contentType}</p>
                    {this.state.icon}
                </div>

                <Modal show={this.state.showPreviewModal} title="Preview" onClose={this.closeModal} fullWidth>
                    <div className={styles.previewModalContent}>
                        {this.props.contentType === ContentTypes.pdf && (
                            <object data={this.props.previewUrl} type="application/pdf" width="100%" height="800px">
                                <p>Unable to show pdf.</p>
                            </object>
                        )}
                        {this.props.contentType === ContentTypes.image && (
                            <img src={this.props.previewUrl} alt="file preview" />
                        )}
                        {this.props.contentType === ContentTypes.video && (
                            <video autoPlay playsInline muted>
                                <source src={this.props.previewUrl} type="video/mp4" />
                                <source src={this.props.previewUrl} type="video/ogg" />
                                <source src={this.props.previewUrl} type="video/webm" />
                            </video>
                        )}
                    </div>
                </Modal>
            </div>
        )
    }
}

export default KnowContentPreview
