export default function isValidHttpUrl(message: string): boolean {
    let url

    try {
        url = new URL(message)
    } catch (_) {
        return false
    }

    return url.protocol === 'http:' || url.protocol === 'https:'
}

export const hasWhiteSpace = (text: string) => /\s/.test(text)
