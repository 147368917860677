import React, { useEffect } from 'react'
import { Router, Switch, Route, Redirect, RouteProps } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { AppState } from 'redux/reducer'
import { connect, ConnectedProps } from 'react-redux'
import Login from 'pages/Login/Login'
import UserService from 'services/UserService'
import Dashboard from 'pages/Dashboard/Dashboard'
import Users from 'pages/Users/Users'
import Knows from 'pages/Knows/KnowsPage/KnowsPage'
import User from 'pages/Users/User'
import Knower from 'pages/Knower/Knower'
import Know from 'pages/Knows/Know/Know'
import Transactions from 'pages/Transaction/Transactions'
import Subjects from 'pages/Subjects/Subjects'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { ApiError } from 'services/ApiService'
import Reports from 'pages/Reports/Reports'
import Report from 'pages/Reports/Report'
import DiscussionForumQuestion from 'pages/DiscussionForumQuestion/DiscussionForumQuestion'
import FeedbackDialogComponent from 'pages/FeedbackDialog/FeedbackDialog'
import FeedbackDialogs from 'pages/FeedbackDialog/FeedbackDialogs'
import styled from 'styled-components'
import routes from 'lib/constants/routes'
import PayoutRequests from 'pages/PayoutRequests/PayoutRequests'
import PayoutRequest from 'pages/PayoutRequests/PayoutRequest'
import AdCustomer from 'pages/AdsSystem/AdCustomer'
import ManualPushNotifications from 'pages/ManualPushNotifications/ManualPushNotifications'
import PushNotification from 'pages/ManualPushNotifications/ManualPushNotification'
import AdCampaign from 'pages/AdsSystem/AdCampaign'
import AdAsset from 'pages/AdsSystem/AdAsset'
import ManualEmails from 'pages/ManualEmails/ManualEmails'
import ManualEmail from 'pages/ManualEmails/ManualEmail'
import ManualChatMessages from 'pages/ManualChatMessages/ManualChatMessages'
import ManualChatMessage from 'pages/ManualChatMessages/ManualChatMessage'
import Subject from 'pages/Subjects/Subject'
import KnowTypes from 'pages/KnowTypes/KnowTypes'
import KnowType from 'pages/KnowTypes/KnowType'
import SchoolTypes from 'pages/SchoolType/SchoolTypes'
import SchoolType from 'pages/SchoolType/SchoolType'
import Grades from 'pages/Grades/Grades'
import GradeComponent from 'pages/Grades/Grade'
import CurriculumAnalysisComponent from 'pages/CurriculumAnalysis/CurriculumAnalysis'
import Regions from 'pages/Regions/Regions'
import RegionComponent from 'pages/Regions/Region'
import Experiments from 'pages/ExperimentationPlatform/Experiments'
import ExperimentInterface from 'pages/ExperimentationPlatform/Experiment'
import SidebarMenu from 'pages/Dashboard/SidebarMenu'
import { useProSidebar } from 'react-pro-sidebar'
import AdSystem from 'pages/AdsSystem/AdSystem'
import SeoSubjects from 'pages/Glossary/SeoSubject/SeoSubjects'
import SeoSubjectComponent from 'pages/Glossary/SeoSubject/SeoSubject'
import SeoTopicComponent from 'pages/Glossary/SeoTopic/SeoTopic'
import SeoSubTopicComponent from 'pages/Glossary/SeoSubTopic/SeoSubTopic'
import KnowerBonusPayments from 'pages/KnowerBonusPayments/KnowerBonusPayments'
import store from 'redux/store'
import { setOauthToken, setUser } from 'redux/actions'
import Competitions from 'pages/Competition/Competitions'
import CompetitionComponent from 'pages/Competition/Competition'
import TaxonomyImportComponent from 'pages/Taxonomy/TaxonomyImport'
import ModerationKeywordsComponent from 'pages/ModerationKeywords/ModerationKeywords'
import TaxonomyComponent from 'pages/Taxonomy/Taxonomy'
import CareersPartnersComponent from 'pages/CareersPartners/CareersPartners'
import CareersPartnerComponent from 'pages/CareersPartners/CareersPartner'
import SchoolCreateForm from 'pages/SchoolSuggestions/SchoolSuggestionCreateForm'
import SchoolsSuggestions from 'pages/SchoolSuggestions/SchoolsSuggestions'
import SchoolSuggestionComponent from 'pages/SchoolSuggestions/SchoolSuggestion'
import Schools from 'pages/Schools/Schools'
import School from 'pages/Schools/SchoolComponent'
import AiChatBotComponent from 'pages/AiChatBot/AiChatBotComponent'
import Fundraise from 'pages/Course/Fundraise'
import SchoolHolidays from 'pages/SchoolHoliday/SchoolHolidays'
import SchoolHolidayComponent from 'pages/SchoolHoliday/SchoolHolidayComponent'

const Main = styled.main`
    transform: all 0.3s ease-in-out;
    min-height: calc(100vh);
    display: grid;
    grid-template-columns: auto 1fr;
    position: relative;
    @media (max-width: 750px) {
        min-height: calc(100vh);
    }
`

const MenuPlaceholder = styled.div<{ isCollapsed: boolean }>`
    width: ${({ isCollapsed }) => (isCollapsed ? 100 : 300)}px;
`

interface Props extends ConnectedProps<typeof connector> {}

export const history = createBrowserHistory()

const App = (props: Props) => {
    const { collapsed } = useProSidebar()

    useEffect(() => {
        if (props.oauthToken) {
            UserService.getMe().catch((error: ApiError) => {
                error.handleUnknown('An error occurred while fetching authenticated user.')
            })
        } else {
            store.dispatch(setOauthToken(null))
            store.dispatch(setUser(null))
        }
    }, [props.oauthToken])

    const isLoggedIn = !!props.oauthToken

    return (
        <Router history={history}>
            <div>
                <Main>
                    {isLoggedIn ? (
                        <>
                            <MenuPlaceholder isCollapsed={collapsed} />
                        </>
                    ) : (
                        <span />
                    )}
                    <Switch>
                        <Route path="/" exact>
                            <Redirect to={isLoggedIn ? '/dashboard' : '/login'} />
                        </Route>
                        <Route path="/login" exact component={Login} />
                        <PrivateRoute path="/dashboard" exact component={Dashboard} isAuthenticated={isLoggedIn} />
                        <PrivateRoute path="/users" exact component={Users} isAuthenticated={isLoggedIn} />
                        <PrivateRoute path="/users/:id" exact component={User} isAuthenticated={isLoggedIn} />
                        <PrivateRoute path="/users/create" exact component={User} isAuthenticated={isLoggedIn} />
                        <PrivateRoute path="/knowers/:id" exact component={Knower} isAuthenticated={isLoggedIn} />
                        <PrivateRoute path="/knows" exact component={Knows} isAuthenticated={isLoggedIn} />
                        <PrivateRoute path="/knows/:uuid" exact component={Know} isAuthenticated={isLoggedIn} />
                        <PrivateRoute path={routes.subjectsRoute} exact component={Subjects} isAuthenticated={isLoggedIn} />
                        <PrivateRoute
                            path={`${routes.subjectsRoute}/:id`}
                            exact
                            component={Subject}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={routes.subjectsCreateRoute}
                            exact
                            component={Subject}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute path="/transactions" exact component={Transactions} isAuthenticated={isLoggedIn} />
                        <PrivateRoute path={routes.adsSystemRoute} exact component={AdSystem} isAuthenticated={isLoggedIn} />
                        <PrivateRoute
                            path={`/ads-customers/:id`}
                            exact
                            component={AdCustomer}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={routes.adsCreateCustomersRoute}
                            exact
                            component={AdCustomer}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path="/ads-customers/:uuid/campaigns/:id"
                            exact
                            component={AdCampaign}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path="/ads-customers/:uuid/campaigns/create"
                            exact
                            component={AdCampaign}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path="/ads-customers/:customerUuid/campaigns/:campaignUuid/assets/:uuid"
                            exact
                            component={AdAsset}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path="/ads-customers/:customerUuid/campaigns/:campaignUuid/assets/create"
                            exact
                            component={AdAsset}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute path="/reports/" exact component={Reports} isAuthenticated={isLoggedIn} />
                        <PrivateRoute path="/reports/:id" exact component={Report} isAuthenticated={isLoggedIn} />
                        <PrivateRoute
                            path="/discussion-forum/questions/:uuid"
                            exact
                            component={DiscussionForumQuestion}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path="/feedback-dialogs/create"
                            exact
                            component={FeedbackDialogComponent}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path="/feedback-dialogs/:uuid"
                            exact
                            component={FeedbackDialogComponent}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path="/feedback-dialogs"
                            exact
                            component={FeedbackDialogs}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={routes.payoutRequestsRoute}
                            exact
                            component={PayoutRequests}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={`${routes.payoutRequestsRoute}/:id`}
                            exact
                            component={PayoutRequest}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={routes.manualPushNotificationsRoute}
                            exact
                            component={ManualPushNotifications}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={routes.manualPushNotificationsCreateRoute}
                            exact
                            component={PushNotification}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={`${routes.manualPushNotificationsRoute}/:id`}
                            exact
                            component={PushNotification}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={routes.manualEmailsRoute}
                            exact
                            component={ManualEmails}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={routes.manualEmailCreateRoute}
                            exact
                            component={ManualEmail}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={`${routes.manualEmailsRoute}/:id`}
                            exact
                            component={ManualEmail}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={routes.manualChatMessagesRoute}
                            exact
                            component={ManualChatMessages}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={routes.manualChatMessageCreateRoute}
                            exact
                            component={ManualChatMessage}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={`${routes.manualChatMessagesRoute}/:id`}
                            exact
                            component={ManualChatMessage}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={routes.knowTypesRoute}
                            exact
                            component={KnowTypes}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={`${routes.knowTypesRoute}/:id`}
                            exact
                            component={KnowType}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={routes.schoolTypesRoute}
                            exact
                            component={SchoolTypes}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={`${routes.schoolTypesRoute}/:id`}
                            exact
                            component={SchoolType}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute path={routes.gradesRoute} exact component={Grades} isAuthenticated={isLoggedIn} />
                        <PrivateRoute
                            path={`${routes.gradesRoute}/:id`}
                            exact
                            component={GradeComponent}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute path={routes.regionsRoute} exact component={Regions} isAuthenticated={isLoggedIn} />
                        <PrivateRoute
                            path={`${routes.regionsRoute}/:id`}
                            exact
                            component={RegionComponent}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={routes.curriculumAnalysisRoute}
                            exact
                            component={CurriculumAnalysisComponent}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={routes.experimentsRoute}
                            exact
                            component={Experiments}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={`${routes.experimentsRoute}/:uuid`}
                            exact
                            component={ExperimentInterface}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={routes.experimentCreateRoute}
                            exact
                            component={ExperimentInterface}
                            isAuthenticated={isLoggedIn}
                        />

                        <PrivateRoute
                            path={routes.careerPartnersRoute}
                            exact
                            component={CareersPartnersComponent}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={routes.careerPartnersCreateRoute}
                            exact
                            component={CareersPartnerComponent}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={`${routes.careerPartnersRoute}/:uuid`}
                            exact
                            component={CareersPartnerComponent}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={routes.seoSubjectsRoute}
                            exact
                            component={SeoSubjects}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={routes.experimentCreateRoute}
                            exact
                            component={SeoSubjectComponent}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={`${routes.seoSubjectsRoute}/:id`}
                            exact
                            component={SeoSubjectComponent}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path="/seo-subjects/:subjectUuid/seo-topics/:uuid"
                            exact
                            component={SeoTopicComponent}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path="/seo-subjects/:subjectUuid/seo-topics/create"
                            exact
                            component={SeoTopicComponent}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path="/seo-subjects/:subjectUuid/seo-topics/:topicUuid/seo-subtopics/:uuid"
                            exact
                            component={SeoSubTopicComponent}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path="/seo-subjects/:subjectUuid/seo-topics/:topicUuid/seo-subtopics/create"
                            exact
                            component={SeoSubTopicComponent}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path="/competitions/create"
                            exact
                            component={CompetitionComponent}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path="/competitions/:uuid"
                            exact
                            component={CompetitionComponent}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute path="/competitions" exact component={Competitions} isAuthenticated={isLoggedIn} />
                        <PrivateRoute
                            path={routes.knowerBonusPayments}
                            exact
                            component={KnowerBonusPayments}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={routes.knowerBonusPayments}
                            exact
                            component={KnowerBonusPayments}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={routes.taxonomyImportRoute}
                            exact
                            component={TaxonomyImportComponent}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={routes.taxonomyRoute}
                            exact
                            component={TaxonomyComponent}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={routes.moderationKeywordsRoute}
                            exact
                            component={ModerationKeywordsComponent}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={routes.schoolsSuggestionCreateRoute}
                            exact
                            component={SchoolCreateForm}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={routes.schoolsSuggestionsRoute}
                            exact
                            component={SchoolsSuggestions}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={`${routes.schoolsSuggestionsRoute}/:uuid`}
                            exact
                            component={SchoolSuggestionComponent}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute path={routes.schoolsRoute} exact component={Schools} isAuthenticated={isLoggedIn} />
                        <PrivateRoute
                            path={`${routes.schoolsRoute}/:uuid`}
                            exact
                            component={School}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={routes.schoolCreateRoute}
                            exact
                            component={School}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={routes.schoolHolidaysRoute}
                            exact
                            component={SchoolHolidays}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={`${routes.schoolHolidaysRoute}/:uuid`}
                            exact
                            component={SchoolHolidayComponent}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={routes.schoolHolidayCreateRoute}
                            exact
                            component={SchoolHolidayComponent}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={`${routes.aiChatBotsRoute}/:uuid`}
                            exact
                            component={AiChatBotComponent}
                            isAuthenticated={isLoggedIn}
                        />
                        <PrivateRoute
                            path={routes.courseFundraiseRoute}
                            exact
                            component={Fundraise}
                            isAuthenticated={isLoggedIn}
                        />
                    </Switch>
                    {isLoggedIn ? <SidebarMenu /> : null}
                </Main>

                <ToastContainer position="bottom-right" />
            </div>
        </Router>
    )
}

const PrivateRoute = ({ component, isAuthenticated, ...rest }: RouteProps & { isAuthenticated: boolean }) => {
    const routeComponent = (props: RouteProps) =>
        isAuthenticated && component ? React.createElement(component, props) : <Redirect to="/" />

    return <Route {...rest} render={routeComponent} />
}

const mapStateToProps = (state: AppState) => ({
    oauthToken: state.oauthToken,
    user: state.user,
})

const connector = connect(mapStateToProps)
export default connector(App)
