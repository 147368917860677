import React, { useEffect, useState } from 'react'
import { MessageBubble, ChatWrapper, MessageRowWrapper, MessageText, MessageOwner } from './ReportedChatSnippet.styles'
import { Chat, SurroundingChatMessages } from 'interfaces/Chat'
import ChatService from 'services/ChatService'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import routes from 'lib/constants/routes'
import Row from 'components/elements/Row'
import styled from 'styled-components'
import theme from 'lib/constants/theme'
import { Subtitle2 } from 'components/elements/Text'
import { previewMessage } from 'utils/chat'
import Stack from 'components/elements/Stack'

interface MessageRowProps {
    isRightSide: boolean
    message: SurroundingChatMessages
}

const Badge = styled.div`
    border-radius: ${theme.borderRadius.card};
    border: 1px solid ${theme.colors.white};
    padding: 5px 10px;
`

const formatMessageItem = (surroundingMessage: SurroundingChatMessages, isRightSide: boolean) => (
    <MessageText isRightSide={isRightSide}>{previewMessage(surroundingMessage.ChatMessage)}</MessageText>
)

const messageStatus = (chatMessage: SurroundingChatMessages) => {
    const badges = []

    chatMessage.isDeleted && badges.push('Deleted')

    chatMessage.isReportedMessage && badges.push('Reported')

    chatMessage.isShadowBanned && badges.push('Shadow Banned')

    if (!badges?.length) return

    return (
        <Row gap={5}>
            {badges.map((badge) => (
                <Badge key={badge}>
                    <Subtitle2 fontWeigth={400} color={theme.colors.white}>
                        {badge}
                    </Subtitle2>
                </Badge>
            ))}
        </Row>
    )
}

const MessageRow = ({ isRightSide, message }: MessageRowProps) => {
    return (
        <MessageRowWrapper isRightSide={isRightSide}>
            <MessageBubble
                isRightSide={isRightSide}
                isReportedMessage={message.isReportedMessage}
                isHidden={message.isShadowBanned || message.isDeleted}
            >
                <Row>
                    <MessageOwner isRightSide={isRightSide} to={routes.userRoute(message.userUuid)}>
                        {`${message.userName}`}
                    </MessageOwner>
                    {messageStatus(message)}
                </Row>
                {formatMessageItem(message, isRightSide)}
            </MessageBubble>
        </MessageRowWrapper>
    )
}

interface ReportedChatSnippetProps {
    chatUUID: string
    messageUUID: string
    reportedKnowerUUID: string
    areAllReportsAutomatic?: boolean
}

const AlertBanner = styled.div`
    border-radius: 5px;
    background-color: ${theme.colors.secondaryRed};
    padding: 10px;
`

export const ReportedChatMessage = ({
    chatUUID,
    messageUUID,
    reportedKnowerUUID,
    areAllReportsAutomatic,
}: ReportedChatSnippetProps) => {
    const [reportedChatMessages, setReportedChatMessages] = React.useState<SurroundingChatMessages[]>([])
    const [reportedChat, setReportedChat] = useState<Chat>()

    useEffect(() => {
        trackPromise(
            ChatService.get(chatUUID)
                .then((chat) => setReportedChat(chat))
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while getting reported chat.')
                    } else {
                        throw error
                    }
                })
        )

        trackPromise(
            ChatService.getSurroundingMessages(chatUUID, messageUUID)
                .then((response) => {
                    setReportedChatMessages(response)
                })
                .catch((error) => {
                    setReportedChatMessages([])
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while getting reported chat messages.')
                    } else {
                        throw error
                    }
                })
        )
    }, [chatUUID, messageUUID])

    if (!reportedChatMessages.length) return <>** No chat to display **</>

    return (
        <>
            <Stack>
                {reportedChat ? (
                    <>
                        <p>
                            <strong>Chat Type: </strong>
                            {`
                            ${
                                reportedChat.isGroupChat
                                    ? `${reportedChat.isPrivate ? 'private' : 'public'} group chat`
                                    : '1:1'
                            }
                        `}
                        </p>
                        {areAllReportsAutomatic && (reportedChat.isPrivate || reportedChat.isPrivate === null) ? (
                            <AlertBanner>
                                {`If a report is automatically created and concerns a private chat (whether that's 1:1 or a
                                group chat), you should only accept the report if it concerns minor safety, or adult nudity
                                and sexual activities.`}
                            </AlertBanner>
                        ) : null}
                    </>
                ) : null}
            </Stack>
            <ChatWrapper>
                {reportedChatMessages.map((message, index) => (
                    <MessageRow key={index} isRightSide={message.userUuid === reportedKnowerUUID} message={message} />
                ))}
            </ChatWrapper>
        </>
    )
}
