import React, { useCallback, useState, useEffect } from 'react'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import { formatDateTime } from 'utils'
import { Body1, ButtonText } from 'components/elements/Text'
import theme from 'lib/constants/theme'
import { NoWrapText } from 'style'
import KnowService from 'services/KnowService'
import RefactoredTable from 'components/modules/RefactoredTable'
import routes from 'lib/constants/routes'
import { ContentTypes } from 'interfaces/Know'
import ContentPreview from 'components/modules/ContentPreview/ContentPreview'
import Button, { ButtonA } from 'components/elements/Button'
import Modal from 'components/modules/Modal'
import { useToggleState } from 'utils/hooks/useToggleState'

const loadingAreas = {
    container: 'plagiarismIndicatorContainer',
}

interface Props {
    knowUuid: string
}

const PlagiarismIndicator = ({ knowUuid }: Props) => {
    const [showPlagiarismModal, togglePlagiarismModal] = useToggleState(false)
    const [score, setScore] = useState(0)

    const fetchAssets = useCallback(
        (page: number) => {
            return trackPromise(
                KnowService.getPlagiarismCandidates(knowUuid, page, 10)
                    .then((data) => {
                        if (page !== data.page) return

                        if (data.content.length === 0) {
                            setScore(0)
                            return
                        } else {
                            setScore(Math.round(data.content[0].score))
                        }

                        return {
                            totalPages: data.totalPages,
                            totalElements: data.totalElements,
                            elements: data.content.map((candidate) => ({
                                id: candidate.know.uuid,
                                columns: [
                                    <NoWrapText key={candidate.know.uuid}>{candidate.score}</NoWrapText>,
                                    candidate.know.title,
                                    candidate.know.knowType.name,
                                    candidate.know.subjects.map((s) => s.name).join(', '),
                                    candidate.know.grades.map((g) => g.shortName).join(', '),
                                    candidate.know?.publishedOn ? formatDateTime(candidate.know?.publishedOn) : 'not yet',
                                    <ContentPreview
                                        key={`preview-${candidate.know.uuid}`}
                                        contentType={ContentTypes.pdf}
                                        previewUrl={candidate.know.thumbnailLargeUrl}
                                    />,
                                    <ButtonA
                                        key={`know-redirect-${candidate.know.uuid}`}
                                        fullWidth={false}
                                        to={routes.knowRoute(candidate.know.uuid)}
                                        target="_blank"
                                    >
                                        <ButtonText textAlign="center" color={theme.colors.white}>
                                            Go to know
                                        </ButtonText>
                                    </ButtonA>,
                                ],
                            })),
                        }
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while getting plagiarism candidates.')
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.container
            )
        },
        [knowUuid]
    )

    useEffect(() => {
        fetchAssets(0)
    }, [fetchAssets])

    return (
        <>
            {score ? (
                <div>
                    <Button fullWidth={false} onClick={togglePlagiarismModal} loadingArea={loadingAreas.container}>
                        {`Plagiarism Candidates (Top Score: ${score})`}
                    </Button>
                    <Modal
                        fullWidth
                        title="Plagiarism Candidates"
                        show={showPlagiarismModal}
                        onClose={togglePlagiarismModal}
                    >
                        <RefactoredTable
                            columns={['Score', 'Title', 'Type', 'Subject', 'Grades', 'Publish date', 'Preview', 'Action']}
                            loadData={fetchAssets}
                            loadingArea={loadingAreas.container}
                            refetchKey={undefined}
                        />
                    </Modal>
                </div>
            ) : (
                <Body1 color={theme.colors.knowunityBlue}>
                    <strong>Plagiarism candidates:</strong>&nbsp; None
                </Body1>
            )}
        </>
    )
}

export default PlagiarismIndicator
