import React from 'react'
import { Grade } from 'interfaces/Grade'
import { Subject } from 'interfaces/Subject'
import styled, { css } from 'styled-components'
import { ReactComponent as SchoolIcon } from 'assets/icons/education.svg'
import { KnowStatus, KnowType } from 'interfaces/Know'
import { ReactNode } from 'react'
import { complimentingColor, colorToCssFilter, formatAmount } from 'utils'
import CheckIcon from 'assets/icons/small-check.svg'
import routes from 'lib/constants/routes'
import { Currency } from 'interfaces/Transaction'
import theme from 'lib/constants/theme'
import { SchoolType } from 'interfaces/SchoolType'
import { Link } from 'react-router-dom'

export const BadgeWrapper = styled.div<{
    readonly align: 'left' | 'right'
    fullWidth?: boolean
    forceMobileAlignment?: boolean
}>`
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    color: #c2c7cc;
    align-items: center;
    justify-content: ${(props) => (props.align === 'left' ? 'flex-start' : 'flex-end')};
    ${({ fullWidth }) =>
        fullWidth
            ? css`
                  width: 100%;
              `
            : ''}

    span {
        margin: 3px;
    }
    @media (max-width: 750px) {
        ${({ forceMobileAlignment }) =>
            !forceMobileAlignment
                ? css`
                      justify-content: center;
                  `
                : ''}
    }
`

const BadgeElement = styled.span<{
    readonly textColor: string | null
    readonly backgroundColor: string
}>`
    color: ${(props) => props.textColor || 'blue'};
    background: ${(props) => props.backgroundColor || 'blue'};
    border-radius: 5px;
    font-weight: 550;
    padding: 0px 10px;
    margin: 0 6px 0px 0;
    display: flex;
    align-items: center;
    min-height: 32px;
    @media (max-width: 750px) {
        margin: 0 6px 6px 0;
    }

    & .icon {
        display: flex;
        align-items: center;
        fill: ${(props) => props.textColor || 'blue'};
    }

    & img {
        height: 14px;
    }
    & p {
        margin: 0;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 0em;
        text-align: left;
    }
`

interface BadgeProps {
    text: string
    backgroundColor: string
    textColor: string
    icon?: ReactNode
}

export const Badge = (props: BadgeProps) => (
    <BadgeElement backgroundColor={props.backgroundColor} textColor={props.textColor}>
        {props.icon ? <div className="icon">{props.icon} &nbsp;</div> : null}
        <p>{props.text}</p>
    </BadgeElement>
)

const FilteredImage = styled.span<{ readonly filter: string }>`
    & img {
        filter: ${(props) => props.filter};
    }
`

export const SubjectBadge = (props: { subject: Subject; shortenSubjectName?: boolean }) => {
    const color = props.subject.color

    const formatSubjectName = (subjectName: string) =>
        props.shortenSubjectName && subjectName?.length > 8 ? `${subjectName.slice(0, 8)}...` : subjectName

    return (
        <Link to={routes.subjectRoute(props.subject.id)}>
            <Badge
                textColor={props.subject.color}
                backgroundColor={complimentingColor(color.toLowerCase())}
                text={formatSubjectName(props.subject.name)}
                icon={
                    <FilteredImage filter={colorToCssFilter(color)}>
                        <img src={props.subject.iconUrl} alt="" height={16} width={16} />
                    </FilteredImage>
                }
            />
        </Link>
    )
}

export const GradeBadge = (props: { grades: Grade[]; schoolType?: SchoolType }) => {
    const text = props.grades.map((grade) => grade.shortName).join('/')

    return (
        <Badge
            textColor="var(--secondary-color)"
            backgroundColor="rgba(24, 130, 255, 0.2)"
            text={`${text}${props?.schoolType ? ` - ${props.schoolType.name}` : ''}`}
            icon={<SchoolIcon />}
        />
    )
}

export const KnowTypeBadge = (props: { knowType: KnowType }) => {
    return <Badge textColor={theme.colors.body2Black} backgroundColor="rgba(97, 99, 102, 0.15)" text={props.knowType.name} />
}

export const KnowStatusBadge = (props: { status: KnowStatus }) => {
    return <Badge textColor="#ff4848" backgroundColor="rgba(255, 72, 72, 0.2)" text={props.status} />
}

export const AnsweredQuestionBadge = () => {
    return <Badge textColor={theme.colors.body2Black} backgroundColor="#ddfacf" text="Gelöst" icon={<CheckIcon />} />
}

export const PayAmountBadge = (props: { amount: number; currency: Currency }) => {
    return (
        <Badge
            textColor={theme.colors.body2Black}
            backgroundColor={theme.colors.captionGray}
            text={formatAmount(props.amount, props.currency).split(' ').reverse().join('⠀')}
        />
    )
}
