import React from 'react'
import { User } from 'interfaces/User'
import theme from 'lib/constants/theme'
import styled from 'styled-components'

interface Props {
    user: User
    size?: number
    boxShadow?: boolean
    hasBorder?: boolean
    borderColor?: string
}

interface RawProps {
    name: string
    profilePicture: string | null
    size?: number
    boxShadow?: boolean
    hasBorder?: boolean
    borderColor?: string
}

const Container = styled.div<{
    readonly size: number
    readonly boxShadow?: boolean
    readonly hasBorder?: boolean
    readonly borderColor?: string
}>`
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    background-size: cover;
    background-position: center;
    border: ${({ hasBorder, borderColor }) => (hasBorder ? `5px solid ${borderColor ?? '#fff'}` : 'none')};
    border-radius: 100%;
    box-shadow: ${({ boxShadow }) =>
        boxShadow ? 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px' : 'none'};
`

const Placeholder = styled(Container)`
    background: ${theme.colors.knowunityBlue};
    color: white;
    display: grid;
    place-items: center;
    user-select: none;
    overflow: hidden;
`

export const ProfilePictureRaw = (props: RawProps) => {
    const size = props.size ? props.size : 30

    if (!props.profilePicture) {
        return (
            <Placeholder size={size} boxShadow={props.boxShadow} hasBorder={props.hasBorder}>
                <span>{props?.name?.charAt(0)?.toUpperCase() ?? 'N/A'}</span>
            </Placeholder>
        )
    }

    return (
        <Container
            size={size}
            boxShadow={props.boxShadow}
            hasBorder={props.hasBorder}
            style={{
                backgroundImage: `url(${props.profilePicture})`,
            }}
            borderColor={props.borderColor}
        >
            <img style={{ borderRadius: '50%' }} src={props.profilePicture} alt="user profile" width={size} height={size} />
        </Container>
    )
}

const ProfilePicture = (props: Props) => {
    return (
        <ProfilePictureRaw
            name={props.user.name}
            profilePicture={props.user.profilePicture}
            size={props.size}
            boxShadow={props.boxShadow}
            hasBorder={props.hasBorder}
            borderColor={props.borderColor}
        />
    )
}

export default ProfilePicture
