import { LanguageInterface } from 'interfaces/Language'
import ApiService from './ApiService'

const LanguageService = {
    list(showNotLaunched?: boolean) {
        return ApiService.get(`languages${showNotLaunched ? `?showNotLaunched=${showNotLaunched}` : ''}`).then(
            (response) => {
                return response.data.content as LanguageInterface[]
            }
        )
    },
}

export default LanguageService
