/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react'
import Button from 'components/elements/Button'
import { Body1, ButtonText } from 'components/elements/Text'
import styled from 'styled-components'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import InputElement from './InputElement'
import theme from 'lib/constants/theme'
import { Input } from './Input'

const FormContainer = styled.div`
    padding: 5px;
    display: grid;
    .row {
        display: grid;
        grid-template-columns: 1fr 1fr auto;
        place-content: center;
        gap: 15px;
    }
    .button {
        display: grid;
        place-content: center;
        padding-top: 20px;
    }
    .empty-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`

interface Props {
    defaultRecords?: Record<string, string | number | boolean | number[] | string[]>
    addRecord: (title: string, value: string) => void
    disableAddingRecords?: boolean
    selectedVariantId?: number
    type: 'CONFIG_FLAG' | 'BACKEND_TOGGLE'
}

const RecordForm = ({ defaultRecords, disableAddingRecords, selectedVariantId, type }: Props) => {
    const recordEntries = defaultRecords ? Object.entries(defaultRecords) : null
    const [recordCount, setRecordsCount] = useState(recordEntries?.length ?? 0)

    const addNewRow = () => setRecordsCount((count) => ++count)

    return (
        <FormContainer>
            {!disableAddingRecords ? (
                <>
                    <div className="empty-list">
                        <Body1 color={theme.colors.knowunityBlue}>
                            {type === 'CONFIG_FLAG' ? 'App Config Flags' : 'Backend Toggles'}
                        </Body1>
                        <Button fullWidth={false} icon={<AddIcon />} onClick={addNewRow} type="button">
                            <ButtonText color={theme.colors.white}>{`Add ${
                                type === 'CONFIG_FLAG' ? 'app config flag' : 'backend toggle'
                            }`}</ButtonText>
                        </Button>
                    </div>

                    {recordCount ? (
                        <>
                            <br />
                            {new Array(recordCount).fill(0).map((_, i) => (
                                <div className="row" key={`record-row-${i}`}>
                                    <Input
                                        name={`${type === 'CONFIG_FLAG' ? 'app' : 'backend'}-record-title-${i}-${
                                            selectedVariantId ?? ''
                                        }`}
                                        placeholder="Property title"
                                        noMargin
                                    />
                                    <Input
                                        name={`${type === 'CONFIG_FLAG' ? 'app' : 'backend'}-record-value-${i}-${
                                            selectedVariantId ?? ''
                                        }`}
                                        placeholder={`Property value (separe by "," for arrays)`}
                                        noMargin
                                    />
                                </div>
                            ))}
                        </>
                    ) : (
                        'No data added to the variant'
                    )}
                </>
            ) : (
                <>
                    <Body1 color={theme.colors.white}>
                        {type === 'CONFIG_FLAG' ? 'App Config Flags' : 'Backend Toggles'}
                    </Body1>
                    <br />
                    {recordEntries?.length ? (
                        <>
                            {new Array(recordEntries?.length).fill(0).map((_, i) => (
                                <div className="row" key={`${recordEntries[i]}-${i}`}>
                                    <InputElement
                                        defaultValue={recordEntries[i][0]}
                                        noMargin
                                        disabled
                                        label="Property title"
                                        onInput={() => {}}
                                    />
                                    <InputElement
                                        defaultValue={recordEntries[i][1].toString()}
                                        noMargin
                                        disabled
                                        label="Property title"
                                        onInput={() => {}}
                                    />
                                </div>
                            ))}
                        </>
                    ) : (
                        'No data added to the variant'
                    )}
                </>
            )}
        </FormContainer>
    )
}

export default RecordForm
