import React from 'react'
import Stack from 'components/elements/Stack'
import { Body2 } from 'components/elements/Text'
import { Country } from 'interfaces/Country'
import theme from 'lib/constants/theme'
import { useEffect, useState } from 'react'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import styled from 'styled-components'
import CountriesService from 'services/CountriesService'

const SelectionCard = styled.div`
    width: 100%;
    cursor: pointer;
    border: 2px solid ${theme.colors.accentBlue};
    border-radius: ${theme.borderRadius.small};
    height: 50px;
    padding: 10px;

    & p {
        margin: 0;
    }

    &:hover {
        border: 2px solid ${theme.colors.knowunityBlue};
    }
`

interface Props {
    handleCountrySelect: (country: Country) => void
}

const GradeSelectionCountry = ({ handleCountrySelect }: Props) => {
    const [countries, setCountries] = useState<Country[]>([])

    useEffect(() => {
        trackPromise(
            CountriesService.list()
                .then((country) => {
                    setCountries(country)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while fetching the countries.')
                    } else {
                        throw error
                    }
                })
        )
    }, [])

    return (
        <Stack>
            <Body2>{`Choose country`}</Body2>
            {countries.map((country) => (
                <SelectionCard key={country.id} onClick={() => handleCountrySelect(country)}>
                    <p>{`${country.localizedName} (${country.code})`}</p>
                </SelectionCard>
            ))}
        </Stack>
    )
}

export default GradeSelectionCountry
