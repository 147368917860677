import React, { useCallback, useEffect, useState } from 'react'
import { Link, RouteComponentProps, useHistory } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker'
import { ApiError } from 'services/ApiService'
import routes from 'lib/constants/routes'
import Meta from 'components/modules/Head'
import Box from 'components/elements/Box'
import Stack from 'components/elements/Stack'
import CopyIcon from 'components/elements/CopyIcon'
import AiChatBotService from 'services/AiChatBotService'
import { AiChatBot } from 'interfaces/AiChatBot'
import AiChatBotForm from './AiChatBotForm'
import { Body1 } from 'components/elements/Text'
import { toast } from 'react-toastify'
import Button from 'components/elements/Button'

interface Props extends RouteComponentProps<{ uuid: string }> {}

const loadingAreas = {
    container: 'aiBotContainer',
    delete: 'aiBotDelete',
}

const AiChatBotComponent = (props: Props) => {
    const [aiBot, setAiBot] = useState<AiChatBot | null>(null)
    const uuid = props.match.params.uuid
    const history = useHistory()

    const fetchData = useCallback(() => {
        if (!uuid) return

        trackPromise(
            AiChatBotService.get(uuid)
                .then((aiBot) => {
                    setAiBot(aiBot)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting AI Chat Bot.')
                }),
            loadingAreas.container
        )
    }, [uuid])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const updateAiBot = (bot: AiChatBot) => setAiBot(bot)

    const deleteBot = () => {
        if (!window.confirm('Do you really want to delete the bot?')) return

        trackPromise(
            AiChatBotService.delete(uuid)
                .then(() => {
                    toast.success(`Bot has been deleted`)
                    history.push(routes.dashboardRoute)
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        error.handleUnknown('An error occurred while deleting ai chat bot.')
                    } else {
                        throw error
                    }
                }),
            loadingAreas.delete
        )
    }

    return (
        <section>
            <Meta title="AI Chat Bot" />
            <h2>AI Chat Bot</h2>
            {aiBot && (
                <Box>
                    <Stack>
                        <h3>Information</h3>
                        <p>
                            <strong>Uuid:</strong>&nbsp;
                            {aiBot.uuid}
                            <CopyIcon text={aiBot.uuid} />
                        </p>
                        <p>
                            <strong>Name:</strong>&nbsp;
                            {aiBot.name}
                        </p>
                        <p>
                            <strong>Description:</strong>&nbsp;
                            {aiBot.description}
                        </p>
                        <p>
                            <strong>Creator: </strong>&nbsp;
                            <Link to={routes.userRoute(aiBot.creatorUserUuid)}>{aiBot.creatorUserUuid}</Link>
                            <CopyIcon text={aiBot.creatorUserUuid} />
                        </p>
                        <p>
                            <strong>Icon:</strong>&nbsp;
                        </p>
                        <img width={100} height="auto" src={aiBot.avatarImageUrl} alt="Chat Bot Icon" />
                        <p>
                            <strong>Approved:</strong>&nbsp;
                            {aiBot.isApproved === null ? 'not set' : aiBot.isApproved ? 'Yes' : 'No (rejected)'}
                        </p>
                        <p>
                            <strong>Public:</strong>&nbsp;
                            {aiBot.isPublic ? 'Yes' : 'No'}
                        </p>
                        {aiBot?.categories?.length ? (
                            <p>
                                <strong>Categories:</strong>&nbsp;
                                {aiBot.categories.map((c) => (
                                    <Body1 key={c.uuid} color={c.color}>
                                        {c.name}
                                    </Body1>
                                ))}
                            </p>
                        ) : null}
                        {aiBot?.abilities?.length ? (
                            <p>
                                <strong>Abilities:</strong>&nbsp;
                                {aiBot.abilities.map((x) => x).join(', ')}
                            </p>
                        ) : null}
                        {aiBot?.userDefinedAbilities?.length ? (
                            <p>
                                <strong>User defined abilities:</strong>&nbsp;
                                {aiBot.userDefinedAbilities.map((x) => x).join(', ')}
                            </p>
                        ) : null}
                        <p>
                            <strong>Greeting:</strong>&nbsp;
                            {aiBot.greeting}
                        </p>
                        <p>
                            <strong>Personality description:</strong>&nbsp;
                            {aiBot.personalityDescription}
                        </p>
                        {aiBot?.personalityTraits?.length ? (
                            <p>
                                <strong>Personality traits:</strong>&nbsp;
                                {aiBot.personalityTraits.map((x) => x).join(', ')}
                            </p>
                        ) : null}
                        <p>
                            <strong>Situation description:</strong>&nbsp;
                            {aiBot.situationDescription}
                        </p>
                        <Button
                            fullWidth
                            onClick={deleteBot}
                            loadingArea={loadingAreas.delete}
                            color="var(--red)"
                            hoverColor="var(--red-dark)"
                        >
                            {`Delete bot`}
                        </Button>
                    </Stack>
                </Box>
            )}
            {aiBot ? <AiChatBotForm bot={aiBot} botUuid={uuid} updateBot={updateAiBot} /> : null}
            <div style={{ height: 100 }} />
        </section>
    )
}

export default AiChatBotComponent
