import { getDataCenterRegion } from './dataCenter'

export enum Environment {
    Prod = 'prod',
    Staging = 'staging',
    Dev = 'dev',
    Local = 'local',
}

function getVariable(value: string | undefined, name: string) {
    if (!value) {
        throw new Error(`Environment variable ${name} not set`)
    }
    return value
}

function getEnvironment() {
    switch (process.env.REACT_APP_ENV) {
        case 'prod':
            return Environment.Prod
        case 'staging':
            return Environment.Staging
        case 'dev':
            return Environment.Dev
        default:
            return Environment.Local
    }
}

function getApiBaseUrl() {
    const region = getDataCenterRegion()

    if (getEnvironment() === Environment.Prod) {
        return `https://apiedge-${region}.knowunity.com`
    }
    return `https://apiedge-${region}.knowunity.dev`
}

function getContentBaseUrl() {
    const region = getDataCenterRegion()

    if (getEnvironment() === Environment.Prod) {
        return `https://content-${region}.knowunity.com`
    }
    return `https://content-${region}.knowunity.dev`
}

function getGoogleClientId() {
    return getVariable(process.env.REACT_APP_AUTH_GOOGLE_CLIENT_ID, 'REACT_APP_AUTH_GOOGLE_CLIENT_ID')
}

function getHCaptchaKey() {
    if (getEnvironment() === Environment.Prod) {
        return getVariable(process.env.REACT_APP_HCAPTCHA_KEY_PROD, 'REACT_APP_HCAPTCHA_KEY_PROD')
    }
    return getVariable(process.env.REACT_APP_HCAPTCHA_KEY_DEV, 'REACT_APP_HCAPTCHA_KEY_DEV')
}

function getGlobalAuthenticationBaseUrl() {
    if (getEnvironment() === Environment.Prod) {
        return getVariable(
            process.env.REACT_APP_GLOBAL_AUTHENTICATION_BASE_URL_PROD,
            'REACT_APP_GLOBAL_AUTHENTICATION_BASE_URL_PROD'
        )
    }
    return getVariable(
        process.env.REACT_APP_GLOBAL_AUTHENTICATION_BASE_URL_DEV,
        'REACT_APP_GLOBAL_AUTHENTICATION_BASE_URL_DEV'
    )
}

function getApiSecretCode() {
    if (getEnvironment() === Environment.Prod) {
        return getVariable(
            process.env.REACT_APP_API_CLIENT_VALIDATION_SECRET_PROD,
            'REACT_APP_API_CLIENT_VALIDATION_SECRET_PROD'
        )
    }
    return getVariable(process.env.REACT_APP_API_CLIENT_VALIDATION_SECRET_DEV, 'REACT_APP_API_CLIENT_VALIDATION_SECRET_DEV')
}

const config = {
    Environment,
    getEnvironment,
    getApiBaseUrl,
    getContentBaseUrl,
    getGoogleClientId,
    getHCaptchaKey,
    getGlobalAuthenticationBaseUrl,
    getApiSecretCode,
}

export default config
