import ApiService from 'services/ApiService'
import { AdminTransaction, Transaction, TransactionType } from 'interfaces/Transaction'

const TransactionService = {
    list(page: number) {
        return ApiService.get(`transactions?page=${page}`).then((response) => {
            return {
                transactions: response.data.content as Array<Transaction>,
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    listByKnower(knowerUuid: string, page: number) {
        return ApiService.get(`knowers/${knowerUuid}/transactions?page=${page}`).then((response) => {
            return {
                transactions: response.data.content as Array<Transaction>,
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    listByKnowerAdmin(knowerUuid: string, page: number) {
        return ApiService.get(`knowers/${knowerUuid}/transactions/admin?page=${page}`).then((response) => {
            return {
                transactions: response.data.content as AdminTransaction[],
                page: response.data.number as number,
                totalPages: response.data.totalPages as number,
                totalElements: response.data.totalElements as number,
            }
        })
    },

    createTransaction(knowerUuid: string, amount: number, type: TransactionType, description: string) {
        return ApiService.post(`knowers/${knowerUuid}/transactions`, {
            amount,
            type,
            description,
        }).then((response) => {
            return response.data as Transaction
        })
    },
}

export default TransactionService
