import React, { useEffect, useRef, useState } from 'react'
import Button from 'components/elements/Button'
import Modal from 'components/modules/Modal'
import styled from 'styled-components'
import Stack from 'components/elements/Stack'
import { useToggleState } from 'utils/hooks/useToggleState'
import { AcceptReportReason, ReportItem } from 'interfaces/Report'
import { filterReportReasonsByVialionLevel, formatReportReasonTitle } from 'lib/features/reports/reportsReasons'
import { ApiError } from 'services/ApiService'
import ReportService from 'services/ReportService'

const ReasonBox = styled.div`
    cursor: pointer;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 15px;
    place-items: center start;
    background: var(--background-light);
    padding: 5px;

    & input {
        width: 100%;
    }

    &:hover {
        background: var(--background-blue-darker);
    }
`

const Number = styled.p`
    background: var(--primary-color);
    padding: 10px;
    width: 30px;
    height: 30px;
    display: grid;
    place-content: center;
`

interface Props {
    reportItem: ReportItem
    show: boolean
    onClose(): void
    onReject(message: string): void
}

const ReportReasonModal = ({ reportItem, show, onClose, onReject }: Props) => {
    const [showContentSafetyOptions, toggleContentSafetyOptions] = useToggleState(false)
    const [contentQuality, setQualityRejectionReasons] = useState<AcceptReportReason[]>([])
    const [contentSafety, setSafetyRejectionReasons] = useState<AcceptReportReason[]>([])

    const inputRef = useRef<HTMLInputElement>(null)

    const returnReasonOptions = () => (showContentSafetyOptions ? contentSafety : contentQuality)

    useEffect(() => {
        ReportService.getReportReasons()
            .then((acceptReportReasons) => {
                const reportReasonsFilteredByReportType = acceptReportReasons.filter((reasons) =>
                    reasons.supportedContentTypes.includes(reportItem.type)
                )

                setQualityRejectionReasons(filterReportReasonsByVialionLevel(reportReasonsFilteredByReportType, 'QUALITY'))
                setSafetyRejectionReasons(filterReportReasonsByVialionLevel(reportReasonsFilteredByReportType, 'SAFETY'))
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknown('An error occurred while getting accept report reasons.')
                } else {
                    throw error
                }
            })
    }, [reportItem.type])

    useEffect(() => {
        const handler = (event: KeyboardEvent) => {
            if ((event.target && (event.target as Element).nodeName === 'INPUT') || showContentSafetyOptions) {
                return
            }

            const number = parseInt(event.key)
            if (isNaN(number)) return
            if (number <= contentQuality.length) {
                onReject(contentQuality[number - 1].name)
            } else if (number === contentQuality.length + 1) {
                if (!inputRef.current) return
                inputRef.current.focus()
            }
        }

        document.addEventListener('keyup', handler)
        return () => {
            document.removeEventListener('keyup', handler)
        }
    }, [contentQuality, onReject, showContentSafetyOptions])

    const handleReasonClicked = (reason: string) => {
        onReject(reason)
    }

    const onCloseRejectModal = () => {
        showContentSafetyOptions && toggleContentSafetyOptions()
        onClose()
    }

    return (
        <Modal show={show} title="Choose the report reason" onClose={onCloseRejectModal}>
            <Stack>
                {returnReasonOptions().map((reason, index) => (
                    <ReasonBox
                        key={index}
                        onClick={() => handleReasonClicked(reason.name)}
                        title={`Press '${index + 1}' to reject with this message (keyboard shortcut)`}
                    >
                        <Number>{showContentSafetyOptions ? '•' : `${index + 1}`}</Number>
                        <p>{formatReportReasonTitle(reason.name)}</p>
                    </ReasonBox>
                ))}
                <Button fullWidth noMargin onClick={toggleContentSafetyOptions} hoverColor="#3a5791">
                    {`Show Content ${showContentSafetyOptions ? 'Quality' : 'Safety'} Options`}
                </Button>
            </Stack>
        </Modal>
    )
}

export default ReportReasonModal
