import React, { useCallback, useEffect, useRef, useState } from 'react'
import { AppState } from 'redux/reducer'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import AuthService from 'services/AuthService'
import styled from 'styled-components'
import config from 'utils/config'
import { useCaptcha } from 'utils/hooks/useCaptcha'
import LogoImage from 'assets/icon.png'
import { toast } from 'react-toastify'
import Meta from 'components/modules/Head'

const loadingAreas = {
    login: 'loginClicked',
    continueGoogle: 'loginContinueGoogle',
}

const Container = styled.div`
    display: grid;
    place-items: center;
    min-height: calc(100vh - 70px - 55px);
`

const Wrapper = styled.div`
    width: 95%;
    max-width: 500px;
    display: grid;
    place-items: center;
    gap: 10px;
`

const GoogleWrapper = styled.div`
    transition: all 2s ease;
    animation: showGoogleButton 2s 1 ease-in-out forwards;
    @keyframes showGoogleButton {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
`

const loadScript = (src: string, onload: () => void) => {
    const script = document.createElement('script')
    script.async = false
    script.src = src
    const body = document.getElementsByTagName('body')[0]
    script.addEventListener('load', onload)
    body.appendChild(script)
}

const ContainerButton = styled.div`
    position: relative;
    height: 50px;
    width: 300px;
    display: grid;

    @media (max-width: 750px) {
        width: 100%;
        place-content: start;
        padding: 0px 0 0px 25px;
    }
    && div {
        border: none;
        width: 375px !important;
        margin: 0;
        @media (max-width: 750px) {
            width: 225px !important;
        }
    }
`

const Login = () => {
    const authenticatedUser = useSelector((state: AppState) => state.user)
    const history = useHistory()
    const [isScriptLoaded, setIsScriptLoaded] = useState(false)
    const [captchaToken, renderCaptcha] = useCaptcha()
    const divRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (authenticatedUser) {
            history.push('/')
        }
    }, [authenticatedUser, history])

    const handleGoogleButtonClick = useCallback(
        async (idToken: string) => {
            if (!captchaToken) {
                toast.error('Error verifying captcha. Please try again.')
                return
            }

            try {
                await trackPromise(AuthService.signInGoogle(idToken, captchaToken ?? ''), loadingAreas.continueGoogle)
            } catch (error) {
                if (error instanceof ApiError) {
                    error.handleUnknown('An error occurred while signing in with Google.')
                    return
                } else {
                    throw error
                }
            }

            history.push('/')
        },
        [captchaToken, history]
    )

    const handleClick = useCallback(
        async ({ credential: idToken }: CredentialResponse) => {
            if (!idToken) return

            handleGoogleButtonClick(idToken)
        },
        [handleGoogleButtonClick]
    )

    useEffect(() => {
        if (isScriptLoaded || !captchaToken) return undefined

        const initializeGoogle = () => {
            if (!window.google || isScriptLoaded) return

            setIsScriptLoaded(true)
            window.google.accounts.id.initialize({
                client_id: config.getGoogleClientId(),
                callback: handleClick,
            })
            window.google.accounts.id.renderButton(divRef.current!, {
                width: 300,
                shape: 'rectangular',
                text: 'continue_with',
                type: 'standard',
            })
        }

        loadScript('https://accounts.google.com/gsi/client', initializeGoogle)

        return () => {
            window.google?.accounts.id.cancel()
            document.getElementById('google-client-script')?.remove()
        }
    }, [handleClick, isScriptLoaded, setIsScriptLoaded, captchaToken])

    return (
        <Container>
            <Meta title="Login to Admin Dashboard" />
            <Wrapper>
                <img src={LogoImage} alt="Knowunity Logo" width={25} />

                <h2>Login</h2>

                {renderCaptcha()}

                <ContainerButton>
                    <GoogleWrapper ref={divRef} className="google-icon" />
                </ContainerButton>
            </Wrapper>
        </Container>
    )
}

export default Login
