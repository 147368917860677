import React, { useEffect, useRef, useState } from 'react'
import { useField } from '@unform/core'
import SelectElement from './SelectElement'

interface Props<Data> {
    name: string
    placeholder: string
    options: SelectOptionWithLabel<Data>[]
    tooltip?: string
    onUpdate?: (data: Data) => void
    noMargin?: boolean
}

export interface SelectOptionWithLabel<Data> {
    label: string
    identifier: string
    data: Data | null
}

export interface SelectOption<Data> {
    identifier: string
    data: Data | null
}

const FormSelect = <Data,>(props: Props<Data>) => {
    const { name, options } = props
    const selectRef = useRef<HTMLSelectElement>(null)
    const { fieldName, defaultValue, registerField, error } = useField(name)
    const [selectedValue, setSelectedValue] = useState<SelectOption<Data> | null>()

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            path: undefined,
            getValue: (ref: HTMLSelectElement) => {
                if (selectedValue === undefined || selectedValue?.identifier === undefined) {
                    return options[0]
                }
                return selectedValue
            },
            setValue: (ref: HTMLSelectElement, value: SelectOption<Data> | null) => {
                setSelectedValue(value)
            },
        })
    }, [fieldName, registerField, selectedValue, options])

    const handleUpdate = (data: Data) => {
        if (!selectRef.current) return
        const selectedIndex = parseInt(selectRef.current.value)
        const newSelectedValue = options[selectedIndex]
        setSelectedValue(newSelectedValue)
        props.onUpdate && props.onUpdate(data)
    }

    useEffect(() => {
        setSelectedValue(defaultValue)
    }, [setSelectedValue, defaultValue])

    useEffect(() => {
        if (!selectRef.current) return
        if (!selectedValue) return

        const selectedIndex = options?.findIndex((option) => option.identifier === selectedValue.identifier)
        selectRef.current.value = selectedIndex !== undefined && selectedIndex !== -1 ? selectedIndex.toString() : '0'
    }, [options, selectedValue])

    return (
        <SelectElement
            label={props.placeholder}
            defaultValue={defaultValue}
            noMargin={!!props.noMargin}
            onUpdate={handleUpdate}
            errorMessage={error}
            options={options}
            ref={selectRef}
        />
    )
}

export default FormSelect
