import React, { useEffect, useRef, useState } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'
import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import { ApiError } from 'services/ApiService'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { badRequestFormErrors, formatUTCDateForRequest, getFileNameFromFileUrl } from 'utils'
import { ErrorTypes } from 'utils/constants/ErrorTypes'
import { useHistory } from 'react-router-dom'
import Button from 'components/elements/Button'
import { Input, FormSelect, Textarea, SelectOptionWithLabel, SelectOption } from 'components/inputs/Input'
import routes from 'lib/constants/routes'
import { CreateManualPushNotification, ManualPushNotification } from 'interfaces/ManualPushNotification'
import { LanguageInterface } from 'interfaces/Language'
import ManualPushNotificationsService from 'services/ManualPushNotificationsService'
import { useUserTargetingForm } from 'utils/hooks/useUserTargetingForm'
import LanguageService from 'services/LanguageService'
import FileUploadService from 'services/FileUploadService'
import { ContentPrefixes } from 'utils/constants/ContentPrefix'
import UploadWrapper from 'components/FileUpload/UploadWrapper'
import ContentPreview from 'components/modules/ContentPreview/ContentPreview'
import { ContentTypes } from 'interfaces/Know'

interface Props {
    manualPushNotification: ManualPushNotification | null
    isNew: boolean
    updateManualPushNotification: (notification: ManualPushNotification) => void
}

export type FormData = {
    body: string
    clickPath: string
    title: string
    type: string
    language: SelectOption<LanguageInterface>
    scheduledOn: string | null
    sound: string | null
    experimentVariantIdentifier: string | null
}

const loadingAreas = {
    form: 'manualPushNotificationsForm',
    save: 'saveManualPushNotifications',
    thumbnailImageUpload: 'manualPushNotificationThumbnailImageUpload',
}

interface UploadContent {
    filename: string
    url: string
}

const ManualPushNotificationsForm = ({ isNew, manualPushNotification, updateManualPushNotification }: Props) => {
    const { userTargetingId, renderTargetingModal, renderTargetingButton } = useUserTargetingForm(
        manualPushNotification?.userTargetingId,
        isNew
    )
    const [initialData, setInitialData] = useState<FormData>()
    const [languageOptions, setLanguageOptions] = useState<SelectOptionWithLabel<LanguageInterface>[]>()
    const [uploadedThumbnailImage, setUploadedThumbnailImage] = useState<UploadContent | null>(null)
    const formRef = useRef<FormHandles>(null)
    const history = useHistory()

    useEffect(() => {
        trackPromise(
            LanguageService.list(true)
                .then((languages) => {
                    const options = languages.map((lang) => ({
                        label: lang.englishName,
                        identifier: lang.id.toString(),
                        data: lang,
                    }))
                    setLanguageOptions(options)
                })
                .catch((error: ApiError) => {
                    error.handleUnknown('An error occurred while getting languages.')
                }),
            loadingAreas.form
        )
    }, [])

    useEffect(() => {
        if (!manualPushNotification) return

        const initialData: FormData = {
            title: manualPushNotification.title,
            body: manualPushNotification.body,
            clickPath: manualPushNotification.clickPath,
            language: { identifier: manualPushNotification.language.id.toString(), data: manualPushNotification.language },
            type: manualPushNotification.type,
            scheduledOn: manualPushNotification?.scheduledOn ? manualPushNotification.scheduledOn.slice(0, -1) : '',
            sound: manualPushNotification.sound,
            experimentVariantIdentifier: manualPushNotification.experimentVariantIdentifier,
        }
        setInitialData(initialData)

        if (manualPushNotification.thumbnailImageUrl) {
            setUploadedThumbnailImage({
                filename: getFileNameFromFileUrl(manualPushNotification.thumbnailImageUrl),
                url: manualPushNotification.thumbnailImageUrl,
            })
        }
    }, [manualPushNotification, userTargetingId])

    const handleSubmit: SubmitHandler<FormData> = (data) => {
        if (!manualPushNotification && !isNew) return
        formRef.current!.setErrors({})

        if (!userTargetingId) {
            toast.error('You need to add User Targeting!')
            return
        }

        if (data.scheduledOn && data.scheduledOn?.length > 0 && new Date() > new Date(data.scheduledOn)) {
            formRef.current!.setFieldError('scheduledOn', 'Schedule date has to be in the future.')
            return
        }

        const requestBody: CreateManualPushNotification = {
            languageId: data.language.data.id,
            userTargetingId,
            body: data.body,
            clickPath: data.clickPath,
            title: data.title,
            type: data.type,
            thumbnailImageFileName: uploadedThumbnailImage ? uploadedThumbnailImage.filename : null,
            scheduledOn: data.scheduledOn ? formatUTCDateForRequest(data.scheduledOn) : null,
            sound: data.sound,
            experimentVariantIdentifier: data.experimentVariantIdentifier,
        }

        let handleFunc
        if (isNew) {
            handleFunc = ManualPushNotificationsService.create(requestBody)
        } else {
            handleFunc = ManualPushNotificationsService.update(manualPushNotification!.uuid, requestBody)
        }

        trackPromise(
            handleFunc
                .then((notification) => {
                    updateManualPushNotification(notification)
                    toast.success('The changes to Push Notifications have been saved!')
                    history.push(routes.manualPushNotificationRoute(notification.uuid))
                })
                .catch((error: ApiError) => {
                    if (error.type === ErrorTypes.FormValidation) {
                        badRequestFormErrors(error, formRef.current!)
                    } else {
                        error.handleUnknown('An error occurred while saving push notification.')
                    }
                }),
            loadingAreas.save
        )
    }

    const allowedFileFormats = ['image/png', 'image/jpg', 'image/jpeg', 'video/mp4', 'image/gif']

    const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const innerFile = event.target.files![0]
        if (!innerFile) {
            toast.error('You need to upload an asset image!')
            return
        }
        const maxFileSize = 25 * 1000000

        if (!allowedFileFormats.includes(innerFile.type)) {
            toast.error('Please upload your file as an image!')
            return
        }

        if (innerFile.size > maxFileSize) {
            toast.error('Sorry, the file is too big. The maximum file size is 25 MB.')
            return
        }

        trackPromise(
            FileUploadService.upload(innerFile, ContentPrefixes.ManualPushNotificationThumbnailImage)
                .then(({ url, filename }) => {
                    setUploadedThumbnailImage({
                        url,
                        filename,
                    })
                })
                .catch((error) => {
                    if (error instanceof ApiError) {
                        if (error.type === ErrorTypes.InvalidMIMEType) {
                            toast.error('Please choose a different file format.')
                        } else {
                            error.handleUnknown('An error occurred while uploading the image')
                        }
                    } else {
                        throw error
                    }
                }),
            loadingAreas.thumbnailImageUpload
        )
    }

    return (
        <div>
            <h3>{isNew ? 'Create new' : 'Update'} Push Notification</h3>
            <Form ref={formRef} initialData={initialData} onSubmit={handleSubmit}>
                <Input name="title" placeholder="Title" />
                <Textarea name="body" placeholder="Body" />
                <Input name="type" placeholder="Type" />
                <Input name="clickPath" placeholder="Click path" />
                <Input name="scheduledOn" placeholder="Scheduled on in UTC time (Optional)" type="datetime-local" />
                <>
                    <label>Thumbnail Image</label>
                    <UploadWrapper
                        handleFileUpload={onChangeFile}
                        area={loadingAreas.thumbnailImageUpload}
                        acceptRules={allowedFileFormats.join(', ')}
                    >
                        <p>Click here to select an image or video.</p>
                        <ContentPreview contentType={ContentTypes.image} previewUrl={uploadedThumbnailImage?.url ?? ''} />
                    </UploadWrapper>
                </>
                {renderTargetingButton()}
                {languageOptions && <FormSelect name="language" options={languageOptions} placeholder="Language" />}
                <Input name="sound" placeholder="Sound (Optional)" type="text" />
                <Input
                    name="experimentVariantIdentifier"
                    placeholder="Experiment Variant Identifier (Optional)"
                    type="text"
                />
                <Button fullWidth icon={<SaveIcon />} loadingArea={loadingAreas.save}>
                    {isNew ? 'Save' : 'Update'}
                </Button>
            </Form>
            {renderTargetingModal()}
        </div>
    )
}

export default ManualPushNotificationsForm
