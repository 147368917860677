export const subjectIconUrl = (iconName: string) => `https://static.knowunity.com/subjects/${iconName}`

export const feedbackDialogResult = (uuid: string) =>
    `https://metabase.knowunity.de/dashboard/327-instant-feedback?feedback_uuid=${uuid}`

export const tiktokAccount = (username: string) => `https://www.tiktok.com/@${username}`

export const instagramAccount = (username: string) => `https://www.instagram.com/${username}`

export enum URLs {
    automaticKnowDemandsGuidelines = `https://docs.google.com/document/d/15u6NLxFYmclRAktU4d_MPNTdY4KBZ94DK0BNgJoxkHw/edit?usp=sharing`,
}
