import React from 'react'
import Tabs from 'components/modules/Tabs'
import AdCustomers from './AdCustomers'
import AdListAssets from './AdListAssets'
import Meta from 'components/modules/Head'

const AdSystem = () => {
    return (
        <section>
            <Meta title="Ad System" />
            <Tabs>
                <div data-label="Customers">
                    <AdCustomers />
                </div>
                <div data-label="Current Assets">
                    <AdListAssets />
                </div>
            </Tabs>
        </section>
    )
}

export default AdSystem
