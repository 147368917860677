import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { useToggleState } from 'utils/hooks/useToggleState'
import { ReactComponent as Arrow } from 'assets/icons/chefron-left.svg'
import theme from 'lib/constants/theme'

const Container = styled.div<{ isOpen: boolean }>`
    background: ${theme.colors.darkViolet};
    border-radius: 10px;
    border: 1px solid var(--primary-color);
    height: ${({ isOpen }) => (isOpen ? 'auto' : 'fit-content')};
    * {
        word-break: break-all;
    }
`

const Header = styled.div<{ isOpen: boolean }>`
    display: flex;
    justify-content: space-between;
    grid-gap: 10px;
    background: ${theme.colors.lightViolet};
    border-radius: ${theme.borderRadius.large} ${theme.borderRadius.large}
        ${({ isOpen }) => (isOpen ? `${theme.borderRadius.large} ${theme.borderRadius.large}` : '0 0')};
    border-bottom: ${({ isOpen }) => (isOpen ? '1px solid var(--primary-color)' : 'none')};
    cursor: pointer;

    padding: 15px;

    & svg {
        transition: transform 0.4s ease-in-out;
    }
`

const Card = styled.div`
    border-radius: 10px;
`

const Main = styled.div`
    padding: 15px;
`

const ArrowIcon = styled(Arrow)`
    fill: ${theme.colors.body1Black};
    width: 30px;
    height: 30px;
`

interface Props {
    openByDefault: boolean
    title: string | ReactNode
    children?: ReactNode
}

const CollapsibleCard = ({ title, openByDefault, children }: Props) => {
    const [open, toggleOpen] = useToggleState(openByDefault ?? false)

    return (
        <Container isOpen={open}>
            <Card>
                <Header onClick={toggleOpen} isOpen={open}>
                    {typeof title === 'string' ? <h3>{title}</h3> : title}
                    <ArrowIcon
                        style={{
                            transform: `rotate(${open ? '90deg' : '-90deg'})`,
                        }}
                    />
                </Header>
                <Main hidden={!open}>{children}</Main>
            </Card>
        </Container>
    )
}

export default CollapsibleCard
