import React from 'react'
import styled from 'styled-components'

const ColorCircle = styled.span<{ color: string }>`
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: inline-block;
    margin-right: 10px;
    background-color: ${({ color }) => color};
`

export const getColorIndicator = (color: string) => (
    <span>
        <ColorCircle color={color} /> {color}
    </span>
)
