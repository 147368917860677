import ApiService from './ApiService'
import { Grade } from 'interfaces/Grade'

const GradeService = {
    listGradesBySchoolType(schoolTypeId: number) {
        return ApiService.get(`/schooltypes/${schoolTypeId}/grades`).then((response) => {
            return response.data.content as Grade[]
        })
    },

    get(id: number) {
        return ApiService.get(`/grades/${id}/admin`).then((response) => {
            return response.data as Grade
        })
    },
}

export default GradeService
