import Search from 'components/elements/Search'
import React from 'react'
import styled from 'styled-components'

const TopSearchBarContainer = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    margin-top: -15px;
    padding-bottom: 10px;
    @media (max-width: 1050px) {
        grid-template-columns: 1fr 650px 1fr;
        padding: 0 15px;
        margin: 0;
    }
    @media (max-width: 750px) {
        grid-template-columns: 1fr auto 1fr;
    }
`

interface Props {
    onSearch: (query: string) => void
    onDebounce: (query: string) => void
}

const TopSearchBar = ({ onSearch, onDebounce }: Props) => {
    return (
        <TopSearchBarContainer>
            <span />
            <Search
                label="⠀"
                onSearch={onSearch}
                fullWidth
                placeholder="Search by section title..."
                onDebounce={onDebounce}
            />
            <span />
        </TopSearchBarContainer>
    )
}

export default TopSearchBar
