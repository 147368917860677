import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'
import { Subtitle1 } from 'components/elements/Text'
import { FormGroup, InputErrorMessage } from 'components/inputs/Input'
import theme from 'lib/constants/theme'
import styled from 'styled-components'

const Wrapper = styled.div`
    position: relative;

    & .fake-input {
        min-height: 52px;
        user-select: none;
        padding: 12px 20px;

        p {
            margin-bottom: 0px;
            padding: 0;
        }
        .placeholder {
            font-size: 18px;
        }
    }
`

const Text = styled.p`
    && {
        color: ${theme.colors.white};
    }
`

const Placeholder = styled.p`
    && {
        color: ${theme.colors.placeholderGray};
    }
`

interface Props<T, E> {
    name: string
    placeholder: string
    label?: string
    noMargin?: boolean

    value: T
    onClick(): void
    onUpdate(value: E): void
    selectedPreview: string
}

const GenericFormElement = <T, E>(props: Props<T, E>) => {
    const fakeInputRef = useRef<HTMLDivElement>(null)
    const { fieldName, defaultValue, registerField, error } = useField(props.name)

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: undefined,
            path: undefined,
            getValue: () => {
                return props.value
            },
        })
    }, [fieldName, registerField, props.value, props.onUpdate])

    useEffect(() => {
        if (defaultValue) {
            props.onUpdate(defaultValue)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue])

    return (
        <FormGroup noMargin>
            <label>
                <Subtitle1 fontWeigth={500} color={theme.colors.white}>
                    {props.label}
                </Subtitle1>
            </label>
            <Wrapper>
                <div ref={fakeInputRef} className="fake-input" tabIndex={0} onClick={props.onClick} data-id={props.name}>
                    {props.selectedPreview ? (
                        <Text>{props.selectedPreview}</Text>
                    ) : (
                        <Placeholder>{props.placeholder}</Placeholder>
                    )}
                </div>
            </Wrapper>

            <InputErrorMessage>{error}</InputErrorMessage>
        </FormGroup>
    )
}

export default GenericFormElement
