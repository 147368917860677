import React, { useCallback, useState } from 'react'
import { ButtonLink } from 'components/elements/Button'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { ApiError } from 'services/ApiService'
import { trackPromise } from 'react-promise-tracker'
import RefactoredTable from 'components/modules/RefactoredTable'
import FeedbackDialogService from './FeedbackDialogService'
import routes from 'lib/constants/routes'
import { ControlWrapper, FilterFormGrid } from 'style'
import SelectElement from 'components/inputs/SelectElement'
import Search from 'components/elements/Search'
import Meta from 'components/modules/Head'
import FeedbackDialogTargetingCountry from './FeedbackDialogTargetingCountry'

const loadingAreas = {
    container: 'feedbackDialogsContainer',
}

const FeedbackDialogs = () => {
    const [searchQuery, setSearchQuery] = useState<string>()
    const [isActive, setIsActive] = useState<boolean | null>(null)

    const handleUpdateActiveFilter = (filter: boolean) => setIsActive(filter)

    const activeType = [
        {
            label: 'All',
            data: null,
        },
        {
            label: 'Active',
            data: true,
        },
        {
            label: 'Not active',
            data: false,
        },
    ]

    const fetchData = useCallback(
        (page: number) => {
            return trackPromise(
                FeedbackDialogService.list(page, isActive, searchQuery)
                    .then((data) => {
                        if (page !== data.page) return

                        return {
                            totalPages: data.totalPages,
                            totalElements: data.totalElements,
                            elements: data.dialogs.map((d) => ({
                                id: d.uuid,
                                columns: [
                                    d.uuid,
                                    d.title,
                                    d.identifier,
                                    d.actionsUntilTriggered,
                                    d.isActive ? 'Yes' : 'No',
                                    d?.answerOptions?.length ?? 0,
                                    <FeedbackDialogTargetingCountry
                                        userTargetingId={d.userTargetingId}
                                        key={`targeting-${d.uuid}`}
                                    />,
                                ],
                                onClick: routes.feedbackDialogRoute(d.uuid),
                            })),
                        }
                    })
                    .catch((error) => {
                        if (error instanceof ApiError) {
                            error.handleUnknown('An error occurred while getting feedback dialogs.')
                        } else {
                            throw error
                        }
                    }),
                loadingAreas.container
            )
        },
        [isActive, searchQuery]
    )

    return (
        <section>
            <Meta title="Feedback dialogs" />
            <h2>Feedback dialogs</h2>

            <FilterFormGrid gridAmount={3}>
                <ControlWrapper>
                    <ButtonLink to={routes.feedbackDialogCreateRoute} fullWidth={false} icon={<AddIcon />}>
                        Create Feedback Dialog
                    </ButtonLink>
                </ControlWrapper>
                <SelectElement label="Filter" noMargin options={activeType} onUpdate={handleUpdateActiveFilter} />
                <Search onSearch={setSearchQuery} fullWidth label="Search by title" />
            </FilterFormGrid>

            <RefactoredTable
                columns={[
                    '#',
                    'Title',
                    'Identifier',
                    'Actions until triggered',
                    'Active',
                    'Answer count',
                    'Targeted countries',
                ]}
                loadData={fetchData}
                loadingArea={loadingAreas.container}
                refetchKey={`${isActive}`}
            />
        </section>
    )
}

export default FeedbackDialogs
