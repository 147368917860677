import React from 'react'
import { ReactComponent as BackIcon } from 'assets/icons/chefron-left.svg'
import { ControlsWrapper, ControlWrapper } from 'style'
import { ButtonLink } from './Button'

interface Props {
    routeName: string
    route: string
}

const GoBackButton = ({ routeName, route }: Props) => {
    return (
        <ControlsWrapper>
            <ControlWrapper>
                <ButtonLink to={route} fullWidth={false} icon={<BackIcon />}>
                    {`Go back to ${routeName}`}
                </ButtonLink>
            </ControlWrapper>
        </ControlsWrapper>
    )
}

export default GoBackButton
