import React, { FunctionComponent } from 'react'
import { usePromiseTracker } from 'react-promise-tracker'
import Spinner from 'components/elements/Spinner'

interface Props {
    area?: string
}

const LoadingArea: FunctionComponent<Props> = (props) => {
    const { promiseInProgress } = usePromiseTracker({ area: props.area })

    if (promiseInProgress) {
        return <Spinner area={props.area} />
    } else {
        return <>{props.children}</>
    }
}

export default LoadingArea
