import Box from 'components/elements/Box'
import theme from 'lib/constants/theme'
import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    padding-bottom: 100px;
    .wrapper {
        padding: 20px;
    }
`

export const Grid = styled.div`
    display: grid;
    grid-template-columns: 20% 60% 20%;
    grid-gap: 10px;
`

export const MetaBox = styled(Box)`
    position: relative;
    & .edit {
        position: absolute;
        top: 20px;
        right: 20px;
        fill: white;
        cursor: pointer;
    }

    & svg:hover {
        fill: var(--primary-color);
    }
`

export const RatingMessage = styled.p`
    color: ${theme.colors.knowunityBlue};
    font-weight: 700;
`

export const Label = styled.span<{ isGreen?: boolean }>`
    padding: 3px 5px;
    border: 2px solid ${({ isGreen }) => (isGreen ? theme.colors.forestGreen : theme.colors.secondaryRedDarker)};
    border-radius: ${theme.borderRadius.normal};
    color: ${({ isGreen }) => (isGreen ? theme.colors.forestGreen : theme.colors.secondaryRedDarker)};
    font-weight: 700;
`

export const InfoWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;
    word-break: break-word;
`
